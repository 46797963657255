import { useContext } from 'react';
import { EditorContext } from '~/contexts/EditorContext';
import {
    MultiPageImageModel,
    MultiPageVideoModel,
    UndoRedoManager,
    ValidationManager,
} from '@bynder-studio/render-core';
import {
    creativeModelFactory,
    ImageManipulationRenderer,
    PlaybackManipulationRenderer,
} from '@bynder-studio/render-web';
import { CreativeAccessType, CreativeType } from 'packages/types/creative';
import { Reviewer } from 'packages/hooks/useVirtualProperties';

type EditorData = {
    loading: boolean;
    creativeModelFactory: ReturnType<typeof creativeModelFactory>;
    creativeModel: MultiPageVideoModel | MultiPageImageModel;
    width: number;
    height: number;
    format: string | null;
    initManipulationRenderer: (canvasWrapper, currentFrame) => void;
    manipulationRenderer: PlaybackManipulationRenderer | ImageManipulationRenderer;
    validationManager: ValidationManager;
    undoRedoManager: UndoRedoManager;
    type: CreativeAccessType;
    creativeType: CreativeType;
    exportIntegrations: any;
    toggleExportIntegration: (exportName) => void;
    changeExportValue: (newValue) => void;
    approvalEnabled: boolean;
    setApprovalEnabled: (enabled: boolean) => void;
    reviewers: Reviewer[];
    addReviewer: (reviewer: Reviewer) => void;
    removeReviewer: (reviewer: Reviewer) => void;
    canUndo: boolean;
    globalsTab: 'Page' | 'Design';
    setGlobalsTab: (tab: 'Page' | 'Design') => void;
    isPublishing: boolean;
    setIsPublishing: (isPublishing: boolean) => void;
    guideManager: undefined;
    assetLoader: undefined;
    frameRate: number;
};

export default function useEditor() {
    return useContext(EditorContext) as EditorData;
}
