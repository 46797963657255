import { useCallback, useState } from 'react';
import { TextRenderer, TextElement, MultiPageImageModel, MultiPageVideoModel } from '@bynder-studio/render-core';

export const useOversetValidation = ({
    creativeModel,
}: {
    creativeModel?: MultiPageVideoModel | MultiPageImageModel;
}) => {
    const [oversetVariationsSet, setOversetVariationsSet] = useState(new Set());

    const updateVariationOverset = ({ isValid, currentVariation }: { isValid: boolean; currentVariation: string }) => {
        if (!currentVariation) {
            return;
        }

        setOversetVariationsSet((prev) => {
            const temp = new Set(prev);
            isValid ? temp.delete(currentVariation) : temp.add(currentVariation);
            return temp;
        });
    };

    const validateVariation = useCallback(
        (variation: any, textRenderer = TextRenderer.getInstance()) => {
            return creativeModel.getModelsMetaData()?.some(({ id: pageId }, pageIndex) => {
                const { elements } = variation?.sizes[pageId];
                const pageModel = creativeModel.getModels()[pageIndex];

                return Object.entries(elements).some(([elId, value]) => {
                    const { formattedText } = value as any;

                    if (!formattedText) {
                        return false;
                    }

                    const element = pageModel.getElementById(Number(elId)) as TextElement;
                    if (element && !element?.limitTextToBounds) {
                        return false;
                    }

                    const {
                        dimension: { width, height },
                        contentTransform,
                        textControl,
                        minFontScale,
                        fontScale,
                    } = element;
                    const config = {
                        boxWidth: width,
                        boxHeight: height,
                        placement: textControl,
                        horizontalAlignment: contentTransform?.horizontalAlignment,
                        verticalAlignment: contentTransform?.verticalAlignment,
                        textStruct: formattedText,
                        minFontScale,
                        fontScale,
                    };

                    const boundedText = textRenderer.createBoundedText(formattedText, config);
                    if (
                        boundedText.getShapedText().getContentHeight() > height ||
                        boundedText.getShapedText().getContentWidth() > width
                    ) {
                        return true;
                    }
                });
            });
        },
        [creativeModel],
    );

    const updateAllVariationsOverset = useCallback(
        (variations: any[]) => {
            const textRenderer = TextRenderer.getInstance();
            const tempOversetVariations = new Set();

            variations.forEach((variation) => {
                const hasOversetText = validateVariation(variation, textRenderer);
                if (hasOversetText) {
                    tempOversetVariations.add(variation.variationSetId);
                }
            });

            setOversetVariationsSet(tempOversetVariations);
        },
        [validateVariation],
    );

    return {
        oversetVariationsSet,
        setOversetVariationsSet,
        validateVariation,
        updateVariationOverset,
        updateAllVariationsOverset,
    };
};
