import type {
    IVariationThumbnailRenderer,
    RenderEndListener,
    RenderStartListener,
} from './IVariationThumbnailRenderer';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import { type BaseMultiPageModel } from '../../Models/Models/BaseMultiPageModel';
import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompositor } from '../../Compositor/ICompositor';
import { Dimension } from '../../Models/Shared/Dimension';
import type { IAsset } from '../../Models/Assets/IAsset';
import type { ElementUpdatedEventData, EventData } from '../../event-types';
import type { BaseModel } from '../../Models/Models/BaseModel';
import { getElementType } from '../../Helpers/elementUtils';
import { ElementTypes } from '../../Enums/ElementTypes';
import type { VideoElement } from '../../Models/Elements/VideoElement';
import { VideoModel } from '../../Models/Models/VideoModel';
import type { VideoAsset } from '../../Models/Assets/VideoAsset';
import { frameIndexToHTMLSeekTime } from '../../Helpers/framesToTime';
import { type ModelMetadata } from '../../types';

export class VariationThumbnailRenderer implements IVariationThumbnailRenderer {
    private eventEmitter: DynamicEventEmitter = new DynamicEventEmitter();

    private multiPageModels: Map<string, BaseMultiPageModel> = new Map();

    private assetLoader: IAssetsLoader;

    private compositor: ICompositor;

    private thumbnailDimension: Dimension;

    private renderingQueue: { [key: string]: number | any } = {};

    private seekFrame = -1;

    private isSeeking = false;

    private seekWaitingCallbacks: Function[] = [];

    private currentPage = 0;

    private assetLoadListener = ({ asset }: { asset: IAsset }) => {
        this.multiPageModels.forEach((multiPageModel, variationId) => {
            const elements = multiPageModel.getElementsByAssetUsage(asset);

            // can't find element for AUDIO
            if (!elements.length) {
                return;
            }

            multiPageModel.getModels().forEach((model, pageIndex) => {
                if (pageIndex !== this.currentPage) {
                    return;
                }

                const { frameIndex } = this.getFrameInfo(model);
                elements.forEach((element) => {
                    if (frameIndex >= element.startFrame && frameIndex <= element.startFrame + element.duration - 1) {
                        return this.render(variationId, pageIndex);
                    }
                });
            });
        });
    };

    private elementUpdatedListener =
        (variationId: string) =>
        ({ element, pageIndex = -1 }: ElementUpdatedEventData & EventData) => {
            this.eventEmitter.emit('pageChanged', { variationId, pageIndex });

            if (pageIndex !== this.currentPage) {
                return;
            }

            this.multiPageModels.forEach((multiPageModel, variationId) => {
                const { frameIndex } = this.getFrameInfo(multiPageModel.getModels()[pageIndex]);

                if (frameIndex >= element.startFrame && frameIndex <= element.startFrame + element.duration - 1) {
                    return this.render(variationId, pageIndex);
                }
            });
        };

    private backgroundColorUpdatedListener =
        (variationId: string) =>
        ({ pageIndex = -1 }) => {
            this.eventEmitter.emit('pageChanged', { variationId, pageIndex });

            if (pageIndex !== this.currentPage) {
                return;
            }

            this.multiPageModels.forEach((_, variationId) => {
                return this.render(variationId, pageIndex);
            });
        };

    constructor(assetLoader: IAssetsLoader, compositor: ICompositor) {
        this.thumbnailDimension = new Dimension(64, 64);
        this.assetLoader = assetLoader;
        this.compositor = compositor;

        this.assetLoader.eventEmitter.on('asset.load', this.assetLoadListener);
    }

    getVariationIds(): Set<string> {
        return new Set([...this.multiPageModels.keys()]);
    }

    setCurrentPage(pageIndex: number): void {
        this.currentPage = pageIndex;
    }

    addModel(variationId: string, multiPageModel: BaseMultiPageModel) {
        multiPageModel.eventEmitter.on('elementUpdated', this.elementUpdatedListener(variationId));
        multiPageModel.eventEmitter.on('backgroundColorUpdated', this.backgroundColorUpdatedListener(variationId));

        multiPageModel.getModels().forEach((model, pageIndex) => {
            model.eventEmitter.on('backgroundColorUpdated', (params) => {
                this.backgroundColorUpdatedListener(variationId)({
                    ...params,
                    pageIndex,
                });
            });
        });

        multiPageModel.getModels().forEach((model, pageIndex) => {
            model.eventEmitter.on('elementUpdated', (params) => {
                this.elementUpdatedListener(variationId)({
                    ...params,
                    pageIndex,
                });
            });
        });

        this.multiPageModels.set(variationId, multiPageModel);
        this.render(variationId, this.currentPage);
    }

    getModel(variationId: string, pageId: string) {
        const multiPageModel = this.multiPageModels.get(variationId);

        if (!multiPageModel) {
            return;
        }

        return multiPageModel.getModelByPageId(pageId);
    }

    removeModel(variationId: string) {
        const multiPageModel = this.multiPageModels.get(variationId);

        if (!multiPageModel) {
            return;
        }

        this.multiPageModels.delete(variationId);
    }

    swapModel(oldVariationId: string, newVariationId: string) {
        const multiPageModel = this.multiPageModels.get(oldVariationId);

        if (!multiPageModel) {
            return;
        }

        this.removeModel(oldVariationId);
        this.addModel(newVariationId, multiPageModel);
    }

    setThumbnailSize(width: number, height: number): void {
        this.thumbnailDimension = new Dimension(width, height);
    }

    getThumbnailSize(): Dimension {
        return new Dimension(this.thumbnailDimension.getWidth(), this.thumbnailDimension.getHeight());
    }

    public onRenderStart(listener: RenderStartListener) {
        this.eventEmitter.on('renderStart', listener);

        return () => {
            this.eventEmitter.off('renderStart', listener);
        };
    }

    public onRenderEnd(listener: RenderEndListener) {
        this.eventEmitter.on('renderEnd', listener);

        return () => {
            this.eventEmitter.off('renderEnd', listener);
        };
    }

    public onPageChanged(listener: RenderEndListener) {
        this.eventEmitter.on('pageChanged', listener);

        return () => {
            this.eventEmitter.off('pageChanged', listener);
        };
    }

    public render(variationId: string, pageIndex = -1, width?: number, height?: number) {
        const multiPageModel = this.multiPageModels.get(variationId);

        if (!multiPageModel || pageIndex === -1) {
            return;
        }

        const models = multiPageModel.getModels();
        const modelsMetaData = multiPageModel.getModelsMetaData();

        const mode = models[pageIndex];
        const metaData = modelsMetaData[pageIndex];

        if (!mode || !metaData) {
            return;
        }

        this.renderPageThumbnail(
            mode,
            metaData,
            variationId,
            pageIndex,
            width || this.thumbnailDimension.getWidth(),
            height || this.thumbnailDimension.getHeight(),
        );
    }

    private async renderPageThumbnail(
        model: BaseModel,
        metaData: ModelMetadata,
        variationId: string,
        pageIndex: number,
        width: number,
        height: number,
    ) {
        const { frameRate, frameIndex } = this.getFrameInfo(model);
        const queueKey = `${variationId}-${pageIndex}`;

        const videoElements = model
            .getAllElementsRecursively()
            .map((el) => {
                if (
                    frameIndex >= el.startFrame &&
                    frameIndex <= el.startFrame + el.duration - 1 &&
                    getElementType(el) === ElementTypes.VIDEO
                ) {
                    return el as VideoElement;
                }

                return null;
            })
            .filter((el) => el !== null);

        if (this.seekFrame === frameIndex && this.isSeeking) {
            await new Promise((resolve) => this.seekWaitingCallbacks.push(resolve));
        } else if (videoElements.length) {
            this.seekFrame = frameIndex;
            this.isSeeking = true;
            await Promise.all(videoElements.map((el) => this.seekVideoElement(el, frameIndex, frameRate)));
            this.isSeeking = false;
            this.seekWaitingCallbacks.reverse().forEach((cb) => cb());
            this.seekWaitingCallbacks = [];
        }

        if (this.renderingQueue[queueKey]) {
            clearTimeout(this.renderingQueue[queueKey]);
        }

        if (!this.renderingQueue[queueKey]) {
            this.eventEmitter.emit('renderStart', {
                variationId,
                pageIndex,
                metaData,
                width,
                height,
            });
        }

        this.renderingQueue[queueKey] = setTimeout(() => {
            this.renderingQueue[queueKey] = 0;
            const compModel = model.getCompModel(frameIndex);
            compModel.setElements(model.getVisibleElements(frameIndex));
            this.calculateAndSetScale(model, width, height);
            this.compositor.drawCompModel(compModel);
            const dataUrl = this.compositor.getDataUrl();

            this.eventEmitter.emit('renderEnd', {
                variationId,
                pageIndex,
                metaData,
                width,
                height,
                dimension: this.compositor.getCompositorDimension(),
                dataUrl,
            });
        });
    }

    private getFrameInfo(pageModel: BaseModel) {
        let frameRate = 25;
        let frameIndex = 0;

        if (pageModel instanceof VideoModel) {
            frameRate = pageModel.getPlaybackDuration().getFrameRate();
            frameIndex = pageModel.getPosterFrame().frame;
        }

        return { frameRate, frameIndex };
    }

    private async seekVideoElement(el: VideoElement, frameIndex: number, frameRate: number, attempt = 0) {
        const loadedVideoAsset = this.assetLoader.getAsset(el.generateAssetId()) as VideoAsset;

        if (!loadedVideoAsset || !loadedVideoAsset.object) {
            return;
        }

        const seekTime = frameIndexToHTMLSeekTime(frameIndex, frameRate);
        const key = parseFloat(seekTime.toFixed(2));

        if (!loadedVideoAsset.frameBuffer.has(key)) {
            const isBufferCreated = await loadedVideoAsset.createFrameBuffer(frameIndex);

            if (!isBufferCreated && attempt < 256) {
                await this.seekVideoElement(el, frameIndex, frameRate, attempt + 1);
            }
        }
    }

    private calculateAndSetScale(mode: BaseModel, width: number, height: number) {
        // current shot wrapper page
        const kBox = width / height;
        // template page
        const templateDimension = mode.getDimensions();
        const kTemplate = templateDimension.getWidth() / templateDimension.getHeight();
        let scale = 1;
        let newDimension;

        if (kTemplate < kBox) {
            const newWidth = kTemplate * height;
            newDimension = new Dimension(newWidth, height);
            scale = newWidth / templateDimension.getWidth();
        } else {
            const newHeight = width / kTemplate;
            newDimension = new Dimension(width, newHeight);
            scale = width / templateDimension.getWidth();
        }

        this.compositor.setCompositorDimension(newDimension);
        this.compositor.setScale(scale);
    }
}
