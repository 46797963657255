import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class TemplatesServiceFromXML extends ProxyServerConnector {
    constructor(path = '/templates') {
        super(path);
    }

    uploadTemplate(formData: FormData) {
        return this.post(`/xml/convert`, formData);
    }
}

export default new TemplatesServiceFromXML();
