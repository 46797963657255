import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class BynderIdentitiesService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/bynder/identity') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    fetchIdentities(query: string) {
        return this.gateway.get(`?query=${encodeURIComponent(query)}`);
    }
}

export default new BynderIdentitiesService();
