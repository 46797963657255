import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { BaseProperty } from './BaseProperty';
import { type PosterFrameParams } from '../../types';

export class PosterFrame extends BaseProperty {
    frame!: number;

    constructor(params: PosterFrameParams) {
        super();
        this.setProperties(params);
    }

    update(params: PosterFrameParams): Set<ElementUpdateTypes> {
        return this.setProperties(params);
    }

    setProperties(params: PosterFrameParams): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.frame !== undefined) {
            this.frame = params.frame;
        }

        return updateTypes;
    }

    getValuesByUpcomingUpdate(data: Partial<PosterFrameParams>): Partial<PosterFrameParams> {
        return super.getValuesByUpcomingUpdate(data);
    }

    toObject() {
        const baseObject = super.toObject();
        return { ...baseObject, frame: this.frame };
    }
}
