import { useCallback, useSyncExternalStore } from 'react';
import { ElementUpdateTypes, type VisualElement } from '@bynder-studio/render-core';
import useElement from './useElement';

export default function useDesignElementContentTransform(id: number) {
    const { element, model } = useElement(id);

    const subscribe = useCallback(
        (onUpdate: () => void) => {
            if (!model || !id) {
                return () => {};
            }

            return model.onElementPartialUpdate(id, ElementUpdateTypes.CONTENT_TRANSFORM, onUpdate);
        },
        [model, id],
    );

    const getSnapshot = useCallback(() => {
        const el = element as VisualElement;

        return 'contentTransform' in el ? el.contentTransform : null;
    }, [element]);

    return useSyncExternalStore(subscribe, getSnapshot);
}
