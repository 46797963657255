import { Template } from '../templates/types';

export type ImportedTemplate = Omit<Template, 'videosCount'> & { videoElementCount: number; correctFile: boolean };

export type DesignFileAnalysis = DesignFileAnalysisGlobal | DesignFileAnalysisLocal;

type DesignFileAnalysisBase = {
    type: 'IMAGE';
    fileSize: number;
    designCreationResponses: {
        type: string;
        width: number;
        height: number;
        name: string;
        elements: any;
    }[];
};

export type DesignFileAnalysisLocal = DesignFileAnalysisBase & {
    extension: string;
    fileName: string;
    sourceType: 'INTERNAL';
    fileUrl: string;
    storagePath: string;
};

export type DesignFileAnalysisGlobal = DesignFileAnalysisBase & {
    name: string;
    extensions: string[];
    databaseId: string;
};

export enum ImportType {
    DESIGN_FILE = 'DESIGN_FILE',
    XML = 'XML',
}

export type MultiDesignMode = {
    multiDesignMode: boolean;
};

export type ImportTemplateState = {
    type?: ImportType;
    loading: boolean;
    progress: number;
    requestTime?: number;
    template?: ImportedTemplate;
    errors?: any;
    validationErrors: string[];
    cancelImportRequest?: boolean;
    designFileAnalysis?: DesignFileAnalysis;
    addingInProgress: boolean;
    multiDesignMode: boolean;
};

export enum actionTypes {
    IMPORT_TEMPLATE_REQUEST = 'IMPORT_TEMPLATE_REQUEST',
    IMPORT_TEMPLATE_SUCCESS = 'IMPORT_TEMPLATE_SUCCESS',
    IMPORT_TEMPLATE_DESIGN_FILE_PROGRESS = 'IMPORT_TEMPLATE_DESIGN_FILE_PROGRESS',
    IMPORT_TEMPLATE_DESIGN_FILE_SUCCESS = 'IMPORT_TEMPLATE_DESIGN_FILE_SUCCESS',
    IMPORT_TEMPLATE_FAILURE = 'IMPORT_TEMPLATE_FAILURE',
    DROP_IMPORTED_TEMPLATE = 'DROP_IMPORTED_TEMPLATE',
    IMPORT_TEMPLATE_CREATE_DESIGNS_REQUEST = 'IMPORT_TEMPLATE_CREATE_DESIGNS_REQUEST',
    IMPORT_TEMPLATE_CREATE_DESIGNS_SUCCESS = 'IMPORT_TEMPLATE_CREATE_DESIGNS_SUCCESS',
    IMPORT_TEMPLATE_CREATE_DESIGNS_FAILURE = 'IMPORT_TEMPLATE_CREATE_DESIGNS_FAILURE',
    IMPORT_TEMPLATE_SET_MULTIDESIGN_MODE = 'IMPORT_TEMPLATE_SET_MULTIDESIGN_MODE',
}

export const MAX_INPUT_SIZE = 120;
export const errorKeys = {
    SERVER_ERROR: 'SERVER_ERROR',
    EMPTY_NAME: 'EMPTY_NAME',
    TOO_LONG_NAME: 'TOO_LONG_NAME',
    WRONG_TYPE: 'WRONG_TYPE',
    MIN_DURATION: 'MIN_DURATION',
    MAX_DURATION: 'MAX_DURATION',
    MIN_SIZE: 'MIN_SIZE',
    MAX_SIZE: 'MAX_SIZE',
    MAX_ASSETS: 'MAX_ASSETS',
    MIN_RESOLUTION: 'MIN_RESOLUTION',
    MAX_RESOLUTION: 'MAX_RESOLUTION',
    ODD_RESOLUTION: 'ODD_RESOLUTION',
    NOT_ACCEPTABLE: 'NOT_ACCEPTABLE',
    FAILED: 'FAILED',
};
