import type { Image } from 'canvaskit-wasm';
import { sleep } from '../../Helpers/sleep';
import { Dimension } from '../Shared/Dimension';
import { BaseAsset, type BaseAssetParams } from './BaseAsset';
import { MediaController } from '../../types';
import { frameIndexToHTMLSeekTime } from '../../Helpers/framesToTime';

export type VideoAssetParams = BaseAssetParams & {
    startFrame: number;
    offsetTime: number;
    frameRate: number;
    duration: number;
    isAlpha: boolean;
    useAudio: boolean;
    naturalDimension: Dimension;
};

type GetImageFrameType = (objectClone: HTMLVideoElement, isAlfa: boolean) => Promise<HTMLImageElement | false>;

export type VideoObject = HTMLVideoElement & { mediaController: MediaController };

export class VideoAsset extends BaseAsset {
    objectClone: VideoObject;

    startFrame: number;

    offsetTime: number;

    frameRate: number;

    duration: number;

    isAlpha: boolean;

    useAudio: boolean;

    naturalDimension: Dimension;

    frameBuffer!: Map<number, any>;

    getImageFrame!: GetImageFrameType;

    object!: VideoObject;

    private buffering: { [key: number]: Function[] } = {};

    constructor(params: VideoAssetParams) {
        super(params);
        this.startFrame = params.startFrame;
        this.offsetTime = params.offsetTime;
        this.frameRate = params.frameRate;
        this.duration = params.duration;
        this.isAlpha = params.isAlpha;
        this.useAudio = params.useAudio;
        this.naturalDimension = params.naturalDimension;
        this.frameBuffer = new Map();
    }

    setMediaData(video: VideoObject, videoClone: VideoObject, getImageFrame: GetImageFrameType) {
        this.object = video;
        this.objectClone = videoClone;
        this.getImageFrame = getImageFrame;

        if (!(this.naturalDimension.getWidth() && this.naturalDimension.getHeight())) {
            this.naturalDimension.setWidth(video.videoWidth);
            this.naturalDimension.setHeight(video.videoHeight);
        }
    }

    private async frameBufferHandler(seekToTime: number, key: number, resolve: (bool: boolean) => void) {
        if (!this.objectClone) {
            resolve(false);
            (this.buffering[key] || []).reverse().forEach((cb) => cb(false));
            delete this.buffering[key];

            return;
        }

        const seeked = await Promise.race([
            new Promise<boolean>((res) => {
                this.objectClone.addEventListener('seeked', () => res(true), { once: true });
                this.objectClone.currentTime = seekToTime;
            }),
            sleep(500),
        ]);

        if (this.objectClone && seeked && Math.abs(this.objectClone.currentTime - seekToTime) < 0.004) {
            const img = await this.getImageFrame(this.objectClone, this.isAlpha);
            let result = false;

            if (img) {
                this.frameBuffer.set(key, img);
                result = true;
            }

            resolve(result);

            (this.buffering[key] || []).reverse().forEach((cb) => cb(result));
            delete this.buffering[key];
        } else {
            requestIdleCallback(this.frameBufferHandler.bind(this, seekToTime, key, resolve), { timeout: 2000 });
        }
    }

    createFrameBuffer(frameIndex: number): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.objectClone || !this.getImageFrame) {
                resolve(false);

                return;
            }

            const offsetTime = this.offsetTime / 1000 - this.startFrame / this.frameRate;
            const currentTime = frameIndexToHTMLSeekTime(frameIndex, this.frameRate);
            const seekToTime = offsetTime + currentTime;
            const key = parseFloat(currentTime.toFixed(2));

            if (key in this.buffering) {
                this.buffering[key].push(resolve);
            } else {
                this.buffering[key] = [];

                this.frameBufferHandler(seekToTime, key, resolve);
            }
        });
    }

    async createAllFramesBuffer() {
        for (let f = this.startFrame; f < this.startFrame + this.duration; f++) {
            await this.createFrameBuffer(f);
        }
    }

    destroy() {
        if (this.object && this.object.mediaController) {
            this.object.mediaController.destroy();
            (this.object as any).mediaController = null;
        }

        (this as any).object = null;
        this.objectClone = null;
        (this as any).frameBuffer = null;
        this.frameBuffer = new Map();
        this.accessible = false;
        this.loading = false;
    }
}
