import { ColorParams, Stroke, TextDecoration, TextScript, TextTransform } from '@bynder-studio/misc';
import { CursorOrientation, Direction, LayoutElement, Region } from './Types';
import { cloneObj } from '../Helpers/utils';
import {
    addLayoutRuns,
    addRuns,
    applyStyleOnRuns,
    DenormalizedRun,
    denormalizeRun,
    getDenormalizedRunsForSelection,
    getlayoutRunsForExport,
    getUniqueObject,
    isRtlGlyph,
    splitRun,
    updateLayoutRuns,
    updateRuns,
} from './utils';

export type StyleProps = {
    fontId: string;
    fontSize: number;
    styleId: string | null;
    color: ColorParams;
    tracking: number;
    leading: number;
    textTransform: TextTransform;
    textDecoration: TextDecoration;
    textScript: TextScript;
    stroke: Stroke;
    paragraphSpacing: number;
};

export const SETTINGS_KEYS: (keyof Required<StyleProps>)[] = [
    'fontId',
    'color',
    'fontSize',
    'leading',
    'styleId',
    'tracking',
    'textTransform',
    'textScript',
    'textDecoration',
    'stroke',
    'paragraphSpacing',
];

export type TextSegment = {
    length: number;
};

export type RunProps = StyleProps & TextSegment;

export type LayoutRun = {
    type: LayoutElement;
} & TextSegment;

export type Runs = RunProps[];
export type LayoutRuns = LayoutRun[];

export interface TextProps {
    runs: Runs;
    layoutRuns: LayoutRuns;
    value: string;
}

export type ShapedTextPosition = {
    lineIdx: number;
    glyphIdx: number; // in case of -1 it is placed before the first glyph of the line
    textIdx: number;
    subTextIdx: number; // the index of the text in case a glyph is created from multiple chars
    cursorOrientation: CursorOrientation;
};

export class ShapedLineMetrics {
    ascenderEm = 0;

    descenderEm = 0;

    lineGapEm = 0;

    lineHeightEm = 0; // Max ascender - descender + linegap

    widthEm = 0; // Total width of the glyphs

    ascenderPx = 0;

    descenderPx = 0;

    lineGapPx = 0;

    lineHeightPx = 0;

    widthPx = 0;

    leadingPx = 0; // base-to-base height in pixels

    leftTrimmedPx = 0;
}

export type AppliedStyles = { [key in keyof Omit<RunProps, 'length'>]-?: RunProps[key][] };
const appliedStylesCache = new WeakMap<TextProps | StyleProps, { key: string; value: AppliedStyles }>();

export const deriveAppliedStylesFromStyleProps = (defaultProps: StyleProps) => {
    const cachedVal = appliedStylesCache.get(defaultProps)?.value;

    if (cachedVal) {
        return cachedVal;
    }

    const settingsKeys = Object.keys(defaultProps);

    const value = settingsKeys.reduce((acc, key) => {
        acc[key] = key in defaultProps ? [defaultProps[key]] : [];

        return acc;
    }, {} as AppliedStyles);

    appliedStylesCache.set(defaultProps, { key: '', value });

    return value;
};

export class ShapedText {
    protected glyphs: IStructuredGlyph[] = [];

    protected shapedLines: ShapedLine[] = [];

    protected defaultLineMetrics: ShapedLineMetrics = new ShapedLineMetrics();

    scale = 1.0;

    absBbox: Region | null = null; // Absolute scaled bbox in px. This includes spaces around glyphs

    constructor(protected readonly textProps: TextProps) {}

    s(n: number) {
        return n * this.scale;
    }

    getText() {
        return this.textProps.value;
    }

    getTextProps() {
        return this.textProps;
    }

    getGlyphs() {
        return this.glyphs;
    }

    getShapedLines() {
        return this.shapedLines;
    }

    setShapedLines(shapedLines: ShapedLine[]) {
        this.shapedLines = shapedLines;
    }

    setGlyphs(glyphs: IStructuredGlyph[]) {
        this.glyphs = glyphs;
        this.defaultLineMetrics = new ShapedLineMetrics();
        this.shapeLines();
    }

    setDefaultLineMetrics(defaultLineMetrics: ShapedLineMetrics) {
        this.glyphs = [];
        this.defaultLineMetrics = defaultLineMetrics;
        this.shapeLines();
    }

    getDefaultLineMetrics() {
        return this.defaultLineMetrics;
    }

    /**
     * Determine line metrics base on glyphs
     * // TODO: implement leading updates
     */
    private shapeLines() {
        this.shapedLines = [];

        if (!this.glyphs.length) {
            // In case no content is present, return height based on default metrics
            this.shapedLines.push(new ShapedLine([], this.defaultLineMetrics));
        } else {
            const maxLineIdx = Math.max(...this.glyphs.map((o) => o.data.lineIdx));

            // create list of lines
            const lineGlyphs: IStructuredGlyph[][] = Array.from(Array(maxLineIdx + 1), () => []);

            // split glyphs per line
            this.glyphs.forEach((g) => {
                lineGlyphs[g.data.lineIdx].push(g);
            });

            // create shapedLines
            lineGlyphs.forEach((glyphs) => {
                this.shapedLines.push(new ShapedLine(glyphs));
            });

            // In case the last glyph is a newline, add another line with the last glyph metrics
            const lastGlyph = this.glyphs[this.glyphs.length - 1];

            if (lastGlyph.isNewLine()) {
                this.shapedLines.push(new ShapedLine([], ShapedText.createShapedLineMetrics([lastGlyph])));
            }
        }
    }

    static createShapedLineMetrics(glyphs: IStructuredGlyph[] = []) {
        const m = new ShapedLineMetrics();

        if (glyphs.length) {
            // to make sure we keep the baseline on the same level, we re-calculate the metrics for lineheight individually

            glyphs.forEach((g) => {
                m.ascenderEm = Math.max(m.ascenderEm, g.unitToEm(g.data.ascender / g.data.textScriptScale));
                // descender is negative, so we take the mimimum to max it.
                m.descenderEm = Math.min(m.descenderEm, g.unitToEm(g.data.descender / g.data.textScriptScale));
                m.lineGapEm = Math.max(m.lineGapEm, g.unitToEm(g.data.lineGap));
                m.lineHeightEm = Math.max(m.lineHeightEm, g.unitToEm(g.data.lineHeight / g.data.textScriptScale));
                // m.lineHeightEm = Math.max(m.lineHeightEm, g.leadingEm);

                // each glyph can contain a different fontsize
                m.ascenderPx = Math.max(m.ascenderPx, g.unitToPx(g.data.ascender / g.data.textScriptScale));
                m.descenderPx = Math.min(m.descenderPx, g.unitToPx(g.data.descender / g.data.textScriptScale));
                m.lineGapPx = Math.max(m.lineGapPx, g.unitToPx(g.data.lineGap));
                m.lineHeightPx = Math.max(m.lineHeightPx, g.unitToPx(g.data.lineHeight / g.data.textScriptScale));
                // m.lineHeightPx = Math.max(m.lineHeightPx, g.emToPx(g.leadingEm));

                if (!g.isNewLine() || glyphs.length === 1) {
                    m.leadingPx = Math.max(m.leadingPx, g.emToPx(g.data.leadingEm / g.data.textScriptScale));
                }

                // to calculate width, we take the advance width
                const widthEm = g.getXAdvanceAndSpacingEm();

                m.widthEm += widthEm;
                m.widthPx += g.emToPx(widthEm);
            });

            // Remove spacing at the end of the line
            let gIdx = glyphs.length - 1;

            if (glyphs[gIdx].isNewLine()) {
                // if last glyph is newline, ignore it
                gIdx--;
            }

            const trimEnd = () => {
                if (gIdx < 0) {
                    return;
                }

                if (glyphs[gIdx].data.path !== '') {
                    // if a visible character is encountered, only remove the spacing
                    const em = glyphs[gIdx].getXSpacingEm();
                    const px = glyphs[gIdx].emToPx(em);
                    m.widthEm -= em;
                    m.widthPx -= px;

                    if (isRtlGlyph(glyphs[gIdx])) {
                        m.leftTrimmedPx += px;
                    }

                    return;
                }

                // if a non-visible character is encountered, fully remove width and spacing
                const em = glyphs[gIdx].getXAdvanceAndSpacingEm();
                const px = glyphs[gIdx].emToPx(em);
                m.widthEm -= em;
                m.widthPx -= px;

                if (isRtlGlyph(glyphs[gIdx])) {
                    m.leftTrimmedPx += px;
                }

                gIdx--;
                trimEnd();
            };

            trimEnd();

            // Instead of taking the max lineheight per glyph, recalculate line height on the basis of the individual metrics
            // m.lineHeightEm = m.ascenderEm - m.descenderEm + m.lineGapEm;
            // m.lineHeightPx = m.ascenderPx - m.descenderPx + m.lineGapPx;
        }

        return m;
    }

    /**
     * @returns Total content height in px
     */
    getContentHeight() {
        if (!this.shapedLines.length) {
            // In case no content is present, return height based on default metrics
            return this.defaultLineMetrics.lineHeightPx;
        }

        let height = 0;
        let baseline = 0;

        this.shapedLines.forEach((sLine, idx) => {
            if (idx > 0) {
                // recalculate baseline and min/max the height
                baseline += sLine.metrics.leadingPx;
            }

            // check if ascender from current baseline is exceeding the top, and adjust if necessary
            if (baseline - sLine.metrics.ascenderPx < 0) {
                baseline = sLine.metrics.ascenderPx;
            }

            // calculate current max height based on baseline and lineHeight remainder
            height = Math.max(height, baseline + (sLine.metrics.lineHeightPx - sLine.metrics.ascenderPx));
        });

        return height;
    }

    getContentWidth() {
        return this.shapedLines.reduce<number>((acc, sLine) => {
            return Math.max(acc, sLine.metrics.widthPx);
        }, 0);
    }

    getTextIdx(pos: ShapedTextPosition): number {
        const glyph = this.glyphs[pos.glyphIdx];
        const { textIdx, lineIdx, cursorOrientation } = pos;

        if (
            glyph &&
            isRtlGlyph(glyph) &&
            cursorOrientation === CursorOrientation.Left &&
            this.getShapedLines()?.[lineIdx]?.glyphs?.[0]?.data?.glyphIdx === pos.glyphIdx
        ) {
            return Math.min(textIdx + 1, this.getText().length);
        }

        return textIdx;
    }

    textIdxToPosition(textIdx: number): ShapedTextPosition {
        if (textIdx >= this.getText().length) {
            const shapedLines = this.getShapedLines();

            return {
                lineIdx: shapedLines.length - 1,
                glyphIdx: this.glyphs.length,
                textIdx: this.getText().length,
                subTextIdx: 0,
                cursorOrientation: CursorOrientation.Left,
            };
        }

        const glyph = this.glyphs.find((g) => g.data.textIdxs.includes(textIdx));
        const subTextIdx = glyph.data.textIdxs.indexOf(textIdx);
        const lineIdx = glyph.data.lineIdx;
        const glyphIdx = glyph.data.glyphIdx;

        return {
            lineIdx,
            glyphIdx,
            textIdx,
            subTextIdx,
            cursorOrientation: isRtlGlyph(glyph) ? CursorOrientation.Right : CursorOrientation.Left,
        };
    }

    getFirstShapedTextPosition(): ShapedTextPosition {
        return this.textIdxToPosition(0);
    }

    getLastShapedTextPosition(): ShapedTextPosition {
        return this.textIdxToPosition(this.getText().length);
    }

    getGlyphAreaByPosition(pos: ShapedTextPosition): [number, number, number, number] {
        if (pos.glyphIdx === this.glyphs.length) {
            if (pos.glyphIdx === 0 || this.glyphs[this.glyphs.length - 1].isNewLine()) {
                // In case text is empty or if the position is the end, where the last glyph is a newline
                // get area from the last shaped line
                const sLine = this.shapedLines[this.shapedLines.length - 1];

                return [sLine.absLeftPx, sLine.absBbox.top, 0, sLine.absBbox.height];
            }

            // get area after last glyph
            const glyph = this.glyphs[pos.glyphIdx - 1];
            const xPos = glyph.data.absBbox.left + (isRtlGlyph(glyph) ? 0 : glyph.data.absBbox.width);

            return [xPos, glyph.data.absBbox.top, 0, glyph.data.absBbox.height];
        }

        // get area based on provided position
        const glyph = this.glyphs[pos.glyphIdx];
        const glyphWidth = glyph.data.absBbox.width / glyph.data.textIdxs.length;
        const xPos = glyph.data.absBbox.left + glyphWidth * pos.subTextIdx;
        // return [xPos, glyph.absBbox.top, glyphWidth, glyph.absBbox.height];

        // the y position and height we take from the line
        const sLine = this.shapedLines[pos.lineIdx];

        return [xPos, sLine.absBbox.top, glyphWidth, sLine.absBbox.height];
    }

    getTextIdxFromShapedTextPosition(pos: ShapedTextPosition): number {
        if (pos.glyphIdx >= this.glyphs.length) {
            if (this.glyphs.length && this.glyphs[this.glyphs.length - 1].data.direction === Direction.RTL) {
                return -1;
            }

            return this.textProps.value.length;
        }

        return this.glyphs[pos.glyphIdx].data.textIdxs[pos.subTextIdx];
    }

    getShapedTextPositionFromTextIdx(textIdx: number): ShapedTextPosition {
        if (textIdx < 0) {
            if (this.glyphs.length && this.glyphs[0].data.direction === Direction.RTL) {
                return this.getLastShapedTextPosition();
            }

            return this.getFirstShapedTextPosition();
        }

        // loop through glyphs to check which reference matches
        for (let glyphIdx = 0; glyphIdx < this.glyphs.length; glyphIdx++) {
            const glyph = this.glyphs[glyphIdx];

            for (let subTextIdx = 0; subTextIdx < glyph.data.textIdxs.length; subTextIdx++) {
                if (glyph.data.textIdxs[subTextIdx] === textIdx) {
                    return {
                        lineIdx: glyph.data.lineIdx, // TODO: what if this is a NewLineGlyph?
                        glyphIdx,
                        textIdx,
                        subTextIdx,
                        cursorOrientation: CursorOrientation.Left,
                    };
                }
            }
        }

        if (this.glyphs.length && this.glyphs[this.glyphs.length - 1].data.direction === Direction.RTL) {
            return this.getFirstShapedTextPosition();
        }

        return this.getLastShapedTextPosition();
    }

    getLineIdxFromShapedTextPosition(pos: ShapedTextPosition): number {
        if (pos.glyphIdx === 0) {
            return 0;
        } else if (pos.glyphIdx === this.glyphs.length) {
            if (this.glyphs[this.glyphs.length - 1].isNewLine()) {
                // Selection is after last glyph, which is a newline
                return this.glyphs[this.glyphs.length - 1].data.lineIdx + 1;
            }

            return this.glyphs[this.glyphs.length - 1].data.lineIdx;
        }

        return this.glyphs[pos.glyphIdx].data.lineIdx;
    }

    getTextIdxsFromSelection(startPos: ShapedTextPosition, endPos: ShapedTextPosition): [number, number] {
        const start = this.getTextIdx(startPos);
        const end = this.getTextIdx(endPos);

        if (start > end) {
            return [end, start];
        }

        return [start, end];
    }

    /**
     * Get the text for a position range
     */
    getTextForSelection(textIdxStart: number, textIdxEnd: number) {
        // const [textIdxStart, textIdxEnd] = this.getTextIdxsFromSelection(startPos, endPos);
        return this.textProps.value.substring(textIdxStart, textIdxEnd);
    }

    getRunsIdxsForSelection(textIdxStart: number | undefined, textIdxEnd: number | undefined, strict = false) {
        const isCorrectIdx = (idx: number | undefined) =>
            Number.isInteger(idx) && (idx as number) >= 0 && (idx as number) <= this.textProps.value.length;

        const start = isCorrectIdx(textIdxStart) ? textIdxStart : 0;
        const end = isCorrectIdx(textIdxEnd) ? textIdxEnd : this.textProps.value.length;
        const denormalisedRuns = this.textProps.runs.reduce(denormalizeRun, []);

        return getDenormalizedRunsForSelection(denormalisedRuns, start, end, strict).map((run) => run.index);
    }

    getUpdatedRunsForConverting(textIdxStart: number, textIdxEnd: number): [RunProps[], LayoutRuns] {
        const selectedRuns = this.getRunsIdxsForSelection(textIdxStart, textIdxEnd, true);

        const runStart = selectedRuns[0];
        const runEnd = selectedRuns[selectedRuns.length - 1];

        const clonedRuns = cloneObj(this.textProps.runs);

        let offset = 0;
        const result: Runs = [];

        clonedRuns.forEach((run, idx) => {
            const isStartInsideRun = textIdxStart >= offset && textIdxStart <= run.length + offset;
            const isEndInsideRun = textIdxEnd >= offset && textIdxEnd <= offset + run.length;
            const oldLength = run.length;

            if (isStartInsideRun && isEndInsideRun) {
                const startIdxRelativeToRun = textIdxStart - offset;
                const endIdxRelativeToRun = textIdxEnd - offset;
                result.push({
                    ...run,
                    length: endIdxRelativeToRun - startIdxRelativeToRun,
                });
            } else if (isStartInsideRun) {
                const idxRelativeToRun = textIdxStart - offset;
                result.push({
                    ...run,
                    length: run.length - idxRelativeToRun,
                });
            } else if (isEndInsideRun) {
                const idxRelativeToRun = textIdxEnd - offset;
                result.push({
                    ...run,
                    length: idxRelativeToRun,
                });
            } else if (idx >= runStart && idx <= runEnd) {
                result.push({ ...run });
            }

            offset += oldLength;
        });

        return [
            result.filter((run) => run.length > 0),
            getlayoutRunsForExport(cloneObj(this.textProps.layoutRuns), textIdxStart, textIdxEnd),
        ];
    }

    getStylesForSelection(textIdxStart?: number, textIdxEnd?: number, onlyUnique = true) {
        const key = `${textIdxStart}-${textIdxEnd}`;
        const cachedVal = appliedStylesCache.get(this.textProps);

        if (onlyUnique && cachedVal?.key === key) {
            return cachedVal.value;
        }

        const { runs } = this.textProps;

        const addAppliedStyle = <T extends keyof AppliedStyles>(acc: AppliedStyles, key: T) => {
            acc[key] = [];

            return acc;
        };

        const value = SETTINGS_KEYS.reduce(addAppliedStyle, {} as AppliedStyles);

        let selectedRuns = this.getRunsIdxsForSelection(textIdxStart, textIdxEnd, true);

        if (textIdxStart !== textIdxEnd && textIdxStart !== undefined) {
            selectedRuns = selectedRuns.filter((idx) => runs[idx]?.length > 0);
        }

        selectedRuns.forEach((idx) => {
            SETTINGS_KEYS.forEach((key) => {
                (value[key] as any[]).push(runs[idx][key]);
            });
        });

        const isNotPrimitive = (val: any) => typeof val === 'object' && val !== null;

        if (onlyUnique) {
            // remove duplicated values
            SETTINGS_KEYS.forEach((key) => {
                if (value[key].length > 1) {
                    value[key] = isNotPrimitive(value[key][0])
                        ? getUniqueObject<any>(value[key]).filter((a) => a !== undefined)
                        : [...new Set(value[key] as any)].filter((a) => a !== undefined);
                }
            });

            appliedStylesCache.set(this.textProps, { key, value });
        }

        return value;
    }

    updateStylesForSelection(textIdxStart: number, textIdxEnd: number, styles: Partial<Omit<RunProps, 'length'>>) {
        const textProps = cloneObj(this.textProps);
        textProps.runs = applyStyleOnRuns(textProps.runs, textIdxStart, textIdxEnd, styles);

        return textProps;
    }

    updateStylesForEachRun(start: number, end: number, styles: Omit<RunProps, 'length'>[]) {
        let [runsToAdd, layoutRunsToAdd] = this.getUpdatedRunsForConverting(start, end);

        runsToAdd = runsToAdd.map((run, idx) => ({
            ...run,
            ...styles[idx],
        }));

        const fTextAfterDeleting = this.updateTextForSelection(start, end, '');

        return this.updateRunsWithRuns({
            runsToAdd,
            layoutRunsToAdd,
            textIdxStart: start,
            text: '',
            runsToBeUpdated: fTextAfterDeleting.runs,
        });
    }

    updateTextForSelection(textIdxStart: number, textIdxEnd: number, text: string, updateLayout = false) {
        const textProps = cloneObj(this.textProps);
        textProps.value = textProps.value.substring(0, textIdxStart) + text + textProps.value.substring(textIdxEnd);
        textProps.runs = updateRuns(textProps.runs, textIdxStart, textIdxEnd, text);
        textProps.layoutRuns = updateLayoutRuns(textProps.layoutRuns, textIdxStart, textIdxEnd, text);

        if (updateLayout) {
            const end = textIdxStart + text.length - 1;

            const splitRuns = (fromIdx: number) => {
                const idx = textProps.value.indexOf('\n', fromIdx);

                if (idx < textIdxStart || idx > end) {
                    return;
                }

                textProps.layoutRuns = splitRun(textProps.layoutRuns, idx + 1);
                splitRuns(idx + 1);
            };

            splitRuns(textIdxStart);
        }

        // join layout runs in case of delete/backspace
        if (textIdxEnd > textIdxStart) {
            const runsWithStartEnd = textProps.layoutRuns.reduce(denormalizeRun, [] as DenormalizedRun<LayoutRun>[]);
            const runsToUpdate = runsWithStartEnd
                .filter(
                    (layoutRunWithStartEnd) =>
                        layoutRunWithStartEnd.end >= textIdxStart && layoutRunWithStartEnd.start <= textIdxStart,
                )
                .filter(
                    (layoutRunWithStartEnd) =>
                        !textProps.value
                            .substring(layoutRunWithStartEnd.start, layoutRunWithStartEnd.end)
                            .endsWith('\n'),
                );

            if (runsToUpdate.length < 2) {
                return textProps;
            }

            const joinedRun = runsToUpdate.reduce((acc, run) => ({ ...acc, length: acc.length + run.length }));

            textProps.layoutRuns[runsToUpdate[0].index] = joinedRun;
            textProps.layoutRuns.splice(runsToUpdate[1].index, runsToUpdate.length - 1);
        }

        return textProps;
    }

    updateRunsWithRuns({
        runsToAdd,
        layoutRunsToAdd,
        textIdxStart,
        text = '',
        runsToBeUpdated = null,
    }: {
        runsToAdd: RunProps[];
        layoutRunsToAdd: LayoutRuns;
        textIdxStart: number;
        text?: string;
        runsToBeUpdated?: RunProps[];
    }) {
        const textProps = cloneObj(this.textProps);
        const [startRunIdx] = this.getRunsIdxsForSelection(textIdxStart, textIdxStart);

        textProps.runs = addRuns(runsToBeUpdated || textProps.runs, runsToAdd, textIdxStart, startRunIdx);
        textProps.layoutRuns = addLayoutRuns(textProps.layoutRuns, layoutRunsToAdd, textIdxStart);
        textProps.value = textProps.value.substring(0, textIdxStart) + text + textProps.value.substring(textIdxStart);

        return textProps;
    }

    /**
     * Request property from a specific run with fallback to defaultProps
     * TODO: move into a textProps object.
     * @param propertyName
     * @param runIdx
     * @returns
     */
    getTextPropsProperty(propertyName: string, runIdx: number): any {
        if (propertyName === 'color') {
            const filteredRuns = this.textProps.runs.filter((run) => run.length > 0);

            if (
                runIdx >= 0 &&
                runIdx < filteredRuns.length &&
                filteredRuns[runIdx].color &&
                filteredRuns[runIdx].length > 0
            ) {
                return filteredRuns[runIdx].color;
            }

            return this.textProps.runs[0].color;
        }
    }
}

export class ShapedLine {
    metrics: ShapedLineMetrics;

    absBbox!: Region; // Absolute scaled bbox in px. This includes spaces around glyphs

    absLeftPx?: number; // Asolute scaled x position to baseline for drawing

    absTopPx?: number; // Asolute scaled x position to baseline for drawing

    absLeadingBbox!: Region; // Absolute scaled bbox in px. This includes leading to the line above

    constructor(
        public glyphs: IStructuredGlyph[] = [],
        metrics: ShapedLineMetrics | null = null,
    ) {
        if (glyphs.length && metrics === null) {
            this.metrics = ShapedText.createShapedLineMetrics(glyphs);
        } else if (metrics) {
            this.metrics = metrics;
        } else {
            this.metrics = new ShapedLineMetrics();
        }
    }
}

type EmptyGlyphData = {
    fontId: string;
    fontSize: number;
    upem: number;
    ascender: number;
    descender: number;
    lineGap: number;
    lineHeight: number;
};

export class EmptyGlyph {
    // font details
    fontId;

    fontSize; // in px

    upem;

    ascender; // Ascender in units

    descender; // Descender in units

    lineGap; // LineGap in units

    lineHeight; // LineHeight in units

    path = '';

    xAdvance; // XAdvance in units

    yAdvance; // YAdvance in units

    xOffset; // XOffset in units

    yOffset; // YOffset in units

    width!: number; // glyph width (without bearing) in units

    height!: number; // glyph height (without bearing) in units

    // bbox metrics
    xMin!: number; // xMin in units

    xMax!: number; // xMax in units

    yMin!: number; // yMin in units

    yMax!: number; // yMax in units

    pathScale = 1;

    textIdxs: number[] = []; // references to original text

    lineIdx = 0; // indicate on which line this glyph is displayed

    runIdx = 0; // helper to reference the run index

    text: string[] = []; // used for debugging

    // other
    leadingEm = 0.0; // glyph specific leadingEm

    trackingEm = 0.0; // glyph specific trackingEm

    // absolute
    absBbox!: Region; // Absolute scaled bbox in px. This includes spaces around glyphs

    absContentBbox: Region | undefined; // Absolute scaled content bbox in px.

    // baseline position for drawing
    absLeftPx: number | undefined; // Asolute scaled x position to baseline for drawing

    absTopPx: number | undefined; // Asolute scaled x position to baseline for drawing
    // leftPx = 0;
    // topPx = 0;

    // Properties managed by TextElement
    // TODO: find a different solution
    id!: number;

    bboxX!: number;

    bboxY!: number;

    bboxW!: number;

    bboxH!: number;

    bboxOffsetX!: number;

    bboxOffsetY!: number;

    glyphIdx!: number;

    constructor(data: EmptyGlyphData) {
        this.fontId = data.fontId;
        this.fontSize = data.fontSize;
        this.upem = data.upem;
        this.ascender = data.ascender;
        this.descender = data.descender;
        this.lineGap = data.lineGap;
        this.lineHeight = data.lineHeight;
        this.xAdvance = 0;
        this.yAdvance = 0;
        this.xOffset = 0;
        this.yOffset = 0;
    }

    unitToEm(metric: number) {
        return metric / this.upem;
    }

    unitToPx(metric: number) {
        return (metric / this.upem) * this.fontSize;
    }

    emToPx(metric: number) {
        return metric * this.fontSize;
    }

    getXSpacingEm() {
        return 0;
    }

    getXAdvanceAndSpacingEm() {
        return 0;
    }

    getYAdvanceAndSpacingEm() {
        return 0;
    }
}

export class NewLineGlyph extends EmptyGlyph {
    constructor(data: StructuredGlyphData) {
        super(data);

        this.absLeftPx = 0;
        this.absTopPx = 0;
        this.absBbox = new Region(0, 0, 0, 0);
        this.absContentBbox = new Region(0, 0, 0, 0);
    }
}

export type StructuredGlyphData = EmptyGlyphData & {
    glyphId: number;
    glyphIdx: number;
    clusterId: number;
    path: string;
    xAdvance: number;
    yAdvance: number;
    xOffset: number;
    yOffset: number;
    width: number;
    height: number;
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    leadingEm: number;
    trackingEm: number;
    pathScale: number;
    absLeftPx: number;
    absTopPx: number;
    absBbox: Region;
    absContentBbox: Region;
};

export interface IStructuredGlyph {
    data: StructuredGlyphData & {
        direction: Direction;
        [key: string]: any;
    };
    unitToEm: (metric: number) => number;
    unitToPx: (metric: number) => number;
    emToPx: (metric: number) => number;
    getXSpacingEm: () => number;
    getXAdvanceAndSpacingEm: () => number;
    getYAdvanceAndSpacingEm: () => number;
    isNewLine: () => boolean;
    markAsNewLine: () => IStructuredGlyph;
}

export function StructuredGlyph(data: StructuredGlyphData) {
    this.data = data;
    this.data.pathScale = this.unitToPx(1);
}

StructuredGlyph.prototype.unitToEm = function (metric: number) {
    return metric / this.data.upem;
};

StructuredGlyph.prototype.unitToPx = function (metric: number) {
    return (metric / this.data.upem) * this.data.fontSize;
};

StructuredGlyph.prototype.emToPx = function (metric: number) {
    return metric * this.data.fontSize;
};

StructuredGlyph.prototype.getXSpacingEm = function () {
    return this.data.trackingEm;
};

StructuredGlyph.prototype.getXAdvanceAndSpacingEm = function () {
    // Total advance, including tracking distance.
    // todo: ignore adding trackingEm if the glyph representing accent, diacritic, vowel
    return this.data.xAdvance / this.data.upem + this.data.trackingEm;
};

StructuredGlyph.prototype.getYAdvanceAndSpacingEm = function () {
    // Total advance, including tracking distance.
    return this.data.yAdvance / this.data.upem;
};

StructuredGlyph.prototype.markAsNewLine = function () {
    this._isNewLine = true;

    return this;
};

StructuredGlyph.prototype.isNewLine = function () {
    return !!this._isNewLine;
};

export class StructuredGlyph3 extends EmptyGlyph {
    // glyph details
    glyphId;

    clusterId;

    constructor(data: StructuredGlyphData) {
        super(data);

        this.glyphId = data.glyphId;
        this.clusterId = data.clusterId;
        this.path = data.path;
        this.xAdvance = data.xAdvance;
        this.yAdvance = data.yAdvance;
        this.xOffset = data.xOffset;
        this.yOffset = data.yOffset;
        this.width = data.width;
        this.height = data.height;

        this.xMin = data.xMin;
        this.xMax = data.xMax;
        this.yMin = data.yMin;
        this.yMax = data.yMax;

        this.pathScale = this.unitToPx(1);
    }

    getXSpacingEm() {
        // Get the additional margin applied for horizontal spacing (useful to remove from last item if text needs to fit a box)
        return this.trackingEm;
    }

    getXAdvanceAndSpacingEm() {
        // Total advance, including tracking distance.
        return this.xAdvance / this.upem + this.getXSpacingEm();
    }

    getYAdvanceAndSpacingEm() {
        // Total advance, including tracking distance.
        return this.yAdvance / this.upem;
    }

    /**
     * Calculate total Em width for a list of glyphs, where the last spacing is removed.
     * NOTE: we can also remove the right bearing of the last glyph to make the text fit exactly
     * @param glyphs
     * @returns em width of the list of glyphs
     */
    static getGlyphsWidthEm = (glyphs: StructuredGlyph3[]) => {
        // Static helper to get EM width from any list of structured glyphs
        let widthEm = 0;
        glyphs.forEach((glyph) => {
            widthEm += glyph.getXAdvanceAndSpacingEm();
        });

        // Subtract additional spacing margin for last glyph
        if (glyphs.length) {
            widthEm -= glyphs[glyphs.length - 1].getXSpacingEm();
        }

        return widthEm;
    };
}
