import type { ColorParams } from '@bynder-studio/misc';
import { compareByRenderOrder, getAllElementsRecursively } from '../../Helpers/elementUtils';
import type { IAsset } from '../Assets/IAsset';
import { Dimension } from '../Shared/Dimension';
import { BaseCompElement } from './Elements/BaseCompElement';
import { getBoundingBoxAfterRotation } from '../Elements/Helpers/GroupElementHelpers';
import { Ranges } from '../../Helpers/Ranges';
import { MaskModeTypes } from '../../Enums/MaskModeTypes';
import { type CompElement, type CompModelParams, type VisualElement } from '../../types';

export class CompModel {
    private compElements: CompElement[] = [];

    private backgroundColor!: ColorParams;

    private frameIndex!: number;

    private width!: number;

    private height!: number;

    private _isUpToDate = true;

    private maskElementRanges!: Map<string, Ranges<MaskModeTypes>>;

    constructor() {
        this.compElements = [];
    }

    setFrame(frameIndex: number) {
        this.frameIndex = frameIndex;

        return this;
    }

    getFrame(): number {
        return this.frameIndex;
    }

    setDimensions(dimension: Dimension) {
        const { width, height } = dimension;
        this.width = width;
        this.height = height;

        return this;
    }

    getDimension(): Dimension {
        return new Dimension(this.width, this.height);
    }

    setBackgroundColor(backgroundColor: ColorParams) {
        this.backgroundColor = backgroundColor;

        return this;
    }

    getBackgroundColor(): ColorParams {
        return this.backgroundColor;
    }

    setElements(elements: VisualElement[]) {
        this.compElements = [];
        elements.sort(compareByRenderOrder).forEach((el) => {
            const compEl = el.getCompElement(this.frameIndex);

            if (compEl) {
                this.compElements.push(compEl);
            }
        });

        return this;
    }

    setCompElements(compElements: CompElement[]) {
        this.compElements = compElements;

        return this;
    }

    setMaskElementRanges(maskElementRanges: Map<string, Ranges<MaskModeTypes>>) {
        this.maskElementRanges = maskElementRanges;

        return this;
    }

    getCompElements(): CompElement[] {
        return this.compElements;
    }

    getAllCompElementsRecursively(): CompElement[] {
        return getAllElementsRecursively<CompElement>(this.compElements);
    }

    invalidate(): void {
        this._isUpToDate = false;
    }

    isContainsAsset(asset: IAsset): boolean {
        return this.getCompElements().some((compElement) => compElement.isContainsAsset(asset));
    }

    isElementMask(elementId: number | string): boolean {
        const key = elementId.toString();

        return this.maskElementRanges.has(key);
    }

    isElementMaskEnabled(elementId: number | string): boolean {
        const key = elementId.toString();
        const ranges = this.maskElementRanges.get(key);

        if (!ranges) {
            return false;
        }

        const mode = ranges.get(this.frameIndex);

        if (!mode) {
            return false;
        }

        return [MaskModeTypes.ALPHA, MaskModeTypes.ALPHA_INVERTED].includes(mode);
    }

    isElementHiddenBecauseOfMask(elementId: number | string): boolean {
        const key = elementId.toString();
        const ranges = this.maskElementRanges.get(key);

        if (!ranges) {
            return false;
        }

        const mode = ranges.get(this.frameIndex);

        return mode === null || mode !== MaskModeTypes.NONE;
    }

    isUpToDate(): boolean {
        return this._isUpToDate;
    }

    getBoundingBox(): { x: number; y: number; width: number; height: number } {
        let xMin = Number.MAX_SAFE_INTEGER;
        let yMin = Number.MAX_SAFE_INTEGER;
        let xMax = Number.MIN_SAFE_INTEGER;
        let yMax = Number.MIN_SAFE_INTEGER;

        this.compElements.forEach((compElement) => {
            const points = getBoundingBoxAfterRotation((compElement as unknown as BaseCompElement).originalElement);
            xMin = Math.min(xMin, ...points.map((p) => p.x));
            yMin = Math.min(yMin, ...points.map((p) => p.y));
            xMax = Math.max(xMax, ...points.map((p) => p.x));
            yMax = Math.max(yMax, ...points.map((p) => p.y));
        });

        return {
            x: xMin,
            y: yMin,
            width: xMax - xMin,
            height: yMax - yMin,
        };
    }

    toObject(): CompModelParams {
        return {
            frameIndex: this.frameIndex,
            elements: this.compElements.map((el) => (el as unknown as BaseCompElement).toObject()),
            backgroundColor: this.backgroundColor,
            width: this.width,
            height: this.height,
        };
    }
}
