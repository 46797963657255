import { BaseAsset, type BaseAssetParams } from './BaseAsset';

export type FontAssetParams = BaseAssetParams & {
    fontId: number | string;
};

export class FontAsset extends BaseAsset {
    fontId: number | string;

    object: ArrayBuffer | null | undefined;

    constructor(params: FontAssetParams) {
        super(params);
        this.fontId = params.fontId;
        this.object = null;
    }
}
