import { ReviewStatus } from 'packages/store/review/types';
import ProxyServerConnector from '../web/src/services/connectors/ProxyServerConnector';

export type RequestApprovalData = {
    comment: string | null;
    status: ReviewStatus;
};

class ReviewRequestService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/creative') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    requestApproval(entityId: number, data: RequestApprovalData) {
        const { status, comment } = data;

        return this.gateway.post(`/${entityId}/approvals/status`, { status, comment });
    }
}

export default new ReviewRequestService();
