import { AudioAsset, type MediaController, sleep, VideoAsset } from '@bynder-studio/render-core';
import MCorp from './mediasync';
import TIMINGSRC from './timingsrc';

export default function createBrowserMediaController(mediaAsset: VideoAsset | AudioAsset): MediaController {
    let self = this;
    let timingObject = null;
    let sync = null;
    let lastSeeked = -1;

    if (mediaAsset instanceof VideoAsset) {
        const rangeEnd = (mediaAsset.startFrame + mediaAsset.duration) / mediaAsset.frameRate;
        timingObject = new TIMINGSRC.TimingObject({
            velocity: 0.0,
            position: 0.0,
            range: [0, rangeEnd],
        });
        sync = MCorp.mediaSync(self, timingObject, {
            target: 0.025,
            skew: mediaAsset.offsetTime / 1000 - mediaAsset.startFrame / mediaAsset.frameRate,
            debug: false,
        });
    } else if (mediaAsset instanceof AudioAsset) {
        timingObject = new TIMINGSRC.TimingObject({
            velocity: 0.0,
            position: 0.0,
        });
        sync = MCorp.mediaSync(self, timingObject, {
            target: 0.025,
            skew: mediaAsset.offsetTime / 1000,
            debug: false,
        });
    }

    const onSeeked = () => {
        lastSeeked = timingObject.query().position;
    };

    self.addEventListener('seeked', onSeeked);

    const getCurrentTime = () => timingObject.query().position;
    const isPlaying = () => timingObject.query().velocity;

    return {
        isPlaying,
        play: () => {
            if (isPlaying()) {
                return;
            }

            timingObject.update({
                velocity: 1.0,
            });
        },
        pause: () => {
            if (!isPlaying()) {
                return;
            }

            timingObject.update({
                velocity: 0.0,
            });
        },
        getCurrentTime,
        setCurrentTime: (currentTime) =>
            Promise.race([
                new Promise((res) => {
                    self.addEventListener('seeked', () => res(true), {
                        once: true,
                    });
                    timingObject.update({
                        position: currentTime,
                    });
                }),
                sleep(500),
            ]),
        destroy: () => {
            self.removeEventListener('seeked', onSeeked);
            self.pause();
            sync.stop();
            timingObject = null;
            sync = null;
            self = null;
        },
        isSeeked(time, startFrom, frameRate, isPlaying) {
            const threshold = (isPlaying ? 5 : 0.1) / frameRate;
            const currentTime = isPlaying ? getCurrentTime() : lastSeeked;

            return Math.abs(time - currentTime) < threshold || self.duration < time - startFrom;
        },
    };
}

export const connectMediaNodes = (element) => {
    try {
        const audioContext = new (window.AudioContext || window['webkitAudioContext'])();
        const source = audioContext.createMediaElementSource(element);
        const gainNode = audioContext.createGain();
        source.connect(gainNode);
        gainNode.connect(audioContext.destination);
        element.addEventListener('play', () => {
            if (audioContext.state === 'suspended') {
                audioContext.resume();
            }
        });
        element.mediaController.gainNode = gainNode;
        element.mediaController.source = source;
    } catch (e) {
        console.warn('Failed to create audio context', e);
    }
};

export const updateMediaController = (audioAsset: AudioAsset) => {
    if (!audioAsset.object) {
        return;
    }

    let gainNode;

    if (audioAsset.object.mediaController) {
        gainNode = audioAsset.object.mediaController.gainNode;
        audioAsset.object.mediaController.destroy();
    }

    audioAsset.object.mediaController = createBrowserMediaController.call(audioAsset.object, audioAsset);

    if (gainNode) {
        audioAsset.object.mediaController.gainNode = gainNode;
    } else {
        connectMediaNodes(audioAsset.object);
    }
};
