import React from 'react';
import { TemplateElement } from '@bynder-studio/render-core';
import { Button, Inspector, List, Thumbnail, token } from '@bynder/design-system';
import { IconBuildShape, IconSync, IconTextField } from '@bynder/icons';
import { TextElement } from 'packages/pages/design/components/TextElement';
import { useShotItem } from 'packages/pages/design/sidebar/shots/useShotItem';
import { VideoPreview } from 'packages/pages/editor/RightSideMenu/FormComponents/BrowseButton/VideoPreview';
import ImageVideoElement from 'packages/pages/design/components/ImageVideoElement';
import { VideoThumbnail } from 'packages/pages/design/sidebar/shots/Component.styled';
import ShapeElement from 'packages/pages/design/components/ShapeElement';

type Props = {
    isLast: boolean;
    shotElement: TemplateElement;
    isToolbarDisabled: boolean;
    isLoading: boolean;
    isDisabledByReview: boolean;
};

const ShotItem = ({ isLast, shotElement, isToolbarDisabled, isLoading, isDisabledByReview }: Props) => {
    const {
        id,
        type,
        isVisibleOnCanvas,
        isTextElement,
        isImageElement,
        isShapeElement,
        isDisabled,
        isBaseContentVisible,
        element,
        shotToBeHidden,
        allowToggleVisibility,
        toggleShow,
        isContentProperty,
    } = useShotItem({ shotElement, isToolbarDisabled, isLoading, isDisabledByReview });

    if (shotToBeHidden || !element) {
        return null;
    }

    const getThumbnail = () => {
        if (isTextElement || isShapeElement) {
            return (
                <Thumbnail
                    variant="clean"
                    icon={isTextElement ? <IconTextField /> : <IconBuildShape />}
                    iconColor={token.gray400}
                >
                    {isContentProperty && <Thumbnail.Indicator backgroundColor="teal500" icon={<IconSync />} />}
                </Thumbnail>
            );
        } else if (isImageElement) {
            return (
                <Thumbnail variant="content" imageUrl={element.src || shotElement.properties?.value?.thumbnail}>
                    {isContentProperty && <Thumbnail.Indicator backgroundColor="teal500" icon={<IconSync />} />}
                </Thumbnail>
            );
        }

        return (
            <VideoThumbnail variant="clean" icon={<VideoPreview src={element.src} isAlpha={element.isAlpha} />}>
                {isContentProperty && <Thumbnail.Indicator backgroundColor="teal500" icon={<IconSync />} />}
            </VideoThumbnail>
        );
    };

    return (
        <>
            <List>
                <List.Item
                    thumbnail={getThumbnail()}
                    rightElements={
                        allowToggleVisibility ? (
                            <Button isDisabled={isDisabled} variant="clean" onClick={toggleShow}>
                                {isVisibleOnCanvas ? 'Hide' : 'Show'}
                            </Button>
                        ) : undefined
                    }
                >
                    {shotElement.name}
                </List.Item>
                {isBaseContentVisible && isTextElement && (
                    <TextElement
                        key={id}
                        elementId={id}
                        elementName={shotElement.name}
                        disabled={isDisabled || isDisabledByReview}
                        isLoading={isLoading}
                    />
                )}
                {isBaseContentVisible && !isTextElement && !isShapeElement && (
                    <ImageVideoElement
                        key={id}
                        id={id}
                        type={type}
                        disabled={isDisabled || isDisabledByReview}
                        isLoading={isLoading}
                    />
                )}
                {isBaseContentVisible && isShapeElement && (
                    <ShapeElement elementId={shotElement.id} disabled={isDisabled || isDisabledByReview} />
                )}
            </List>
            {!isLast && <Inspector.Divider />}
        </>
    );
};

export default ShotItem;
