import { type FontFamily, setFontFamilies } from '@bynder-studio/structured-text';
import { Dimension } from '../Models/Shared/Dimension';
import { GlobalAudio } from '../Models/Properties/GlobalAudio';
import { PosterFrame } from '../Models/Properties/PosterFrame';
import { ElementTypes } from '../Enums/ElementTypes';
import { PlaybackDuration } from '../Models/Shared/PlaybackDuration';
import { VideoModel } from '../Models/Models/VideoModel';
import { MultiPageVideoModel } from '../Models/Models/MultiPageVideoModel';
import { WebSpecificationParser } from './WebSpecificationParser';
import { Shot } from '../Models/Shot/Shot';
import type { Template, TemplatePage } from '../Renderers/BaseRenderer/IBaseRenderer';

export class VideoWebSpecificationParser extends WebSpecificationParser {
    static parseShots({ shots }: any): Shot[] {
        return shots
            .map((shot) => ({
                ...shot,
                elements: shot.elements.map((el) => el.id),
            }))
            .sort((a, b) => a.startFrame - b.startFrame)
            .map((shot) => new Shot(shot));
    }

    parse(videoSpecification: Template, pageIndex: number, fontFamilies: FontFamily[]): VideoModel {
        setFontFamilies(fontFamilies);
        const testSpec = videoSpecification.pages[0];

        return new VideoModel()
            .setElements(this.parseElements(testSpec, fontFamilies))
            .setBackgroundColor(this.parseBackgroundColor(testSpec))
            .setDimensions(this.parseDimensions(videoSpecification, pageIndex))
            .setGlobalAudioTrack1(this.parseGlobalAudio(testSpec, 1))
            .setGlobalAudioTrack2(this.parseGlobalAudio(testSpec, 2))
            .setPosterFrame(this.parsePosterFrame(testSpec))
            .setPlaybackDuration(this.parsePlaybackDuration(testSpec))
            .setShots(VideoWebSpecificationParser.parseShots(testSpec));
    }

    parseMultiPage(videoSpecification: Template, fontFamilies: FontFamily[]): MultiPageVideoModel {
        const pages = this.parsePages(videoSpecification);

        return new MultiPageVideoModel()
            .setVideoModels(
                pages.map((page) => {
                    const { width, height } = page;
                    const dimension = new Dimension(width, height);

                    return new VideoModel()
                        .setDimensions(dimension)
                        .setElements(this.parseElements(page, fontFamilies))
                        .setBackgroundColor(this.parseBackgroundColor(page))
                        .setGlobalAudioTrack1(this.parseGlobalAudio(page, 1))
                        .setGlobalAudioTrack2(this.parseGlobalAudio(page, 2))
                        .setPosterFrame(this.parsePosterFrame(page))
                        .setPlaybackDuration(this.parsePlaybackDuration(page))
                        .setShots(VideoWebSpecificationParser.parseShots(page));
                }),
            )
            .setVideoModelsMetaData(
                pages.map(({ id, name, displayOrder, format }: TemplatePage) => ({
                    id,
                    name,
                    displayOrder,
                    format,
                })),
            );
    }

    // todo: template video page
    parsePlaybackDuration({ duration, frameRate = 25 }: any): PlaybackDuration {
        if (!duration) {
            throw new Error('PLAYBACK_DURATION_MISSING');
        }

        return new PlaybackDuration(duration, frameRate);
    }

    parsePosterFrame({ elements }: TemplatePage): PosterFrame | null | undefined {
        const posterFrameSpec: any = Object.values(elements).find((el: any) => el.type === ElementTypes.POSTER_FRAME);

        return new PosterFrame(this.getPosterFrameParams(posterFrameSpec));
    }

    parseGlobalAudio({ elements }: TemplatePage, trackNumber: number): GlobalAudio | null | undefined {
        const getGlobalAudioSpecsByName = (...names) => {
            for (const name of names) {
                if (!name) {
                    continue;
                }

                const globalAudioSpecs = Object.values(elements).find((el) => el.name === name);

                if (globalAudioSpecs) {
                    return globalAudioSpecs;
                }
            }

            return null;
        };

        const name = `Global audio track ${trackNumber}`;
        const globalAudioSpecs = getGlobalAudioSpecsByName(name, trackNumber === 1 && 'Global audio');
        const params = this.getGlobalAudioParams(globalAudioSpecs);
        params.name = name;

        if (!globalAudioSpecs) {
            params.id += 10 ** trackNumber;
        }

        return new GlobalAudio(params);
    }
}
