import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { equals } from 'rambda';
import { getUnixTime } from 'date-fns';
import { notify } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import type { MultiPageImageModel, MultiPageVideoModel } from '@bynder-studio/render-core';
import { STATUS } from 'packages/variation-export/types';
import { catchResponseError } from 'packages/helpers/helpers';
import { useOversetValidation } from 'packages/pages/design/VariationsContext/useOversetValidation';
import { VariationsThumbnailsProvider } from 'packages/pages/design/VariationsContext/VariationsThumbnailsProvider';
import {
    ADD_VARIATION_FAILED,
    COPY,
    DELETING_VARIATIONS,
    DELETING_VARIATIONS_FAILED,
    DUPLICATING_VARIATION,
    RENAMING_VARIATION,
    RENAMING_VARIATIONS_FAILED,
    SAVING_VARIATIONS_FAILED,
    VARIATION,
    VARIATION_DUPLICATED,
    VARIATION_DUPLICATION_FAILED,
    VARIATION_NAME_TOO_LONG,
    VARIATION_NAME_TOO_LONG_DESCRIPTION,
    VARIATION_NAME_UPDATED,
    VARIATIONS_DELETED,
} from '~/helpers/textConstants';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import features from '~/configs/features';
import { sendAmplitudeDesignEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import useForceUpdate from '~/hooks/useForceUpdate';
import VariationSetsService from '~/services/VariationSetsService';
import { pendingDesignSavingRequest } from '~/store/creatives/creatives.actions';
import useDesign from '../hooks/useDesign';
import {
    applyVariationDataToCreativeModel,
    cleanUpNewValues,
    isVariationInvalid,
    parseVariation,
    prepareVariationToSave,
} from '../sidebar/variations/utils';

export const VariationsContext = createContext({});
const MAX_VARIATION_COUNT = 50;
export const MAX_VARIATION_NAME_LENGTH = 50;
const MIN_DOUBLE_CHAR_NUMBER = 10;

const getFinalVariationNumber = (number) => (number < MIN_DOUBLE_CHAR_NUMBER ? `0${number}` : number);

const getDeletedAssets = (variation) => {
    const sizes = Object.values(variation.sizes ?? {});

    // Currently it's not neccessary to go through all the sizes,
    // but at some point we're planning to have unique elements in sizes
    const values = sizes
        .filter((item) => item.hasDeletedAsset)
        .flatMap((item) =>
            item.properties.filter((prop) => prop.value?.deleted).map((prop) => prop.elementProperty.templateElementId),
        );

    // removing duplicated ids
    return [...new Set(values)];
};

export default function VariationsProvider({ children }) {
    const dispatch = useDispatch();
    const maxVariationCount = AuthorizationHelper.isFeatureAvailable(features.MULTI_VARIATION_NO_LIMIT)
        ? Infinity
        : MAX_VARIATION_COUNT;
    const {
        creativeId,
        recalculateShots,
        creativeVersionId,
        creativeModel,
        isMultiVersionCreative,
        setUpdatedByVariation,
        setPausePlayback,
        setPlayPlayback,
        isPlaying,
        template,
    } = useDesign();
    const forceUpdate = useForceUpdate();

    const data = useRef({
        variations: [] as any,
        total: 0,
        isLoading: true,
        isLastPage: false,
        isLoadingNextPage: false,
        isLimitExceeded: false,
        page: 0,
        currentVariation: null,
        deletedAssets: [] as any,
    });
    const variationSaveInProgressMap = useRef({});
    const {
        oversetVariationsSet,
        setOversetVariationsSet,
        updateAllVariationsOverset,
        updateVariationOverset,
        validateVariation,
    } = useOversetValidation({
        creativeModel,
    });

    // todo: remove after QA
    // const variationThumbnailRefs = useRef({});
    // variationThumbnailRefs.current = {};

    // const registerVariationThumbnailRef = useCallback(
    //     (variationSetId, key) => (ref) => {
    //         if (!ref) {
    //             return;
    //         }

    //         if (!variationThumbnailRefs.current[variationSetId]) {
    //             variationThumbnailRefs.current[variationSetId] = {};
    //         }

    //         variationThumbnailRefs.current[variationSetId][key] = ref;
    //     },
    //     [],
    // );

    const showLoadingNotification = useCallback(
        (title) =>
            notify({
                title,
                isPersistent: true,
                variant: 'loading',
            }),
        [],
    );

    const updateNotification = useCallback((id, title, variant = 'success', json?) => {
        if (json && json.appErrorCode && json.appErrorCode === 15004) {
            notify({
                id,
                variant: 'error',
                isPersistent: false,
                title: <Translate id="design.save.approval.error" />,
            });
        } else {
            notify({
                id,
                title,
                isPersistent: false,
                variant: variant as 'loading' | 'success' | 'error' | undefined,
            });

            setTimeout(() => {
                notify.dismiss(id);
            }, 1000);
        }
    }, []);

    const checkLimitExceed = useCallback(() => {
        data.current.isLimitExceeded = !isMultiVersionCreative && data.current.total >= maxVariationCount;
    }, [isMultiVersionCreative, maxVariationCount]);

    const checkDynamicLengthEl = useCallback(
        () => creativeModel.getAllElementsRecursively().find((el) => el.useDynamicLength),
        [creativeModel],
    );

    const selectVariation = useCallback(
        (id) => {
            if (id === data.current.currentVariation) {
                return;
            }

            const variation = data.current.variations.find((v) => v.id === id);

            if (!variation || !creativeModel) {
                return;
            }

            saveVariation(data.current.currentVariation);

            data.current.currentVariation = id;
            data.current.deletedAssets = getDeletedAssets(variation);

            applyVariationDataToCreativeModel(variation, creativeModel);

            if (checkDynamicLengthEl()) {
                recalculateShots();
            }

            const wasPlaying = isPlaying();
            setPausePlayback();

            setUpdatedByVariation();
            forceUpdate();

            if (wasPlaying) {
                setPlayPlayback();
            }
        },
        [checkDynamicLengthEl, creativeModel, forceUpdate, recalculateShots, setUpdatedByVariation],
    );

    const getVariations = useCallback(
        (page = 0) =>
            new Promise((resolve, reject) => {
                VariationSetsService.fetchVariationSetsByAggregate(creativeVersionId, {
                    groupBy: 'variation',
                    limit: MAX_VARIATION_COUNT,
                    includeProperties: true,
                    includeHasDeletedAssetFlag: true,
                    orderBy: 'updated',
                    sortingOrder: 'DESC',
                    page,
                })
                    .then(({ json: { items, totalItems } }) => {
                        data.current.total = totalItems;
                        let parsedVariations = [];

                        if (items) {
                            parsedVariations = items.map(({ entries, ...item }) => ({
                                id: item.variationSetId,
                                ...item,
                                sizes: parseVariation({ entries, creativeModel, template }),
                            }));
                        }

                        resolve(parsedVariations);
                    })
                    .then(() => {
                        updateAllVariationsOverset(data.current.variations);
                    })
                    .catch(reject);
            }),
        [creativeVersionId, creativeModel, updateAllVariationsOverset],
    );

    const addVariation = useCallback(
        () =>
            new Promise((resolve, reject) => {
                const newVariationName = `${VARIATION} ${getFinalVariationNumber(data.current.total + 1)}`;
                VariationSetsService.addCreativeVariation(creativeId, newVariationName)
                    .then(({ json, status }) => {
                        const { id: newVariationId, entries, updated, created, customerId } = json;

                        if (status >= 400) {
                            updateNotification(ADD_VARIATION_FAILED, ADD_VARIATION_FAILED, 'error', json);
                            return resolve(null);
                        }

                        dispatch(sendAmplitudeDesignEvent({ eventType: AMPLITUDE_TYPES.NEW_VARIATION }));
                        sendAppcuesEvent('Variation created', { id: newVariationId });

                        const variation = {
                            aggregateBase: 'SET',
                            id: newVariationId,
                            variationSetId: newVariationId,
                            name: newVariationName,
                            customerId,
                            displayOrder: 1,
                            sizes: parseVariation({ entries, creativeModel, template }),
                            created,
                            updated,
                        };

                        data.current.variations.splice(0, 0, variation);
                        data.current.total++;
                        checkLimitExceed();

                        if (template?.approvalState?.status) {
                            if (template?.approvalState?.status !== 'APPROVED') {
                                saveVariationsOrdering(data.current.variations);
                            }
                        } else {
                            saveVariationsOrdering(data.current.variations);
                        }

                        forceUpdate();
                        resolve(variation);
                    })
                    .catch((exception) => {
                        updateNotification(ADD_VARIATION_FAILED, ADD_VARIATION_FAILED, 'error');
                        reject(exception);
                    });
            }),
        [creativeId, dispatch, getVariations, checkLimitExceed, forceUpdate],
    );

    const createDefaultVariation = useCallback(() => {
        return addVariation()
            .then((variation) => {
                if (!variation) {
                    return;
                }

                selectVariation(variation.id);
            })
            .catch(catchResponseError);
    }, [addVariation, selectVariation]);

    const renameVariationLongError = useCallback(() => {
        notify({
            title: VARIATION_NAME_TOO_LONG,
            description: VARIATION_NAME_TOO_LONG_DESCRIPTION,
            isPersistent: false,
            variant: 'error',
        });
    }, []);

    const renameVariation = useCallback(
        (id, newName) =>
            new Promise((resolve, reject) => {
                const notificationId = showLoadingNotification(RENAMING_VARIATION);
                const variation = data.current.variations.find((v) => v.id === id);
                const oldName = variation.name;
                variation.name = newName;
                forceUpdate();

                VariationSetsService.renameVariationSet(id, newName)
                    .then(({ json, status }) => {
                        if (status === 200) {
                            updateNotification(notificationId, VARIATION_NAME_UPDATED);
                            data.current.variations = data.current.variations.map((v) =>
                                v.id === id ? { ...v, name: newName } : v,
                            );

                            forceUpdate();
                            resolve();
                            sendAppcuesEvent('Variation renamed', { id, name: newName });
                        } else {
                            updateNotification(notificationId, RENAMING_VARIATIONS_FAILED, 'error', json);
                            variation.name = oldName;
                            forceUpdate();
                        }
                    })
                    .catch((ex) => {
                        updateNotification(notificationId, RENAMING_VARIATIONS_FAILED, 'error');
                        variation.name = oldName;
                        forceUpdate();
                        reject(ex);
                    });
            }),
        [forceUpdate, showLoadingNotification, updateNotification],
    );

    const sortVariations = useCallback(
        (orderBy) => {
            if (orderBy === 'updated') {
                data.current.variations.sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime());
            }

            if (orderBy === 'name') {
                data.current.variations.sort((a, b) => a.name.localeCompare(b.name));
            }

            updateAllVariationsOverset(data.current.variations);
            forceUpdate();
        },
        [forceUpdate],
    );

    const deleteVariations = useCallback(
        (ids) =>
            new Promise((resolve, reject) => {
                const notificationId = showLoadingNotification(DELETING_VARIATIONS);

                VariationSetsService.deleteVariationSets(ids)
                    .then(({ json, status }) => {
                        if (status === 200) {
                            updateNotification(notificationId, VARIATIONS_DELETED, 'success');

                            data.current.variations = data.current.variations.filter((v) => !ids.includes(v.id));
                            data.current.total -= ids.length;

                            if (data.current.total === 0) {
                                createDefaultVariation();
                            }

                            checkLimitExceed();
                            updateAllVariationsOverset(data.current.variations);
                            forceUpdate();

                            if (ids.includes(data.current.currentVariation) && data.current.variations.length) {
                                selectVariation(data.current.variations[0].id);
                            }

                            resolve();
                            sendAppcuesEvent('Variations removed', { ids });
                        } else {
                            updateNotification(notificationId, DELETING_VARIATIONS_FAILED, 'error', json);
                        }
                    })
                    .catch((ex) => {
                        updateNotification(notificationId, DELETING_VARIATIONS_FAILED, 'error');
                        reject(ex);
                    });
            }),
        [
            showLoadingNotification,
            updateNotification,
            checkLimitExceed,
            forceUpdate,
            createDefaultVariation,
            selectVariation,
            updateAllVariationsOverset,
        ],
    );

    const getNameForDuplicated = useCallback((originalName) => {
        const letterLengthDiff = MAX_VARIATION_NAME_LENGTH - originalName.length;

        if (letterLengthDiff >= COPY.length) {
            return `${originalName}${COPY}`;
        }

        const firstIndexOfCopy = originalName.indexOf(COPY);

        // name has many letters but no "copy" word
        if (firstIndexOfCopy === -1) {
            return `${originalName.slice(0, originalName.length - COPY.length)}${COPY}`;
        }

        // if name is already consists from the copy words - return original name
        if (firstIndexOfCopy <= COPY.length) {
            return originalName;
        }

        const copyWordsPart = originalName.slice(firstIndexOfCopy);
        const slicedOriginalNamePart = originalName.slice(0, firstIndexOfCopy - COPY.length);

        // if name has copy words from prev. duplicating
        return `${slicedOriginalNamePart}${COPY}${copyWordsPart}`;
    }, []);

    const duplicateVariation = useCallback(
        (id) =>
            new Promise((resolve, reject) => {
                const item = data.current.variations.find((v) => v.id === id);

                if (!item || !creativeModel) {
                    return reject();
                }

                const notificationId = showLoadingNotification(DUPLICATING_VARIATION);
                const name = getNameForDuplicated(item.name);
                const desiredCreativeModel = creativeModel.getCopy() as MultiPageVideoModel | MultiPageImageModel;
                applyVariationDataToCreativeModel(item, desiredCreativeModel);
                const creativeSizeProperties = prepareVariationToSave({
                    template,
                    variation: item,
                    creativeModel: desiredCreativeModel,
                });

                return VariationSetsService.addCreativeVariation(creativeId, name, creativeSizeProperties)
                    .then(({ json, status }) => {
                        const { id: newVariationId, entries, updated, created, customerId } = json;

                        if (status === 200) {
                            sendAppcuesEvent('Variation duplicated', { id, newVariationId });

                            updateNotification(notificationId, VARIATION_DUPLICATED);

                            const duplicatedVariation = {
                                aggregateBase: 'SET',
                                id: newVariationId,
                                variationSetId: newVariationId,
                                name,
                                customerId,
                                displayOrder: 1,
                                sizes: parseVariation({ entries, creativeModel, template }),
                                created,
                                updated,
                            };

                            data.current.variations.splice(0, 0, duplicatedVariation);
                            data.current.total++;
                            checkLimitExceed();
                            saveVariationsOrdering(data.current.variations);
                            forceUpdate();
                            resolve(duplicatedVariation);
                        } else {
                            updateNotification(notificationId, VARIATION_DUPLICATION_FAILED, 'error', json);
                        }
                    })
                    .catch((ex) => {
                        updateNotification(notificationId, VARIATION_DUPLICATION_FAILED, 'error');
                        reject(ex);
                    });
            }),
        [
            showLoadingNotification,
            getNameForDuplicated,
            creativeModel,
            creativeId,
            getVariations,
            updateNotification,
            checkLimitExceed,
            forceUpdate,
        ],
    );

    const batchDuplicateVariations = useCallback(
        (ids) =>
            new Promise((resolve, reject) => {
                const notificationId = showLoadingNotification(DUPLICATING_VARIATION);

                const variationSets = ids.map((id) => {
                    const item = data.current.variations.find((v) => v.id === id);
                    const name = getNameForDuplicated(item.name);
                    const propertiesNamedPerPage = prepareVariationToSave({ template, variation: item, creativeModel });

                    return { name, propertiesNamedPerPage };
                });

                return VariationSetsService.addBatchCreativeVariations(creativeId, { variationSets })
                    .then(({ json, status }) => {
                        if (status >= 400) {
                            return reject(json);
                        }

                        const idsForTrackingThumbnails = [];
                        const { items: newVariations } = json;
                        sendAppcuesEvent('Variations duplicated', { ids });
                        getVariations()
                            .then((items) => {
                                updateNotification(notificationId, VARIATION_DUPLICATED);

                                newVariations.reverse().forEach(({ id }) => {
                                    const variation = items.find((v) => v.id === id);

                                    if (variation) {
                                        idsForTrackingThumbnails.push(variation.id);
                                        data.current.variations.splice(0, 0, variation);
                                    }
                                });

                                checkLimitExceed();
                                forceUpdate();
                                resolve(newVariations);
                            })
                            .catch(catchResponseError);
                    })
                    .catch((ex) => {
                        updateNotification(notificationId, VARIATION_DUPLICATION_FAILED, 'error');
                        reject(ex);
                    });
            }),
        [
            showLoadingNotification,
            creativeId,
            getNameForDuplicated,
            creativeModel,
            getVariations,
            updateNotification,
            checkLimitExceed,
            forceUpdate,
        ],
    );

    const getVariationsAndSave = useCallback(() => {
        if (data.current.deletedAssets.length > 0) {
            return;
        }

        getVariations()
            .then((items) => {
                data.current.isLoading = false;
                data.current.isLastPage = items.length < 20;
                data.current.variations = items;

                if (!items.length) {
                    setUpdatedByVariation();

                    return createDefaultVariation();
                }

                if (!data.current.currentVariation) {
                    const [variation] = items;
                    selectVariation(variation.id);
                }

                forceUpdate();
                verifyVariationsOrdering();
            })
            .catch(catchResponseError);
    }, [getVariations, forceUpdate, setUpdatedByVariation, createDefaultVariation, selectVariation]);

    const saveVariation = useCallback(
        (id, callback?, resetPages = []) => {
            const variation = data.current.variations.find((v) => v.id === id);

            if (!variation || !creativeModel) {
                return;
            }

            if (!variation.hasUnsavedChanges) {
                if (callback) {
                    callback();
                }

                return;
            }

            const isValid = getDeletedAssets(variation).length === 0;
            const isFixed = data.current.currentVariation === id && data.current.deletedAssets.length === 0;
            const inProgress = id in variationSaveInProgressMap.current;

            if (!(isValid || isFixed)) {
                return;
            }

            if (!inProgress) {
                variationSaveInProgressMap.current[id] = [];
            }

            if (callback) {
                variationSaveInProgressMap.current[id].push(callback);
            }

            if (inProgress) {
                return new Promise((resolve) => {
                    variationSaveInProgressMap.current[id].push(resolve);
                });
            }

            const saveTime = getUnixTime(new Date());
            const { name } = variation;

            const resetAllPages = resetPages.length === Object.keys(variation.sizes).length;
            const creativeModelCopy = creativeModel.getCopy() as MultiPageVideoModel | MultiPageImageModel;
            const creativeSizeProperties = resetAllPages
                ? {}
                : prepareVariationToSave({ template, variation, creativeModel: creativeModelCopy, resetPages });

            Object.values(variation.sizes).forEach((sizeItem) => {
                if (!sizeItem?.previewRendition) {
                    return;
                }

                sizeItem.previewRendition.status = STATUS.PENDING;
            });

            forceUpdate();

            return VariationSetsService.saveCreativeVariation(creativeId, id, name, creativeSizeProperties)
                .then(({ json, status }) => {
                    dispatch(pendingDesignSavingRequest(true));

                    if (status >= 400) {
                        updateNotification(SAVING_VARIATIONS_FAILED, SAVING_VARIATIONS_FAILED, 'error', json);
                        dispatch(pendingDesignSavingRequest(false));

                        return;
                    }

                    // INVALID: DELETE OR CORRECT LATER
                    // dispatch(sendAmplitudeDesignEvent({ eventType: AMPLITUDE_TYPES.SAVE_VARIATION }));
                    sendAppcuesEvent('Variation saved', { id, name });

                    const { id: newVariationSetId, entries, updated } = json;

                    variation.variationSetId = newVariationSetId;
                    variation.oldId = variation.id;
                    variation.id = newVariationSetId;
                    variation.updated = updated;

                    if (saveTime >= variation.hasUnsavedChangesTimestamp) {
                        variation.sizes = parseVariation({ entries, creativeModel: creativeModelCopy, template });
                        variation.hasUnsavedChanges = false;
                        variation.hasUnsavedChangesTimestamp = 0;
                        variation.newValues = {};
                    }

                    const isSelectedNow = data.current.currentVariation === id;

                    if (isSelectedNow) {
                        data.current.currentVariation = newVariationSetId;

                        if (resetAllPages) {
                            applyVariationDataToCreativeModel(variation, creativeModel);

                            if (checkDynamicLengthEl()) {
                                recalculateShots();
                            }
                        }
                    }

                    const hasOversetText = validateVariation(variation);

                    if (hasOversetText) {
                        setOversetVariationsSet((prev) => {
                            const temp = new Set(prev);
                            temp.delete(id);
                            temp.add(newVariationSetId);
                            return temp;
                        });
                    }

                    variationSaveInProgressMap.current[id].forEach((cb) => cb());
                    forceUpdate();
                })
                .catch((err) => {
                    updateNotification(SAVING_VARIATIONS_FAILED, SAVING_VARIATIONS_FAILED, 'error');
                    catchResponseError(err);
                })
                .finally(() => {
                    delete variationSaveInProgressMap.current[id];
                    dispatch(pendingDesignSavingRequest(false));
                });
        },
        [
            creativeId,
            creativeModel,
            dispatch,
            forceUpdate,
            getVariationsAndSave,
            showLoadingNotification,
            validateVariation,
            checkDynamicLengthEl,
            recalculateShots,
        ],
    );

    const addNewUnsavedValue = useCallback(
        ({
            variation,
            pageId,
            elementId,
            newValues,
        }: {
            variation: any;
            pageId: number;
            elementId: number;
            newValues: { [propName: string]: any };
        }) => {
            if (!variation.newValues) {
                variation.newValues = {};
            }

            const prevValue = variation.newValues?.[pageId]?.[elementId] || new Set([]);
            const newValueSet = new Set([...prevValue, ...Object.keys(newValues)]);

            variation.newValues = {
                ...variation.newValues,
                [pageId]: {
                    ...(variation.newValues?.[pageId] || {}),
                    [elementId]: newValueSet,
                },
            };
        },
        [],
    );

    const updateVariationElement = useCallback((pageId, elementId, rawNewValues) => {
        const { variations, currentVariation } = data.current;

        if (!(Array.isArray(variations) && variations.length && currentVariation) || !pageId) {
            return;
        }

        const newValues = cleanUpNewValues(rawNewValues);

        const variation = variations.find((v) => v.id === currentVariation);

        if (!variation) {
            return;
        }

        const sizeElement = variation?.sizes[pageId]?.elements[elementId];
        const hasChanges = !sizeElement
            ? !!newValues
            : !Object.entries(newValues)?.every(([key, value]) =>
                  key === 'id' ? true : equals(sizeElement[key], value),
              );

        addNewUnsavedValue({ variation, pageId, elementId, newValues });

        variation.hasUnsavedChanges ||= hasChanges;
        variation.hasUnsavedChangesTimestamp = getUnixTime(new Date());
        variation.sizes[pageId].elements[elementId] = {
            ...sizeElement,
            ...newValues,
        };
    }, []);

    const updateVariationGlobalProperty = useCallback(
        (elementId, newValues) => {
            const { variations, currentVariation } = data.current;

            if (!(Array.isArray(variations) && variations.length && currentVariation)) {
                return;
            }

            const variation = variations.find((v) => v.id === currentVariation);

            if (!variation || !variation.sizes) {
                return;
            }

            Object.values(variation.sizes).forEach((size) => {
                const sizeElement = size.elements[elementId] || {};
                const hasChanges = !sizeElement
                    ? !!newValues
                    : !Object.entries(newValues)?.every(([key, value]) =>
                          key === 'id' ? true : equals(sizeElement[key], value),
                      );

                variation.hasUnsavedChanges ||= hasChanges;
                variation.hasUnsavedChangesTimestamp = getUnixTime(new Date());

                addNewUnsavedValue({ variation, pageId: size.sizeId, elementId, newValues });

                size.elements[elementId] = {
                    ...(sizeElement || {}),
                    ...newValues,
                };
            });
        },
        [addNewUnsavedValue],
    );

    const hasValidVariations = useCallback(() => {
        if (!data.current.variations.length) {
            return false;
        }

        if (data.current.variations.length === oversetVariationsSet.size) {
            return false;
        }

        return !!data.current.variations.find((variation) => {
            return Object.values(variation.sizes).every((size) => !isVariationInvalid(size));
        });
    }, [oversetVariationsSet.size]);

    const hasInvalidVariations = useCallback(() => {
        if (!data.current.variations.length) {
            return true;
        }

        if (data.current.variations.length === oversetVariationsSet.size) {
            return true;
        }

        return !!data.current.variations.find((variation) => {
            return Object.values(variation.sizes).some((size) => isVariationInvalid(size));
        });
    }, [oversetVariationsSet.size]);

    const removeDeletedAsset = useCallback(
        (id) => {
            if (!data.current.deletedAssets.includes(id)) {
                return;
            }

            data.current.deletedAssets = data.current.deletedAssets.filter((item) => item !== id);

            if (data.current.deletedAssets.length === 0) {
                saveVariation(data.current.currentVariation);
            } else {
                forceUpdate();
            }
        },
        [forceUpdate, saveVariation],
    );

    const saveVariationsOrdering = useCallback(
        <T extends { displayOrder?: number; name: string; id: string }>(reorderedList: T[]) => {
            if (template?.approvalState?.status === 'APPROVED') {
                return;
            }

            data.current.variations = reorderedList.map((item, idx) => ({
                ...item,
                displayOrder: idx + 1,
            }));
            forceUpdate();

            return VariationSetsService.saveVariationsOrdering(
                data.current.variations.map((v) => ({
                    id: v.id,
                    name: v.name,
                    displayOrder: v.displayOrder,
                })),
            )
                .then((res) => {
                    if (res.status >= 400) {
                        updateNotification(
                            <Translate id="design.save.order.error.message" />,
                            <Translate id="design.save.order.error.message" />,
                            'error',
                            res.json,
                        );
                    }

                    return res;
                })
                .catch(catchResponseError);
        },
        [forceUpdate, VariationSetsService.saveVariationsOrdering],
    );

    const verifyVariationsOrdering = useCallback(() => {
        const hasUnorderedVariation = data.current.variations.some((v) => typeof v.displayOrder !== 'number');
        const duplicatedVariationOrdersMap = data.current.variations.reduce((acc: Record<number, string[]>, v: any) => {
            if (acc[v.displayOrder]) {
                acc[v.displayOrder].push(v.id);
            } else {
                acc[v.displayOrder] = [v.id];
            }

            return acc;
        }, {});

        const isDuplicatedOrders = Object.values(duplicatedVariationOrdersMap).some((ids: any) => ids.length > 1);

        if (hasUnorderedVariation || isDuplicatedOrders) {
            saveVariationsOrdering(data.current.variations);
        }
    }, [saveVariationsOrdering]);

    const isCurrentVariationPending = variationSaveInProgressMap.current.hasOwnProperty(
        data.current.currentVariation as unknown as string,
    );

    useEffect(() => {
        if (!creativeModel || !creativeId || data.current.deletedAssets.length > 0) {
            return;
        }

        getVariationsAndSave();
    }, [creativeId, creativeModel]);

    const value = {
        ...data.current,
        selectVariation,
        getVariations,
        getVariationsAndSave,
        addVariation,
        renameVariation,
        renameVariationLongError,
        deleteVariations,
        hasValidVariations,
        hasInvalidVariations,
        duplicateVariation,
        batchDuplicateVariations,
        saveVariation,
        addNewUnsavedValue,
        updateVariationElement,
        updateVariationGlobalProperty,
        maxVariationCount,
        isCurrentVariationPending,
        variationSaveInProgressMap,
        removeDeletedAsset,
        oversetVariationsSet,
        updateVariationOverset,
        sortVariations,
        saveVariationsOrdering,
    };

    return (
        <VariationsContext.Provider value={value}>
            <VariationsThumbnailsProvider>{children}</VariationsThumbnailsProvider>
        </VariationsContext.Provider>
    );
}
