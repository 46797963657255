import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { VerticalNav } from '@bynder/design-system';
import {
    IconDevice,
    IconArrowBack,
    IconUpload,
    IconResolutionsOutline,
    IconVideoComponent,
    IconVideoTemplate,
    IconFigma,
} from '@bynder/icons';
import { Translate } from '@bynder/localization';
import { setPage } from 'packages/store/newDesign/newDesign.actions';
import { getNewDesignPage, getNewDesignPageHistory } from 'packages/store/newDesign/newDesign.selectors';
import useQueryParams from 'packages/hooks/useQueryParams';
import { ModalSidebar, StyledSidebar, VerticalNavBackAction } from './sidebar.styled';
import {
    DesignSidebarItemType,
    DesignSubPages,
    GetDefaultSidebarItems,
    SidebarProps,
    SidebarPermissions,
} from './types';

export const getSidebarItems: GetDefaultSidebarItems = (permissions: SidebarPermissions) => ({
    top: [
        {
            title: <Translate id="modal.design.create.sidebar.all" />,
            to: [DesignSubPages.ALL, DesignSubPages.TEMPLATE_PREVIEW],
            icon: <IconDevice />,
            hide: false,
            path: '?create=true&page=all',
        },
        {
            title: <Translate id="modal.design.create.sidebar.blank" />,
            to: [DesignSubPages.BLANK],
            icon: <IconResolutionsOutline />,
            hide: false,
            path: '?create=true&page=blank',
        },
        {
            title: <Translate id="modal.design.create.sidebar.templates" />,
            to: [DesignSubPages.CATEGORIES, DesignSubPages.TEMPLATE_PREVIEW],
            icon: <IconVideoTemplate />,
            hide: false,
            path: '?create=true&page=categories&categoryId=ALL',
        },
        {
            title: <Translate id="modal.design.create.sidebar.gallery" />,
            to: [DesignSubPages.GALLERY, DesignSubPages.GALLERY_PREVIEW],
            icon: <IconVideoComponent />,
            hide: permissions.isGalleryDisabled,
            path: '?create=true&page=gallery',
        },
        {
            title: <Translate id="modal.design.create.sidebar.import" />,
            to: [DesignSubPages.IMPORT, DesignSubPages.IMPORT_STATUS],
            icon: <IconUpload />,
            hide: permissions.isImportDisabled,
            path: '?create=true&page=import',
        },
        {
            title: <Translate id="modal.design.create.figma_import.title" />,
            to: [DesignSubPages.FIGMA_IMPORT],
            icon: <IconFigma />,
            hide: false,
            path: '?create=true&page=figma_import',
        },
    ].filter((item: DesignSidebarItemType) => !item.hide),
    bottom: [].filter((item: DesignSidebarItemType) => !item.hide),
});

const DesignCreateSidebar = ({ sidebarItems, backButton }: SidebarProps) => {
    const dispatch = useDispatch();
    const activePage = useSelector(getNewDesignPage);
    const history = useSelector(getNewDesignPageHistory);
    const { getQueryParam } = useQueryParams();

    const isSelectedPageActive = (item) => {
        if (activePage === DesignSubPages.TEMPLATE_PREVIEW || activePage === DesignSubPages.GALLERY_PREVIEW) {
            if (getQueryParam('templates')) {
                return item.to[0] === DesignSubPages.CATEGORIES;
            }

            return history[0] === item.to[0];
        }

        if (activePage === DesignSubPages.CATEGORIES || activePage === DesignSubPages.CATEGORY_DETAILS) {
            const queriedCategoryId = getQueryParam('categoryId');

            return item.path.includes(`categoryId=${queriedCategoryId}`);
        }

        return item.to[0] === activePage;
    };

    return (
        <ModalSidebar>
            <StyledSidebar data-testid="new design sidebar">
                {backButton && (
                    <VerticalNav>
                        <VerticalNavBackAction onClick={backButton.onClick} icon={<IconArrowBack />}>
                            {backButton.title}
                        </VerticalNavBackAction>
                    </VerticalNav>
                )}
                {['top', 'bottom'].map((type) => {
                    const items = sidebarItems[type];

                    return items.length ? (
                        <VerticalNav key={type}>
                            {items.map((item) => {
                                if (item.to[0] === DesignSubPages.IMPORT) {
                                    return (
                                        <>
                                            <VerticalNav.Title>Import</VerticalNav.Title>
                                            <VerticalNav.Item
                                                key={item.to}
                                                icon={item.icon}
                                                isActive={isSelectedPageActive(item)}
                                                onClick={() => dispatch(setPage(item.to[0]))}
                                                as={NavLink}
                                                to={item.path}
                                            >
                                                {item.title}
                                            </VerticalNav.Item>
                                        </>
                                    );
                                }
                                return (
                                    <VerticalNav.Item
                                        key={item.to}
                                        icon={item.icon}
                                        isActive={isSelectedPageActive(item)}
                                        onClick={() => dispatch(setPage(item.to[0]))}
                                        as={NavLink}
                                        to={item.path}
                                    >
                                        {item.title}
                                    </VerticalNav.Item>
                                );
                            })}
                        </VerticalNav>
                    ) : null;
                })}
            </StyledSidebar>
        </ModalSidebar>
    );
};

export default DesignCreateSidebar;
