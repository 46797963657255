import { ListenerFn } from 'eventemitter2';
import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementTypes } from '../../Enums/ElementTypes';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import { exportSpecificationTemplate } from '../../SpecificationParser/SpecificationExporter';
import { BaseModel } from './BaseModel';
import {
    type AudioElementParams,
    type ContentPropertiesSettings,
    type CreateElementOptions,
    type ElementForContentProperty,
    type ElementUpdateOptions,
    type ModelMetadata,
    type PageDuplicateParams,
    type Reason,
    type RemoveElementOptions,
    type UpdateElementOptions,
    type UpdateGlobalPropertyOptions,
    type VisualElement,
} from '../../types';
import { Dimension } from '../Shared/Dimension';
import { ElementUpdatedEventData, EventDataMap, TextStyleChangeEventData } from '../../event-types';
import { GroupElement } from '../Elements/GroupElement';
import { BackgroundColor, BackgroundColorParams, defaultBackgroundColorParams } from '../Properties/BackgroundColor';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { TextElement } from '../Elements/TextElement';
import { Ranges } from '../../Helpers/Ranges';
import { MaskModeTypes } from '../../Enums/MaskModeTypes';
import { TextStyles } from '../Shared/TextStyles';
import { BaseProperty } from '../Properties/BaseProperty';
import type { ContentPropertiesManager } from '../../Helpers/ContentPropertiesManager';
import { matchElementTypeToContentPropertySetting } from '../../Helpers/elementUtils';
import { updateListOrderByIdxs } from '../../Helpers/utils';
import { IAsset } from '../Assets/IAsset';
import { type EventAccumulator } from '../Shared/EventAccumulator';

export abstract class BaseMultiPageModel<TModel extends BaseModel = BaseModel> extends BaseModel {
    eventEmitter: DynamicEventEmitter = new DynamicEventEmitter();

    protected index = 0;

    protected listeners: ListenerFn[] = [];

    protected models: TModel[] = [];

    protected modelsMetaData: ModelMetadata[] = [];

    protected contentPropertiesManager!: ContentPropertiesManager;

    createModel(): TModel {
        throw new Error('Method not implemented.');
    }

    setAssetLoader(assetLoader: IAssetsLoader) {
        this.assetLoader = assetLoader;
        this.models.forEach((model) => {
            model.setAssetLoader(assetLoader);
        });

        return this;
    }

    getAssetLoader(): IAssetsLoader {
        return this.assetLoader;
    }

    setTextStyles(textStyles: TextStyles) {
        this.textStyles = textStyles;
        textStyles.setEventEmitter(this.eventEmitter);

        this.models.forEach((model) => {
            model.setTextStyles(textStyles);
        });

        return this;
    }

    setContentPropertiesManager(contentPropertiesManager: ContentPropertiesManager) {
        this.contentPropertiesManager = contentPropertiesManager;
        this.contentPropertiesManager.setEventEmitter(this.eventEmitter);
        this.contentPropertiesManager.setMultiPageModel(this);

        return this;
    }

    getTextStyles(): TextStyles {
        return this.textStyles;
    }

    getContentPropertiesManager(): ContentPropertiesManager {
        return this.contentPropertiesManager;
    }

    setup() {
        this.models.forEach((model, index) => {
            model.setup();
            this.subscribeToModelEvents(model, index);
        });

        this.subscribe();

        return this;
    }

    getEventEmitter(): DynamicEventEmitter {
        return this.eventEmitter;
    }

    getCurrentPageIndex(): number {
        return this.index;
    }

    setCurrentPageIndex(index: number) {
        if (this.index === index) {
            return this;
        }

        this.eventEmitter.emit('requestToChangeCurrentPage');
        this.index = index;
        this.eventEmitter.emit('currentPageChange', index);

        return this;
    }

    getCurrentPageId() {
        return this.modelsMetaData[this.index].id;
    }

    getCurrentModel() {
        return this.models[this.index];
    }

    getModelByPageId(pageId: string) {
        const modelIdx = this.modelsMetaData.findIndex((modelMetaData) => modelMetaData.id === pageId);

        if (modelIdx !== -1) {
            return this.models[modelIdx];
        }
    }

    getModels() {
        return this.models;
    }

    setModels(models: TModel[]) {
        this.models = models;

        return this;
    }

    getModelsMetaData() {
        return this.modelsMetaData;
    }

    setModelsMetaData(modelsMetaData: ModelMetadata[]) {
        this.modelsMetaData = modelsMetaData;
        this.eventEmitter.emit('currentPageChange', this.index);

        return this;
    }

    protected createPage(name: string, width: number, height: number, format: ModelMetadata['format'] = null): TModel {
        const dimension = new Dimension(width, height);

        return this.createModel()
            .setElements([])
            .setBackgroundColor(new BackgroundColor(defaultBackgroundColorParams))
            .setDimensions(dimension)
            .setAssetLoader(this.assetLoader)
            .setTextStyles(this.textStyles) as TModel;
    }

    public addPage(name: string, width: number, height: number, format: ModelMetadata['format']) {
        const model = this.createPage(name, width, height, format).setup() as TModel;

        return this.addModel(model, name, width, height, format);
    }

    protected addModel(model: TModel, name: string, width: number, height: number, format: ModelMetadata['format']) {
        const newPageId = this.generatePageId();

        const metaData: ModelMetadata = {
            id: newPageId,
            name,
            displayOrder: this.models.length + 1,
            format,
            dimensions: { width, height },
        };

        const pageIndex = this.models.push(model) - 1;
        this.modelsMetaData.push(metaData);

        this.subscribeToModelEvents(model, pageIndex);

        this.eventEmitter.emit('pageAdded', {
            model,
            videoModel: model, // for backward compatibility
            metaData,
            pageIndex,
        });
        this.setCurrentPageIndex(pageIndex);

        return model;
    }

    removePage(pageIndex: number) {
        if (pageIndex >= this.models.length || pageIndex < 0) {
            throw new Error("There isn't creative model with such index");
        }

        const metaData: ModelMetadata = this.modelsMetaData[pageIndex];
        const model = this.models[pageIndex];

        this.unsubscribeFromModelEvents(model, pageIndex);
        this.modelsMetaData.splice(pageIndex, 1);
        this.models.splice(pageIndex, 1);

        this.eventEmitter.emit('pageRemoved', {
            model,
            videoModel: model,
            metaData,
            pageIndex,
        });

        if (this.index >= pageIndex) {
            let newIndex = this.index - 1;

            if (newIndex < 0 && this.models.length > 0) {
                newIndex = pageIndex;
            }

            this.index = -1;
            this.setCurrentPageIndex(newIndex);
        }

        // Update remaining page indexes after deletion
        const newModelsMeta = [];

        this.models.forEach((model, index) => {
            const modelMeta = this.modelsMetaData[index];
            const newmodelMeta = { ...modelMeta, displayOrder: index + 1 };

            newModelsMeta.push(newmodelMeta);
        });

        if (newModelsMeta) {
            this.setModelsMetaData(newModelsMeta);
        }

        return model;
    }

    renamePage(pageIndex: number, newName: string) {
        if (pageIndex >= this.models.length || pageIndex < 0) {
            throw new Error('There is no creative model exists with this index');
        }

        this.modelsMetaData[pageIndex].name = newName;

        this.eventEmitter.emit('pageRenamed', {
            model: this.models[pageIndex],
            metaData: this.modelsMetaData[pageIndex],
            pageIndex,
        });

        return this;
    }

    updatePageOrder(order: number[], pagesMeta: ModelMetadata[]) {
        const currentPageId = this.getCurrentPageId();

        this.models = updateListOrderByIdxs(this.models, order);
        this.modelsMetaData = pagesMeta;

        const updatedPageIdx = this.modelsMetaData.findIndex((meta) => meta.id === currentPageId);

        if (updatedPageIdx >= 0 && this.getCurrentPageIndex() !== updatedPageIdx) {
            this.setCurrentPageIndex(updatedPageIdx);
        }

        return this;
    }

    public duplicatePage(
        pageIndex: number,
        params: PageDuplicateParams,
        contentPropertiesSettings: ContentPropertiesSettings,
    ) {
        if (pageIndex >= this.models.length || pageIndex < 0) {
            throw new Error('There is no creative model exists with this index');
        }

        const { model } = this.getPageDuplicate(pageIndex, contentPropertiesSettings);
        model.setup();

        return this.addDuplicatedPage(pageIndex, model, params);
    }

    protected getPageDuplicate(pageIndex: number, contentPropertiesSettings?: ContentPropertiesSettings) {
        const model = this.models[pageIndex]
            .getCopy()
            .setAssetLoader(this.assetLoader)
            .setTextStyles(this.textStyles) as TModel;

        let id = Date.now() * 2;
        const idMap = new Map();

        model.getAllGlobalProperties().forEach((property: BaseProperty) => {
            const oldId = property.id;
            property.id = ++id;
            idMap.set(oldId, property.id);
        });
        model.getAllElementsRecursively().forEach((element: VisualElement) => {
            const oldId = element.id;
            element.id = ++id;
            idMap.set(oldId, element.id);

            if (
                contentPropertiesSettings &&
                !element.contentPropertyId &&
                matchElementTypeToContentPropertySetting(element, contentPropertiesSettings)
            ) {
                const propertyId = this.contentPropertiesManager.createContentPropertyFromElement(
                    element as ElementForContentProperty,
                    element.name,
                );

                if (propertyId) {
                    this.models[pageIndex].updateElement(oldId, { contentPropertyId: propertyId });
                    element.contentPropertyId = propertyId;
                }
            }
        });
        model.getAllElementsRecursively().forEach((element: VisualElement) => {
            if (element.mask) {
                element.mask.elementId = idMap.get(element.mask.elementId);
            }
        });

        return { model, idMap };
    }

    protected addDuplicatedPage(pageIndex: number, model: TModel, params: PageDuplicateParams) {
        const metaData = {
            id: this.generatePageId(),
            name: (params || {}).name || `${this.modelsMetaData[pageIndex].name} Copy`,
            displayOrder: this.models.length + 1,
            dimensions: { width: params.dimensions.width, height: params.dimensions.height },
            format: params.dimensions.format,
        };

        if ((params || {}).dimensions) {
            model.updateDimension(params.dimensions);
        }

        const index = this.models.push(model) - 1;
        this.modelsMetaData.push(metaData);
        this.subscribeToModelEvents(model, index);

        this.getEventEmitter().emit('pageAdded', {
            model,
            metaData,
            pageIndex: index,
        });
        this.setCurrentPageIndex(index);

        return model;
    }

    getCompModel(frameIndex = 0) {
        return this.getCurrentModel().getCompModel(frameIndex);
    }

    beginAccumulation(): void {
        this.getCurrentModel().beginAccumulation();
    }

    endAccumulation(reason: Reason = 'user'): void {
        this.getCurrentModel().endAccumulation(reason);
    }

    setElements(elements: VisualElement[]): this {
        throw new Error('Not Allowed method "setElements" for the MultiPageModel');
    }

    getElements() {
        return this.getCurrentModel().getElements();
    }

    getAllElementsRecursively(): VisualElement[] {
        return this.getCurrentModel().getAllElementsRecursively();
    }

    getAllGlobalProperties(): BaseProperty[] {
        return this.getCurrentModel().getAllGlobalProperties() as BaseProperty[];
    }

    getElementById(id: number | string): VisualElement | undefined {
        return this.getCurrentModel().getElementById(id);
    }

    getNextElementSibling(id: number): VisualElement | null {
        return this.getCurrentModel().getNextElementSibling(id);
    }

    getPrevElementSibling(id: number): VisualElement | null {
        return this.getCurrentModel().getPrevElementSibling(id);
    }

    getElementsByAssetUsage(asset: IAsset): VisualElement[] {
        const elements = [];
        this.models.forEach((model) => {
            model.getAllElementsRecursively().forEach((element) => {
                if (element.isContainsAsset(asset)) {
                    elements.push(element);
                }
            });
        });

        return elements;
    }

    setBackgroundColor(backgroundColor: BackgroundColor) {
        this.getCurrentModel().setBackgroundColor(backgroundColor);

        return this;
    }

    getBackgroundColor(): BackgroundColor {
        return this.getCurrentModel().getBackgroundColor();
    }

    setDimensions(dimension: Dimension) {
        this.getCurrentModel().setDimensions(dimension);

        return this;
    }

    getDimensions(): Dimension {
        return this.getCurrentModel().getDimensions();
    }

    getVisibleElements(frameIndex = 0): VisualElement[] {
        return this.getCurrentModel().getVisibleElements(frameIndex);
    }

    updateElement(elementId: number | string, data: any, options: UpdateElementOptions = {}): void {
        return this.getCurrentModel().updateElement(elementId as number, data, options);
    }

    updateElements(rawElements: any[], options: UpdateElementOptions = {}): void {
        return this.getCurrentModel().updateElements(rawElements, options);
    }

    updateGlobalProperty(
        elType: string,
        rawElement: Partial<BackgroundColorParams> | Partial<AudioElementParams>,
        options: UpdateGlobalPropertyOptions = {},
    ): void {
        return this.getCurrentModel().updateGlobalProperty(elType, rawElement, options);
    }

    updateDimension(
        rawDimension: {
            width: number;
            height: number;
        },
        options: UpdateGlobalPropertyOptions = {},
    ): void {
        return this.getCurrentModel().updateDimension(rawDimension, options);
    }

    createElement(type: ElementTypes, rawParams: any, options: CreateElementOptions): VisualElement {
        return this.getCurrentModel().createElement(type, rawParams, options);
    }

    addElements(
        elements: VisualElement[],
        startFrame: number,
        options: CreateElementOptions = {},
        flattenElementsArray?: VisualElement[][],
    ): void {
        return this.getCurrentModel().addElements(elements, startFrame, options, flattenElementsArray);
    }

    removeElements(elementIds: number[], options: RemoveElementOptions = {}): void {
        return this.getCurrentModel().removeElements(elementIds, options);
    }

    wrapElementsIntoGroup(elementIds: number[]): GroupElement {
        return this.getCurrentModel().wrapElementsIntoGroup(elementIds);
    }

    unwrapGroupElement(groupId: number | string): void {
        return this.getCurrentModel().unwrapGroupElement(groupId as number);
    }

    addExistingElementToGroup(elementId: number | string, groupId: number | string): GroupElement {
        return this.getCurrentModel().addExistingElementToGroup(elementId as number, groupId as number);
    }

    unwrapElementFromGroup(elementId: number | string): VisualElement {
        return this.getCurrentModel().unwrapElementFromGroup(elementId);
    }

    getAllMaskElementRanges(): Map<string, Ranges<MaskModeTypes>> {
        return this.getCurrentModel().getAllMaskElementRanges();
    }

    onElementPartialUpdate(elementId: number | string, eventType: ElementUpdateTypes, callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { element, updateTypes } = data;

            if (element.id === elementId && updateTypes.has(eventType)) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    onElementPartialUpdates(elementId: number | string, eventTypes: ElementUpdateTypes[], callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { element, updateTypes } = data;

            if (element.id === elementId && eventTypes.some((eventType) => updateTypes.has(eventType))) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    onElementsPartialUpdate(eventType: ElementUpdateTypes, callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { updateTypes } = data;

            if (updateTypes.has(eventType)) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    onElementUpdate(elementId: number | string, callbackFn: any): () => void {
        const listener = (data: ElementUpdatedEventData) => {
            const { element } = data;

            if (element.id === elementId) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementUpdated', listener);

        return () => this.eventEmitter.off('elementUpdated', listener);
    }

    on(eventName: string, callback: ListenerFn): void {
        this.eventEmitter.on(eventName, callback);
    }

    off(eventName: string, callback: ListenerFn): void {
        this.eventEmitter.off(eventName, callback);
    }

    protected handleTextStylesChanged = (data: TextStyleChangeEventData) => this.syncTextStyleChange(data);

    protected subscribe() {
        this.eventEmitter.on('textStylesChanged', this.handleTextStylesChanged);
    }

    protected unsubscribe() {
        this.eventEmitter.off('textStylesChanged', this.handleTextStylesChanged);
    }

    protected subscribeToModelEvents(model: BaseModel, index: number): void {
        const listener = (eventName: string, eventData: any = {}) => {
            eventData.pageIndex = index;
            this.eventEmitter.emit(eventName, eventData);
        };

        this.listeners[index] = listener;
        model.eventEmitter.onAny(listener as ListenerFn);
    }

    protected unsubscribeFromModelEvents(model: BaseModel, index: number): void {
        model.eventEmitter.offAny(this.listeners[index]);
        this.listeners.splice(index, 1);
    }

    protected syncTextStyleChange({ action, oldValue, newValue, reason }: TextStyleChangeEventData) {
        const update = (model: TModel) => {
            const updateElements = [];
            const { uuid: styleId, name, ...style } = newValue;

            if (Object.keys(style).length) {
                model.getAllElementsRecursively().forEach((element) => {
                    if (element instanceof TextElement) {
                        const formattedText = TextStyles.updateStyle(element.getTextProps(), styleId, style, oldValue);
                        updateElements.push({ id: element.id, formattedText });
                    }
                });
            }

            if (updateElements.length) {
                model.updateElements(updateElements, { reason });
            }
        };
        const remove = (model: TModel) => {
            const updateElements = [];
            const styleId = oldValue.uuid;
            model.getAllElementsRecursively().forEach((element) => {
                if (element instanceof TextElement) {
                    const formattedText = TextStyles.removeStyleId(element.getTextProps(), styleId);
                    const propsToChange: any = { id: element.id, formattedText };

                    if (element.textStyles.includes(styleId)) {
                        propsToChange.textStyles = element.textStyles.filter((id) => id !== styleId);
                    }

                    updateElements.push(propsToChange);
                }
            });

            if (updateElements.length) {
                model.updateElements(updateElements, { reason });
            }
        };

        this.models.forEach((model) => {
            if (action === 'remove') {
                remove(model);
            }

            if (action === 'update') {
                update(model);
            }
        });
    }

    protected generatePageId() {
        let id = Date.now();

        while (this.modelsMetaData.some((item) => item.id.toString() === id.toString())) {
            id++;
        }

        return id.toString();
    }

    toObject() {
        return exportSpecificationTemplate(this);
    }

    emit<K extends keyof EventDataMap>(eventName: K, eventData: EventDataMap[K]): void {
        throw new Error('Illegal operation for MultiPageModel');
    }

    getCopy(): BaseMultiPageModel<TModel> {
        throw new Error('Illegal operation for MultiPageModel');
    }

    copyFrom(model: BaseMultiPageModel<TModel>): void {
        this.index = model.index;
        this.assetLoader = model.assetLoader;
        this.textStyles = model.textStyles;
        this.contentPropertiesManager = model.contentPropertiesManager.getCopy();
        this.contentPropertiesManager.setEventEmitter(this.eventEmitter);
        this.contentPropertiesManager.setMultiPageModel(this);
        this.models = model.models.map((subModel) => {
            const sub = subModel.getCopy().setAssetLoader(this.assetLoader).setTextStyles(this.textStyles) as TModel;
            sub.setup();

            return sub;
        });
        this.modelsMetaData = model.modelsMetaData.map((metaData) => {
            const copyMetaData = { ...metaData };

            if (copyMetaData.dimensions) {
                copyMetaData.dimensions = { ...copyMetaData.dimensions };
            }

            return copyMetaData;
        });
    }

    getEventAccumulator(): EventAccumulator {
        throw new Error('Illegal operation for MultiPageModel');
    }

    getUpdateElementOptions(): ElementUpdateOptions {
        throw new Error('Illegal operation for MultiPageModel');
    }
}
