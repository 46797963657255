import { useContext } from 'react';
import { DesignContext } from '../DesignContext';
import { MultiPageImageModel, MultiPageVideoModel } from '@bynder-studio/render-core';

export default function useDesign<T extends MultiPageImageModel | MultiPageVideoModel | undefined>() {
    const {
        loading,
        isUpdatedByVariation,
        creativeModelFactory,
        creativeModel,
        width,
        height,
        initCanvasRenderer,
        canvasRenderer,
        updatedPlaybackDuration,
        validationManager,
        creativeType,
        assetLoader,
        template,
        fonts,
        creativeId,
        creativeVersionId,
        creativeName,
        isMultiVersionCreative,
        openDownloadModal,
        openUploadModal,
        recalculateShots,
        setCurrentPageIndex,
        setUpdatedByVariation,
        setPausePlayback,
        setPlayPlayback,
        isPlaying,
    } = useContext(DesignContext);

    const frameRate = creativeType === 'VIDEO' ? creativeModel?.getPlaybackDuration()?.getFrameRate() || 25 : null;
    const duration = creativeType === 'VIDEO' ? creativeModel?.getPlaybackDuration()?.getDuration() || 0 : 0;

    return {
        loading,
        isUpdatedByVariation,
        creativeModelFactory,
        creativeModel: creativeModel as T,
        width,
        height,
        frameRate,
        duration,
        initCanvasRenderer,
        updatedPlaybackDuration,
        canvasRenderer,
        validationManager,
        creativeType,
        assetLoader,
        template,
        fonts,
        creativeId,
        creativeVersionId,
        creativeName,
        isMultiVersionCreative,
        openDownloadModal,
        openUploadModal,
        recalculateShots,
        setCurrentPageIndex,
        setUpdatedByVariation,
        setPausePlayback,
        setPlayPlayback,
        isPlaying,
    };
}
