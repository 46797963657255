import { useCallback, useEffect, useState } from 'react';
import { BaseMultiPageModel, BaseMultiPageModel, TextElement } from '@bynder-studio/render-core';
import useTextSelection from 'packages/hooks/useTextSelection';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import useOverrides from '../TextStyles/useOverrides';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import { OverrideKey } from '../TextStyles/types';

const useFontSettingInput = ({
    creativeModel,
    propName,
    autoCorrectKey,
    selectedElement,
    disabled,
    mapDisplayValue = (x) => x.toString(),
    mapSubmitValue = (x) => x,
}: {
    creativeModel: BaseMultiPageModel;
    propName: OverrideKey;
    autoCorrectKey: string;
    selectedElement: TextElement;
    disabled: boolean;
    mapDisplayValue?: (value: any) => string;
    mapSubmitValue?: (value: any) => any;
}) => {
    const { onFocus, unsubscribeListener } = useInputBlur();
    const { styleIdData, ...selection } = useTextSelection();
    const { isOverridden, getOriginalStyleProp } = useOverrides(creativeModel);
    const [value, setValue] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [prop, isMixed, mixedValue] = selection[`${propName}Data`];

    const isTextStyleApplied = styleIdData[0] || styleIdData[1];
    const isOverriddenValue = isOverridden(propName);

    const setInitialState = useCallback(() => {
        const v: string | 'Mixed' = !isMixed ? mapDisplayValue(prop) : mixedValue;

        // mixed => placeholder
        // !textStyle => value
        // textStyle => overriden ? value : placeholder
        if (!isMixed && (!isTextStyleApplied || isOverriddenValue)) {
            setValue(v);
            setPlaceholder('');
        } else {
            setValue('');
            setPlaceholder(v);
        }
    }, [isMixed, mapDisplayValue, mixedValue, isTextStyleApplied, isOverriddenValue]);

    useEffect(() => {
        setInitialState();
    }, [prop, isMixed, mixedValue, isOverriddenValue, isTextStyleApplied]);

    const updateElement = useCallback(
        (valueToApply: string) => {
            const correctValue = editorAutocorrect(autoCorrectKey, valueToApply);
            const param = {
                updateTextSettingForSelection: {
                    settings: {
                        [propName]: mapSubmitValue(correctValue),
                    },
                },
            };

            setValue(correctValue);
            creativeModel.updateElement(selectedElement.id, param);
        },
        [autoCorrectKey, mapSubmitValue, creativeModel, selectedElement],
    );

    const applyValue = useCallback(
        (val: string) => {
            if (isMixed && val === '') return;

            const useValueFromStyle = isTextStyleApplied && !val;
            const valueToApply = useValueFromStyle ? mapDisplayValue(getOriginalStyleProp(propName)) : val;

            if (isTextStyleApplied && mapDisplayValue(prop) === valueToApply) {
                setInitialState();
            } else {
                updateElement(valueToApply);
            }
        },
        [isMixed, creativeModel, getOriginalStyleProp, isTextStyleApplied, prop, mapDisplayValue, updateElement],
    );

    const onBlur = useCallback(() => {
        applyValue(value);
        unsubscribeListener();
    }, [value, applyValue, unsubscribeListener]);

    const getValueAsNum = useCallback(() => {
        // has value and not mixed --> value
        // mixed (by textStyles or usual value) --> 0
        // has textStyle (not overridden) --> textStyle value
        if (value) return Number(value);
        if (isMixed) return 0;
        if (isTextStyleApplied) return Number(mapDisplayValue(prop));
        return 0;
    }, [value, isMixed, isTextStyleApplied, prop]);

    const onKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onBlur();
                event.target.blur();
            }
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                applyValue(String(getValueAsNum() + numberToAdd * factor));
            }
        },
        [onBlur, getValueAsNum, applyValue],
    );

    const onChange = setValue;
    const type = Number.isNaN(Number(value)) ? 'text' : 'number';
    const isDisabled = disabled || selectedElement.locked;

    return { value, placeholder, isDisabled, type, onBlur, onFocus, onKeyDown, onChange } as const;
};

export default useFontSettingInput;
