import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { BaseEffect } from './BaseEffect';
import { type CompElement } from '../../types';

export class ClipEffect extends BaseEffect {
    static type = EffectTypes.CLIP;

    static getRegionOfDefinition(region: Region, compElement: CompElement) {
        return region;
    }

    static getRegionOfInterest(region: Region, compElement: CompElement) {
        //TODO: Implement intersection calculation between clip path and region.
        return region;
    }
}
