import type { ColorParams } from '@bynder-studio/misc';
import { mat2d } from 'gl-matrix';
import { Dimension } from '../../Models/Shared/Dimension';
import { Region } from '../../Models/Shared/Region';
import type { ICompLayer } from '../ICompLayer';
import { type CompElement } from '../../types';

export abstract class BaseCompLayer implements ICompLayer {
    el: CompElement;

    id: string;

    region: Region;

    absoluteRegion: Region;

    scale: number;

    constructor(el: CompElement, id: string, region: Region, absoluteRegion: Region, scale: number = 1) {
        this.el = el;
        this.id = id;
        this.region = region;
        this.absoluteRegion = absoluteRegion;
        this.scale = scale;
    }

    abstract getWidth(): number;

    abstract getHeight(): number;

    /**
     * Reset the transform of the canvas or context
     */
    abstract resetTransform(): void;

    /**
     * Get current transform of the canvas or context
     */
    abstract getTransform(): mat2d;

    /**
     * Clear the layer, optionally setting color fill
     * @param color
     */
    abstract clear(color?: ColorParams | null): void;

    /**
     * Save state on a canvas or context
     */
    abstract save(): void;

    /**
     * Restore state on a canvas or context
     */
    abstract restore(): void;

    /**
     * Apply translation with scaling to the canvas or context
     * @param x
     * @param y
     */
    abstract applyTranslation(x: number, y: number): void;

    abstract delete(): void;

    getDimension(): Dimension {
        return new Dimension(this.getWidth(), this.getHeight());
    }

    getScale(): number {
        return this.scale;
    }

    s(n: number): number {
        // shorthand scale function, apply to everything related to coordinates
        return n * this.scale;
    }
}
