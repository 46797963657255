import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class FontsService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/font/customer/') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getFonts(customerId: number) {
        return this.gateway.get(`${customerId}/fonts`);
    }
}

export default new FontsService();
