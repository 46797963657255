import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class TrackingService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/weadapt-users') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    registerLogin(data) {
        if (data.bynderUserId) {
            return this.gateway.post('/register/login', data);
        }

        return null;
    }
}

export default new TrackingService();
