import React, { useCallback, useState } from 'react';
import { Divider, Dropdown } from '@bynder/design-system';
import {
    IconAddCircle,
    IconBuildShape,
    IconCircle,
    IconImage,
    IconRectangle,
    IconTextField,
    IconVideo,
    IconVideoTemplate,
} from '@bynder/icons';
import PresetModal from '../presets/PresetsModal';
import AuthorizationHelper from '../../../helpers/AuthorizationHelper';
import permissions from '../../../configs/permissions';
import { AddAssetButton } from './EditorComponents.styled';
import useElementCreator from 'packages/pages/editor/useElementCreator';
import ContentPickerModal from 'packages/pages/editor/ContentPickerModal';
import useEditor from '~/hooks/useEditor';
import { type MultiPageVideoModel } from '@bynder-studio/render-core';
import usePresets from '../presets/usePresets';
import useCreateElementHotkeys from 'packages/pages/editor/useCreateElementHotkeys';

export const AddElementDropdown = () => {
    const { creativeModel, manipulationRenderer } = useEditor();
    const elementCreator = useElementCreator();
    const { applyPreset } = usePresets();
    const [showPresetModal, setShowPresetModal] = useState(false);
    const [showContentSelectorModal, setShowContentSelectorModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState({});
    const [assetType, setAssetType] = useState<'video' | 'image'>();

    const handleConfirmSelection = useCallback(
        (source) => {
            if (assetType === 'video') {
                elementCreator.createVideoElement(source);
            } else {
                elementCreator.createImageElement(source);
            }

            setShowContentSelectorModal(false);
        },
        [creativeModel, manipulationRenderer, assetType],
    );

    const addElementFromSource = useCallback(
        (type: 'video' | 'image') => {
            const startFrame = manipulationRenderer.getCurrentFrame();
            const playbackDuration = (creativeModel as MultiPageVideoModel).getPlaybackDuration().getDuration();
            const duration = startFrame >= playbackDuration ? playbackDuration : playbackDuration - startFrame;

            setAssetType(type);
            setSelectedObject({ startFrame, duration });
            setShowContentSelectorModal(true);
        },
        [manipulationRenderer, creativeModel],
    );

    const addImageElement = useCallback(() => addElementFromSource('image'), [addElementFromSource]);
    const addVideoElement = useCallback(() => addElementFromSource('video'), [addElementFromSource]);

    useCreateElementHotkeys(elementCreator, addImageElement, addVideoElement);

    const handleAddPreset = useCallback(
        (selectedPreset) => {
            // Temporary solution
            const finalSelectedElement = selectedPreset;
            finalSelectedElement.elements[Object.keys(finalSelectedElement.elements)].name = selectedPreset.name;
            applyPreset(finalSelectedElement);
            setShowPresetModal(false);
        },
        [applyPreset],
    );

    return (
        <>
            <Dropdown
                trigger={({ isOpen, ...triggerProps }) => (
                    <AddAssetButton isDisabled={false} variant="primary" icon={<IconAddCircle />} {...triggerProps}>
                        Add asset
                    </AddAssetButton>
                )}
            >
                {AuthorizationHelper.hasPermission(permissions.ASSETS.READ) && (
                    <>
                        <Dropdown.Item onClick={addVideoElement} icon={<IconVideo />}>
                            Video
                        </Dropdown.Item>
                        <Dropdown.Item onClick={addImageElement} icon={<IconImage />}>
                            Image
                        </Dropdown.Item>
                    </>
                )}
                <Dropdown.Item onClick={elementCreator.createTextElement} icon={<IconTextField />}>
                    Text
                </Dropdown.Item>
                <Dropdown.Item
                    icon={<IconBuildShape />}
                    submenu={
                        <>
                            <Dropdown.Item icon={<IconRectangle />} onClick={elementCreator.createRectangleElement}>
                                Rectangle
                            </Dropdown.Item>
                            <Dropdown.Item icon={<IconCircle />} onClick={elementCreator.createEllipseElement}>
                                Ellipse
                            </Dropdown.Item>
                        </>
                    }
                >
                    Shape
                </Dropdown.Item>
                <Divider />
                {AuthorizationHelper.hasPermissions([permissions.ASSETS.READ, permissions.PRESETS.READ]) && (
                    <Dropdown.Item icon={<IconVideoTemplate />} onClick={() => setShowPresetModal(true)}>
                        Preset
                    </Dropdown.Item>
                )}
            </Dropdown>
            {AuthorizationHelper.hasPermission(permissions.PRESETS.READ) && showPresetModal && (
                <PresetModal
                    toggleModal={(show) => setShowPresetModal(show)}
                    confirmSelection={handleAddPreset}
                    show={showPresetModal}
                />
            )}
            {showContentSelectorModal && assetType && (
                <ContentPickerModal
                    type={assetType}
                    frameRate={(creativeModel as MultiPageVideoModel)?.getPlaybackDuration().getFrameRate()}
                    toggleModal={setShowContentSelectorModal}
                    show={showContentSelectorModal}
                    confirmSelection={handleConfirmSelection}
                    selectedObject={selectedObject}
                    resizableRange
                    isEditorPage
                />
            )}
        </>
    );
};
