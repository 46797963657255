import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initNewDesignModal } from 'packages/store/categories/categories.actions';
import { getCategoriesState } from 'packages/store/categories/categories.selectors';
import { CollectionSubType, CollectionType } from 'packages/store/collections/types';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { OrderFilterType, SortingFilterType } from 'packages/pages/components/filters/components/Ordering/types';
import { deselectBlankType } from 'packages/store/newDesign/newDesign.actions';
import { getTemplates } from 'packages/store/templates/templates.selectors';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import useThunkDispatch from 'packages/hooks/useThunkDispatch';
import { Template } from 'packages/store/templates/types';
import { deselectTemplate, initializeTemplates, duplicateTemplates } from '~/store/templates/templates.actions';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import features from '~/configs/features';
import roles from '~/configs/roles';

const useGroupedTemplates = () => {
    const dispatch = useDispatch();
    const thunkDispatch = useThunkDispatch();
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const { setQueryParam } = useQueryParams();

    const { categories, isFetching: categoriesLoading } = useSelector(getCategoriesState);
    const { templates, loadingData: templatesLoading } = useSelector(getTemplates);
    const selectedTemplate: Template | null = useSelector(selectedTemplateSelector);
    const type: CollectionType = 'CREATIVE';
    const subType: CollectionSubType = 'TEMPLATE';
    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    useEffect(() => {
        dispatch(deselectTemplate());
        dispatch(initializeTemplates());
        dispatch(
            initNewDesignModal({
                type,
                subType,
                ownershipSource: isTrialAccount
                    ? OwnershipFilterType.OWNED_BY_OTHERS
                    : OwnershipFilterType.OWNED_BY_ANYONE,
                includeEmptyCollection: false,
                sort: [SortingFilterType.LASTMODIFIED, OrderFilterType.DESCENDING],
                size: 5,
                gallery: isPlatformAllowed([features.GALLERY_ENABLED]),
            }),
        );

        return () => {
            dispatch(deselectBlankType());
        };
    }, []);

    const duplicateTemplate = async (template: Template) => {
        await thunkDispatch(duplicateTemplates([template.id], template.categoryId));
        setQueryParam('template', '');

        dispatch(
            initNewDesignModal({
                type,
                subType,
                ownershipSource: isTrialAccount
                    ? OwnershipFilterType.OWNED_BY_OTHERS
                    : OwnershipFilterType.OWNED_BY_ANYONE,
                includeEmptyCollection: false,
                sort: [SortingFilterType.LASTMODIFIED, OrderFilterType.DESCENDING],
                size: 5,
                gallery: isPlatformAllowed([features.GALLERY_ENABLED]),
            }),
        );
    };

    return { categories, templates, categoriesLoading, templatesLoading, selectedTemplate, duplicateTemplate };
};

export default useGroupedTemplates;
