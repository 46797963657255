import React, { useEffect, useState } from 'react';
import { BaseMultiPageModel, ContentTransform, TextElement } from '@bynder-studio/render-core';
import { Form } from '@bynder/design-system';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import generateTestId from '~/helpers/testIdHelpers';
import {
    type AlignmentNumber,
    VERTICAL_ALIGNMENTS,
} from 'packages/pages/editor/RightSideMenu/Shots/Text/TextAlignment/utils';
import { AlignmentButton, AlignmentItem, Wrapper } from './Component.styled';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
    contentTransform: ContentTransform;
};

const toTestId = (testIdName: string, selected: boolean) =>
    `shots_text_vertical_alignment_${testIdName}${selected ? '_selected' : ''}`;

const VerticalAlignment = ({ creativeModel, selectedElement, contentTransform }: Props) => {
    const { id, locked } = selectedElement;
    const { verticalAlignment } = contentTransform || {};
    const [value, setValue] = useState(verticalAlignment);

    const handleOnChange = (newValue: AlignmentNumber) => {
        const param = {
            contentTransform: {
                ...contentTransform,
                verticalAlignment: newValue,
            },
        };

        setValue(newValue);
        creativeModel.updateElement(id, param);
    };

    useEffect(() => {
        setValue(verticalAlignment);
    }, [verticalAlignment]);

    return (
        <Form.Group>
            <Wrapper>
                {VERTICAL_ALIGNMENTS.map((alignment) => (
                    <AlignmentItem key={alignment.testIdName}>
                        <MaybeTooltip hasTooltip={!locked} content={alignment.name}>
                            <AlignmentButton
                                variant="clean"
                                onClick={() => handleOnChange(alignment.value)}
                                isDisabled={locked}
                                isPressed={alignment.value === value}
                                {...generateTestId(toTestId(alignment.testIdName, alignment.value === value))}
                            >
                                {alignment.icon({})}
                            </AlignmentButton>
                        </MaybeTooltip>
                    </AlignmentItem>
                ))}
            </Wrapper>
        </Form.Group>
    );
};

export default VerticalAlignment;
