import { framesToTime } from '../../Helpers/framesToTime';
import { deepClone } from '../../Helpers/utils';
import { getTimingFunction } from './getTimingFunction';
import { BaseVisualElement } from '../Elements/BaseVisualElement';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { ValidationRule } from '../../types';
import { type BaseAnimationInConfig, type BaseAnimationOutConfig, type CommonBaseAnimationConfig } from './types';

export type AnimationPropertiesValidationRules = Partial<{
    blinks: ValidationRule;
    scale: ValidationRule;
    rotations: ValidationRule;
    angle: ValidationRule;
    startFrame: ValidationRule;
    duration: ValidationRule;
    pins: ValidationRule;
    data: ValidationRule;
    direction: ValidationRule;
    opacity: ValidationRule;
    timing: ValidationRule;
    blur: ValidationRule;
    distance: ValidationRule;
    softness: ValidationRule;
}>;

export abstract class BaseAnimation<C extends CommonBaseAnimationConfig> {
    el!: BaseVisualElement; // element

    startFrame!: number; // animation startFrame

    duration!: number; // animation duration

    timingFn!: (t: number, b: number, c: number, d: number) => number; // timing function to use

    config!: C; // animation config

    constructor(startFrame: number, duration: number, config: C, el: BaseVisualElement) {
        this.setProperties(startFrame, duration, config, el);
    }

    setProperties(startFrame: number, duration: number, config: C, el: BaseVisualElement | null = null) {
        this.startFrame = startFrame;
        this.duration = duration;
        this.config = config; // animation config

        if (el) {
            this.el = el;
        }

        this.timingFn = getTimingFunction(config.timing);
    }

    protected updateAnimationConfig(startFrame: number, duration: number, config: C): void {
        this.setProperties(startFrame, duration, config);
    }

    update(config): void {
        this.updateAnimationConfig(config.startFrame, config.duration, config);
    }

    containsFrame(frameIndex: number): boolean {
        // check if animation affects given frame index
        // note that frames can contain fractions, so we cannot just check for 'frameIndex < this.startFrame + this.duration'
        return frameIndex >= (this.startFrame || 0) && frameIndex <= (this.startFrame || 0) + (this.duration || 1) - 1;
    }

    updateCompEl(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        // Override in subclass
        throw new Error('Not implemented');
    }

    getValidationRules(frameRate: number): AnimationPropertiesValidationRules {
        if (!this.el) {
            return {};
        }

        const startFrame = this.startFrame ? this.startFrame - this.el.startFrame : 0;

        return {
            blinks: {
                GREATER_THAN: 0,
            },
            scale: {
                GREATER_THAN: 0,
            },
            rotations: {
                GREATER_THAN: 0,
            },
            angle: {
                LESS_THAN: 359,
                GREATER_THAN: -359,
            },
            startFrame: {
                LESS_THAN: Math.max(0, this.el.startFrame + this.el.duration - this.duration),
                FRAMES_TO_TIME: true,
            },
            ...(this.el.duration > startFrame
                ? {
                      duration: {
                          LESS_THAN: this.el.duration - startFrame,
                          FRAMES_TO_TIME: true, // LESS_THAN_MSG: `Start frame and duration exceeds element duration of ${properties.duration} frames or ${framesToTime(metadata.duration, frameRate)}`,
                      },
                  }
                : {}),
            pins: {
                REQUIRED: true,
                EQUAL_LENGTHS: this.el.duration,
                EQUAL_LENGTHS_MSG: `Corner pin data does not correspond to element duration of ${this.el.duration} frames`,
            },
            data: {
                REQUIRED: true,
                EQUAL_LENGTHS: this.el.duration,
                EQUAL_LENGTHS_MSG: `Import data does not correspond to element duration of ${this.el.duration} frames`,
            },
        };
    }

    toObject() {
        return deepClone(this.config);
    }
}

export abstract class BaseAnimationIn<C extends BaseAnimationInConfig> extends BaseAnimation<C> {
    constructor(config, el: BaseVisualElement) {
        const startFrame = el.startFrame;
        const duration = config.duration;
        super(startFrame, duration, config, el);
    }

    update(config): void {
        const startFrame = this.el.startFrame;
        const duration = config.duration;
        super.updateAnimationConfig(startFrame, duration, config);
    }

    // Add checking
    getValidationRules(frameRate: number): AnimationPropertiesValidationRules {
        // move to separate fn and add transform to string
        // NOTE: // pass duration without transforming to string
        const singleError = `The maximum allowed value for the field is ${this.el.duration} frames or ${framesToTime(
            this.el.duration,
            frameRate,
        )}`;
        const multipleError = `Total transition duration must not exceed ${this.el.duration} frames or ${framesToTime(
            this.el.duration,
            frameRate,
        )}`;
        const onlyOne = this.el.animationIn && this.el.animationOut;

        return {
            direction: {},
            duration: {
                LESS_THAN: this.el.duration - (this.el.animationOut?.config?.duration || 0),
                LESS_THAN_MSG: onlyOne ? multipleError : singleError,
            },
            opacity: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            timing: {},
            scale: {
                GREATER_THAN: 0,
            },
            blur: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            distance: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            softness: {
                GREATER_THAN: 0,
                LESS_THAN: 8000,
            },
        };
    }
}

export abstract class BaseAnimationOut<C extends BaseAnimationOutConfig> extends BaseAnimation<C> {
    constructor(config, el: BaseVisualElement) {
        const startFrame = el.startFrame + (el.duration - config.duration);
        const duration = config.duration;
        super(startFrame, duration, config, el);
    }

    update(config): void {
        const startFrame = this.el.startFrame + (this.el.duration - config.duration);
        const duration = config.duration;
        super.updateAnimationConfig(startFrame, duration, config);
    }

    getValidationRules(frameRate: number): AnimationPropertiesValidationRules {
        const singleError = `The maximum allowed value for the field is ${this.el.duration} frames or ${framesToTime(
            this.el.duration,
            frameRate,
        )}`;
        const multipleError = `Total transition duration must not exceed ${this.el.duration} frames or ${framesToTime(
            this.el.duration,
            frameRate,
        )}`;
        const onlyOne = this.el.animationIn && this.el.animationOut;

        return {
            direction: {},
            duration: {
                LESS_THAN: this.el.duration - (this.el.animationIn?.config?.duration || 0),
                LESS_THAN_MSG: onlyOne ? multipleError : singleError,
            },
            opacity: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            timing: {},
            scale: {
                GREATER_THAN: 0,
            },
            blur: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            distance: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            softness: {
                GREATER_THAN: 0,
                LESS_THAN: 8000,
            },
        };
    }
}
