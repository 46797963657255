import {
    Dimension,
    GroupCompElement,
    GroupElement,
    type IAssetsLoader,
    type CompElement,
    type CompModel,
    ManipulableObjectParams,
    Position,
    Scrollbars,
} from '@bynder-studio/render-core';
import { TextEditor } from '@bynder-studio/structured-text';
import { CanvasLayerCompositor } from '../CanvasLayerCompositor/CanvasLayerCompositor';
import TransformerBox from './BoundingBox/TransformerBox';
import { type DrawElementParams, ElementSelectionHelper } from './ElementSelectionHelper';

const MASK_COLOR = '#002233';

export default class CanvasManipulationCompositor extends CanvasLayerCompositor {
    pan: Position = new Position(0, 0);

    public scrollbar: Scrollbars | null = null;

    public scrollbarValues: any;

    elementSelectionHelper: ElementSelectionHelper;

    isDragging = false;

    isResizing = false;

    showSnapLines = false;

    overScrollBar = false;

    resizeHandleIndex = -1;

    offset: Position = new Position(0, 0);

    isRotating = false;

    isPlaying = false;

    mousePosition: Position = new Position(0, 0);

    mouseStartPosition: Position = new Position(0, 0);

    isPanMode = false;

    _isPanning = false;

    topLevelSelectedQuantity: number = null;

    currentSelectedGroupId: number = null;

    currentParentId: number = null;

    selectedElIds: ManipulableObjectParams['id'][] = [];

    textEditorElement: number = null;

    textEditor: TextEditor = null;

    isContextMenuOpen = false;

    constructor(canvasWrapperEl: HTMLElement, assetLoader: IAssetsLoader, dpr = 1) {
        super(canvasWrapperEl, assetLoader, dpr);
        this.elementSelectionHelper = new ElementSelectionHelper();
    }

    set isPanning(value: boolean) {
        if (!this.isDragging && !this.isResizing) {
            this.requestToChangeCursor(value ? 'grabbing' : this.isPanMode ? 'grab' : '');
        }

        this._isPanning = value;
    }

    get isPanning(): boolean {
        return this._isPanning;
    }

    requestToChangeCursor(newValue) {
        if (this.canvas.style.cursor !== newValue) {
            this.canvas.style.cursor = newValue;
        }
    }

    calculateOffset(transformerBox: TransformerBox) {
        this.offset = this.mousePosition.subtract(transformerBox.position);
    }

    toggleTextEditor(elementId?: number | null) {
        this.textEditorElement = elementId;
    }

    startDragging(transformerBox: TransformerBox) {
        this.showSnapLines = true;
        this.isDragging = true;
        this.isPanning = false;
        this.isResizing = false;
        this.resizeHandleIndex = -1;
        this.isRotating = false;
        this.calculateOffset(transformerBox);
    }

    startResizing(handleIndex) {
        this.showSnapLines = true;
        this.isResizing = true;
        this.isPanning = false;
        this.resizeHandleIndex = handleIndex;
        this.isDragging = false;
        this.isRotating = false;
    }

    calculateMousePosition(e: MouseEvent, mousePosition: Position) {
        if (!e.isTrusted) {
            return;
        }

        const bounds: DOMRect = this.canvas.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const y = e.clientY - bounds.top;

        mousePosition.setX(x / this.getScale());
        mousePosition.setY(y / this.getScale());
    }

    getCalculatedMousePosition() {
        if (!this.currentCompModel) {
            return;
        }

        const dimension = this.currentCompModel.getDimension();
        const pan = this.getPanPosition();
        const factor = this.getScale() * this.devicePixelRatio;

        const x = pan.getX() / factor + (this.canvas.width / factor - dimension.getWidth()) / 2;
        const y = pan.getY() / factor + (this.canvas.height / factor - dimension.getHeight()) / 2;

        const calculatedMousePosition: Position = this.mousePosition.getCopy();
        calculatedMousePosition.setX(calculatedMousePosition.getX() - x);
        calculatedMousePosition.setY(calculatedMousePosition.getY() - y);

        return calculatedMousePosition;
    }

    setPanPosition(panPosition: Position) {
        this.pan.setX(panPosition.getX());
        this.pan.setY(panPosition.getY());
    }

    getPanPosition(): Position {
        return this.pan;
    }

    setCompositorDimension(dimension: Dimension) {
        const wrapperDimension = super.getCompositorWrapperDimension();
        this.canvas.width = this.devicePixelRatio * wrapperDimension.getWidth();
        this.canvas.height = this.devicePixelRatio * wrapperDimension.getHeight();
        this.canvas.style.width = wrapperDimension.getWidth() + 'px';
        this.canvas.style.height = wrapperDimension.getHeight() + 'px';
    }

    getCompositorWrapperPadding(): number {
        return 20;
    }

    // todo: check and remove if it is not used
    clearLayer(ctx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    drawCompModel(compModel: CompModel) {
        this.currentCompModel = compModel;

        this.prepareDraw(compModel);

        if (this.baseLayer) {
            this.baseLayer.resetTransform();
            this.baseLayer.clear(null);
        }

        // todo: refactor after zoom to fit
        const dimension = this.currentCompModel.getDimension();
        const x = this.pan.getX() + (this.ctx.canvas.width - this.s(dimension.getWidth())) / 2;
        const y = this.pan.getY() + (this.ctx.canvas.height - this.s(dimension.getHeight())) / 2;

        this.ctx.translate(x, y);

        // clip artboard
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.rect(0, 0, this.s(dimension.getWidth()), this.s(dimension.getHeight()));
        this.ctx.clip();

        this.drawCheckerboardPattern(this.ctx);

        this.applyBackgroundColor(compModel.getBackgroundColor(), this.ctx);

        compModel.getCompElements().forEach((el) => {
            this.drawCompElement(el);
        });

        // draw the artboard border
        this.ctx.save();
        this.ctx.globalAlpha = 0.1;
        this.ctx.strokeStyle = MASK_COLOR;
        this.ctx.lineWidth = this.devicePixelRatio * 2;
        this.ctx.beginPath();
        this.ctx.rect(0, 0, this.s(dimension.getWidth()), this.s(dimension.getHeight()));
        this.ctx.stroke();
        this.ctx.restore();

        this.ctx.restore();

        this.cleanupDraw();
    }

    drawGhostCompModel(compModel: CompModel): void {
        if (!this.currentCompModel) {
            return;
        }

        const dimension = this.currentCompModel.getDimension();
        const x = this.pan.getX() + (this.ctx.canvas.width - this.s(dimension.getWidth())) / 2;
        const y = this.pan.getY() + (this.ctx.canvas.height - this.s(dimension.getHeight())) / 2;
        const elementsForSelection = compModel.getCompElements().map((el) => this.convertCompElementForSelection(el));

        this.elementSelectionHelper.setOrigin(x, y);
        this.elementSelectionHelper.setSize(this.canvas.width, this.canvas.height);
        this.elementSelectionHelper.setDevicePixelRatio(this.devicePixelRatio);
        this.elementSelectionHelper.setScrollbarParams(this.scrollbarsCalculations());
        this.elementSelectionHelper.setElements(elementsForSelection);
        this.elementSelectionHelper.draw();
    }

    convertCompElementForSelection(el: CompElement): DrawElementParams {
        const contentBoxToDraw = el.ghostContentBox || el.contentBox;
        const element = {
            id: el.id,
            x: this.s(el.boundingBox.position.getX()),
            y: this.s(el.boundingBox.position.getY()),
            width: this.s(contentBoxToDraw.dimension.getWidth()),
            height: this.s(contentBoxToDraw.dimension.getHeight()),
            boxX: this.s(contentBoxToDraw.position.getX()),
            boxY: this.s(contentBoxToDraw.position.getY()),
            boxWidth: this.s(contentBoxToDraw.dimension.getWidth()),
            boxHeight: this.s(contentBoxToDraw.dimension.getHeight()),
            rotation: el.rotation,
            scale: el.scale,
            children: [],
        };

        if (el instanceof GroupCompElement && el.originalElement instanceof GroupElement) {
            el.getChildren().forEach((childEl) => {
                element.children.push(this.convertCompElementForSelection(childEl));
            });
        }

        return element;
    }

    getElementIdForPoint(point: Position) {
        return this.elementSelectionHelper.getElementId(point.getX(), point.getY());
    }

    getScrollLimits() {
        const dimension = this.currentCompModel.getDimension();
        const bbox = this.currentCompModel.getBoundingBox();

        const w = this.s(dimension.getWidth());
        const h = this.s(dimension.getHeight());
        const x = this.pan.getX() + (this.ctx.canvas.width - w) / 2;
        const y = this.pan.getY() + (this.ctx.canvas.height - h) / 2;
        const canvasWidth = this.ctx.canvas.width;
        const canvasHeight = this.ctx.canvas.height;

        const { min, max } = Math;
        const padding = this.getCompositorWrapperPadding() * this.devicePixelRatio;

        const bx1 = x + min(this.s(bbox.x), 0) - padding;
        const bx2 = x + max(this.s(bbox.x + bbox.width), w) + padding;
        const by1 = y + min(this.s(bbox.y), 0) - padding;
        const by2 = y + max(this.s(bbox.y + bbox.height), h) + padding;

        return {
            deltaXMin: -max(0, -bx1, canvasWidth - bx2),
            deltaXMax: max(0, bx1, bx2 - canvasWidth),
            deltaYMin: -max(0, -by1, canvasHeight - by2),
            deltaYMax: max(0, by1, by2 - canvasHeight),
        };
    }

    scrollbarsCalculations() {
        const dimension = this.currentCompModel.getDimension();
        const bbox = this.currentCompModel.getBoundingBox();

        const w = this.s(dimension.getWidth());
        const h = this.s(dimension.getHeight());
        const x = this.pan.getX() + (this.ctx.canvas.width - w) / 2;
        const y = this.pan.getY() + (this.ctx.canvas.height - h) / 2;
        const canvasWidth = this.ctx.canvas.width;
        const canvasHeight = this.ctx.canvas.height;

        const { min, max } = Math;
        const padding = this.getCompositorWrapperPadding() * this.devicePixelRatio;

        const bpx1 = x + min(this.s(bbox.x), 0) - padding;
        const bpx2 = x + max(this.s(bbox.x + bbox.width), w) + padding;
        const bpy1 = y + min(this.s(bbox.y), 0) - padding;
        const bpy2 = y + max(this.s(bbox.y + bbox.height), h) + padding;

        const bx1 = x + this.s(bbox.x);
        const bx2 = x + this.s(bbox.x + bbox.width);
        const by1 = y + this.s(bbox.y);
        const by2 = y + this.s(bbox.y + bbox.height);

        const x1 = min(0, x - padding, bx1 - padding);
        const x2 = max(canvasWidth, x + w + padding, bx2 + padding);
        const boxWidth = bpx2 - bpx1;
        const fullWidth = x2 - x1;
        let xScale = canvasWidth / fullWidth;
        let horizontalScaleRatio = min(boxWidth, canvasWidth) / fullWidth;
        let horizontalScrollbarPosition = max(0, -bpx1, canvasWidth - bpx2) / fullWidth;

        if (this.scrollbar === Scrollbars.HORIZONTAL) {
            xScale = this.scrollbarValues.xScale;
            horizontalScaleRatio = this.scrollbarValues.horizontalScaleRatio;
            horizontalScrollbarPosition = this.scrollbarValues.horizontalScrollbarPosition;
            horizontalScrollbarPosition += (this.scrollbarValues.x - x) / this.scrollbarValues.fullWidth;
        }

        const y1 = Math.min(0, y - padding, by1 - padding);
        const y2 = Math.max(canvasHeight, y + h + padding, by2 + padding);
        const boxHeight = bpy2 - bpy1;
        const fullHeight = y2 - y1;
        let yScale = canvasHeight / fullHeight;
        let verticalScaleRatio = min(boxHeight, canvasHeight) / fullHeight;
        let verticalScrollbarPosition = max(0, -bpy1, canvasHeight - bpy2) / fullHeight;

        if (this.scrollbar === Scrollbars.VERTICAL) {
            yScale = this.scrollbarValues.yScale;
            verticalScaleRatio = this.scrollbarValues.verticalScaleRatio;
            verticalScrollbarPosition = this.scrollbarValues.verticalScrollbarPosition;
            verticalScrollbarPosition += (this.scrollbarValues.y - y) / this.scrollbarValues.fullHeight;
        }

        return {
            x,
            xScale,
            fullWidth,
            horizontalScaleRatio,
            horizontalScrollbarPosition,
            y,
            yScale,
            fullHeight,
            verticalScaleRatio,
            verticalScrollbarPosition,
        };
    }

    applyTranslation(deltaX, deltaY, ctx) {
        ctx.translate(this.s(deltaX), this.s(deltaY));
    }
}
