import { Dimension } from '../Shared/Dimension';
import { BaseAsset, type BaseAssetParams } from './BaseAsset';

export type ImageAssetParams = BaseAssetParams & {
    startFrame: number;
    naturalDimension: Dimension;
};

export class ImageAsset extends BaseAsset {
    startFrame: number;

    object!: HTMLImageElement | Uint8Array;

    width: number;

    height: number;

    naturalDimension: Dimension;

    constructor(params: ImageAssetParams) {
        super(params);
        this.startFrame = params.startFrame;
        this.naturalDimension = params.naturalDimension;
        this.width = 0;
        this.height = 0;
    }

    setMediaData(image: HTMLImageElement) {
        this.object = image;
        this.width = image.naturalWidth;
        this.height = image.naturalHeight;
    }
}
