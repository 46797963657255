const features = {
    MULTI_SIZE_CREATIVE: 'multi_size_creative',
    BYNDER_DAM_ASSETS: 'bynder_dam_assets',
    INTERNAL_ASSET: 'internal_asset',
    MULTI_VARIATION: 'multi_variation',
    IMAGES_ENABLED: 'images_enabled',
    VIDEOS_ENABLED: 'videos_enabled',
    MULTI_VARIATION_NO_LIMIT: 'multi_variation_no_limit',
    GALLERY_ENABLED: 'gallery_enabled',
    APPROVALS_ENABLED: 'approvals_enabled',
    UCV_DERIVATIVES: 'ucv_derivatives',
    TRIAL_ENABLED: 'trial_enabled',
    UCV_LIMITED: 'ucv_limited',
    CONTENT_PROPERTIES: 'content_properties',
    EDITABLE_TEMPLATES: 'editable_templates',
    AI_VARIATIONS_TRANSLATE: 'ai_variations_translate',
    TEXT_BACKGROUND: 'text_background',
    MULTI_TRACK_AUDIO: 'multi_track_audio',
    ACTIVITY_LOG: 'activity_log',
};

export default features;
