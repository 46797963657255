import React, { useMemo } from 'react';
import { type MultiPageVideoModel } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { Button, Flex, Tooltip } from '@bynder/design-system';
import {
    IconPause,
    IconPlay,
    IconReplay,
    IconSkipNext,
    IconSkipPrevious,
    IconSound,
    IconSoundOff,
} from '@bynder/icons';
import { useReviewStatus } from 'packages/pages/design/sidebar/shots/useReviewStatus';
import { convertFrameToTime } from '~/common/editor/timeline/helpers/frame-to-time-converter';
import VolumeControl from 'packages/pages/components/VolumeControl';
import { DesignTab } from 'packages/pages/design/sidebar/types';
import { EllapsedTime, TimelineHeaderContainer, TotalTime } from './Component.styled';

type Props = {
    isPlaying: boolean;
    currentFrame: number;
    togglePlayback: () => void;
    toNextShot: () => void;
    toPrevShot: () => void;
    selectedShotIndex: number;
    shots: [];
    duration: number;
    frameRate: number;
    creativeModel: MultiPageVideoModel;
    designType: 'TEMPLATE' | 'CREATIVE';
    className?: string;
    setDesignTab: (tab: DesignTab) => void;
};

const TimelineHeader = ({
    isPlaying,
    currentFrame,
    togglePlayback,
    toNextShot,
    toPrevShot,
    selectedShotIndex,
    shots,
    duration,
    frameRate,
    setDesignTab,
    creativeModel,
    designType,
    className = '',
}: Props) => {
    const { translate } = useTranslate();
    const { isDisabledByReview } = useReviewStatus();

    const convertedDuration = useMemo(() => convertFrameToTime(duration, frameRate), [duration, frameRate]);

    const isAudioExists = !!creativeModel?.getGlobalAudioTrack1()?.src || !!creativeModel?.getGlobalAudioTrack2()?.src;
    const isLocked = creativeModel?.getGlobalAudioTrack1()?.locked && creativeModel?.getGlobalAudioTrack2()?.locked;

    return (
        <TimelineHeaderContainer className={`${className || ''}`}>
            <Flex alignItems="center">
                <EllapsedTime>{convertFrameToTime(currentFrame, frameRate)}</EllapsedTime>
                &nbsp;/&nbsp;
                <TotalTime>{convertedDuration}</TotalTime>
            </Flex>
            <Flex alignItems="center">
                <Button
                    variant="clean"
                    title={translate('editor.timeline.shots.previous')}
                    isDisabled={!currentFrame}
                    onClick={toPrevShot}
                    icon={<IconSkipPrevious />}
                />
                <Button
                    title={translate('editor.timeline.shots.play')}
                    onClick={togglePlayback}
                    variant="clean"
                    icon={isPlaying ? <IconPause /> : currentFrame === duration - 1 ? <IconReplay /> : <IconPlay />}
                />
                <Button
                    title={translate('editor.timeline.shots.next')}
                    isDisabled={
                        (!isPlaying && selectedShotIndex === shots.length - 1) ||
                        (isPlaying && selectedShotIndex === shots.length - 1)
                    }
                    onClick={toNextShot}
                    variant="clean"
                    icon={<IconSkipNext />}
                />
            </Flex>
            <VolumeControl
                size={designType === 'CREATIVE' ? 'm' : 's'}
                creativeModel={creativeModel}
                iconRight={
                    designType === 'CREATIVE' && (
                        <Tooltip
                            content={
                                isAudioExists
                                    ? translate('editor.timeline.shots.audio.edit')
                                    : translate('editor.timeline.shots.audio.none')
                            }
                        >
                            <Button
                                variant="clean"
                                icon={isAudioExists ? <IconSound /> : <IconSoundOff />}
                                onClick={() => {
                                    setDesignTab(DesignTab.AUDIO);
                                }}
                                title={translate('editor.timeline.shots.audio.track')}
                                isDisabled={isLocked || isDisabledByReview}
                            />
                        </Tooltip>
                    )
                }
            />
        </TimelineHeaderContainer>
    );
};

export default TimelineHeader;
