import { truthy } from '@bynder-studio/misc';
import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { createAnimationIn } from '../Animations/AnimationIn';
import { createAnimationOut } from '../Animations/AnimationOut';
import { createAnimation } from '../Animations/Animations';
import { Dimension } from '../Shared/Dimension';
import { DropShadow } from '../Shared/DropShadow';
import { Mask } from '../Shared/Mask';
import { Position } from '../Shared/Position';
import { BaseElement } from './BaseElement';
import { computeAndApplyAnimations } from './Helpers/AnimationsHelper';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { GroupElement } from './GroupElement';
import { BlendMode } from '../Shared/BlendMode';
import { CreativeTypes } from '../../Enums/CreativeTypes';
import { PreviewTypes } from '../../Enums/PreviewTypes';
import { isDefinedNumber } from '../../Helpers/utils';
import { type AnimationConfig, type AnimationInConfig, type AnimationOutConfig } from '../Animations/types';
import {
    type Animation,
    type AnimationIn,
    type AnimationOut,
    type BaseVisualElementParams,
    type CompElement,
    type ElementUpdateOptions,
    type Reason,
} from '../../types';

declare let VIDEO_MAX_DIMENSIONS: number;
declare let IMAGE_MAX_DIMENSIONS: number;

export abstract class BaseVisualElement extends BaseElement {
    hidden = false;

    startFrame!: number;

    duration!: number;

    renderOrder = 0;

    opacity = 1;

    rotation = 0;

    scale = 1;

    dropShadow: DropShadow | null = null;

    mask: Mask | null = null;

    blendMode: BlendMode | null = null;

    position!: Position;

    dimension!: Dimension;

    animationIn?: AnimationIn | null = null;

    animationOut?: AnimationOut | null = null;

    animations?: Animation[] | null = null;

    parent: GroupElement | null = null;

    canvasDimension!: Dimension;

    assetLoader: IAssetsLoader | null = null;

    isAssetLoading = false;

    lockUniScaling = false;

    allowToggleVisibility = false;

    static ELEMENT_MIN_WIDTH = 1;

    static ELEMENT_MIN_HEIGHT = 1;

    setAssetLoader(loader: IAssetsLoader): void {
        this.assetLoader = loader;
    }

    setProperties(rawParams: Partial<BaseVisualElementParams>): Set<ElementUpdateTypes> {
        const params = this.correctPropertiesToUpdate(rawParams);
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);
        const oldStartFrame = this.startFrame;
        const oldDuration = this.duration;

        if (params.hidden !== undefined && params.hidden !== this.hidden) {
            this.hidden = params.hidden;
            updateTypes.add(ElementUpdateTypes.VISIBILITY);
        }

        if (params.startFrame !== undefined && params.startFrame !== this.startFrame) {
            this.startFrame = params.startFrame;
            updateTypes.add(ElementUpdateTypes.TIMEFRAME);
        }

        if (params.duration !== undefined && params.duration !== this.duration) {
            this.duration = params.duration;
            updateTypes.add(ElementUpdateTypes.TIMEFRAME);
        }

        if (params.renderOrder !== undefined && params.renderOrder !== this.renderOrder) {
            this.renderOrder = params.renderOrder;
            updateTypes.add(ElementUpdateTypes.RENDER_ORDER);
        }

        if (params.opacity !== undefined && params.opacity !== this.opacity) {
            this.opacity = params.opacity;
            updateTypes.add(ElementUpdateTypes.OPACITY);
        }

        if (params.rotation !== undefined && params.rotation !== this.rotation) {
            this.rotation = params.rotation;
            updateTypes.add(ElementUpdateTypes.ROTATION);
        }

        if (params.scale !== undefined && params.scale !== this.scale) {
            this.scale = params.scale;
            updateTypes.add(ElementUpdateTypes.SCALE);
        }

        if (params.dropShadow !== undefined) {
            this.dropShadow = params.dropShadow ? new DropShadow(params.dropShadow) : null;
            updateTypes.add(ElementUpdateTypes.DROP_SHADOW);
        }

        if (params.mask !== undefined) {
            this.mask = params.mask ? new Mask(params.mask) : null;
            updateTypes.add(ElementUpdateTypes.MASK);
        }

        if (params.blendMode !== undefined) {
            this.blendMode = params.blendMode ? new BlendMode(params.blendMode) : null;
            updateTypes.add(ElementUpdateTypes.BLEND_MODE);
        }

        if (params.position !== undefined && params.position !== null) {
            this.position = new Position(params.position.x, params.position.y);
            updateTypes.add(ElementUpdateTypes.POSITION);
        }

        if (params.dimension !== undefined && params.dimension !== null) {
            this.dimension = new Dimension(params.dimension.width, params.dimension.height);
            updateTypes.add(ElementUpdateTypes.DIMENSION);
        }

        if (params.lockUniScaling !== undefined) {
            this.lockUniScaling = params.lockUniScaling;
            updateTypes.add(ElementUpdateTypes.LOCK_UNI_SCALING);
        }

        if (params.allowToggleVisibility !== undefined) {
            this.allowToggleVisibility = params.allowToggleVisibility;
            updateTypes.add(ElementUpdateTypes.ALLOW_VISIBILITY_TOGGLE);
        }

        if (params.animationIn !== undefined) {
            if (params.animationIn) {
                const config = this.mergeAnimationConfigs<AnimationInConfig>(
                    params.animationIn,
                    this?.animationIn?.config as AnimationInConfig,
                );
                this.animationIn = createAnimationIn(config, this);
            } else {
                this.animationIn = null;
            }

            updateTypes.add(ElementUpdateTypes.ANIMATION_IN);
        }

        if (params.animationOut !== undefined) {
            if (params.animationOut) {
                const config = this.mergeAnimationConfigs<AnimationOutConfig>(
                    params.animationOut,
                    this?.animationOut?.config as AnimationOutConfig,
                );
                this.animationOut = createAnimationOut(config, this);
            } else {
                this.animationOut = null;
            }

            updateTypes.add(ElementUpdateTypes.ANIMATION_OUT);
        }

        if (params.animations !== undefined) {
            if (params.animations) {
                this.animations = params.animations
                    .map((config, index) =>
                        createAnimation(
                            this.mergeAnimationConfigs<AnimationConfig>(
                                config,
                                this.animations ? (this.animations[index]?.config as AnimationConfig) : null,
                            ),
                            this,
                        ),
                    )
                    .filter(truthy);
            } else {
                this.animations = null;
            }

            updateTypes.add(ElementUpdateTypes.ANIMATIONS);
        }

        if ('parent' in params) {
            this.parent = params.parent;
        }

        if (isDefinedNumber(params.startFrame) && isDefinedNumber(oldStartFrame) && oldStartFrame !== this.startFrame) {
            // TODO: Added ignore. need checking
            computeAndApplyAnimations(this, {
                startFrame: this.startFrame - oldStartFrame,
            });
        }

        if (isDefinedNumber(params.duration) && isDefinedNumber(oldDuration) && oldDuration !== this.duration) {
            computeAndApplyAnimations(this, {
                duration: this.duration - oldDuration,
            });
        }

        return updateTypes;
    }

    mergeAnimationConfigs<T extends AnimationInConfig | AnimationOutConfig | AnimationConfig>(
        newConfig: T,
        currentConfig: T | null | undefined,
    ) {
        if (!currentConfig || newConfig.type === currentConfig.type) {
            return newConfig;
        }

        const ignoredProperties = ['type', 'direction'];

        return Object.entries(newConfig).reduce((acc: T, [key, value]) => {
            if (currentConfig.hasOwnProperty(key) && !ignoredProperties.includes(key)) {
                acc[key] = currentConfig[key];
            } else {
                acc[key] = value;
            }

            return acc;
        }, {} as T);
    }

    isVisible(frameIndex: number): boolean {
        // note that frames can contain fractions (for motion-blur), so we cannot just check for 'frameIndex < this.startFrame + this.duration'
        return !this.hidden && frameIndex >= this.startFrame && frameIndex <= this.startFrame + this.duration - 1;
    }

    hasMotionBlur(): boolean {
        // check if motion blur is applied to any of the animations
        return [...(this.animations || []), this.animationIn, this.animationOut]
            .filter(truthy)
            .some((anim) => 'motionBlur' in anim.config && anim.config.motionBlur === true);
    }

    setCanvasDimension(canvasDimension: Dimension): void {
        this.canvasDimension = canvasDimension;
    }

    constructAsset(frameRate = 0): void {
        // Not Implemented;
    }

    getGreatestParent(): GroupElement | null {
        return this.parent?.getGreatestParent() || this.parent;
    }

    getCompElement(frameIndex: number): CompElement | null | undefined {
        // Override in subclass
        throw new Error('Not implemented');
    }

    applyAnimations(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        if (this.animations && this.animations.length) {
            this.animations.forEach((animation) => {
                compEl = animation.updateCompEl(frameIndex, compEl);
            });
        }

        if (this.animationOut) {
            compEl = this.animationOut.updateCompEl(frameIndex, compEl);
        }

        if (this.animationIn) {
            compEl = this.animationIn.updateCompEl(frameIndex, compEl);
        }

        return compEl;
    }

    correctPropertiesToUpdate(properties: Partial<BaseVisualElementParams>) {
        const temp = { ...properties };
        Object.keys(temp).forEach((propName) => {
            const value = temp[propName];

            if (propName === 'dimension') {
                const { width, height } = value;
                temp[propName].width =
                    width < BaseVisualElement.ELEMENT_MIN_WIDTH ? BaseVisualElement.ELEMENT_MIN_WIDTH : width;
                temp[propName].height =
                    height < BaseVisualElement.ELEMENT_MIN_HEIGHT ? BaseVisualElement.ELEMENT_MIN_HEIGHT : height;
            }

            if (propName === 'animationIn' && value?.duration === 0) {
                temp[propName] = null;
            }

            if (propName === 'animationOut' && value?.duration === 0) {
                temp[propName] = null;
            }

            if (propName === 'animations' && !!value?.length) {
                const { startFrame, duration } = value[0];

                if (duration === 0) {
                    temp[propName] = null;
                } else if (startFrame + duration > this.duration + this.startFrame) {
                    temp[propName][0].duration = this.duration + this.startFrame - startFrame;
                }
            }
        });

        return temp;
    }

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes) {
        if (previewType === PreviewTypes.CONTENT) {
            return {};
        }

        const DELTA = 1;
        // prettier-ignore
        const MAX_HEIGHT_WIDTH = creativeType === CreativeTypes.VIDEO ?
            VIDEO_MAX_DIMENSIONS ? VIDEO_MAX_DIMENSIONS : 4096 :
            IMAGE_MAX_DIMENSIONS ? IMAGE_MAX_DIMENSIONS : 4096;

        return {
            width: {
                GREATER_THAN: DELTA,
                LESS_THAN: MAX_HEIGHT_WIDTH,
            },
            height: {
                GREATER_THAN: DELTA,
                LESS_THAN: MAX_HEIGHT_WIDTH,
            },
            // removed position validation by https://bynder.atlassian.net/browse/VBS-7357
            // horizontalPosition: {
            //     GREATER_THAN: -this.dimension.getWidth() + DELTA,
            //     LESS_THAN: this.canvasDimension.getWidth() - DELTA,
            // },
            // verticalPosition: {
            //     GREATER_THAN: -this.dimension.getHeight() + DELTA,
            //     LESS_THAN: this.canvasDimension.getHeight() - DELTA,
            // },
            rotation: {
                GREATER_THAN: -359,
                LESS_THAN: 359,
            },
            opacity: {
                GREATER_THAN: 0,
                LESS_THAN: 100,
            },
            duration: {
                GREATER_THAN: 1,
                FRAMES_TO_TIME: true,
            },
        };
    }

    isAnimated() {
        if (this.parent && this.parent.isAnimated()) {
            return true;
        }

        return !!this.animationIn || !!this.animationOut || (this.animations || []).length > 0;
    }

    hasAnimatedNeighbor() {
        if (!this.parent) {
            return false;
        }

        return this.parent.children.some((el) => el.isAnimated());
    }

    getCopy(): typeof this {
        const element = super.getCopy();
        element.setCanvasDimension(this.canvasDimension);
        element.parent = this.parent;

        return element;
    }

    toObject(): BaseVisualElementParams {
        const baseObject = super.toObject();

        return {
            ...baseObject,
            parentId: this.parent?.id,
            hidden: this.hidden,
            startFrame: this.startFrame,
            duration: this.duration,
            renderOrder: this.renderOrder,
            opacity: this.opacity,
            rotation: this.rotation,
            scale: this.scale,
            dropShadow: this.dropShadow?.toObject() ?? null,
            mask: this.mask?.toObject() ?? null,
            blendMode: this.blendMode?.toObject() ?? null,
            lockUniScaling: this.lockUniScaling ?? false,
            allowToggleVisibility: this.allowToggleVisibility ?? false,
            position: this.position?.toObject() ?? null,
            dimension: this.dimension?.toObject() ?? null,
            animationIn: this.animationIn?.toObject() ?? null,
            animationOut: this.animationOut?.toObject() ?? null,
            animations: this.animations?.map((animation) => animation?.toObject()).filter(truthy) ?? null,
        };
    }

    specificUpdateDataProcessing(
        params: Partial<BaseVisualElementParams>,
        reason: Reason,
    ): Partial<BaseVisualElementParams> {
        return params;
    }

    update(params: Partial<BaseVisualElementParams>, options?: ElementUpdateOptions): Set<ElementUpdateTypes> {
        return this.setProperties(params);
    }
}
