import { getRunsForExport } from '@bynder-studio/structured-text';
import { cloneObj } from '@bynder-studio/structured-text/src/Helpers/utils';
import { CreativeTypes } from '../Enums/CreativeTypes';
import { ElementTypes } from '../Enums/ElementTypes';
import type { BaseMultiPageModel } from '../Models/Models/BaseMultiPageModel';
import { GroupElement } from '../Models/Elements/GroupElement';
import { ImageElement } from '../Models/Elements/ImageElement';
import { TextElement } from '../Models/Elements/TextElement';
import { VideoElement } from '../Models/Elements/VideoElement';
import { BackgroundColor } from '../Models/Properties/BackgroundColor';
import { GlobalAudio } from '../Models/Properties/GlobalAudio';
import { PosterFrame } from '../Models/Properties/PosterFrame';
import { ShapeElement } from '../Models/Elements/ShapeElement';
import { type Shot } from '../Models/Shot/Shot';
import { type TemplatePage } from '../Renderers/BaseRenderer/IBaseRenderer';
import { ShapeTypes } from '../Enums/ShapeTypes';
import { type ContentProperty, type VisualElement } from '../types';
import { type VideoModel } from '../Models/Models/VideoModel';
import { type BaseModel } from '../Models/Models/BaseModel';

const getBaseSpecElement = (rawElement: any) => {
    return {
        id: rawElement.id,
        name: rawElement.name,
        parentId: rawElement.parentId,
        contentPropertyUuid: rawElement.contentPropertyId,
        properties: {
            opacity: rawElement.opacity,
            rotation: rawElement.rotation,
            // scale: rawElement.scale,  //not yet supported in editor, so keep disabled
            horizontalPosition: rawElement.position.x,
            verticalPosition: rawElement.position.y,
            width: rawElement.dimension.width,
            height: rawElement.dimension.height,
            duration: rawElement.duration,
            startFrame: rawElement.startFrame,
            renderOrder: rawElement.renderOrder,
            hidden: rawElement.hidden,
            locked: rawElement.locked,
            allowToggleVisibility: rawElement.allowToggleVisibility,
            animationIn: rawElement.animationIn,
            animationOut: rawElement.animationOut,
            animations: rawElement.animations,
        },
    };
};

export const exportElements = (elements: VisualElement[]) => {
    const templateSpecElements: { [key: string]: any } = {};
    elements.forEach((el) => {
        if (el instanceof VideoElement) {
            const rawElement = el.toObject();
            const baseSpecElement = getBaseSpecElement(rawElement);

            templateSpecElements[el.id] = {
                ...baseSpecElement,
                type: ElementTypes.VIDEO,
                properties: {
                    ...baseSpecElement.properties,
                    value: {
                        type: rawElement.srcType,
                        value: rawElement.srcId,
                        offsetTime: rawElement.offsetTime,
                        thumbnailStorageUrl: null,
                        thumbnail: null,
                        videoPreviewUrl: rawElement.src,
                        isAlpha: rawElement.isAlpha,
                        bynderCollectionId: rawElement?.virtualData?.bynderCollectionId || null,
                    },
                    allowPersonalUpload: rawElement?.virtualData?.allowPersonalUpload ?? true,
                    timelineBehavior: rawElement.timelineBehavior,
                    contentTransform: rawElement.contentTransform,
                    dropShadow: rawElement.dropShadow,
                    mask: rawElement.mask,
                    blendMode: rawElement.blendMode,
                    useAudio: rawElement.useAudio,
                    gain: rawElement.gain,
                    fadeIn: rawElement.fadeIn,
                    fadeOut: rawElement.fadeOut,
                    useDynamicLength: rawElement.useDynamicLength,
                    naturalWidth: rawElement.naturalDimension.width,
                    naturalHeight: rawElement.naturalDimension.height,
                    fileName: rawElement.fileName || '',
                },
            };
        }

        if (el instanceof ImageElement) {
            const rawElement = el.toObject();
            const baseSpecElement = getBaseSpecElement(rawElement);

            templateSpecElements[el.id] = {
                ...baseSpecElement,
                type: ElementTypes.IMAGE,
                properties: {
                    ...baseSpecElement.properties,
                    value: {
                        type: rawElement.srcType,
                        value: rawElement.srcId,
                        offsetTime: null,
                        thumbnailStorageUrl: null,
                        thumbnail: rawElement.src,
                        bynderCollectionId: rawElement?.virtualData?.bynderCollectionId || null,
                    },
                    allowPersonalUpload: rawElement?.virtualData?.allowPersonalUpload ?? true,
                    timelineBehavior: rawElement.timelineBehavior,
                    contentTransform: rawElement.contentTransform,
                    dropShadow: rawElement.dropShadow,
                    mask: rawElement.mask,
                    blendMode: rawElement.blendMode,
                    naturalWidth: rawElement.naturalDimension.width,
                    naturalHeight: rawElement.naturalDimension.height,
                    fileName: rawElement.fileName || '',
                },
            };
        }

        if (el instanceof TextElement) {
            const rawElement = el.toObject();
            const baseSpecElement = getBaseSpecElement(rawElement);

            templateSpecElements[el.id] = {
                ...baseSpecElement,
                type: ElementTypes.TEXT,
                properties: {
                    ...baseSpecElement.properties,
                    value: {
                        runs: getRunsForExport(rawElement.formattedText.runs, true),
                        layoutRuns: rawElement.formattedText.layoutRuns,
                        value: rawElement.formattedText.value,
                    },
                    textDirection: rawElement.textDirection,
                    timelineBehavior: rawElement.timelineBehavior,
                    contentTransform: rawElement.contentTransform,
                    dropShadow: rawElement.dropShadow,
                    mask: rawElement.mask,
                    blendMode: rawElement.blendMode,
                    textControl: rawElement.textControl,
                    limitTextToBounds: rawElement.limitTextToBounds,
                    minFontScale: rawElement.minFontScale,
                    fontScale: rawElement.fontScale || 1,
                    fontId: Number(rawElement.formattedText.runs[0].fontId),
                    fontSize: rawElement.formattedText.runs[0].fontSize,
                    fontColor: rawElement.formattedText.runs[0].color,
                    lineHeight: rawElement.formattedText.runs[0].leading,
                    charSpacing: rawElement.formattedText.runs[0].tracking,
                    textTransform: rawElement.formattedText.runs[0].textTransform,
                    textDecoration: rawElement.formattedText.runs[0].textDecoration,
                    stroke: rawElement.formattedText.runs[0].stroke,
                    paragraphSpacing: rawElement.formattedText.runs[0].paragraphSpacing,
                    textBackground: rawElement.textBackground,
                    textScript: rawElement.formattedText.runs[0].textScript,
                    styleId: rawElement.formattedText.runs[0].styleId || null,
                    leadingType: rawElement.leadingType,
                    textStyles: rawElement.textStyles,
                    brandColors: rawElement.brandColors,
                    textBackgroundBrandColors: rawElement.textBackgroundBrandColors,
                },
            };
        }

        if (el instanceof ShapeElement) {
            const rawElement = el.toObject();
            const baseSpecElement = getBaseSpecElement(rawElement);

            templateSpecElements[el.id] = {
                ...baseSpecElement,
                type: ElementTypes.SHAPE,
                properties: {
                    ...baseSpecElement.properties,
                    shapeType: rawElement.shapeType,
                    pathMetadata:
                        rawElement.shapeType === ShapeTypes.RECTANGLE
                            ? { borderRadius: rawElement?.borderRadius || 0 }
                            : rawElement.shapeType === ShapeTypes.CUSTOM
                            ? { path: rawElement?.path || '' }
                            : null,
                    fillColor: rawElement.fillColor,
                    borderColor: rawElement.borderColor,
                    borderWidth: rawElement.borderWidth,
                    borderAlignment: rawElement.borderAlignment,
                    timelineBehavior: rawElement.timelineBehavior,
                    dropShadow: rawElement.dropShadow,
                    mask: rawElement.mask,
                    blendMode: rawElement.blendMode,
                    fillBrandColors: rawElement.fillBrandColors,
                    borderBrandColors: rawElement.borderBrandColors,
                },
            };
        }

        if (el instanceof GroupElement) {
            const rawElement = el.toObject();
            const baseSpecElement = getBaseSpecElement(rawElement);

            templateSpecElements[el.id] = {
                ...baseSpecElement,
                type: ElementTypes.GROUP,
                properties: {
                    ...baseSpecElement.properties,
                    mask: rawElement.mask,
                    blendMode: rawElement.blendMode,
                },
                children: exportElements((el as GroupElement).children),
            };
        }
    });

    return templateSpecElements;
};

export const exportContentProperty = (contentProperty: ContentProperty) => {
    const common = {
        name: contentProperty.name,
        uuid: contentProperty.uuid,
        type: contentProperty.type,
    };

    if (contentProperty.type === ElementTypes.IMAGE) {
        const properties = contentProperty.properties;

        return {
            ...common,
            properties: {
                value: {
                    type: properties.srcType,
                    value: properties.srcId,
                    offsetTime: null,
                    thumbnailStorageUrl: null,
                    thumbnail: properties.src,
                    bynderCollectionId: properties?.virtualData?.bynderCollectionId || null,
                },
                naturalWidth: properties.naturalDimension.width,
                naturalHeight: properties.naturalDimension.height,
                allowPersonalUpload: properties.virtualData?.allowPersonalUpload ?? true,
                fileName: properties.fileName,
            },
        };
    }

    if (contentProperty.type === ElementTypes.VIDEO) {
        const properties = contentProperty.properties;

        return {
            ...common,
            properties: {
                value: {
                    type: properties.srcType,
                    value: properties.srcId,
                    offsetTime: properties.offsetTime,
                    thumbnailStorageUrl: null,
                    thumbnail: null,
                    videoPreviewUrl: properties.src,
                    isAlpha: properties.isAlpha,
                    bynderCollectionId: properties?.virtualData?.bynderCollectionId || null,
                },
                useAudio: properties.useAudio,
                useDynamicLength: properties.useDynamicLength,
                naturalWidth: properties.naturalDimension.width,
                naturalHeight: properties.naturalDimension.height,
                allowPersonalUpload: properties.virtualData?.allowPersonalUpload ?? true,
                fileName: properties.fileName,
                gain: properties.gain,
                fadeIn: properties.fadeIn,
                fadeOut: properties.fadeOut,
            },
        };
    }

    if (contentProperty.type === ElementTypes.TEXT) {
        const properties = contentProperty.properties;

        return {
            ...common,
            properties: {
                value: {
                    runs: getRunsForExport(properties.formattedText.runs, true),
                    layoutRuns: cloneObj(properties.formattedText.layoutRuns),
                    value: properties.formattedText.value,
                },
                textStyles: properties.textStyles,
                brandColors: properties.brandColors,
                limitTextToBounds: properties.limitTextToBounds,
            },
        };
    }

    if (contentProperty.type === ElementTypes.SHAPE) {
        const properties = contentProperty.properties;

        return {
            ...common,
            properties: {
                shapeType: properties.shapeType,
                pathMetadata:
                    properties.shapeType === ShapeTypes.RECTANGLE
                        ? { borderRadius: properties?.borderRadius || 0 }
                        : properties.shapeType === ShapeTypes.CUSTOM
                        ? { path: properties?.path || '' }
                        : null,
                fillColor: properties.fillColor,
                borderColor: properties.borderColor,
                borderWidth: properties.borderWidth,
                borderAlignment: properties.borderAlignment,
                borderBrandColors: properties.borderBrandColors,
                fillBrandColors: properties.fillBrandColors,
            },
        };
    }

    return null;
};

const exportShots = (shots: Shot[]) => {
    const rawShots = shots.map((shot) => shot.toObject());

    return rawShots.map((shot) => {
        const shotItem = {
            ...shot,
            elements: shot.elements.map((elId) => ({
                disabled: false,
                id: elId,
            })),
        };
        // todo Why it should be without id i don't know
        delete shotItem.id;

        return shotItem;
    });
};

const exportGlobalAudio = (globalAudio: GlobalAudio) => {
    const rawGlobalAudio = globalAudio.toObject();

    return {
        id: rawGlobalAudio.id,
        name: rawGlobalAudio.name,
        type: ElementTypes.GLOBAL_AUDIO,
        properties: {
            locked: rawGlobalAudio.locked,
            value: rawGlobalAudio.srcId
                ? {
                      type: rawGlobalAudio.srcType,
                      value: rawGlobalAudio.srcId,
                      offsetTime: rawGlobalAudio.offsetTime,
                      url: rawGlobalAudio.src,
                  }
                : null,
            gain: rawGlobalAudio.gain,
            fadeIn: rawGlobalAudio.fadeIn,
            fadeOut: rawGlobalAudio.fadeOut,
        },
    };
};

const exportBgColor = (bgColor: BackgroundColor) => {
    const rawBgColor = bgColor.toObject();

    return {
        id: rawBgColor.id,
        name: rawBgColor.name,
        type: ElementTypes.BACKGROUND_COLOR,
        properties: {
            locked: rawBgColor.locked,
            value: rawBgColor.color,
            brandColors: rawBgColor.brandColors,
        },
    };
};

const exportPosterFrame = (posterFrame: PosterFrame) => {
    const rawPosterFrame = posterFrame.toObject();

    return {
        id: rawPosterFrame.id,
        name: rawPosterFrame.name,
        type: ElementTypes.POSTER_FRAME,
        properties: {
            value: rawPosterFrame.frame,
        },
    };
};

type ExportPage = Exclude<TemplatePage, 'aspectRatio'>;

export const exportPage = (model: BaseModel | VideoModel, creativeType: CreativeTypes) => {
    const specElements = exportElements(model.getElements());
    const specGlobalElements = {};
    let specVideoParams = {};

    const specBgColor = exportBgColor(model.getBackgroundColor());
    specGlobalElements[specBgColor.id] = specBgColor;

    if (creativeType === CreativeTypes.VIDEO) {
        const videoModel = model as VideoModel;
        const specShots = exportShots(videoModel.getShots());
        const specGlobalAudioTrack1 = exportGlobalAudio(videoModel.getGlobalAudioTrack1());
        const specGlobalAudioTrack2 = exportGlobalAudio(videoModel.getGlobalAudioTrack2());
        const specPosterFrame = exportPosterFrame(videoModel.getPosterFrame());
        const specPlaybackDuration = videoModel.getPlaybackDuration().toObject();

        specGlobalElements[specGlobalAudioTrack1.id] = specGlobalAudioTrack1;
        specGlobalElements[specGlobalAudioTrack2.id] = specGlobalAudioTrack2;
        specGlobalElements[specPosterFrame.id] = specPosterFrame;

        specVideoParams = {
            ...specPlaybackDuration,
            shots: specShots,
        };
    }

    return {
        ...model.getDimensions().toObject(),
        ...specVideoParams,
        elements: { ...specElements, ...specGlobalElements },
        globalElements: specGlobalElements,
    } as unknown as ExportPage;
};

const exportPages = (multiPageModel: BaseMultiPageModel, creativeType: CreativeTypes): ExportPage[] => {
    const modelsMetadata = multiPageModel.getModelsMetaData();

    return multiPageModel.getModels().map(
        (model: BaseModel | VideoModel, index: number) =>
            ({
                ...modelsMetadata[index],
                ...exportPage(model, creativeType),
            }) as unknown as ExportPage,
    );
};

export const exportSpecificationTemplate = (
    multiPageModel: BaseMultiPageModel,
    creativeType = CreativeTypes.IMAGE,
) => ({
    pages: exportPages(multiPageModel, creativeType),
    textStyles: multiPageModel.getTextStyles().toObject(),
    contentProperties: multiPageModel.getContentPropertiesManager().toObject(),
});

export const convertGroupSpecElementIntoPreset = (specGroupElement: any) => {
    const diffStartFrame = specGroupElement.properties.startFrame;

    const updateStartFrame = (specElements) => {
        specElements.forEach((specEl: any) => {
            specEl.properties.startFrame -= diffStartFrame;

            if (specEl.properties.animations) {
                specEl.properties.animations.forEach((animation) => {
                    animation.startFrame -= diffStartFrame;
                });
            }

            if (specEl.type === 'GROUP') {
                updateStartFrame(Object.values(specEl.children));
            }
        });
    };

    updateStartFrame([specGroupElement]);

    // preset shouldn't have a parent
    delete specGroupElement.parentId;

    return specGroupElement;
};
export default {
    exportPage,
    exportElements,
    convertGroupSpecElementIntoPreset,
    exportSpecificationTemplate,
};
