import React from 'react';
import useAccessRights from 'packages/hooks/useAccessRights';
import roles from '~/configs/roles';
import { PageWithAppcues } from '~/helpers/RouteWithAppcues';
import { DesignSubPages } from './components/Sidebar/types';
import Gallery from './pages/gallery/Gallery';
import BlankPage from './pages/blank';
import AllPage from './pages/all';
import ImportDesign from './pages/import/Import';
import ImportStatus from './pages/import/ImportStatus';
import PreviewTemplate from './pages/preview/PreviewTemplate';
import PreviewGallery from './pages/preview/PreviewGallery';
import Categories from './pages/categories';
import CategoryDetail from './pages/categoryDetail';
import FigmaImportPage from './pages/figma';

const useRouter = () => {
    const { isAccountRoleAssigned } = useAccessRights();
    let modalRouter;

    if (isAccountRoleAssigned(roles.video_brand_studio.video.manage)) {
        modalRouter = {
            [DesignSubPages.CATEGORIES]: (
                <PageWithAppcues>
                    <Categories />
                </PageWithAppcues>
            ),
            [DesignSubPages.CATEGORY_DETAILS]: (
                <PageWithAppcues>
                    <CategoryDetail />
                </PageWithAppcues>
            ),
            [DesignSubPages.TEMPLATE_PREVIEW]: (
                <PageWithAppcues>
                    <PreviewTemplate />
                </PageWithAppcues>
            ),
        };
    } else {
        modalRouter = {
            [DesignSubPages.ALL]: (
                <PageWithAppcues>
                    <AllPage />
                </PageWithAppcues>
            ),
            [DesignSubPages.BLANK]: (
                <PageWithAppcues>
                    <BlankPage />
                </PageWithAppcues>
            ),
            [DesignSubPages.CATEGORIES]: (
                <PageWithAppcues>
                    <Categories />
                </PageWithAppcues>
            ),
            [DesignSubPages.CATEGORY_DETAILS]: (
                <PageWithAppcues>
                    <CategoryDetail />
                </PageWithAppcues>
            ),
            [DesignSubPages.GALLERY]: (
                <PageWithAppcues>
                    <Gallery />
                </PageWithAppcues>
            ),
            [DesignSubPages.IMPORT]: (
                <PageWithAppcues>
                    <ImportDesign />
                </PageWithAppcues>
            ),
            [DesignSubPages.IMPORT_STATUS]: (
                <PageWithAppcues>
                    <ImportStatus />
                </PageWithAppcues>
            ),
            [DesignSubPages.TEMPLATE_PREVIEW]: (
                <PageWithAppcues>
                    <PreviewTemplate />
                </PageWithAppcues>
            ),
            [DesignSubPages.GALLERY_PREVIEW]: (
                <PageWithAppcues>
                    <PreviewGallery />
                </PageWithAppcues>
            ),
        };

        modalRouter[DesignSubPages.FIGMA_IMPORT] = (
            <PageWithAppcues>
                <FigmaImportPage />
            </PageWithAppcues>
        );
    }

    return modalRouter;
};

export default useRouter;
