import {
    type AppliedStyles,
    BoundedText,
    type BoundedTextProps,
    deriveAppliedStylesFromStyleProps,
    Direction,
    getFontFamilies,
    getTextDirections,
    HorizontalAlignment,
    type IStructuredGlyph,
    Placement,
    type RunProps,
    ShapedText,
    TextConfig,
    type TextEditorData,
    type TextProps,
    TextRenderer,
    VerticalAlignment,
} from '@bynder-studio/structured-text';
import { ORDINARY_STROKE_COLOR_OBJ, StrokeType, TextBackground } from '@bynder-studio/misc';
import { getTextPropsFonts } from '@bynder-studio/structured-text/src/Helpers/textUtils';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { horizontalAlignment, verticalAlignment } from '../../Enums/TextContentTransform';
import { createAnimationIn } from '../Animations/AnimationIn';
import { createAnimationOut } from '../Animations/AnimationOut';
import { createCurvedRectPoints, createRectanglePath } from '../../Helpers/pathFunctions';
import { FontAsset } from '../Assets/FontAsset';
import type { IAsset } from '../Assets/IAsset';
import { BaseCompElement } from '../CompModels/Elements/BaseCompElement';
import { GroupCompElement } from '../CompModels/Elements/GroupCompElement';
import { SvgCompElement } from '../CompModels/Elements/SvgCompElement';
import { Box } from '../Shared/Box';
import { ContentTransform } from '../Shared/ContentTransform';
import { Dimension } from '../Shared/Dimension';
import { Font } from '../Shared/Font';
import { Position } from '../Shared/Position';
import { BaseVisualElement } from './BaseVisualElement';
import { TimelineBehavior } from '../../Enums/TimelineBehavior';
import { LeadingTypes } from '../../Enums/LeadingTypes';
import { CreativeTypes } from '../../Enums/CreativeTypes';
import { PreviewTypes } from '../../Enums/PreviewTypes';
import { deepClone, equals } from '../../Helpers/utils';
import { TextStyles } from '../Shared/TextStyles';
import { type ElementUpdateOptions, Reason, type TextElementParams, TextStyle } from '../../types';
import type { BaseAsset } from '../Assets/BaseAsset';

declare let VIDEO_MAX_CHARACTERS: number;
declare let IMAGE_MAX_CHARACTERS: number;

export class TextElement extends BaseVisualElement {
    charCount!: number;

    formattedText!: TextProps;

    textDirection?: Direction;

    detectedTextDirections: { ltr: number; rtl: number; detectedDirection: Direction } = {
        ltr: 0,
        rtl: 0,
        detectedDirection: Direction.LTR,
    };

    boundedText!: BoundedText;

    textControl?: string;

    /** @deprecated Use getTextProps instead */
    font: Font;

    minFontScale: number = null;

    fontScale = 1;

    leadingType: LeadingTypes;

    glyphData: ShapedText;

    showOversetBox = false;

    limitTextToBounds = false;

    compOversetBox: GroupCompElement[] = [];

    compSvgText: GroupCompElement | null;

    compSvgData: GroupCompElement | null;

    compSvgLines: GroupCompElement[];

    compSvgWords: GroupCompElement[];

    compSvgChars!: SvgCompElement[];

    timelineBehavior: TimelineBehavior = TimelineBehavior.AUTO;

    contentTransform: ContentTransform | null = null;

    textBackground: TextBackground | null = null;

    textEditorData: TextEditorData | null = null;

    isTextEditorActivated = false;

    textStyles: string[] = [];

    brandColors: number[] = [];

    textBackgroundBrandColors: number[] = [];

    private textStylesInstance: TextStyles;

    private glyphPathBboxCache: { [key: string]: any } = {};

    constructor(params: Partial<TextElementParams & { showOversetBox: boolean }>) {
        super();
        // this.glyphData = { lines: [] };
        // todo: remove this when we have removed all font usage
        this.font = new Font({
            fontId: 'default',
            fontSize: 50,
            fontColor: { red: 0, green: 0, blue: 0, opacity: 1, brandColorId: null },
            lineHeight: 1,
            charSpacing: 0,
        });

        this.setProperties(params);
    }

    static servicePropsList = [
        'updateTextForSelection',
        'updateTextSettingsByEachRun',
        'updateTextSettingForSelection',
    ];

    setTextStyles(textStyles: TextStyles) {
        this.textStylesInstance = textStyles;
    }

    setProperties(params: Partial<TextElementParams & { showOversetBox: boolean }>): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.textControl !== undefined) {
            this.textControl = params.textControl;
            updateTypes.add(ElementUpdateTypes.TEXT_CONTROL);
        }

        if (params.minFontScale !== undefined) {
            this.minFontScale = params.minFontScale;
            updateTypes.add(ElementUpdateTypes.MIN_FONT_SCALE);
        }

        if (params.fontScale !== undefined) {
            this.fontScale = params.fontScale;
            updateTypes.add(ElementUpdateTypes.FONT_SCALE);
        }

        if (params.limitTextToBounds !== undefined) {
            this.limitTextToBounds = params.limitTextToBounds;
            updateTypes.add(ElementUpdateTypes.LIMIT_TEXT_TO_BOUNDS);
        }

        if (params.showOversetBox !== undefined) {
            this.showOversetBox = params.showOversetBox;
            updateTypes.add(ElementUpdateTypes.SHOW_OVERSET_BOX);
        }

        if (params.textBackground !== undefined) {
            this.textBackground = params.textBackground ? new TextBackground(params.textBackground) : null;
            updateTypes.add(ElementUpdateTypes.TEXT_BACKGROUND);
        }

        if (
            params.timelineBehavior !== undefined &&
            this.timelineBehavior !== params.timelineBehavior &&
            Object.values(TimelineBehavior).includes(params.timelineBehavior)
        ) {
            this.timelineBehavior = params.timelineBehavior || TimelineBehavior.AUTO;
            updateTypes.add(ElementUpdateTypes.TIMELINE_BEHAVIOR);
        }

        if (params.contentTransform !== undefined && params.contentTransform !== null) {
            this.contentTransform = new ContentTransform(params.contentTransform);
            updateTypes.add(ElementUpdateTypes.CONTENT_TRANSFORM);
        }

        if (params.formattedText !== undefined && !equals(this.formattedText, params.formattedText)) {
            if (this.formattedText?.value !== params.formattedText?.value) {
                updateTypes.add(ElementUpdateTypes.TEXT);
            } else {
                updateTypes.add(ElementUpdateTypes.TEXT_RUNS);
            }

            this.formattedText = params.formattedText;
            const detectedTextDirections = getTextDirections(this.formattedText.value);

            if (!equals(detectedTextDirections, this.detectedTextDirections)) {
                this.detectedTextDirections = detectedTextDirections;
                updateTypes.add(ElementUpdateTypes.TEXT_DIRECTION);
            }
        }

        if (params.textDirection !== undefined && this.textDirection !== params.textDirection) {
            this.textDirection = params.textDirection;
            updateTypes.add(ElementUpdateTypes.TEXT_DIRECTION);
        }

        if (params.textStyles !== undefined && !equals(this.textStyles, params.textStyles)) {
            this.textStyles = params.textStyles;
            updateTypes.add(ElementUpdateTypes.TEXT_STYLES);
        }

        if (params.brandColors !== undefined && !equals(this.brandColors, params.brandColors)) {
            this.brandColors = params.brandColors;
            updateTypes.add(ElementUpdateTypes.TEXT_BRAND_COLORS);
        }

        if (
            params.textBackgroundBrandColors !== undefined &&
            !equals(this.textBackgroundBrandColors, params.textBackgroundBrandColors)
        ) {
            this.textBackgroundBrandColors = params.textBackgroundBrandColors;
            updateTypes.add(ElementUpdateTypes.TEXT_BACKGROUND_BRAND_COLORS);
        }

        this.leadingType = params.leadingType ?? this.leadingType;
        // todo: check do we actually use this?
        this.glyphData = params.glyphData || this.glyphData;

        return updateTypes;
    }

    specificUpdateDataProcessing(rawElement: any, reason: Reason) {
        if (rawElement.showOversetBox && reason !== 'user') {
            delete rawElement.showOversetBox;
        }

        if (rawElement.isTextEditorActivated && reason !== 'user') {
            delete rawElement.isTextEditorActivated;
        }

        if (rawElement.updateTextForSelection) {
            const { start, end, diff, updateLayout } = rawElement.updateTextForSelection;

            rawElement.formattedText = this.updateTextForSelection(start, end, diff, !!updateLayout);
            delete rawElement.updateTextForSelection;
        }

        if (rawElement.updateTextSettingsByEachRun) {
            const { styles } = rawElement.updateTextSettingsByEachRun;
            let startIdx = 0;
            let endIdx = this.boundedText.getShapedText().getText().length;

            if (this.textEditorData?.selection) {
                const { selection } = this.textEditorData;
                [startIdx, endIdx] = this.boundedText
                    .getShapedText()
                    .getTextIdxsFromSelection(selection.start, selection.end);
            }

            rawElement.formattedText = this.updateTextSettingsByEachRun(startIdx, endIdx, styles);
            delete rawElement.updateTextSettingsByEachRun;
        }

        if (rawElement.updateTextSettingForSelection) {
            const { start, end, settings } = rawElement.updateTextSettingForSelection;
            this.textStylesInstance.applyStyle(settings);

            const isCorrectIdx = (idx: number | undefined) =>
                Number.isInteger(idx) &&
                (idx as number) >= 0 &&
                (idx as number) <= this.boundedText.getShapedText().getText().length;

            let startIdx = isCorrectIdx(start) ? start : 0;
            let endIdx = isCorrectIdx(end) ? end : this.boundedText.getShapedText().getText().length;

            if (this.textEditorData !== null) {
                const { selection, cursorPosition } = this.textEditorData;
                [startIdx, endIdx] = this.boundedText
                    .getShapedText()
                    .getTextIdxsFromSelection(selection.start ?? cursorPosition, selection.end ?? cursorPosition);
            }

            rawElement.formattedText = this.updateTextSettingsForSelection(startIdx, endIdx, settings);
            delete rawElement.updateTextForSelection;
        }

        return rawElement;
    }

    update(
        params: Partial<
            TextElementParams & {
                showOversetBox: boolean;
            }
        >,
        options?: ElementUpdateOptions,
    ): Set<ElementUpdateTypes> {
        const { frameRate = 25 } = options || {};
        const oldFormattedText = deepClone(this.formattedText);
        const oldTextControl = this.textControl;
        const oldContentTransform = this.contentTransform;
        const oldDimension = this.dimension;
        const oldMinFontScale = this.minFontScale;
        const oldFontScale = this.fontScale;
        const oldTextDirection = this.textDirection || this.detectedTextDirections.detectedDirection;
        const oldTextBg = deepClone(this.textBackground);
        const updateTypes: Set<ElementUpdateTypes> = this.setProperties(params);
        const newTextDirection = this.textDirection || this.detectedTextDirections.detectedDirection;

        if (updateTypes.size > 2 || (updateTypes.size && !updateTypes.has(ElementUpdateTypes.POSITION))) {
            this.compSvgData = null;
        }

        if (this.leadingType === LeadingTypes.EXTERNAL_LEADING_PCT) {
            // conversion here is mainly used for tests where textelements are updated with another leadingtype
            const { runs } = this.getTextProps();

            this.formattedText.runs = runs.map((item) => {
                const leading = TextRenderer.getInstance().convertLeadingValue(item.fontId, item.leading);

                return { ...item, leading };
            });

            this.leadingType = LeadingTypes.LEADING_PCT;
        }

        if (params.isTextEditorActivated !== undefined && this.isTextEditorActivated !== params.isTextEditorActivated) {
            this.isTextEditorActivated = params.isTextEditorActivated;
        }

        if (params.showOversetBox !== undefined && this.showOversetBox !== params.showOversetBox) {
            this.showOversetBox = params.showOversetBox;
            updateTypes.add(ElementUpdateTypes.SHOW_OVERSET_BOX);
        }

        if (params.limitTextToBounds !== undefined && this.limitTextToBounds !== params.limitTextToBounds) {
            this.limitTextToBounds = params.limitTextToBounds;
            updateTypes.add(ElementUpdateTypes.LIMIT_TEXT_TO_BOUNDS);
        }

        if (this.textEditorData !== params.textEditorData && params.textEditorData !== undefined) {
            this.textEditorData = params.textEditorData;
            updateTypes.add(ElementUpdateTypes.TEXT_EDITOR_DATA);
        }

        if (params.textBackground !== undefined && !equals(oldTextBg, params.textBackground)) {
            this.textBackground = params.textBackground ? new TextBackground(params.textBackground) : null;
            updateTypes.add(ElementUpdateTypes.TEXT_BACKGROUND);
        }

        if (oldTextControl !== this.textControl) {
            this.minFontScale = null;
        }

        if (
            !equals(oldFormattedText, this.formattedText) ||
            !equals(oldTextBg, this.textBackground) ||
            !this.dimension.equals(oldDimension) ||
            this.contentTransform !== oldContentTransform ||
            this.textControl !== oldTextControl ||
            this.minFontScale !== oldMinFontScale ||
            this.fontScale !== oldFontScale ||
            newTextDirection !== oldTextDirection
        ) {
            const renderConfigs: Partial<TextConfig> = {};

            if (this.dimension !== oldDimension) {
                renderConfigs.boxWidth = this.dimension.getWidth();
                renderConfigs.boxHeight = this.dimension.getHeight();
            }

            if (this.minFontScale !== oldMinFontScale) {
                renderConfigs.minFontScale = this.minFontScale;
            }

            if (this.contentTransform !== oldContentTransform) {
                renderConfigs.xAlign =
                    horizontalAlignment[parseInt(this.contentTransform!.horizontalAlignment as unknown as string)];
                renderConfigs.yAlign =
                    verticalAlignment[parseInt(this.contentTransform!.verticalAlignment as unknown as string)];
            }

            if (this.textControl !== oldTextControl) {
                renderConfigs.placement = this.textControl as Placement;
            }

            if (!equals(this.textBackground, oldTextBg)) {
                renderConfigs.textBackground = this.textBackground;
            }

            if (newTextDirection !== oldTextDirection) {
                renderConfigs.textDirection = newTextDirection;
            }

            if (this.isThereFontsToLoad()) {
                this.constructAsset(frameRate);
            }

            this.boundedText.updateConfig(renderConfigs);
            const boxProps = this.getBoundedTextProps();
            boxProps.textStruct = this.formattedText;
            this.boundedText.updateConfig(boxProps);
            this.compSvgData = null;
            this.setInitialMinFontScale();

            if (renderConfigs.placement || renderConfigs.minFontScale) {
                this.updateCursorPosition();
            }
        }

        return updateTypes;
    }

    private updateCursorPosition() {
        if (this.textEditorData?.cursorPosition) {
            this.textEditorData.cursorPosition = this.boundedText.getShapedTextPositionByGlyphIdx(
                this.textEditorData.cursorPosition.glyphIdx,
            );
        }
    }

    private setInitialMinFontScale() {
        if (this.minFontScale === null) {
            this.minFontScale = this.boundedText.getAutoResizeScale();
        }
    }

    init() {
        const textRenderer = TextRenderer.getInstance();
        this.boundedText = textRenderer.createBoundedText(this.getTextProps(), this.getBoundedTextProps());
    }

    getTextProps(): TextProps {
        return this.formattedText;
    }

    getTextSettingForSelection(start?: number, end?: number, onlyUnique = true) {
        return this.boundedText.getShapedText().getStylesForSelection(start, end, onlyUnique);
    }

    updateTextForSelection(start: number, end: number, text: string, updateLayout = false) {
        return this.boundedText.getShapedText().updateTextForSelection(start, end, text, updateLayout);
    }

    updateTextSettingsForSelection(start: number, end: number, styles: Omit<RunProps, 'length'>) {
        return this.boundedText.getShapedText().updateStylesForSelection(start, end, styles);
    }

    updateTextSettingsByEachRun(start: number, end: number, styles: Omit<RunProps, 'length'>[]) {
        return this.boundedText.getShapedText().updateStylesForEachRun(start, end, styles);
    }

    getCurrentTextSettings(onlyUnique = true): AppliedStyles {
        const shapedText = this.boundedText.getShapedText();

        if (shapedText) {
            const selection = !this.isTextEditorActivated ? null : this.textEditorData?.selection;
            const cursorPosition = !this.isTextEditorActivated ? null : this.textEditorData?.cursorPosition;

            const start = selection?.start ?? cursorPosition;
            const end = selection?.end ?? cursorPosition;

            const textIdxs: [number, number] =
                start && end ? shapedText.getTextIdxsFromSelection(start, end) : [0, this.getTextProps().value.length];

            return shapedText.getStylesForSelection(...textIdxs, onlyUnique);
        }

        // todo: derive from all runs
        return deriveAppliedStylesFromStyleProps(this.getTextProps().runs[0]);
    }

    createTextStyle(name: string): string {
        const settings = this.getCurrentTextSettings();
        const style = { name };
        let isPossible = true;

        Object.entries(settings).forEach(([key, value]) => {
            if (['styleId', 'color'].includes(key)) {
                return;
            }

            isPossible = value.length === 1;

            if (isPossible) {
                style[key] = value[0];
            }
        });

        if (!isPossible) {
            return null;
        }

        return this.textStylesInstance.createStyle(style as TextStyle);
    }

    /**
     * Convert text element properties into bounded text configuration
     * @returns
     */
    getBoundedTextProps(): BoundedTextProps {
        const xAlignStr =
            horizontalAlignment[
                parseInt(this.contentTransform!.horizontalAlignment as unknown as string)
            ].toUpperCase();
        const yAlignStr =
            verticalAlignment[parseInt(this.contentTransform!.verticalAlignment as unknown as string)].toUpperCase();

        return {
            placement: Placement[this.textControl], // ?.toLowerCase(),
            boxWidth: this.dimension!.getWidth(),
            boxHeight: this.dimension!.getHeight(),
            horizontalAlignment: HorizontalAlignment[xAlignStr],
            verticalAlignment: VerticalAlignment[yAlignStr],
            minFontScale: this.minFontScale,
            fontScale: this.fontScale,
            textBackground: this.textBackground,
            textDirection: this.textDirection || this.detectedTextDirections.detectedDirection,
            // tracking: this.font.charSpacing,
            // leading: leading, //TODO: Enable when we support this in shaper
        };
    }

    constructAsset(frameRate: number = 0): void {
        const fontsToLoad = getTextPropsFonts(this.formattedText, getFontFamilies());
        const textRenderer = TextRenderer.getInstance();
        const { runs } = this.getTextProps();
        let loadingCount = 0;

        Object.keys(fontsToLoad).map((fontId) => {
            const inList = this.assetLoader!.getAsset(fontId);
            const asset = new FontAsset({
                id: fontId,
                fontId,
                src: fontsToLoad[fontId],
            });
            this.isAssetLoading = !!++loadingCount;
            this.assetLoader!.setAsset(asset);
            this.assetLoader!.loadFont(asset)
                .then((asset) => {
                    if (asset.object) {
                        if (!inList) {
                            textRenderer.setFont(fontId.toString(), asset.object);
                        }

                        // TODO: check if font leading check is necessary here
                        if (this.leadingType === LeadingTypes.EXTERNAL_LEADING_PCT) {
                            this.formattedText.runs = runs.map((run) => {
                                const leading = textRenderer.convertLeadingValue(run.fontId, run.leading);
                                run.leading = leading;

                                return { ...run, leading };
                            });

                            this.leadingType = LeadingTypes.LEADING_PCT;
                        }

                        const boxProps = this.getBoundedTextProps();
                        boxProps.textStruct = this.formattedText;
                        this.boundedText.updateConfig(boxProps, true);
                        this.compSvgData = null;
                    }
                })
                .finally(() => {
                    this.isAssetLoading = !!--loadingCount;
                });
        });
    }

    isContainsAsset(asset: IAsset): boolean {
        return asset instanceof FontAsset && !!getTextPropsFonts(this.formattedText, getFontFamilies())[asset.fontId];
    }

    private constructCompElement() {
        // TODO: do this during setup (note that since contents changes, we might need to copy for each frame)
        // Prepare SVG comp structure
        // Organise glyphs into lines, words and characters
        // Important note: Make sure that IDs for all of these elements are unique, they are used to match between frames.

        // TODO: logic is breaked after wrapping/unwrapping, improve it later
        // if (!this.isAnimated() && !this.hasAnimatedNeighbor() && this.compSvgData) {
        //     return this.compSvgData;
        // }

        this.compSvgData = null;
        this.compSvgLines = []; // GroupCompElements
        this.compSvgWords = []; // GroupCompElements
        this.compSvgChars = []; // SvgCompElements
        this.compOversetBox = [];

        const glyphData = this.boundedText.getShapedText();
        const shapedLines = glyphData.getShapedLines();

        const isSpace = (glyph: IStructuredGlyph) => glyph.data.text.length === 1 && glyph.data.text[0] === ' ';
        const isBreak = (glyph: IStructuredGlyph) => glyph.data.text.length === 1 && glyph.data.text[0] === '\n';
        const isPrevSpace = (glyphs: IStructuredGlyph[], index: number) => index > 0 && isSpace(glyphs[index - 1]);

        let requireTempLayer = false;

        // (currentIsSpace && !prevIsSpace) || (prevIsSpace && !currentIsSpace)
        shapedLines.forEach((line, lineIdx) => {
            const compLine = [];
            let compWord: SvgCompElement[] = [];
            line.glyphs.forEach((glyph, index) => {
                const svgCompEl = this.getSvgCompElement(glyph);
                this.compSvgChars.push(svgCompEl);

                if (!requireTempLayer && glyph.data?.strokeType === StrokeType.OUTSIDE) {
                    requireTempLayer = true;
                }

                const currentIsSpace = isSpace(glyph);
                const prevIsSpace = isPrevSpace(line.glyphs, index);
                const isSpaceForBreak =
                    currentIsSpace && index === line.glyphs.length - 1 && lineIdx !== shapedLines.length - 1;

                if (isBreak(glyph) || (currentIsSpace && !prevIsSpace) || (prevIsSpace && !currentIsSpace)) {
                    if (compWord.length > 0) {
                        const svgWordEl = new GroupCompElement(compWord);
                        svgWordEl.id = this.id + '_' + this.compSvgLines.length + '_' + this.compSvgWords.length; // unique ID

                        svgWordEl.name = 'word_' + svgWordEl.id;
                        svgWordEl.renderOrder = this.renderOrder;
                        // NOTE: This problem because of undefined in declaration
                        // but we redefine it upper in this function.
                        this.compSvgWords.push(svgWordEl);
                        compLine.push(svgWordEl);
                        compWord = [];
                    }

                    if (
                        !isSpaceForBreak &&
                        (glyph.data.path !== '' ||
                            glyph.data.decorationPaths ||
                            (this.textBackground && !isBreak(glyph)))
                    ) {
                        compWord.push(svgCompEl);
                    }
                } else {
                    compWord.push(svgCompEl);
                }
            });

            if (compWord.length > 0) {
                const svgWordEl = new GroupCompElement(compWord);
                svgWordEl.id = this.id + '_' + this.compSvgLines.length + '_' + this.compSvgWords.length; // unique ID

                svgWordEl.name = 'word_' + svgWordEl.id;
                svgWordEl.renderOrder = this.renderOrder;
                this.compSvgWords.push(svgWordEl);
                compLine.push(svgWordEl);
            }

            if (compLine.length > 0) {
                const compSvgLine = new GroupCompElement(compLine);
                compSvgLine.id = this.id + '_' + this.compSvgLines.length; // unique ID

                compSvgLine.name = 'line_' + compSvgLine.id;
                compSvgLine.renderOrder = this.renderOrder;

                this.compSvgLines.push(compSvgLine);
            }
        });

        if (this.showOversetBox && this.limitTextToBounds) {
            const oversetBox = new GroupCompElement([this.getOversetCompBox()]);

            oversetBox.id = `${this.id}_overset_comp_box${this.compOversetBox.length}`; // unique ID
            oversetBox.name = `overset_box_${oversetBox.id}`;
            oversetBox.renderOrder = this.renderOrder;

            this.compOversetBox.push(oversetBox);
        }

        const groupElementsToRender = [...this.compSvgLines, ...this.compOversetBox];

        if (groupElementsToRender.length) {
            this.compSvgText = new GroupCompElement(groupElementsToRender);
            this.compSvgText.id = this.id + '_text_' + this.compSvgLines.length;
            this.compSvgText.name = `text_${this.compSvgText.id}`;
            this.compSvgText.renderOrder = this.renderOrder;
            this.compSvgText.requireTempLayer = requireTempLayer;

            this.compSvgData = new GroupCompElement([this.compSvgText]);
        } else {
            this.compSvgData = new GroupCompElement([]);
        }

        this.compSvgData.id = this.id; // unique ID
        this.compSvgData.name = `svg_data_${this.compSvgData.id}`;
        this.compSvgData.renderOrder = this.renderOrder;

        return this.compSvgData;
    }

    findIntersections = (lines: GroupCompElement[]) => {
        const hasIntersection = (prevLine: GroupCompElement, currentLine: GroupCompElement) => {
            const { y: prevY } = prevLine.bgBoundingBox.position;
            const { height: prevHeight } = prevLine.bgBoundingBox.dimension;

            return currentLine.bgBoundingBox.position.y <= prevY + prevHeight;
        };

        const getVisiblePrevLine = (lines: GroupCompElement[], lineIdx: number): number => {
            const line = lines?.[lineIdx];

            if (!line) {
                return -1;
            }

            return line.bgBoundingBox.dimension.getWidth() > 0 && line.bgBoundingBox.dimension.getHeight()
                ? lineIdx
                : getVisiblePrevLine(lines, lineIdx - 1);
        };

        lines.forEach((line, lineIdx) => {
            const isSpacingPositive =
                line.bgBoundingBox.dimension.getWidth() > 0 && line.bgBoundingBox.dimension.getHeight() > 0;
            const prevLineIdx = getVisiblePrevLine(lines, lineIdx - 1);
            const prevLine = lines?.[prevLineIdx];

            if (isSpacingPositive && prevLine && hasIntersection(prevLine, line)) {
                const mergeLineIdx = prevLine?.mergeLineIdx ?? prevLineIdx;

                if (mergeLineIdx >= 0 && this.compSvgLines[mergeLineIdx]) {
                    line.mergeLineIdx = mergeLineIdx;
                    this.compSvgLines[mergeLineIdx].isStartMergeLine = true;
                }
            }
        });
    };

    mergeBgPaths(compEl: GroupCompElement) {
        if (!this.textBackground?.isActive() || this.compSvgLines.length === 0) {
            return compEl;
        }

        const isEqual = ({
            letter,
            prevLetter,
            word,
            prevWord,
            line,
            prevLine,
        }: {
            letter: SvgCompElement;
            prevLetter: SvgCompElement;
            word: GroupCompElement;
            prevWord?: GroupCompElement;
            line?: GroupCompElement;
            prevLine?: GroupCompElement;
        }) => {
            const getProps = (el) => ({
                remove: el?.remove,
                translatedX: el?.translatedX,
                translatedY: el?.translatedY,
                scale: el?.scale,
                clipPath: el?.clipPath,
                opacity: el?.opacity,
            });

            return (
                equals(getProps(letter), getProps(prevLetter)) &&
                equals(getProps(word), getProps(prevWord)) &&
                equals(getProps(line), getProps(prevLine))
            );
        };

        const getPrevLetter = (wordGr: GroupCompElement[], wordIndex: number, index: number): SvgCompElement | null => {
            const words = wordGr[wordIndex];

            if (index < 0) {
                const prevWord = wordGr?.[wordIndex - 1];

                if (prevWord) {
                    return prevWord.getChildren()[prevWord.getChildren().length - 1] as SvgCompElement;
                }
            }

            if (!words?.getChildren()?.[index]) {
                return null;
            }

            return words.getChildren()[index] as SvgCompElement;
        };

        const getFirstLetterByLineIdx = (lines: GroupCompElement[], lineIdx: number) =>
            (lines[lineIdx]?.getChildren()?.[0] as GroupCompElement)?.getChildren()?.[0];

        const lines = this.compSvgLines;

        const isNotAnimated = (line: GroupCompElement | SvgCompElement) => !line?.isInAnimation;

        this.findIntersections(lines);

        lines.forEach((line, lineIdx) => {
            for (let j = line.getChildren().length - 1; j >= 0; j--) {
                const word = line.getChildren()?.[j] as GroupCompElement;

                for (let i = word.getChildren().length - 1; i >= 0; i--) {
                    const letter = word.getChildren()[i] as SvgCompElement;
                    const prevLetter = getPrevLetter(
                        line.getChildren() as GroupCompElement[],
                        j,
                        i - 1,
                    ) as SvgCompElement;

                    if (!prevLetter) {
                        let { mergeLineIdx } = line;

                        const allowedToMerge = (line: GroupCompElement, lineIdx: number) =>
                            line &&
                            isNotAnimated(line) &&
                            isNotAnimated(line.getChildren()[0] as GroupCompElement) &&
                            isNotAnimated(getFirstLetterByLineIdx(lines, lineIdx) as SvgCompElement);

                        const isMergeLineInAnimation = !allowedToMerge(lines?.[mergeLineIdx], mergeLineIdx);
                        const isPrevLineInAnimation = !allowedToMerge(lines?.[lineIdx - 1], lineIdx - 1);

                        const letterWhichDraws =
                            mergeLineIdx !== null
                                ? !isMergeLineInAnimation
                                    ? (getFirstLetterByLineIdx(lines, mergeLineIdx) as SvgCompElement)
                                    : !isPrevLineInAnimation
                                    ? (getFirstLetterByLineIdx(lines, lineIdx - 1) as SvgCompElement)
                                    : null
                                : null;
                        const isMergeLetterEqual = letterWhichDraws
                            ? isEqual({
                                  letter,
                                  word,
                                  prevLetter: letterWhichDraws,
                                  prevWord: letterWhichDraws.parent,
                                  line,
                                  prevLine: isMergeLineInAnimation
                                      ? isPrevLineInAnimation
                                          ? null
                                          : lines?.[lineIdx - 1]
                                      : lines?.[mergeLineIdx],
                              })
                            : false;

                        if (isMergeLineInAnimation) {
                            if (!isPrevLineInAnimation) {
                                mergeLineIdx = lineIdx - 1;
                            } else {
                                mergeLineIdx = null;
                            }
                        }

                        if (mergeLineIdx === null || !isMergeLetterEqual) {
                            if ((line as any).isStartMergeLine || !letter.bgPaths.length) {
                                return;
                            }

                            letter.bgPaths = [];
                            const bgCompElement = this.getSvgBgCompElement(lineIdx, lineIdx + 1);

                            if (bgCompElement) {
                                this.compSvgData.setChildren([bgCompElement, ...this.compSvgData.getChildren()]);
                            }
                        } else {
                            const nextLine = lines?.[lineIdx + 1];

                            if ((nextLine && nextLine.mergeLineIdx !== null) || !letter.bgPaths.length) {
                                return;
                            }

                            letter.bgPaths = [];
                            const bgCompElement = this.getSvgBgCompElement(mergeLineIdx, lineIdx + 1);

                            if (bgCompElement) {
                                this.compSvgData.setChildren([bgCompElement, ...this.compSvgData.getChildren()]);
                            }
                        }
                    }
                }
            }
        });

        return compEl;
    }

    private getOversetCompBox() {
        const compEl = new SvgCompElement();

        compEl.id = `${this.id}_overset_box`; // unique ID
        compEl.color = ORDINARY_STROKE_COLOR_OBJ;
        compEl.path = createRectanglePath(0, 0, this.dimension.width, this.dimension.height);
        compEl.horizontalPathScale = 1;
        compEl.verticalPathScale = 1;
        compEl.isAssetLoading = this.isAssetLoading;
        compEl.renderOrder = this.renderOrder;
        compEl.strokeWidth = 4;
        compEl.strokeType = StrokeType.CENTER;
        compEl.contentBox = new Box(new Position(0, 0), this.dimension.getCopy());
        compEl.boundingBox = new Box(this.position.getCopy(), this.dimension.getCopy());
        compEl.pathPosition = new Position(0, 0);

        return compEl;
    }

    private getSvgCompElement(structuredGlyph: any) {
        // convert structured text glyph into SVGCompElement
        const compEl = new SvgCompElement();
        compEl.id =
            this.id + '_' + this.compSvgLines.length + '_' + this.compSvgWords.length + '_' + this.compSvgChars.length; // unique ID
        compEl.color = this.boundedText.getShapedText().getTextPropsProperty('color', structuredGlyph.data.runIdx);
        compEl.path = this.assetLoader!.makePath(structuredGlyph.data);
        compEl.decorationPaths = structuredGlyph.data.decorationPaths;
        compEl.horizontalPathScale = structuredGlyph.data.pathScale;
        compEl.verticalPathScale = -1 * structuredGlyph.data.pathScale; // char glyphs are defined upside down
        compEl.isAssetLoading = this.isAssetLoading;
        compEl.renderOrder = this.renderOrder;
        compEl.strokeWidth = structuredGlyph.data.strokeWidth;
        compEl.strokeType = structuredGlyph.data.strokeType;

        // Content bbox relative to text element bbox
        // We set 1px with as a nominal value for empty letter in case of textBg -
        // otherwise filter fn just count this element to be not-visible
        const absContentBbox =
            structuredGlyph.isNewLine() && this.textBackground?.isActive()
                ? { ...structuredGlyph.data.absBbox, width: 1 }
                : structuredGlyph.data.absContentBbox;
        const strokeOverflow = compEl.strokeType === StrokeType.OUTSIDE ? compEl.strokeWidth : 0;

        compEl.contentBox = new Box(
            new Position(-strokeOverflow, -strokeOverflow),
            new Dimension(absContentBbox.width + strokeOverflow * 2, absContentBbox.height + strokeOverflow * 2),
        );
        compEl.pathPosition = new Position(
            structuredGlyph.data.absLeftPx - absContentBbox.left + strokeOverflow,
            structuredGlyph.data.absTopPx - absContentBbox.top + strokeOverflow,
        );
        compEl.boundingBox = new Box(
            new Position(this.position.getX() + absContentBbox.left, this.position.getY() + absContentBbox.top),
            new Dimension(absContentBbox.width, absContentBbox.height),
        );

        const { spacingLeft, spacingBottom, spacingRight, spacingTop } = this.textBackground || {};

        // We only need to handle negative values for dimension because
        // positive values are handled in the textController
        const getOnlyNegative = (v) => (v < 0 ? v : 0);

        if (this.textBackground?.isActive()) {
            compEl.bgPaths = structuredGlyph.data.bgPaths;
            compEl.bgColor = this.textBackground.color;
            compEl.bgBoundingBox = new Box(
                new Position(
                    this.position.getX() + structuredGlyph.data.absBbox.left - spacingLeft,
                    Math.max(compEl.boundingBox.position.getY(), compEl.boundingBox.position.getY() - spacingTop),
                ),
                new Dimension(
                    structuredGlyph.data.absBbox.width + spacingLeft + spacingRight,
                    Math.min(
                        compEl.boundingBox.dimension.getHeight(),
                        compEl.boundingBox.dimension.getHeight() +
                            getOnlyNegative(spacingTop) +
                            getOnlyNegative(spacingBottom),
                    ),
                ),
            );
        } else {
            compEl.bgBoundingBox = new Box(
                compEl.boundingBox.position.getCopy(),
                compEl.boundingBox.dimension.getCopy(),
            );
        }

        return compEl;
    }

    private getSvgBgCompElement(lineIdxStart: number, lineIdxEnd: number): SvgCompElement | null {
        const calcRectPoints = () => {
            const lines = this.compSvgLines.slice(lineIdxStart, lineIdxEnd);
            let cordsLeft = [];
            let cordsRight = [];

            lines.forEach((line, index) => {
                if (line.bgBoundingBox.dimension.getWidth() <= 0 || line.bgBoundingBox.dimension.getHeight() <= 0) {
                    return;
                }

                const prevLine = lines?.[index - 1];
                const nextLine = lines?.[index + 1];
                const passSmallLine =
                    prevLine?.bgBoundingBox?.position?.y + prevLine?.bgBoundingBox?.dimension?.height >
                    nextLine?.bgBoundingBox?.position?.y;

                const hasBiggerLineAfter = () =>
                    !!lines
                        .slice(index + 1)
                        .some(
                            (current) => current?.bgBoundingBox?.dimension?.width > line.bgBoundingBox.dimension.width,
                        );

                if (index && passSmallLine && hasBiggerLineAfter()) {
                    return;
                }

                const { x, y } = line.bgBoundingBox.position;
                const { width, height } = line.bgBoundingBox.dimension;

                const topY = cordsLeft?.[cordsLeft.length - 1]?.y ?? y;
                const rightX = x + width;
                const btmY = y + height;

                const fnToApply = nextLine?.bgBoundingBox?.dimension?.width > width ? Math.min : Math.max;
                const btmYToUse = nextLine ? fnToApply(btmY, nextLine?.bgBoundingBox?.position?.y) : btmY;
                const radius =
                    (lines[0].bgBoundingBox.dimension.height / 2) * (this.textBackground.cornerRadius * 0.01);

                cordsLeft = [...cordsLeft, { x, y: topY, r: radius }, { x, y: btmYToUse, r: radius }];
                cordsRight = [...cordsRight, { x: rightX, y: topY, r: radius }, { x: rightX, y: btmYToUse, r: radius }];
            });

            return cordsLeft.concat(cordsRight.reverse());
        };

        const rectPoints = calcRectPoints();

        if (!rectPoints.length) {
            return null;
        }

        const compEl = new SvgCompElement();
        compEl.pathPoints = createCurvedRectPoints(rectPoints, this.textBackground.cornerRadius);
        compEl.id = `${this.id}_bg${lineIdxStart}_${lineIdxEnd}`;
        compEl.color = this.textBackground.color;
        compEl.isAssetLoading = this.isAssetLoading;
        compEl.renderOrder = this.renderOrder;
        compEl.pathPosition = new Position(0, 0);
        compEl.contentBox = new Box(new Position(0, 0), this.compSvgData.contentBox.dimension);
        compEl.boundingBox = new Box(new Position(0, 0), this.compSvgData.bgBoundingBox.dimension);

        return compEl;
    }

    getCompElement(frameIndex: number) {
        let compEl = this.constructCompElement();

        if (compEl === null) {
            return null;
        }

        if ((compEl as any)._boundingBox) {
            const positionDelta = (compEl as any)._position.subtract(this.position);
            compEl.boundingBox = (compEl as any)._boundingBox;
            compEl.boundingBox.position = compEl.boundingBox.position.subtract(positionDelta);
        }

        compEl.originalElement = this;
        compEl.rotation = this.isTextEditorActivated ? 0 : this.rotation;
        compEl.scale = this.scale;
        compEl.opacity = this.opacity;
        compEl.dropShadow = this.dropShadow;
        compEl.mask = this.mask;
        compEl.blendMode = this.blendMode;
        // ghostContentBox is used here because selecteable area for the textElement
        // is not equal to the contentBox of the element.
        compEl.ghostContentBox = new Box(new Position(0, 0), this.dimension);
        // calculated compEl bounding box behaves like an absolute contentBox, so we have to make position relative
        compEl.contentBox = new Box(compEl.boundingBox.position.subtract(this.position), compEl.contentBox.dimension);
        (compEl as any)._boundingBox = new Box(
            compEl.boundingBox.position.getCopy(),
            compEl.boundingBox.dimension.getCopy(),
        );
        (compEl as any)._position = this.position!.getCopy();
        compEl.boundingBox = new Box(this.position!.getCopy(), this.dimension!.getCopy());

        const isAnimatedFrame = () =>
            this.animationIn?.containsFrame(frameIndex) ||
            this.animationOut?.containsFrame(frameIndex) ||
            this.animations?.some((a) => a.containsFrame(frameIndex));

        const isTextExceedBox = () =>
            this.boundedText.getShapedText().getContentWidth() > this.dimension.width ||
            this.boundedText.getShapedText().getContentHeight() > this.dimension.height;

        if (
            this.limitTextToBounds &&
            !this.isTextEditorActivated &&
            !this.showOversetBox &&
            !isAnimatedFrame() &&
            isTextExceedBox()
        ) {
            this.compSvgData.clipPath = createRectanglePath(
                -compEl.contentBox.position.x,
                -compEl.contentBox.position.y,
                this.dimension.width,
                this.dimension.height,
            );
        } else {
            this.compSvgData.clipPath = null;
        }

        compEl = this.applyAnimations(frameIndex, compEl) as GroupCompElement;
        compEl = this.mergeBgPaths(compEl);

        return compEl;
    }

    // override animations to include text breakup
    applyAnimations(frameIndex: number, compEl: BaseCompElement): BaseCompElement {
        // For ACTIVATED textEditor there will be no animations
        // to give user a possibility see text properly
        // For empty text also don't apply animations
        if (this.isTextEditorActivated || this.compSvgLines.length === 0) {
            return compEl;
        }

        if (this.animations && this.animations.length) {
            this.animations.forEach((animation) => {
                // NOTE: Correct type of the element in update fn
                compEl = animation.updateCompEl(frameIndex, compEl);
            });
        }

        if (this.animationOut) {
            compEl = this.applyTransitionOut(frameIndex, compEl);
        }

        if (this.animationIn) {
            compEl = this.applyTransitionIn(frameIndex, compEl);
        }

        if (this.animationOut || this.animationIn) {
            // Remove compElement items that are marked with removal
            this.cleanCompElementRecursively(compEl);
        }

        return compEl;
    }

    /**
     * Text breakup can cause parts to fall outside of the canvas
     * When motion blur is enabled, this can cause the side-effect that an
     *   invalid translation is applied to parts where the initial position (pre-transform) is the end location.
     * To prevent these side-effects, we remove the invisible elements (marked by the breakup animation functions)
     * NOTE: that these elements are invisible anyway.
     */

    private cleanCompElementRecursively = (compEl: BaseCompElement) => {
        if (compEl instanceof GroupCompElement) {
            const children = compEl.getChildren();

            for (let i = children.length - 1; i >= 0; i--) {
                if ((children[i] as any).remove === true) {
                    children.splice(i, 1);
                } else if (children[i] instanceof GroupCompElement) {
                    this.cleanCompElementRecursively(children[i]);
                }
            }
        }
    };

    private applyTransitionIn(frameIndex: number, compEl: BaseCompElement) {
        if (this.animationIn && this.animationIn.containsFrame(frameIndex)) {
            const animConfig = this.animationIn.config;
            const textBreakup = animConfig.textBreakup;
            let textBreakupDelay = textBreakup?.delay || 0.0;
            let animElements: any[] = [];

            if (textBreakup && textBreakup.type === 'LINE_BREAKUP') {
                animElements = this.compSvgLines;
            } else if (textBreakup && textBreakup.type === 'WORD_BREAKUP') {
                animElements = this.compSvgWords;
            } else if (textBreakup && textBreakup.type === 'CHARACTER_BREAKUP') {
                animElements = this.compSvgChars;
            } else {
                animElements = [this.compSvgData];
                textBreakupDelay = 0.0; // reset because there is no split
            }

            // animation preparations
            const animationDuration = animConfig.duration;
            const animCount = Math.max(1, animElements.length);
            const totalDurations = (animCount - 1) * textBreakupDelay + 1; // total number of full durations (scale of 1 (in case of no delay) to 'groupGount' (in case of 100% delay))

            const animDuration = animationDuration / totalDurations; // calculate the duration for the group to animate

            const animDelay = animDuration * textBreakupDelay; // calculate the delay for a group to start animating

            // determine breakup direction
            if (textBreakup && textBreakup.direction === 'BACKWARD') {
                animElements.reverse();
            }

            const actualStartFrame = this.startFrame;
            const actualAnimDuration = animConfig.duration;
            animConfig.duration = Math.floor(animDuration); // floor to make sure timing function does not overstep boundaries with fractions

            animElements.forEach((animEl, idx) => {
                // adjust duration and startframe of the text element and set the animation
                const animOffsetFrames = Math.floor(animDelay * idx); // floor to make sure no rounding errors occur

                this.startFrame = actualStartFrame + animOffsetFrames;
                // apply animation to group
                createAnimationIn(animConfig, this)?.updateCompEl(frameIndex, animEl);

                if (frameIndex < this.startFrame) {
                    // TODO: hacky way to prevent elements from showing before it is their time.
                    animEl.remove = true;
                }

                animEl.isInAnimation = frameIndex <= this.startFrame + this.duration;
            });

            // restore order
            if (textBreakup && textBreakup.direction === 'BACKWARD') {
                animElements.reverse();
            }

            this.startFrame = actualStartFrame;
            animConfig.duration = actualAnimDuration;
        }

        return compEl;
    }

    private applyTransitionOut(frameIndex: number, compEl: BaseCompElement) {
        if (this.animationOut && this.animationOut.containsFrame(frameIndex)) {
            const animConfig = this.animationOut.config;
            const textBreakup = animConfig.textBreakup;
            let textBreakupDelay = textBreakup?.delay || 0.0;
            let animElements: any[] = [];

            if (textBreakup && textBreakup.type === 'LINE_BREAKUP') {
                animElements = this.compSvgLines;
            } else if (textBreakup && textBreakup.type === 'WORD_BREAKUP') {
                animElements = this.compSvgWords;
            } else if (textBreakup && textBreakup.type === 'CHARACTER_BREAKUP') {
                animElements = this.compSvgChars;
            } else {
                animElements = [this.compSvgData];
                textBreakupDelay = 0.0; // reset because there is no split
            }

            // animation preparations
            const animationDuration = animConfig.duration;
            const animCount = Math.max(1, animElements.length);
            const totalDurations = (animCount - 1) * textBreakupDelay + 1; // total number of full durations (scale of 1 (in case of no delay) to 'groupGount' (in case of 100% delay))

            const animDuration = animationDuration / totalDurations; // calculate the duration for the group to animate

            const animDelay = animDuration * textBreakupDelay; // calculate the delay for a group to start animating

            // determine breakup direction
            if (textBreakup && textBreakup.direction === 'FORWARD') {
                animElements.reverse();
            }

            const actualDuration = this.duration;
            const actualAnimDuration = animConfig.duration;
            animConfig.duration = Math.floor(animDuration); // floor to make sure timing function does not overstep boundaries with fractions
            animElements.forEach((animEl, idx) => {
                // adjust duration and startframe of the text element and set the animation
                const animOffsetFrames = Math.floor(animDelay * idx); // floor to make sure no rounding errors occur

                this.duration = actualDuration - animOffsetFrames;
                // apply animation to group
                createAnimationOut(animConfig, this)?.updateCompEl(frameIndex, animEl);

                if (frameIndex >= this.startFrame + this.duration) {
                    // TODO: hacky way to prevent elements from showing before it is their time
                    animEl.remove = true;
                }

                animEl.isInAnimation = frameIndex <= this.startFrame + this.duration;
            });

            // restore order
            if (textBreakup && textBreakup.direction === 'FORWARD') {
                animElements.reverse();
            }

            this.duration = actualDuration;
            animConfig.duration = actualAnimDuration;
        }

        return compEl;
    }

    private isThereFontsToLoad() {
        const fontsToLoad = getTextPropsFonts(this.formattedText, getFontFamilies());

        return Object.keys(fontsToLoad).some((fontId) => {
            const asset = this.assetLoader!.getAsset(fontId);

            return !asset || (asset as BaseAsset).loading;
        });
    }

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes) {
        const rules = super.getValidationRules(creativeType, previewType);
        const dynamicValidationRules = this.limitTextToBounds
            ? {
                  limitTextToBoundsHeight: { LESS_THAN: this.dimension.height },
                  limitTextToBoundsWidth: { LESS_THAN: this.dimension.width },
              }
            : {};

        if (previewType === PreviewTypes.CONTENT) {
            return {
                ...rules,
                ...dynamicValidationRules,
            };
        }

        const maxCharacters =
            creativeType === CreativeTypes.VIDEO ? VIDEO_MAX_CHARACTERS || 400 : IMAGE_MAX_CHARACTERS || 400;

        return {
            ...rules,
            text: {
                MAX_LENGTH: maxCharacters,
                MIN_LENGTH: 1,
            },
            ...dynamicValidationRules,
            // lineHeight: {
            //     GREATER_THAN: 0,
            // },
            // fontSize: {
            //     GREATER_THAN: 1,
            // },
        };
    }

    cleanupEmittingValues(values: Record<string, any>) {
        delete values.textStyles;
        delete values.brandColors;
        delete values.textBackgroundBrandColors;
        super.cleanupEmittingValues(values);
    }

    toObject(): TextElementParams {
        const baseObject = super.toObject();

        return {
            ...baseObject,
            formattedText: this.formattedText,
            textDirection: this.textDirection,
            textControl: this.textControl,
            timelineBehavior: this.timelineBehavior,
            contentTransform: this.contentTransform?.toObject() ?? null,
            limitTextToBounds: this.limitTextToBounds,
            textBackground: this.textBackground?.toObject() ?? null,
            textEditorData: this.textEditorData,
            isTextEditorActivated: this.isTextEditorActivated,
            minFontScale: this.minFontScale,
            fontScale: this.fontScale,
            leadingType: this.leadingType,
            textStyles: this.textStyles,
            brandColors: this.brandColors,
            textBackgroundBrandColors: this.textBackgroundBrandColors,
        };
    }
}
