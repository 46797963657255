import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Card, Dropdown } from '@bynder/design-system';
import {
    IconAddPerson,
    IconCopy,
    IconDelete,
    IconFileMove,
    IconMore,
    IconRename,
    IconUpload,
    IconVideoTemplate,
} from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import { ActionStatus, DesignAction, Entity } from 'packages/hooks/useAccessRights/types';
import { duplicateCreatives, handleDeselectAll } from '~/store/creatives/creatives.actions';
import features from '~/configs/features';
import { filterDesignObjForCopy } from 'packages/pages/utils';
import useQueryParams from 'packages/hooks/useQueryParams';
import { CreativeStatus } from 'packages/store/creatives/types';
import { ReviewStatus } from 'packages/store/review/types';
import { getDesignEditLink, getDesignEditorLink } from '../../utils';
import { Props } from './types';

const DesignContextMenu = ({ design, menuActions, isListView }: Props) => {
    const { translate } = useTranslate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isPlatformAllowed, isEntityActionAllowed } = useAccessRights();
    const { setQueryParam } = useQueryParams();

    const isDraft = design.status === CreativeStatus.DRAFT;

    const isDesignExportDisabled = () => {
        const isBlockedByApprovalStatus =
            isPlatformAllowed([features.APPROVALS_ENABLED]) &&
            design?.approvalStatus &&
            design.approvalStatus !== ReviewStatus.APPROVED;

        return (
            isBlockedByApprovalStatus ||
            isDraft ||
            isEntityActionAllowed(DesignAction.MANAGE_SIZES, Entity.DESIGN, design.grantedPermissions) ===
                ActionStatus.DISABLED
        );
    };

    const isDesignEditDisabled = () => {
        if (isPlatformAllowed([features.APPROVALS_ENABLED]) && design?.approvalStatus) {
            return (
                (design?.approvalStatus !== ReviewStatus.EDITS_REQUESTED &&
                    design?.approvalStatus !== ReviewStatus.DRAFT) ||
                isEntityActionAllowed(DesignAction.EDIT, Entity.DESIGN, design.grantedPermissions) ===
                    ActionStatus.DISABLED
            );
        }

        return (
            isEntityActionAllowed(DesignAction.EDIT, Entity.DESIGN, design.grantedPermissions) === ActionStatus.DISABLED
        );
    };

    return (
        <>
            <Dropdown
                trigger={({ isOpen, ...triggerProps }) => {
                    const clickProps = {
                        ...triggerProps,
                        onClick: () => {
                            dispatch(handleDeselectAll());
                            triggerProps.onClick();
                        },
                    };
                    return isListView ? (
                        <Button
                            title={translate('pages.design.menu')}
                            variant="clean"
                            icon={<IconMore />}
                            {...clickProps}
                        />
                    ) : (
                        <Card.ContextActionButton
                            aria-label={translate('pages.design.menu')}
                            isPressed={isOpen}
                            {...clickProps}
                        >
                            <IconMore />
                        </Card.ContextActionButton>
                    );
                }}
                position={isListView ? 'bottom-right' : 'bottom-left'}
            >
                {isEntityActionAllowed(DesignAction.RENAME, Entity.DESIGN, design.grantedPermissions) !==
                    ActionStatus.NOT_ALLOWED && (
                    <>
                        <Dropdown.Item
                            isDisabled={
                                isEntityActionAllowed(DesignAction.RENAME, Entity.DESIGN, design.grantedPermissions) ===
                                ActionStatus.DISABLED
                            }
                            icon={<IconRename />}
                            onClick={() => menuActions.setRenameModalDesign(design)}
                        >
                            {translate('pages.design.menu.rename')}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}
                <Dropdown.Item
                    icon={<IconAddPerson />}
                    onClick={() => menuActions.setShareModalDesign && menuActions.setShareModalDesign(design)}
                >
                    {translate('pages.design.menu.share')}
                </Dropdown.Item>
                {isEntityActionAllowed(DesignAction.DUPLICATE, Entity.DESIGN, design.grantedPermissions) !==
                    ActionStatus.NOT_ALLOWED && (
                    <Dropdown.Item
                        isDisabled={
                            isEntityActionAllowed(DesignAction.DUPLICATE, Entity.DESIGN, design.grantedPermissions) ===
                            ActionStatus.DISABLED
                        }
                        icon={<IconCopy />}
                        onClick={() =>
                            dispatch(duplicateCreatives([design.id], null, [filterDesignObjForCopy(design)]))
                        }
                    >
                        {translate('pages.design.menu.duplicate')}
                    </Dropdown.Item>
                )}
                {isEntityActionAllowed(DesignAction.MANAGE_SIZES, Entity.DESIGN, design.grantedPermissions) !==
                    ActionStatus.NOT_ALLOWED && (
                    <Dropdown.Item
                        isDisabled={isDesignExportDisabled()}
                        icon={<IconUpload />}
                        onClick={() => {
                            if (menuActions.handleExportClick && menuActions.handleExportClick(design)) return;
                            navigate(getDesignEditLink(design.id));
                            setQueryParam('export', 'true');
                        }}
                    >
                        {translate('pages.design.menu.export')}
                    </Dropdown.Item>
                )}
                {isEntityActionAllowed(DesignAction.MOVE, Entity.DESIGN, design.grantedPermissions) !==
                    ActionStatus.NOT_ALLOWED && (
                    <Dropdown.Item
                        isDisabled={
                            isEntityActionAllowed(DesignAction.MOVE, Entity.DESIGN, design.grantedPermissions) ===
                            ActionStatus.DISABLED
                        }
                        icon={<IconFileMove />}
                        onClick={() => menuActions.setMoveModalDesign && menuActions.setMoveModalDesign([design])}
                    >
                        {translate('pages.design.menu.move')}
                    </Dropdown.Item>
                )}
                {isEntityActionAllowed(DesignAction.EDIT, Entity.DESIGN, design.grantedPermissions) !==
                    ActionStatus.NOT_ALLOWED && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            isDisabled={isDesignEditDisabled()}
                            icon={<IconVideoTemplate />}
                            onClick={() => navigate(getDesignEditorLink(design.id))}
                        >
                            {translate('pages.design.menu.edit')}
                        </Dropdown.Item>
                    </>
                )}
                {isEntityActionAllowed(DesignAction.DELETE, Entity.DESIGN, design.grantedPermissions) !==
                    ActionStatus.NOT_ALLOWED && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            isDisabled={
                                isEntityActionAllowed(DesignAction.DELETE, Entity.DESIGN, design.grantedPermissions) ===
                                ActionStatus.DISABLED
                            }
                            icon={<IconDelete />}
                            onClick={() => menuActions.setDeleteModalDesign([design])}
                        >
                            {translate('pages.design.menu.delete')}
                        </Dropdown.Item>
                    </>
                )}
            </Dropdown>
        </>
    );
};

export default DesignContextMenu;
