import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

declare let GATEWAY_PREFIX: string;

class CreativeService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/creatives') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    saveAsTemplate({ id, name, description }) {
        return this.gateway.post(`/v2/${id}/saveAsTemplate`, { name, description });
    }

    create(templateId, collectionId) {
        return this.gateway.put(`/${templateId}/creative${collectionId ? `?collectionId=${collectionId}` : ''}`);
    }
}

export default new CreativeService();
