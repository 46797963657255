import { Dimension } from '../../Shared/Dimension';
import { Position } from '../../Shared/Position';
import { BaseCompElement, type BaseCompElementParams } from './BaseCompElement';

export type ImageCompElementParams = BaseCompElementParams & {
    assetId: string;
    cropPositionPct: Position;
    cropDimensionPct: Dimension;
};

export class ImageCompElement extends BaseCompElement {
    assetId: string;
    // Crop of the original asset, part that is visible in the bounding box

    cropPositionPct!: Position;

    cropDimensionPct!: Dimension;

    setProperties(params: ImageCompElementParams) {
        super.setProperties(params);
        this.assetId = params.assetId ?? this.assetId;
        this.cropPositionPct = params.cropPositionPct
            ? new Position(params.cropPositionPct.x, params.cropPositionPct.y)
            : this.cropPositionPct;
        this.cropDimensionPct = params.cropDimensionPct
            ? new Dimension(params.cropDimensionPct.width, params.cropDimensionPct.height)
            : this.cropDimensionPct;
    }

    getLocalHashData() {
        return {
            ...super.getLocalHashData(),
            cropPositionPct: this.cropPositionPct.toObject(),
            cropDimensionPct: this.cropDimensionPct.toObject(),
        };
    }

    toObject() {
        return {
            ...super.toObject(),
            type: 'IMAGE',
            assetId: this.assetId,
            cropPositionPct: this.cropPositionPct.toObject(),
            cropDimensionPct: this.cropDimensionPct.toObject(),
        };
    }
}
