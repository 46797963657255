import {
    AudioAsset,
    BaseRenderer,
    type CompElement,
    type CompModel,
    frameIndexToHTMLSeekTime,
    GlobalAudio,
    MultiPageVideoModel,
    VideoAsset,
    VideoCompElement,
    type VideoElement,
} from '@bynder-studio/render-core';
import type { LoopInterval } from '../PlaybackRenderer/IPlaybackRenderer';

export class Playback {
    isPlaying = false;

    frameIndex: number;

    useWorkerForCompModelGeneration = false;

    private frameToLock = {};

    private rerenderHandlerId = 0;

    private cacheHandlerId = 0;

    constructor(
        private renderer: BaseRenderer<MultiPageVideoModel>,
        public loopInterval: LoopInterval,
    ) {}

    async init(): Promise<void> {
        this.renderer
            .getCreativeModel()
            .eventEmitter.on('requireFramesUpdate', (args) => this.onFramesUpdateRequest(args));

        // drawing comp model takes time (especially when the tab is not active),
        // all the subscription should be done before drawing
        await this.setCurrentFrame(0);
    }

    onFramesUpdateRequest({ startFrame, endFrame }: { startFrame: number; endFrame: number }) {
        if (this.frameIndex >= startFrame && this.frameIndex <= endFrame) {
            this.setCurrentFrame(this.frameIndex);
        }
    }

    async setCurrentFrame(frameIndex: number) {
        const oldFrameIndex = this.frameIndex;
        this.frameIndex = frameIndex;

        if (!this.isPlaying) {
            const compModel = this.renderer.creativeModel.getCompModel(frameIndex);

            if ((await this.renderer.drawCompModel(compModel)) && oldFrameIndex !== this.frameIndex) {
                this.renderer.eventEmitter.emit('frameUpdate', frameIndex);
            }
        } else {
            this.cacheCompModels();
        }

        return true;
    }

    getCurrentFrame(): number {
        return this.frameIndex;
    }

    getPlaybackDuration() {
        return this.renderer.creativeModel.getPlaybackDuration();
    }

    cacheCompModels(fromFrameIndex = this.frameIndex) {
        if (this.useWorkerForCompModelGeneration) {
            throw new Error('Not implemented');
        }

        const cachingFn = (frameIndex: number) => {
            if (this.cacheHandlerId) {
                cancelIdleCallback(this.cacheHandlerId);
                this.cacheHandlerId = 0;
            }

            const playbackDuration = this.getPlaybackDuration();
            const maxFrameIndex = playbackDuration.getDuration() - 1;

            if (frameIndex >= maxFrameIndex || frameIndex < 0) {
                return false;
            }

            this.renderer.creativeModel.createAllCompModels(frameIndex, frameIndex);
            this.cacheHandlerId = requestIdleCallback(() => cachingFn(frameIndex + 1));
        };

        this.cacheHandlerId = requestIdleCallback(() => cachingFn(fromFrameIndex));
    }

    startPlayback() {
        if (this.isPlaying) {
            return;
        }

        this.isPlaying = true;
        this.renderer.eventEmitter.emit('playing');
        // duration and frameRate
        const playbackDuration = this.getPlaybackDuration();
        // init timestamps
        let currentTimeStamp = Date.now();
        let lastTimeStamp = Date.now();
        const frameInterval = 1000 / playbackDuration.getFrameRate();
        this.cacheCompModels();

        // loop function
        const renderNextFrame = () => {
            if (!this.renderer.compositor) {
                return;
            }

            if (this.frameIndex < playbackDuration.getDuration() && this.isPlaying) {
                this.loopInterval(renderNextFrame);
                currentTimeStamp = Date.now();
                const delta = currentTimeStamp - lastTimeStamp;

                if (delta > frameInterval) {
                    this.renderer.drawCompModel(this.renderer.creativeModel.getCompModel(this.frameIndex));
                    this.renderer.eventEmitter.emit('frameUpdate', this.frameIndex);
                    lastTimeStamp = currentTimeStamp - (delta % frameInterval);

                    if (this.frameIndex < playbackDuration.getDuration() - 1) {
                        this.frameIndex++;
                    } else {
                        this.pausePlayback();
                    }
                }
            } else {
                this.pausePlayback();
            }
        };

        renderNextFrame();
    }

    pausePlayback() {
        this.isPlaying = false;
        this.renderer.assetLoader.getAllAssets().forEach((asset) => {
            if (asset instanceof VideoAsset || asset instanceof AudioAsset) {
                asset.object?.mediaController?.pause();
            }
        });
        const playbackDuration = this.getPlaybackDuration();

        if (this.frameIndex >= playbackDuration.getDuration() - 1) {
            this.renderer.eventEmitter.emit('ended');
        } else {
            this.renderer.eventEmitter.emit('paused');
        }
    }

    getIsPlaying = () => {
        return this.isPlaying;
    };

    playGlobalAudio = async (globalAudioEl: GlobalAudio, frameIndex: number, frameRate: number) => {
        if (!globalAudioEl?.src) {
            return;
        }

        const loadedAudioAssetDetails = this.renderer.assetLoader.getAsset<AudioAsset>(globalAudioEl.getAssetId());

        if (!loadedAudioAssetDetails || loadedAudioAssetDetails.loading || !loadedAudioAssetDetails.object?.duration) {
            return;
        }

        const seekTime = frameIndex / frameRate;

        if (!loadedAudioAssetDetails.object?.mediaController?.isSeeked(seekTime, 0, frameRate, this.isPlaying)) {
            loadedAudioAssetDetails?.object?.mediaController?.setCurrentTime(seekTime);
        }

        if (loadedAudioAssetDetails?.object?.volume !== undefined) {
            const audioControl = this.renderer.creativeModel.getAudioControl();
            const audioScale = audioControl.getMutedStatus() ? 0 : audioControl.getVolume();
            const duration = this.getPlaybackDuration().getDuration() / this.getPlaybackDuration().getFrameRate();
            const time = loadedAudioAssetDetails.object.mediaController?.getCurrentTime() ?? seekTime;
            loadedAudioAssetDetails.object.volume = audioScale * globalAudioEl.getFadeScale(time, duration);

            if (loadedAudioAssetDetails.object?.mediaController?.gainNode) {
                loadedAudioAssetDetails.object.mediaController.gainNode.gain.value = globalAudioEl.getGainValue();
            }
        }

        if (this.isPlaying) {
            await loadedAudioAssetDetails.object?.mediaController?.play();
        }
    };

    playVideoCompElement = async (el: CompElement, frameIndex: number, frameRate: number, schedule: () => void) => {
        if (!(this.renderer.creativeModel instanceof MultiPageVideoModel) || !(el instanceof VideoCompElement)) {
            return;
        }

        const loadedVideoAssetDetails = this.renderer.assetLoader.getAsset<VideoAsset>(el.assetId);

        if (!loadedVideoAssetDetails?.object?.duration) {
            return;
        }

        const { mediaController } = loadedVideoAssetDetails.object;
        const videoElement = el.originalElement as VideoElement;
        const startFrom = frameIndexToHTMLSeekTime(videoElement.startFrame, frameRate);

        // Seeking to the end of the video is not well supported in chromium
        // for this purpose we calculate video duration without the last frame
        const offsetTime = loadedVideoAssetDetails.offsetTime / 1000;
        const videoDuration =
            videoElement.startFrame / frameRate + (loadedVideoAssetDetails.object.duration - offsetTime);
        const rawTime = frameIndexToHTMLSeekTime(frameIndex, frameRate);
        const seekTime = Math.min(rawTime, videoDuration);
        const isLastFrame = rawTime > videoDuration;

        loadedVideoAssetDetails.object.muted = !el.useAudio;

        if (el.useAudio && loadedVideoAssetDetails.object.volume !== undefined) {
            const audioControl = this.renderer.creativeModel.getAudioControl();
            loadedVideoAssetDetails.object.volume = audioControl.getMutedStatus() ? 0 : audioControl.getVolume();
            const audioScale = audioControl.getMutedStatus() ? 0 : audioControl.getVolume();
            const time = loadedVideoAssetDetails.object.mediaController?.getCurrentTime() ?? seekTime;
            const playbackDuration = this.getPlaybackDuration().getDuration();

            loadedVideoAssetDetails.object.volume =
                audioScale * videoElement.getFadeScale(time, playbackDuration, frameRate);

            if (loadedVideoAssetDetails.object.mediaController?.gainNode) {
                loadedVideoAssetDetails.object.mediaController.gainNode.gain.value = videoElement.getGainValue();
            }
        }

        if (!mediaController.isSeeked(seekTime, startFrom, frameRate, this.isPlaying)) {
            if (this.isPlaying && !isLastFrame) {
                mediaController.setCurrentTime(seekTime);

                return;
            }

            // frame is available in buffer
            if (loadedVideoAssetDetails.frameBuffer.has(seekTime)) {
                return;
            }

            if (
                !(await mediaController.setCurrentTime(seekTime)) ||
                Math.abs(loadedVideoAssetDetails.object.currentTime - seekTime) >= 0.004
            ) {
                schedule();
            }
        }

        if (this.isPlaying && !isLastFrame) {
            mediaController.play();
        } else {
            mediaController.pause();
        }
    };

    async drawCompModel(compModel: CompModel): Promise<boolean> {
        if (this.rerenderHandlerId) {
            cancelIdleCallback(this.rerenderHandlerId);
        }

        this.rerenderHandlerId = 0;

        const pageIdx = this.renderer.creativeModel.getCurrentPageIndex();

        const schedule = () => {
            if (this.rerenderHandlerId) {
                cancelIdleCallback(this.rerenderHandlerId);
            }

            const isPageChanged = pageIdx !== this.renderer.creativeModel.getCurrentPageIndex();

            if (isPageChanged || this.frameIndex !== compModel.getFrame()) {
                return;
            }

            this.rerenderHandlerId = requestIdleCallback(this.renderer.drawCompModel.bind(this.renderer, compModel));
        };

        const frameRate = this.getPlaybackDuration().getFrameRate();
        await Promise.all([
            this.playGlobalAudio(this.renderer.creativeModel.getGlobalAudioTrack1(), compModel.getFrame(), frameRate),
            this.playGlobalAudio(this.renderer.creativeModel.getGlobalAudioTrack2(), compModel.getFrame(), frameRate),
            ...compModel
                .getAllCompElementsRecursively()
                .map((el) => this.playVideoCompElement(el, compModel.getFrame(), frameRate, schedule)),
        ]);

        const i = compModel.getFrame();
        const lock = i in this.frameToLock ? ++this.frameToLock[i] : (this.frameToLock[i] = 1);

        if (!this.isPlaying && i !== this.frameIndex) {
            return false;
        }

        if (this.frameToLock[i] !== lock) {
            return false;
        }

        this.renderer.requestToDrawCompModel(compModel);

        return true;
    }
}
