import { mat2d } from 'gl-matrix';
import * as CryptoJS from 'crypto-js';

import { type IAsset } from '../../Assets/IAsset';
import { Box } from '../../Shared/Box';
import { Dimension } from '../../Shared/Dimension';
import { DropShadow } from '../../Shared/DropShadow';
import { Mask } from '../../Shared/Mask';
import { Position } from '../../Shared/Position';
import { Region } from '../../Shared/Region';
import { RegionTypes } from '../../../Enums/RegionTypes';
import { TransformData, TransformDataObject } from '../../Shared/TransformData';
import { getSrcDestTransform, transformRegion } from '../../../Helpers/compositorUtils';
import { DropShadowEffect } from '../../Effects/DropShadowEffect';
import { BlurEffect } from '../../Effects/BlurEffect';
import { MotionBlurEffect } from '../../Effects/MotionBlurEffect';
import { BlendMode, type BlendModeParams } from '../../Shared/BlendMode';
import { MaskModeTypes } from '../../../Enums/MaskModeTypes';
import { type ClipSoftness, type VisualElement } from '../../../types';

export type BaseCompElementParams = {
    id: number | string;
    name?: string;
    hidden: boolean;
    opacity: number;
    rotation: number;
    scale: number;
    blur: number;
    clipPath: any;
    clipSoftness?: ClipSoftness;
    renderOrder: number;
    dropShadow: DropShadow;
    mask: Mask;
    requireTempLayer: boolean;
    blendMode: BlendModeParams;
    motionBlur: boolean;
    contentBox: Box;
    boundingBox: Box;
    bgBoundingBox: Box;
    transform: any;
    transforms: [];
    transformData: TransformDataObject | null;
    transformOpenData: TransformDataObject | null;
    transformCloseData: TransformDataObject | null;
    translatedX?: number;
    translatedY?: number;
    isInAnimation?: boolean;
};

export class BaseCompElement {
    id!: number | string;

    parent: any = null;

    name?: string;

    isAssetLoading = false;

    hidden = false;

    opacity = 1.0;

    rotation = 0.0;

    scale = 1.0;

    blur = 0.0;

    clipPath: any; // clippath is always positioned relative to the transformed content region

    clipSoftness?: ClipSoftness;

    renderOrder!: number;

    frameIndex?: number;

    dropShadow?: DropShadow | null;

    mask?: Mask | null;

    requireTempLayer = false;

    isInAnimation = false;

    blendMode?: BlendMode | null;

    motionBlur = false;

    boundingBox: Box; // position and dimension of bounding box relative to parent bounding box

    contentBox: Box; // position and dimension of content relative to bounding box

    ghostContentBox?: Box; // the same as bounding box but for special elements like text where content box !== bounding box

    bgBoundingBox?: Box | null; // position and dimension of background box relative to parent bounding box

    originalElement!: VisualElement;

    checksum: string | null = null;

    transform: mat2d | null = null;

    transforms: mat2d[] = [];

    transformData: TransformData | null = null;

    transformOpenData: TransformData | null = null;

    transformCloseData: TransformData | null = null;

    translatedX = 0;

    translatedY = 0;

    setProperties(params: Partial<BaseCompElementParams>) {
        this.id = params.id ?? this.id;
        this.name = params.name ?? this.name;
        this.hidden = params.hidden ?? this.hidden;
        this.opacity = params.opacity ?? this.opacity;
        this.rotation = params.rotation ?? this.rotation;
        this.scale = params.scale ?? this.scale;
        this.blur = params.blur ?? this.blur;
        this.clipPath = params.clipPath ?? this.clipPath;
        this.clipSoftness = params.clipSoftness ?? this.clipSoftness;
        this.renderOrder = params.renderOrder ?? this.renderOrder;
        this.dropShadow = params.dropShadow ? new DropShadow(params.dropShadow) : this.dropShadow;
        this.mask = params.mask ? new Mask(params.mask) : this.mask;
        this.requireTempLayer = params.requireTempLayer ?? this.requireTempLayer;
        this.blendMode = params.blendMode ? new BlendMode(params.blendMode) : this.blendMode;
        this.contentBox = params.contentBox
            ? new Box(
                  new Position(params.contentBox.position.x, params.contentBox.position.y),
                  new Dimension(params.contentBox.dimension.width, params.contentBox.dimension.height),
                  params.contentBox.rotation,
              )
            : this.contentBox;
        this.boundingBox = params.boundingBox
            ? new Box(
                  new Position(params.boundingBox.position.x, params.boundingBox.position.y),
                  new Dimension(params.boundingBox.dimension.width, params.boundingBox.dimension.height),
                  params.boundingBox.rotation,
              )
            : this.boundingBox;
        this.bgBoundingBox = params.bgBoundingBox
            ? new Box(
                  new Position(params.bgBoundingBox.position.x, params.bgBoundingBox.position.y),
                  new Dimension(params.bgBoundingBox.dimension.width, params.bgBoundingBox.dimension.height),
                  params.bgBoundingBox.rotation,
              )
            : this.bgBoundingBox;
        this.motionBlur = params.motionBlur ?? this.motionBlur;
        this.transform = params.transform ?? this.transform;
        this.transforms = params.transforms ?? this.transforms;
        this.transformData = params.transformData
            ? new TransformData(
                  new Position(params.transformData.position.x, params.transformData.position.y),
                  new Dimension(params.transformData.dimension.width, params.transformData.dimension.height),
                  params.transformData.rotation,
                  params.transformData.scale,
                  params.transformData.transforms,
              )
            : this.transformData;
        this.transformOpenData = params.transformOpenData
            ? new TransformData(
                  new Position(params.transformOpenData.position.x, params.transformOpenData.position.y),
                  new Dimension(params.transformOpenData.dimension.width, params.transformOpenData.dimension.height),
                  params.transformOpenData.rotation,
                  params.transformOpenData.scale,
                  params.transformOpenData.transforms,
              )
            : this.transformOpenData;
        this.transformCloseData = params.transformCloseData
            ? new TransformData(
                  new Position(params.transformCloseData.position.x, params.transformCloseData.position.y),
                  new Dimension(params.transformCloseData.dimension.width, params.transformCloseData.dimension.height),
                  params.transformCloseData.rotation,
                  params.transformCloseData.scale,
                  params.transformCloseData.transforms,
              )
            : this.transformCloseData;
        this.translatedX = params.translatedX ?? this.translatedX;
        this.translatedY = params.translatedY ?? this.translatedY;
        this.isInAnimation = params.isInAnimation ?? this.isInAnimation;
    }

    /**
     * Get the transformation matrix of the bounding box relative to the parent content
     * @returns mat2d transformation matrix
     */
    getBoxTransform(): mat2d {
        const transform = mat2d.create();
        const bboxDim = this.boundingBox.dimension;

        if (this.boundingBox.position.x || this.boundingBox.position.y) {
            mat2d.translate(transform, transform, [this.boundingBox.position.x, this.boundingBox.position.y]);
        }

        if (this.rotation) {
            this.rotateCenterMat2d(transform, this.rotation, bboxDim.width / 2, bboxDim.height / 2);
        }

        if (this.scale != 1) {
            this.scaleCenterMat2d(transform, this.scale, this.scale, bboxDim.width / 2, bboxDim.height / 2);
        }

        return transform;
    }

    /**
     * Get the transformation matrix of the content box relative to the bounding box
     * Note that since rotation and scaling are applied to the bounding box, we don't apply it to the content
     * @returns mat2d transformation matrix
     */
    getContentTransform(): mat2d {
        const transform = mat2d.create();

        if (this.contentBox.position.x || this.contentBox.position.y) {
            mat2d.translate(transform, transform, [this.contentBox.position.x, this.contentBox.position.y]);
        }

        return transform;
    }

    /**
     *
     * @returns mat2d transformation matrix with absolute transformation of the bounding box
     */
    getAbsBoxTransform(): mat2d {
        let transform = null;

        if (this.parent !== null) {
            transform = this.parent.getAbsContentTransform();
        } else {
            transform = mat2d.create();
        }

        return mat2d.multiply(transform, transform, this.getBoxTransform());
    }

    /**
     * Get the absolute transformation matrix of the content
     * @returns mat2d transformation matrix
     */
    getAbsContentTransform(): mat2d {
        const absBoxTransform = this.getAbsBoxTransform();

        return mat2d.multiply(absBoxTransform, absBoxTransform, this.getContentTransform());
    }

    /**
     * Get the absolute untransformed position of the bounding box
     * @returns Position
     */
    getAbsBoxPosition(): Position {
        // get absolute position
        let newPos: Position = this.boundingBox.position;

        if (this.parent !== null) {
            newPos = newPos.add(this.parent.getAbsContentPosition());
        }

        return newPos;
    }

    /**
     * Get the absolute position of the content box
     * @returns Position
     */
    getAbsContentPosition(): Position {
        return this.getAbsBoxPosition().add(this.contentBox.position);
    }

    getAbsRenderOrder(): number {
        let ro: number = this.renderOrder;

        if (this.parent !== null) {
            ro = ro * 0.1 + this.parent.getAbsRenderOrder();
        }

        return ro;
    }

    /**
     * Get the absolute untransformed position of the content box
     * TODO: this seems incorrect, since it does not take parent transformations into account.
     * @returns Position
     */
    getAbsBoxCenterPosition(): Position {
        return this.getAbsBoxPosition().add(
            new Position(this.boundingBox.dimension.width / 2, this.boundingBox.dimension.height / 2),
        );
    }

    /**
     * @returns Position distance between content origin and content centerpoint
     */
    getContentCenterDistance(): Position {
        // Assume we are at content origin. To rotate and scale we need to translate first to the box center.
        return new Position(
            this.boundingBox.dimension.width / 2 - this.contentBox.position.x,
            this.boundingBox.dimension.height / 2 - this.contentBox.position.y,
        );
    }

    /**
     * Get a transform that includes scaling and rotation around its box center, while being located at the content origin
     * @returns mat2d
     */
    getScaleAndRotationTransform(rotation?: number, scale?: number): mat2d {
        const rotationToUse = rotation ?? this.rotation;
        const scaleToUse = scale ?? this.scale;

        if (rotationToUse === 0 && scaleToUse === 1) {
            return mat2d.create(); // return identity matrix
        }

        const ccd = this.getContentCenterDistance();

        return getSrcDestTransform(ccd.x, ccd.y, rotationToUse, scaleToUse, -ccd.x, -ccd.y);
    }

    getChecksum(): string | null {
        if (this.checksum === null) {
            const data = this.getLocalHashData();
            this.checksum = CryptoJS.MD5(this.serializeObj(data)).toString(CryptoJS.enc.Hex);
        }

        return this.checksum;
    }

    hasOverflowEffects(): boolean {
        const hasOverflowEffects = (!!this.dropShadow && this.dropShadow.state !== 'DISABLE') || this.blur !== 0; // || el.motionBlur; (not supported by web compositor)
        const hasMask = !!this.mask && this.mask.mode !== MaskModeTypes.NONE;

        return hasMask || hasOverflowEffects || this.requireTempLayer || !!this.clipSoftness;
    }

    requiresIntermediateLayer(): boolean {
        return this.requireTempLayer;
    }

    /**
     * TODO: Improve performance
     * Check if current element requires motion blurring
     */
    requiresMotionBlur() {
        return (
            this.motionBlur && (this.transformOpenData || this.transformCloseData) /* &&
                (
                    (this.transformOpenData && JSON.stringify(this.transformOpenData) !== JSON.stringify(this.transformData)) ||
                    (this.transformCloseData && JSON.stringify(this.transformCloseData) !== JSON.stringify(this.transformData))
                )*/
        );
    }

    /**
     * Get the local region relative to the provided element content origin
     *
     * When motion blur is enabled, the content origin will be that of the current frame and extended with the open and close shutter data
     *
     * Types:
     * - Untransformed - Region of content without transforms or effects applied
     * - Transformed - Region of content with transforms, but no effects applied
     * - Effect - Region of content with transforms and effects applied. Note that effects are always applied to the transformed region.
     *
     * @type the region type indicating what to include in the region
     * @returns Region
     */
    public getRegion(type: RegionTypes = RegionTypes.TRANSFORMED): Region {
        let region = new Region(0, 0, this.contentBox.dimension.width, this.contentBox.dimension.height);

        if (type == RegionTypes.TRANSFORMED || type == RegionTypes.EFFECT) {
            const hasTransforms = this.rotation != 0 || this.scale != 1;
            const hasMotionBlur = this.requiresMotionBlur();
            const hasOverflowEffects = !!this.dropShadow || this.blur !== 0;

            // Determine the transformed region
            if (hasMotionBlur) {
                // in case motion blur is enabled, the motion blur includes the transforms
                region = MotionBlurEffect.getRegionOfInterest(region, this);
            } else if (hasTransforms) {
                const transform = this.getScaleAndRotationTransform();
                region = transformRegion(transform, region);
            }

            // TODO: apply clip effect to transformed region
            // region = ClipEffect.getRegionOfInterest(region, el);

            // In case of effects, apply to the transformed region
            if (type == RegionTypes.EFFECT && hasOverflowEffects) {
                region = DropShadowEffect.getRegionOfInterest(region, this);
                region = BlurEffect.getRegionOfInterest(region, this);
            }
        }

        return region;
    }

    /**
     * Get the absolute region by transforming the getRegion with the parent transforms
     * NOTE: This is very inefficient, since a lot of whitespace is added to the region when transforms are stacked.
     * @param type
     * @returns
     */
    public getAbsoluteRegion(type: RegionTypes = RegionTypes.TRANSFORMED): Region {
        const region = this.getRegion(type); // local region

        if (this.parent !== null) {
            // In case the element has a parent, transform the region using the parent transform
            const t = this.parent.getAbsContentTransform();

            // since the content region assumes the position of the content we still need to translate content position
            mat2d.translate(t, t, [
                this.boundingBox.position.x + this.contentBox.position.x,
                this.boundingBox.position.y + this.contentBox.position.y,
            ]);

            return transformRegion(t, region);
        }

        // Only translate to content, since the getRegion already applied local transforms
        return region.getTranslation(
            this.boundingBox.position.x + this.contentBox.position.x,
            this.boundingBox.position.y + this.contentBox.position.y,
        );
    }

    serializeObj(obj: any): any {
        if (Array.isArray(obj)) {
            return `[${obj.map((el) => this.serializeObj(el)).join(',')}]`;
        } else if (typeof obj === 'object' && obj !== null) {
            let acc = '';
            const keys = Object.keys(obj).sort();
            acc += `{${JSON.stringify(keys)}`;

            for (const key of keys) {
                acc += `${this.serializeObj(obj[key])},`;
            }

            return `${acc}}`;
        }

        return `${JSON.stringify(obj)}`;
    }

    isContainsAsset(asset: IAsset): boolean {
        return this.originalElement.isContainsAsset(asset);
    }

    rotateCenterMat2d(transform: mat2d, rotateDeg: number, offsetX: number, offsetY: number) {
        // translate to center, rotate and translate back.
        mat2d.translate(transform, transform, [offsetX, offsetY]);
        mat2d.rotate(transform, transform, rotateDeg * (Math.PI / 180));
        mat2d.translate(transform, transform, [-offsetX, -offsetY]);
    }

    scaleCenterMat2d(transform: mat2d, scaleX: number, scaleY: number, offsetX: number, offsetY: number) {
        // translate to center, scale and translate back.
        mat2d.translate(transform, transform, [offsetX, offsetY]);
        mat2d.scale(transform, transform, [scaleX, scaleY]);
        mat2d.translate(transform, transform, [-offsetX, -offsetY]);
    }

    getTransform(): mat2d {
        // apply all parent transformations (up to root)
        let transform = null;

        if (this.parent !== null) {
            transform = this.parent.getTransform();
        } else {
            transform = mat2d.create();
        }

        // apply transform of current component
        const dX = this.contentBox.position.x + this.boundingBox.position.x;
        const dY = this.contentBox.position.y + this.boundingBox.position.y;

        if (dX || dY) {
            mat2d.translate(transform, transform, [dX, dY]);
        }

        if (this.rotation) {
            this.rotateCenterMat2d(
                transform,
                this.rotation,
                this.contentBox.dimension.width / 2,
                this.contentBox.dimension.height / 2,
            );
        }

        if (this.scale != 1) {
            this.scaleCenterMat2d(
                transform,
                this.scale,
                this.scale,
                this.contentBox.dimension.width / 2,
                this.contentBox.dimension.height / 2,
            );
        }

        return transform;
    }

    getTransformData() {
        return new TransformData(
            // we need to take the absolute content position, since for elements in a group a position
            // relative to the group can always be the same since the group grows with the elements
            this.getAbsContentPosition(),
            this.contentBox.dimension.getCopy(),
            this.rotation,
            this.scale,
            [] as mat2d[],
        );
    }

    getMotionBlurTransforms(): mat2d[] {
        const transforms: any = [];

        const baseBoxToCenterX = this.boundingBox.position.x + this.contentBox.position.x;
        const baseBoxToCenterY = this.boundingBox.position.y + this.contentBox.position.y;

        const ccd = this.getContentCenterDistance();

        [this.transformOpenData, this.transformData, this.transformCloseData].forEach((td, i) => {
            if (!td) {
                transforms[i] = null;

                return; // continue
            }

            // calculate location difference with base (in case of base this will be 0)
            const diffX = td.position.x - baseBoxToCenterX;
            const diffY = td.position.y - baseBoxToCenterY;

            const transform = getSrcDestTransform(
                // translate from layer origin to content center
                ccd.x + diffX,
                ccd.y + diffY,
                td.rotation,
                td.scale,
                -ccd.x,
                -ccd.y,
            );

            transforms[i] = transform;
        });

        // make sure we clone repeated values, so we can safely modify them independently later
        return [
            transforms[0] || mat2d.clone(transforms[1]),
            transforms[1],
            transforms[2] || mat2d.clone(transforms[1]),
        ];
    }

    getLocalHashData() {
        return {
            hidden: this.hidden,
            opacity: this.opacity,
            rotation: this.rotation,
            scale: this.scale,
            blur: this.blur,
            clipPath: this.clipPath ?? null,
            clipSoftness: this.clipSoftness ?? null,
            renderOrder: this.renderOrder,
            dropShadow: this.dropShadow?.toObject() ?? null,
            mask: this.mask?.toObject() ?? null,
            blendMode: this.blendMode?.toObject() ?? null,
            contentBox: this.contentBox.toObject(),
            boundingBox: this.boundingBox.toObject(),
            motionBlur: this.motionBlur,
        };
    }

    toObject() {
        return {
            id: this.id,
            name: this.name,
            hidden: this.hidden,
            opacity: this.opacity,
            rotation: this.rotation,
            scale: this.scale,
            blur: this.blur,
            clipPath: this.clipPath ?? null,
            clipSoftness: this.clipSoftness,
            renderOrder: this.renderOrder,
            dropShadow: this.dropShadow?.toObject() ?? null,
            mask: this.mask?.toObject() ?? null,
            requireTempLayer: this.requireTempLayer,
            blendMode: this.blendMode?.toObject() ?? null,
            contentBox: this.contentBox.toObject(),
            boundingBox: this.boundingBox.toObject(),
            bgBoundingBox: this.bgBoundingBox?.toObject() ?? null,
            motionBlur: this.motionBlur,
            transform: this.transform ?? null,
            transforms: this.transforms ?? [],
            transformData: this.transformData?.toObject() ?? null,
            transformOpenData: this.transformOpenData?.toObject() ?? null,
            transformCloseData: this.transformCloseData?.toObject() ?? null,
            translatedX: this.translatedX,
            translatedY: this.translatedY,
            isInAnimation: this.isInAnimation,
        };
    }
}
