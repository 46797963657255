import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

export function PageWithAppcues(props: React.PropsWithChildren<{}>) {
    const location = useLocation();

    useEffect(() => {
        if (APPCUES_ENABLED && window.Appcues && window.Appcues.page) {
            window.Appcues.page();
        }
    }, [location.pathname, location.search]);

    return props.children;
}

function RouteWithAppcues(props) {
    const location = useLocation();

    useEffect(() => {
        if (APPCUES_ENABLED && window.Appcues && window.Appcues.page) {
            window.Appcues.page();
        }
    }, [location.pathname]);

    return <Route {...props} />;
}

export function sendAppcuesEvent(eventName: string, eventProperties: any) {
    if (APPCUES_ENABLED && window.Appcues && window.Appcues.track) {
        window.Appcues.track(eventName, eventProperties);
    }
}

export default RouteWithAppcues;
