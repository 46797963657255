import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    VideoWebSpecificationParser,
    SpecificationExporter,
    BaseVisualElement,
    FontFamily,
    Template,
    getAllElementsRecursively,
    ContentPropertiesManager,
    TextStyles,
    TextElement,
    ElementTypes,
} from '@bynder-studio/render-core';
import { PlaybackManipulationRenderer } from '@bynder-studio/render-web';
import { useDispatch, useSelector } from 'react-redux';
import { fontsElementsSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import useEditor from '~/hooks/useEditor';
import { Account, ActionStatus, ColorPaletteAction } from 'packages/hooks/useAccessRights/types';
import { syncBrandColors } from 'packages/pages/editor/syncBrandColors';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import useAccessRights from 'packages/hooks/useAccessRights';
import { createPresetAction } from 'packages/store/creativeEditor/creativeEditor.actions';
import {
    convertTextStyles,
    getAllNodesRecursively,
    replaceDuplicatedIds,
    type Node,
    type MatchList,
} from '~/common/editor/presets/utils';

const usePresets = () => {
    const dispatch = useDispatch();
    const { palettes } = useSelector(colorPalettes);
    const fonts: FontFamily[] = useSelector(fontsElementsSelector);
    const { creativeModel, manipulationRenderer } = useEditor();
    const { isAccountActionAllowed } = useAccessRights();
    const textStyles = creativeModel.getTextStyles() as TextStyles;
    const contentPropertiesManager = creativeModel.getContentPropertiesManager() as ContentPropertiesManager;

    const getContentPropertiesFromElement = useCallback(
        <T extends { contentPropertyUuid?: string; id: number }>(node: Node<T>, matchList: MatchList) => {
            const elements = getAllNodesRecursively(node);

            const allContentProperties = contentPropertiesManager.toObject();
            const ids = new Set(elements.map((element) => element.contentPropertyUuid as string).filter(Boolean));

            const contentProperties = allContentProperties
                .filter((item) => ids.has(item.uuid))
                .map((item) => {
                    const uuid = uuidv4();

                    elements.forEach((element) => {
                        if (element.contentPropertyUuid !== item.uuid) {
                            return;
                        }

                        const el = elements.find(({ id }) => element.id === id);

                        if (el) {
                            el.contentPropertyUuid = uuid;
                        }
                    });

                    if (item.type === ElementTypes.TEXT) {
                        item.properties.textStyles = (item.properties.textStyles ?? []).reduce((acc, styleId) => {
                            const newVal = matchList[styleId];

                            if (newVal) {
                                acc.push(newVal);
                            }

                            return acc;
                        }, [] as string[]);

                        item.properties.value?.runs?.forEach((run) => {
                            run.styleId = matchList[run.styleId] ?? null;
                        });
                    }

                    return { ...item, uuid };
                });

            return contentProperties;
        },
        [contentPropertiesManager],
    );

    const createPreset = useCallback(
        (selectedGroup: BaseVisualElement) => {
            const specElements = SpecificationExporter.exportElements([selectedGroup]);

            const presetToSave = SpecificationExporter.convertGroupSpecElementIntoPreset(
                Object.values(specElements)[0],
            );

            const [idsMatchList, stylesList] = convertTextStyles([selectedGroup], textStyles.list);

            const contentProperties = getContentPropertiesFromElement(presetToSave, idsMatchList);
            const syncedPreset = {
                ...presetToSave,
                children: replaceDuplicatedIds(presetToSave.children, idsMatchList),
                contentProperties,
            };

            dispatch(createPresetAction(syncedPreset, stylesList));
        },
        [textStyles.list, getContentPropertiesFromElement, dispatch],
    );

    const applyPreset = useCallback(
        (preset: Template) => {
            const startFrame =
                manipulationRenderer instanceof PlaybackManipulationRenderer
                    ? manipulationRenderer.getCurrentFrame()
                    : 0;
            const videoWebSpecificationParser = new VideoWebSpecificationParser();
            const parsedElements = videoWebSpecificationParser.parseElements(preset as any, fonts);

            const updatedTextStylesIds = textStyles.extendStyles(preset.textStyles ?? []);

            const updatedContentPropertyIds = contentPropertiesManager.extendContentProperties(
                preset.contentProperties ?? [],
                fonts,
                updatedTextStylesIds,
            );

            getAllElementsRecursively(parsedElements).forEach((element: any) => {
                if (updatedContentPropertyIds.has(element.contentPropertyId)) {
                    element.contentPropertyId = updatedContentPropertyIds.get(element.contentPropertyId);
                }

                if (element instanceof TextElement) {
                    element.textStyles = element.textStyles.filter((styleId) => textStyles.getStyle(styleId));
                    element.formattedText.runs.forEach((run) => {
                        if (!run.styleId) {
                            return;
                        }

                        const newId = updatedTextStylesIds.get(run.styleId);

                        if (newId) {
                            run.styleId = newId;
                        }
                    });
                }
            });

            creativeModel.addElements(parsedElements, startFrame);

            if (
                isAccountActionAllowed(ColorPaletteAction.EDIT_COLOR_PALETTE, Account.COLOR_PALETTES) ===
                ActionStatus.ALLOWED
            ) {
                syncBrandColors(creativeModel, palettes, () => null);
            }
        },
        [
            manipulationRenderer,
            fonts,
            textStyles,
            contentPropertiesManager,
            creativeModel,
            isAccountActionAllowed,
            palettes,
        ],
    );

    return {
        applyPreset,
        createPreset,
    };
};

export default usePresets;
