import { StrokeType, TextBackground, TextDecoration, TextScript, TextTransform, truthy } from '@bynder-studio/misc';
import { Direction, FontFamily, getStructuredText } from '@bynder-studio/structured-text';
import { LeadingTypes } from '../Enums/LeadingTypes';
import { ElementTypes } from '../Enums/ElementTypes';
import { TimelineBehavior } from '../Enums/TimelineBehavior';
import { Template, TemplateElement } from '../Renderers/BaseRenderer/IBaseRenderer';
import { type BaseVisualElementParams, ContentProperty, type ShapeElementParams } from '../types';
import { type BackgroundColorParams, defaultBackgroundColorParams } from '../Models/Properties/BackgroundColor';
import { ContentTransformParams } from '../Models/Shared/ContentTransform';
import { ContentPropertyData } from './types';

type CommonElementParserParams = Exclude<
    BaseVisualElementParams,
    'scale' | 'animationIn' | 'animationOut' | 'animations' | 'lockUniScaling' | 'allowToggleVisibility'
>;

type CommonTextElementParserParams = CommonElementParserParams & {
    text: string;
    direction?: Direction;
    textControl?: string;
    contentTransform: ContentTransformParams;
    fontScale?: number;
    leadingType?: LeadingTypes;
    minFontScale?: number;
    textStyles?: string[];
    brandColors?: number[];
    textBackground?: TextBackground;
    textBackgroundBrandColors?: number[];
};

type CommonImageElementParserParams = CommonElementParserParams & {
    contentTransform: ContentTransformParams;
};

type CommonVideoElementParserParams = CommonElementParserParams & {
    contentTransform: ContentTransformParams;
    offsetTime: number;
    isAlpha: boolean;
    useAudio: boolean;
    useDynamicLength: boolean;
};

const getAllTemplateElementsRecursively = (elements: TemplateElement) => {
    const result: TemplateElement[] = [];
    elements.filter(truthy).forEach((element) => {
        result.push(element);

        if (element.children) {
            result.push(...getAllTemplateElementsRecursively(element.children));
        }
    });

    return result;
};

export abstract class SpecificationParser {
    readonly ELEMENTS_TYPES_TO_PARSE = [
        ElementTypes.IMAGE,
        ElementTypes.VIDEO,
        ElementTypes.TEXT,
        ElementTypes.SHAPE,
        ElementTypes.GROUP,
    ];

    getBackgroundColorParams(backgroundColorSpec: TemplateElement): BackgroundColorParams {
        if (!backgroundColorSpec) {
            return defaultBackgroundColorParams;
        }

        return {
            id: backgroundColorSpec.id,
            name: backgroundColorSpec.name,
            locked: backgroundColorSpec.properties.locked,
            color: backgroundColorSpec.properties.value,
            brandColors: backgroundColorSpec.properties.brandColors || [],
        };
    }

    static getTextDefaultProps(rawEl: TemplateElement) {
        return {
            styleId: rawEl.properties.styleId || null,
            fontId: (rawEl.properties.fontId || '').toString(),
            fontSize: rawEl.properties.fontSize,
            color: rawEl.properties.fontColor,
            leading: rawEl.properties.lineHeight,
            tracking: rawEl.properties.charSpacing,
            textTransform: rawEl.properties.textTransform || TextTransform.NONE,
            textScript: rawEl.properties.textScript || TextScript.BASE,
            textDecoration: rawEl.properties.textDecoration || TextDecoration.NONE,
            stroke: rawEl.properties.stroke || {
                type: StrokeType.NONE,
                width: 0,
            },
            paragraphSpacing: rawEl.properties.paragraphSpacing || 0,
        };
    }

    static parseContentProperty(
        contentProperty: ContentPropertyData,
        fontFamilies: FontFamily[],
        template?: Template,
    ): ContentProperty {
        const common = {
            name: contentProperty.name,
            uuid: contentProperty.uuid,
        };

        if (contentProperty.type === ElementTypes.IMAGE) {
            const properties = contentProperty.properties;

            return {
                ...common,
                type: ElementTypes.IMAGE,
                properties: {
                    src: properties.value?.thumbnail,
                    srcId: properties.value?.value,
                    srcType: properties.value?.type,
                    fileName: properties?.fileName || '',
                    naturalDimension: {
                        width: properties.naturalWidth,
                        height: properties.naturalHeight,
                    },
                    virtualData: {
                        bynderCollectionId: properties.value?.bynderCollectionId || null,
                        allowPersonalUpload: properties.allowPersonalUpload ?? true,
                    },
                },
            };
        }

        if (contentProperty.type === ElementTypes.VIDEO) {
            const properties = contentProperty.properties;

            return {
                ...common,
                type: ElementTypes.VIDEO,
                properties: {
                    src: properties.value?.videoPreviewUrl,
                    srcId: properties.value?.value,
                    srcType: properties.value?.type,
                    fileName: properties?.fileName || '',
                    offsetTime: properties.value?.offsetTime || 0,
                    isAlpha: properties.value?.isAlpha || false,
                    useAudio: properties.useAudio || false,
                    useDynamicLength: properties.useDynamicLength || false,
                    naturalDimension: {
                        width: properties.naturalWidth,
                        height: properties.naturalHeight,
                    },
                    virtualData: {
                        bynderCollectionId: properties.value?.bynderCollectionId || null,
                        allowPersonalUpload: properties.allowPersonalUpload ?? true,
                    },
                    gain: properties?.gain || 0,
                    fadeIn: properties?.fadeIn || 0,
                    fadeOut: properties?.fadeOut || 0,
                },
            };
        }

        if (contentProperty.type === ElementTypes.TEXT) {
            const properties = contentProperty.properties;
            let defaultProps = SpecificationParser.getTextDefaultProps({
                properties: {},
            } as unknown as TemplateElement);

            (template?.pages || []).forEach((page) => {
                getAllTemplateElementsRecursively(page.elements).forEach((element) => {
                    if (element.contentPropertyUuid === contentProperty.uuid) {
                        defaultProps = SpecificationParser.getTextDefaultProps(element);
                    }
                });
            });

            return {
                ...common,
                type: ElementTypes.TEXT,
                properties: {
                    formattedText: getStructuredText(properties.value, defaultProps),
                    limitTextToBounds: properties.limitTextToBounds || false,
                    textStyles: properties.textStyles || [],
                    brandColors: properties.brandColors || [],
                },
            };
        }

        if (contentProperty.type === ElementTypes.SHAPE) {
            const properties = contentProperty.properties;

            return {
                ...common,
                type: ElementTypes.SHAPE,
                properties: {
                    shapeType: properties.shapeType,
                    fillColor: properties.fillColor,
                    borderColor: properties.borderColor,
                    borderWidth: properties.borderWidth,
                    borderAlignment: properties.borderAlignment,
                    borderRadius: properties.pathMetadata?.borderRadius || 0,
                    path: properties.pathMetadata?.path || '',
                    borderLineCap: null,
                    borderBrandColors: properties.borderBrandColors || [],
                    fillBrandColors: properties.fillBrandColors || [],
                },
            };
        }

        return null;
    }

    static applyTheSameContentPropertyValues(contentPropertyList: ContentProperty[], template?: Template) {
        const applyContentPropertyToImage = (contentProperty: ContentProperty, imageElement: TemplateElement) => {
            if (contentProperty.type !== ElementTypes.IMAGE) {
                return;
            }

            if (!imageElement.properties.value) {
                imageElement.properties.value = {};
            }

            imageElement.properties.value.thumbnail = contentProperty.properties.src;
            imageElement.properties.value.value = contentProperty.properties.srcId;
            imageElement.properties.value.type = contentProperty.properties.srcType;
            imageElement.properties.fileName = contentProperty.properties.fileName;
            imageElement.properties.naturalWidth = contentProperty.properties.naturalDimension.width;
            imageElement.properties.naturalHeight = contentProperty.properties.naturalDimension.height;
            imageElement.properties.value.bynderCollectionId =
                contentProperty.properties.virtualData.bynderCollectionId;
            imageElement.properties.allowPersonalUpload = contentProperty.properties.virtualData.allowPersonalUpload;
        };
        const applyContentPropertyToVideo = (contentProperty: ContentProperty, videoElement: TemplateElement) => {
            if (contentProperty.type !== ElementTypes.VIDEO) {
                return;
            }

            if (!videoElement.properties.value) {
                videoElement.properties.value = {};
            }

            videoElement.properties.value.videoPreviewUrl = contentProperty.properties.src;
            videoElement.properties.value.value = contentProperty.properties.srcId;
            videoElement.properties.value.type = contentProperty.properties.srcType;
            videoElement.properties.fileName = contentProperty.properties.fileName;
            videoElement.properties.value.offsetTime = contentProperty.properties.offsetTime;
            videoElement.properties.value.isAlpha = contentProperty.properties.isAlpha;
            videoElement.properties.useAudio = contentProperty.properties.useAudio;
            videoElement.properties.useDynamicLength = contentProperty.properties.useDynamicLength;
            videoElement.properties.naturalWidth = contentProperty.properties.naturalDimension.width;
            videoElement.properties.naturalHeight = contentProperty.properties.naturalDimension.height;
            videoElement.properties.value.bynderCollectionId =
                contentProperty.properties.virtualData.bynderCollectionId;
            videoElement.properties.allowPersonalUpload = contentProperty.properties.virtualData.allowPersonalUpload;
        };
        const applyContentPropertyToText = (contentProperty: ContentProperty, textElement: TemplateElement) => {
            if (contentProperty.type !== ElementTypes.TEXT) {
                return;
            }

            textElement.properties.value = contentProperty.properties.formattedText;
            textElement.properties.limitTextToBounds = contentProperty.properties.limitTextToBounds;
            textElement.properties.textStyles = contentProperty.properties.textStyles;
            textElement.properties.brandColors = contentProperty.properties.brandColors;
        };
        const applyContentPropertyToShape = (contentProperty: ContentProperty, shapeElement: TemplateElement) => {
            if (contentProperty.type !== ElementTypes.SHAPE) {
                return;
            }

            if (!shapeElement.properties.pathMetadata) {
                shapeElement.properties.pathMetadata = {};
            }

            shapeElement.properties.shapeType = contentProperty.properties.shapeType;
            shapeElement.properties.fillColor = contentProperty.properties.fillColor;
            shapeElement.properties.borderColor = contentProperty.properties.borderColor;
            shapeElement.properties.borderWidth = contentProperty.properties.borderWidth;
            shapeElement.properties.borderAlignment = contentProperty.properties.borderAlignment;
            shapeElement.properties.pathMetadata.borderRadius = contentProperty.properties.borderRadius;
            shapeElement.properties.pathMetadata.path = contentProperty.properties.path;
            shapeElement.properties.borderBrandColors = contentProperty.properties.borderBrandColors;
            shapeElement.properties.fillBrandColors = contentProperty.properties.fillBrandColors;
        };

        const contentPropertyMap = new Map(
            contentPropertyList.map((contentProperty) => [contentProperty.uuid, contentProperty]),
        );
        (template?.pages || []).forEach((page) => {
            getAllTemplateElementsRecursively(page.elements).forEach((element) => {
                const contentPropertyId = element.contentPropertyUuid;

                if (!contentPropertyId || !contentPropertyMap.has(contentPropertyId)) {
                    return;
                }

                const contentProperty = contentPropertyMap.get(contentPropertyId);

                switch (element.type) {
                    case ElementTypes.IMAGE:
                        applyContentPropertyToImage(contentProperty, element);
                        break;
                    case ElementTypes.VIDEO:
                        applyContentPropertyToVideo(contentProperty, element);
                        break;
                    case ElementTypes.TEXT:
                        applyContentPropertyToText(contentProperty, element);
                        break;
                    case ElementTypes.SHAPE:
                        applyContentPropertyToShape(contentProperty, element);
                        break;
                }
            });
        });
    }

    static parseContentProperties(
        contentProperties: ContentPropertyData[],
        fontFamilies: FontFamily[],
        template?: Template,
    ): ContentProperty[] {
        const contentPropertyList = contentProperties
            .map((contentPropertyData) =>
                SpecificationParser.parseContentProperty(contentPropertyData, fontFamilies, template),
            )
            .filter(truthy);
        SpecificationParser.applyTheSameContentPropertyValues(contentPropertyList, template);

        return contentPropertyList;
    }

    getBaseElementParams(rawEl: TemplateElement): any {
        return {
            id: rawEl.id,
            name: rawEl.name,
            contentPropertyId: rawEl.contentPropertyUuid || null,
            locked: rawEl.properties.locked,
            hidden: rawEl.properties.hidden,
            startFrame: rawEl.properties.startFrame || 0,
            duration: rawEl.properties.duration || 1,
            renderOrder: rawEl.properties.renderOrder,
            opacity: rawEl.properties.opacity,
            rotation: rawEl.properties.rotation,
            dimension: {
                width: rawEl.properties.width,
                height: rawEl.properties.height,
            },
            position: {
                x: rawEl.properties.horizontalPosition,
                y: rawEl.properties.verticalPosition,
            },
            dropShadow: rawEl.properties.dropShadow,
            mask: rawEl.properties.mask,
            blendMode: rawEl.properties.blendMode,
            timelineBehavior: rawEl.properties.timelineBehavior || TimelineBehavior.AUTO,
            animationIn: rawEl.properties.animationIn,
            animationOut: rawEl.properties.animationOut,
            animations: rawEl.properties.animations,
        } as unknown as CommonElementParserParams;
    }

    getTextElementParams(rawEl: TemplateElement): any {
        return {
            ...this.getBaseElementParams(rawEl),
            text: rawEl.properties.value,
            textDirection: rawEl.properties.textDirection,
            textControl: rawEl.properties.textControl,
            contentTransform: rawEl.properties.contentTransform,
            minFontScale: rawEl.properties.minFontScale || null,
            fontScale: rawEl.properties.fontScale || 1,
            leadingType: rawEl.properties.leadingType || LeadingTypes.EXTERNAL_LEADING_PCT,
            textBackground: rawEl.properties.textBackground || null,
            textStyles: rawEl.properties.textStyles || [],
            brandColors: rawEl.properties.brandColors || [],
            textBackgroundBrandColors: rawEl.properties.textBackgroundBrandColors || [],
        } satisfies CommonTextElementParserParams;
    }

    getImageElementParams(rawEl: TemplateElement): any {
        return {
            ...this.getBaseElementParams(rawEl),
            contentTransform: rawEl.properties.contentTransform,
        } satisfies CommonImageElementParserParams;
    }

    getShapeElementParams = (rawEl: TemplateElement): ShapeElementParams => {
        return {
            ...this.getBaseElementParams(rawEl),
            shapeType: rawEl.properties.shapeType,
            fillColor: rawEl.properties.fillColor,
            borderColor: rawEl.properties.borderColor,
            borderWidth: rawEl.properties.borderWidth,
            borderAlignment: rawEl.properties.borderAlignment,
            borderRadius: rawEl.properties.pathMetadata?.borderRadius || 0,
            path: rawEl.properties.pathMetadata?.path || '',
            fillBrandColors: rawEl.properties.fillBrandColors || [],
            borderBrandColors: rawEl.properties.borderBrandColors || [],
        } as ShapeElementParams;
    };

    getVideoElementParams(rawEl: TemplateElement): any {
        return {
            ...this.getBaseElementParams(rawEl),
            contentTransform: rawEl.properties.contentTransform,
            offsetTime: rawEl.properties.value?.offsetTime || 0,
            isAlpha: rawEl.properties.value?.isAlpha || false,
            useAudio: rawEl.properties.useAudio || false,
            gain: rawEl.properties.gain || 0,
            fadeIn: rawEl.properties.fadeIn || 0,
            fadeOut: rawEl.properties.fadeOut || 0,
            useDynamicLength: rawEl.properties.useDynamicLength || false,
        } as CommonVideoElementParserParams;
    }
}
