import { Region } from '../Shared/Region';
import { EffectTypes } from '../../Enums/EffectTypes';
import { transformRegion } from '../../Helpers/compositorUtils';
import { BaseEffect } from './BaseEffect';
import { type CompElement } from '../../types';

export class MotionBlurEffect extends BaseEffect {
    static type = EffectTypes.MOTIONBLUR;

    static getRegionOfDefinition(region: Region, compElement: CompElement) {
        return new Region(
            Number.NEGATIVE_INFINITY,
            Number.NEGATIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
        );
    }

    /**
     * //TODO: refactor this, since it is not using the region as an input
     * The region passed should be the untransformed content, since the transformations are included in the motion blur
     * @param region is the content region (only width and height)
     * @param compElement
     * @returns Region local element region, extended with area needed to contain the motion blur
     */
    static getRegionOfInterest(region: Region | null, el: any) {
        //use width and height of the content;
        if (!region) {
            const width = el.contentBox.dimension.width;
            const height = el.contentBox.dimension.height;

            region = new Region(0, 0, width, height);
        }

        const transforms = el.getMotionBlurTransforms();

        // //middle transform holds the current frame content,
        // let r = getTransformedRegion(transforms[1], width, height);

        // //extend region with open and close transform
        // r = r.getUnion(getTransformedRegion(transforms[0], width, height));
        // r = r.getUnion(getTransformedRegion(transforms[2], width, height));

        //middle transform holds the current frame content,
        let r = transformRegion(transforms[1], region);

        //extend region with open and close transform
        r = r.getUnion(transformRegion(transforms[0], region));
        r = r.getUnion(transformRegion(transforms[2], region));

        return r;
    }
}
