import { RangeTree } from './RangeTree';
import { BaseModel } from '../Models/Models/BaseModel';
import { SnapPoint, SnapPointTypes } from './SnapPoint';
import { ElementChangeEvent, ElementChangeType, Point, SnapItem, SnapItems, SnapLine, SnapLines } from '../types';
import { ElementResizeParams } from '../event-types';
import { GroupElement } from '../Models/Elements/GroupElement';
import { CORNERS, SIDES } from '../Enums/ElementSides';
import { Position } from '../Models/Shared/Position';
import { Dimension } from '../Models/Shared/Dimension';
import { equals } from './utils';
import type { BaseVisualElement } from '../Models/Elements/BaseVisualElement';
import { changeElementInsideBox, getBoundingBoxAfterRotation } from '../Models/Elements/Helpers/GroupElementHelpers';
import { getAllChildrenRecursively } from './elementUtils';

class PointList {
    data: Point[] = [];

    constructor(points: Point[]) {
        points.forEach((p) => this.push(p));
    }

    concat(points: Point[]) {
        points.forEach((p) => this.push(p));
    }

    push(point: Point) {
        if (this.data.some((p) => p[0] === point[0] && p[1] === point[1])) return;
        this.data.push(point);
    }

    min(axis: 'x' | 'y') {
        const index = { x: 1, y: 0 }[axis];
        let min = this.data[0];
        this.data.forEach((p) => {
            if (min[index] > p[index]) {
                min = p;
            }
        });
        return min;
    }

    max(axis: 'x' | 'y') {
        const index = { x: 1, y: 0 }[axis];
        let max = this.data[0];
        this.data.forEach((p) => {
            if (max[index] < p[index]) {
                max = p;
            }
        });
        return max;
    }

    toArray() {
        return this.data;
    }
}

function toSnapPoints(element: {
    id?: number;
    position: Position;
    dimension: Dimension;
    rotation: number;
}): SnapPoint[] {
    const id = element.id || 0;
    const bbox = getBoundingBoxAfterRotation(element);
    const x = element.position.getX();
    const y = element.position.getY();
    const w = element.dimension.getWidth();
    const h = element.dimension.getHeight();

    return [
        new SnapPoint(id, SnapPointTypes.NW, bbox[0].x, bbox[0].y),
        new SnapPoint(id, SnapPointTypes.NE, bbox[1].x, bbox[1].y),
        new SnapPoint(id, SnapPointTypes.SE, bbox[2].x, bbox[2].y),
        new SnapPoint(id, SnapPointTypes.SW, bbox[3].x, bbox[3].y),
        new SnapPoint(id, SnapPointTypes.CENTER, x + w / 2, y + h / 2),
    ];
}

function filterSnapPointsMove() {
    return true;
}

function filterSnapPointsRotate(sp: SnapPoint) {
    return sp.type !== SnapPointTypes.CENTER;
}

function createFilterSnapPointsResize({ handleIndex }: ElementResizeParams, axis: 'x' | 'y') {
    return (sp: SnapPoint) => {
        if (([SIDES.LEFT, SIDES.RIGHT] as number[]).includes(handleIndex) && axis === 'x') {
            if (handleIndex === SIDES.LEFT) {
                return [SnapPointTypes.NW, SnapPointTypes.SW].includes(sp.type);
            }

            if (handleIndex === SIDES.RIGHT) {
                return [SnapPointTypes.NE, SnapPointTypes.SE].includes(sp.type);
            }
        }

        if (([SIDES.UP, SIDES.BOTTOM] as number[]).includes(handleIndex) && axis === 'y') {
            if (handleIndex === SIDES.UP) {
                return [SnapPointTypes.NW, SnapPointTypes.NE].includes(sp.type);
            }

            if (handleIndex === SIDES.BOTTOM) {
                return [SnapPointTypes.SW, SnapPointTypes.SE].includes(sp.type);
            }
        }

        if (handleIndex === CORNERS.LEFT_TOP) {
            return sp.type === SnapPointTypes.NW;
        }

        if (handleIndex === CORNERS.RIGHT_TOP) {
            return sp.type === SnapPointTypes.NE;
        }

        if (handleIndex === CORNERS.RIGHT_BOTTOM) {
            return sp.type === SnapPointTypes.SE;
        }

        if (handleIndex === CORNERS.LEFT_BOTTOM) {
            return sp.type === SnapPointTypes.SW;
        }

        return false;
    };
}

function filterSnapPointsFabric(type: ElementChangeType, params: ElementChangeEvent, axis: 'x' | 'y') {
    if (type === 'move') {
        return filterSnapPointsMove;
    }

    if (type === 'rotate') {
        return filterSnapPointsRotate;
    }

    if (type === 'resize') {
        return createFilterSnapPointsResize(params as ElementResizeParams, axis);
    }

    return () => false;
}

function filterSnapItemsHits(items: SnapItem[]) {
    const dList = items.map((item) => item.d);
    const min = Math.min(Number.MAX_SAFE_INTEGER, ...dList);

    return items.filter((item) => Math.abs(item.d - min) < 1);
}

type SnapHandler = (xAxisSnapLines: SnapLines, yAxisSnapLines: SnapLines) => void;

export class GuideManager {
    private xTree: RangeTree<SnapPoint> = new RangeTree<SnapPoint>();
    private yTree: RangeTree<SnapPoint> = new RangeTree<SnapPoint>();
    private snapDistance: number = 5;
    private currentElementIds: (number | string)[] = [];
    private frame: number = 0;
    private model!: BaseModel;
    private handler?: SnapHandler;

    setModel(model: BaseModel) {
        this.model = model;
        this.build();
    }

    setFrame(frame: number) {
        this.frame = frame;
        this.build();
    }

    setSnapDistance(n: number) {
        this.snapDistance = n;
    }

    private getSnapDistance(box: { dimension: Dimension }, eventParams: { scale: number; pixelRatio: number }) {
        return Math.ceil(
            Math.min(
                this.snapDistance / (eventParams.scale * eventParams.pixelRatio),
                box.dimension.getWidth() / 4,
                box.dimension.getHeight() / 4,
            ),
        );
    }

    setHandler(handler: SnapHandler) {
        this.handler = handler;
    }

    markDirty(event?: any) {
        if (event && event.element && this.currentElementIds.includes(event.element.id)) return;

        this.currentElementIds = [];
    }

    build() {
        if (!this.model || !this.currentElementIds.length) return;

        const w = this.model.getDimensions().getWidth();
        const h = this.model.getDimensions().getHeight();
        const points: SnapPoint[] = [
            new SnapPoint(0, SnapPointTypes.NW, 0, 0),
            new SnapPoint(0, SnapPointTypes.NE, w, 0),
            new SnapPoint(0, SnapPointTypes.SW, 0, h),
            new SnapPoint(0, SnapPointTypes.SE, w, h),
            new SnapPoint(0, SnapPointTypes.CENTER, w / 2, h / 2),
        ];

        const groups: GroupElement[] = [];
        this.currentElementIds.forEach((id) => {
            const currentElement = this.model.getElementById(id);

            if (currentElement instanceof GroupElement) {
                groups.push(currentElement);
            }
        });

        this.model.getAllElementsRecursively().forEach((element) => {
            const start = element.startFrame || 0;
            const end = start + (element.duration || 0);
            if (this.frame < start || this.frame > end) return;
            if (this.currentElementIds.includes(element.id)) return;
            if (
                (element as GroupElement).children &&
                this.currentElementIds.some((id) => (element as GroupElement).isContainsElement(id as number))
            )
                return;
            if (groups.some((group) => group.isContainsElement(element.id))) return;

            points.push(...toSnapPoints(element));
        });

        this.xTree.clean();
        this.yTree.clean();

        points.forEach((point) => {
            this.xTree.insert(point.x, point);
            this.yTree.insert(point.y, point);
        });
    }

    private setCurrentElementIds(ids: (number | string)[]) {
        if (equals(this.currentElementIds, ids)) {
            return;
        }

        this.currentElementIds = ids;
        this.build();
    }

    private getCurrentElements() {
        return this.currentElementIds
            .map((id) => this.model.getElementById(id))
            .filter((element) => !(element as GroupElement).children);
    }

    private calculateBox(currentElements: BaseVisualElement[]) {
        const min = new Position(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        const max = new Position(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);

        currentElements
            .flatMap((element) => getBoundingBoxAfterRotation(element))
            .forEach(({ x, y }) => {
                min.setX(Math.min(min.getX(), x));
                min.setY(Math.min(min.getY(), y));
                max.setX(Math.max(max.getX(), x));
                max.setY(Math.max(max.getY(), y));
            });

        return {
            position: min,
            dimension: new Dimension(max.x - min.x, max.y - min.y),
            rotation: 0,
        };
    }

    private getSnapItems(point: SnapPoint, snapDistance: number, axis: 'x' | 'y') {
        const tree = axis === 'x' ? this.xTree : this.yTree;
        const value = point[axis];

        return tree
            .rangeQuery(value - snapDistance, value + snapDistance)
            .map((item) => ({ ...item, d: Math.abs(item.value - value), x: point.x, y: point.y }));
    }

    onElementChange(type: ElementChangeType, params: ElementChangeEvent) {
        const { elementsIds, ignoreSnapping } = params;

        if (!this.handler) {
            return;
        }

        this.setCurrentElementIds(elementsIds);

        const currentElements = this.getCurrentElements();
        const box = this.calculateBox(currentElements);
        const snapPoints = toSnapPoints(box);
        const snapDistance = this.getSnapDistance(box, params);

        const snapItemsX = snapPoints
            .filter(filterSnapPointsFabric(type, params, 'x'))
            .flatMap((point) => this.getSnapItems(point, snapDistance, 'x'));

        const xHits = filterSnapItemsHits(snapItemsX);

        const snapItemsY = snapPoints
            .filter(filterSnapPointsFabric(type, params, 'y'))
            .flatMap((point) => this.getSnapItems(point, snapDistance, 'y'));

        const yHits = filterSnapItemsHits(snapItemsY);

        const xAxisSnapLinesPre = this.toSnapLine(xHits, 'x', 0);
        const deltaX = xAxisSnapLinesPre.length ? xAxisSnapLinesPre[0].delta : 0;

        const yAxisSnapLines = this.toSnapLine(yHits, 'y', deltaX);
        const deltaY = yAxisSnapLines.length ? yAxisSnapLines[0].delta : 0;

        const xAxisSnapLines = this.toSnapLine(xHits, 'x', deltaY);

        if (!ignoreSnapping && (deltaX || deltaY)) {
            this.model.beginAccumulation();

            const elementsIdsToUpdate = this.currentElementIds.reduce((acc, id) => {
                const element = this.model.getElementById(id);

                if (!element) {
                    return acc;
                }

                getAllChildrenRecursively(element).forEach((child) => acc.add(child));

                return acc;
            }, new Set<BaseVisualElement>());

            if (type === 'resize') {
                const {
                    mousePosition,
                    position: startPosition,
                    dimension: startDimension,
                    recalculateSize,
                } = params as ElementResizeParams;
                const newMousePosition = mousePosition.add(new Position(deltaX, deltaY));
                const { position, dimension } = recalculateSize(newMousePosition);
                const groupBox = { startPosition, startDimension, position, dimension };

                elementsIdsToUpdate.forEach((element: BaseVisualElement | undefined) => {
                    const newElement = changeElementInsideBox(element, groupBox);
                    this.model.updateElement(element.id, newElement);
                });
            } else {
                elementsIdsToUpdate.forEach((element) => {
                    const position = element.position.add(new Position(deltaX, deltaY));
                    const newElement = { position };
                    this.model.updateElement(element.id, newElement);
                });
            }

            this.model.endAccumulation();
        }

        this.handler(xAxisSnapLines, yAxisSnapLines);
    }

    private toSnapLine(items: SnapItems, axis: 'x' | 'y', delta: number = 0): SnapLines {
        const lines: { [value: number]: SnapLine } = {};

        items.forEach((item) => {
            const points = new PointList(item.data.map((sp) => [sp.x, sp.y]));
            const value = Math.round(item.value);

            if (axis === 'x') {
                points.push([value, item.y + delta]);
            } else {
                points.push([item.x + delta, value]);
            }

            if (!lines[value]) {
                item.data.forEach((sp) => {
                    if (sp.type !== SnapPointTypes.CENTER) return;

                    const el = this.model.getElementById(sp.elementId);
                    if (axis === 'x') {
                        const y1 = el ? el.position.getY() : 0;
                        const y2 = y1 + (el ? el.dimension : this.model.getDimensions()).getHeight();
                        points.push([sp.x, y1]);
                        points.push([sp.x, y2]);
                    } else {
                        const x1 = el ? el.position.getX() : 0;
                        const x2 = x1 + (el ? el.dimension : this.model.getDimensions()).getWidth();
                        points.push([x1, sp.y]);
                        points.push([x2, sp.y]);
                    }
                });
                lines[value] = {
                    delta: item.value - item[axis],
                    points: points.toArray(),
                    start: points.min(axis),
                    end: points.max(axis),
                };
            } else {
                const line = lines[value];
                points.concat(line.points);
                line.points = points.toArray();
                line.start = points.min(axis);
                line.end = points.max(axis);
            }
        });

        return Object.values(lines);
    }
}
