import { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { IAsset } from '../Assets/IAsset';
import { ManipulableObject } from '../Shared/ManipulableObject';
import { type CreativeTypes } from '../../Enums/CreativeTypes';
import { type PreviewTypes } from '../../Enums/PreviewTypes';
import { type BasePropertyParams } from '../../types';

export class BaseProperty extends ManipulableObject {
    toObject(): BasePropertyParams {
        return super.toObject();
    }

    constructAsset(frameRate: number): void {}

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes): any {}

    isContainsAsset(arg0: IAsset): boolean {
        return false;
    }

    setAssetLoader(loader: IAssetsLoader): void {}
}
