import { type ShotParams, type VisualElement } from '../../types';

export class Shot {
    id: string | number;

    name: string;

    startFrame: number;

    thumbnailFrame: number;

    duration: number;

    displayOrder: number;

    elementIds: number[] = [];

    elements: VisualElement[] = [];

    constructor({ id, startFrame, thumbnailFrame, duration, displayOrder, elements, name }: ShotParams) {
        this.id = id;
        this.name = name;
        this.startFrame = startFrame;
        this.thumbnailFrame = thumbnailFrame;
        this.duration = duration;
        this.displayOrder = displayOrder;
        this.elementIds = elements;
    }

    setElements(elements: VisualElement[]): void {
        this.elements = elements;
    }

    getElements(): VisualElement[] {
        return this.elements;
    }

    setElementIds(elementIds: number[]) {
        this.elementIds = elementIds;
    }

    getElementIds(): number[] {
        return this.elementIds;
    }

    setThumbnailFrame(frame: number): void {
        this.thumbnailFrame = frame;
    }

    getThumbnailFrame(): number {
        return this.thumbnailFrame;
    }

    getDisplayFrame(): number {
        return this.startFrame + this.thumbnailFrame;
    }

    getStartFrame(): number {
        return this.startFrame;
    }

    getDisplayOrder(): number {
        return this.displayOrder;
    }

    getDuration(): number {
        return this.duration;
    }

    update(params: any): void {
        if (params.thumbnailFrame !== undefined) {
            this.thumbnailFrame = params.thumbnailFrame;
        }
    }

    toObject(): ShotParams {
        return {
            id: this.id,
            name: this.name,
            startFrame: this.startFrame,
            thumbnailFrame: this.thumbnailFrame,
            duration: this.duration,
            displayOrder: this.displayOrder,
            elements: this.elementIds,
        };
    }

    getCopy() {
        return new Shot(this.toObject());
    }
}
