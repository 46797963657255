export class AudioControl {
    private volume: number;

    private isMuted: boolean;

    constructor(volume = 0.2, muted = false) {
        this.volume = volume;
        this.isMuted = muted;
    }

    getVolume() {
        return this.volume;
    }

    setVolume(volume: number) {
        this.volume = volume;

        return this;
    }

    mute() {
        return this.setMutedStatus(true);
    }

    unmute() {
        return this.setMutedStatus(false);
    }

    toggleMute() {
        return this.setMutedStatus(!this.isMuted);
    }

    setMutedStatus(muted: boolean) {
        this.isMuted = muted;

        if (!muted && this.volume === 0) {
            this.volume = 0.2;
        }

        return this;
    }

    getMutedStatus() {
        return this.isMuted;
    }
}
