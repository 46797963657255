import { exportSpecificationTemplate } from '../../SpecificationParser/SpecificationExporter';
import { BaseMultiPageModel } from './BaseMultiPageModel';
import { GlobalAudio } from '../Properties/GlobalAudio';
import { PosterFrame } from '../Properties/PosterFrame';
import { PlaybackDuration } from '../Shared/PlaybackDuration';
import { Shot } from '../Shot/Shot';
import {
    type ContentPropertiesSettings,
    type ModelMetadata,
    type PageDuplicateParams,
    type UpdateGlobalPropertyOptions,
    type VideoModelsMetadata,
} from '../../types';
import { AudioControl } from '../Shared/AudioControl';
import { VideoModel } from './VideoModel';
import { CreativeTypes } from '../../Enums/CreativeTypes';
import { type CompModel } from '../CompModels/CompModel';

export class MultiPageVideoModel extends BaseMultiPageModel<VideoModel> {
    createModel(): VideoModel {
        const videoModel = new VideoModel();
        videoModel.setPlaybackDuration(new PlaybackDuration(375, 25));
        videoModel.setPosterFrame(
            new PosterFrame({
                id: Date.now() - 888 ** 2,
                name: 'DEFAULT_POSTER_FRAME_NAME',
                frame: 0,
            }),
        );
        videoModel.setGlobalAudioTrack1(
            new GlobalAudio({
                id: Date.now() - 999 ** 2 + 10,
                name: 'GLOBAL_AUDIO',
                locked: false,
                startFrame: 0,
            }),
        );
        videoModel.setGlobalAudioTrack2(
            new GlobalAudio({
                id: Date.now() - 999 ** 2 + 100,
                name: 'GLOBAL_AUDIO',
                locked: false,
                startFrame: 0,
            }),
        );
        videoModel.setShots([]);

        return videoModel;
    }

    setVideoModels(videoModels: VideoModel[]) {
        super.setModels(videoModels);

        return this;
    }

    getVideoModels(): VideoModel[] {
        return super.getModels() as VideoModel[];
    }

    getCurrentModel() {
        return super.getCurrentModel();
    }

    setVideoModelsMetaData(videoModelsMetadata: VideoModelsMetadata[]) {
        super.setModelsMetaData(videoModelsMetadata);

        return this;
    }

    getVideoModelsMetaData(): VideoModelsMetadata[] {
        return super.getModelsMetaData();
    }

    setup() {
        super.setup();

        return this;
    }

    setCompModel(frameIndex: number, compModel: CompModel): void {
        this.getCurrentModel().setCompModel(frameIndex, compModel);
    }

    createAllCompModels(startFrame = 0, endFrame = 0): void {
        this.getCurrentModel().createAllCompModels(startFrame, endFrame);
    }

    setGlobalAudioTrack1(globalAudioTrack1: GlobalAudio) {
        this.getCurrentModel().setGlobalAudioTrack1(globalAudioTrack1);

        return this;
    }

    getGlobalAudioTrack1(): GlobalAudio {
        return this.getCurrentModel().getGlobalAudioTrack1();
    }

    setGlobalAudioTrack2(globalAudioTrack2: GlobalAudio) {
        this.getCurrentModel().setGlobalAudioTrack2(globalAudioTrack2);

        return this;
    }

    getGlobalAudioTrack2(): GlobalAudio {
        return this.getCurrentModel().getGlobalAudioTrack2();
    }

    setPosterFrame(posterFrame: PosterFrame) {
        this.getCurrentModel().setPosterFrame(posterFrame);

        return this;
    }

    getPosterFrame(): PosterFrame {
        return this.getCurrentModel().getPosterFrame();
    }

    setPlaybackDuration(playbackDuration: PlaybackDuration) {
        this.getCurrentModel().setPlaybackDuration(playbackDuration);

        return this;
    }

    getPlaybackDuration(): PlaybackDuration {
        return this.getCurrentModel().getPlaybackDuration();
    }

    public addPage(name: string, width: number, height: number, format: ModelMetadata['format']): VideoModel {
        const model = this.createPage(name, width, height, format).setup();

        return super.addModel(model, name, width, height, format);
    }

    protected createPage(
        name: string,
        width: number,
        height: number,
        format: ModelMetadata['format'] = null,
    ): VideoModel {
        const model = super.createPage(name, width, height, format) as VideoModel;

        return model.setShots([
            new Shot({
                displayOrder: 0,
                duration: model.getPlaybackDuration().getDuration(),
                elements: [],
                id: '',
                name: '1',
                startFrame: 0,
                thumbnailFrame: 0,
            }),
        ]);
    }

    public duplicatePage(
        pageIndex: number,
        params: PageDuplicateParams,
        contentPropertiesSettings?: ContentPropertiesSettings,
    ) {
        const { model } = this.getPageDuplicate(pageIndex, contentPropertiesSettings);
        model.setup();

        return this.addDuplicatedPage(pageIndex, model, params);
    }

    protected getPageDuplicate(pageIndex: number, contentPropertiesSettings?: ContentPropertiesSettings) {
        const page = super.getPageDuplicate(pageIndex, contentPropertiesSettings);
        const model = page.model as VideoModel;
        const { idMap } = page;

        const newShots = model.getShots().map((shot) => {
            const newElementIds = shot.getElementIds().map((oldId) => idMap.get(oldId));

            return new Shot({
                ...shot.toObject(),
                elements: newElementIds,
            });
        });

        model.setShots(newShots);

        return { model, idMap };
    }

    setShots(shots: Shot[]) {
        this.getCurrentModel().setShots(shots);

        return this;
    }

    getShots(): Shot[] {
        return this.getCurrentModel().getShots();
    }

    updateShot(shotId: number, params: { thumbnailFrame: number }): void {
        this.getCurrentModel().updateShot(shotId, params);
    }

    replaceShots(parsedShots: Shot[]): void {
        this.getCurrentModel().replaceShots(parsedShots);
    }

    updatePlaybackDuration(duration: number, options: UpdateGlobalPropertyOptions = {}): void {
        this.getCurrentModel().updatePlaybackDuration(duration, options);
    }

    getAudioControl(): AudioControl {
        return this.getCurrentModel().getAudioControl();
    }

    disableCompModelCache() {
        throw new Error('Method "disableCompModelCache" only on the VideoModel.');
    }

    toObject() {
        return exportSpecificationTemplate(this, CreativeTypes.VIDEO);
    }

    getCopy(): MultiPageVideoModel {
        const copy = new MultiPageVideoModel();
        copy.copyFrom(this);

        return copy;
    }
}
