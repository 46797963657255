import React, { useEffect, useMemo, useRef } from 'react';
import TransitionControl from '../../../../TransitionControl/TransitionControl';
import { widthToDuration } from '../../tracks/helper';
import { AnimationControlStyled } from './AnimationControl.styled';

type Props = {
    onChangeEnd: () => void;
    trackDuration: () => void;
    elementStartFrame: number;
    elementDuration: number;
    animations: any;
    containerRef: any;
    trackContainerRef: any;
    animationsIsValid: boolean;
    disabled: boolean;
};

const pixelToPercentage = (trackWidth, subWidth) => {
    return (subWidth / trackWidth) * 100;
};

const overlappingModifierClass = 'TransitionControl--overlapping';

const overlapIfNeeded = (elementRef) => {
    const elementStyles = getComputedStyle(elementRef.current);

    if (parseFloat(elementStyles.width) <= 18) {
        elementRef.current.classList.add(overlappingModifierClass);
    } else {
        elementRef.current.classList.remove(overlappingModifierClass);
    }
};

const applyChanges = (trackContainerRef, elementRef, trackDuration, onChangeEnd) => {
    const trackStyles = getComputedStyle(trackContainerRef.current);
    const elementStyles = getComputedStyle(elementRef.current);

    const startFrame = widthToDuration(
        trackDuration,
        pixelToPercentage(parseFloat(trackStyles.width), parseFloat(elementStyles.left)),
    );
    const duration = widthToDuration(
        trackDuration,
        pixelToPercentage(parseFloat(trackStyles.width), parseFloat(elementStyles.width)),
    );

    onChangeEnd({
        startFrame: Math.max(0, startFrame),
        duration,
    });
};

const getAnimationsStyles = (animations, elementStartFrame, elementDuration, animationsIsValid, disabled) => {
    const leftPosition = pixelToPercentage(elementDuration, animations.startFrame - elementStartFrame);

    const animationsStyles = {
        left: `${Math.sign(leftPosition) >= 0 ? leftPosition : 0}%`,
        width: `${pixelToPercentage(elementDuration, animations.duration)}%`,
    };

    if (disabled) {
        // animationsStyles.left = 0;
        // animationsStyles.width = '100%';
    } else if (!animationsIsValid) {
        animationsStyles.left = 0;
        animationsStyles.width = 0;
    }

    return animationsStyles;
};

const AnimationControl = ({
    containerRef,
    trackContainerRef,
    trackDuration,
    elementStartFrame,
    elementDuration,
    onChangeEnd,
    animations,
    animationsIsValid,
    disabled,
    color,
    backgroundColor,
}: Props) => {
    const elementRef = useRef();

    useEffect(() => {
        overlapIfNeeded(elementRef);
    }, []);

    const animationsStyles = useMemo(
        () => getAnimationsStyles(animations, elementStartFrame, elementDuration, animationsIsValid, disabled),
        [animations, animations.startFrame, animations.duration, elementDuration, animationsIsValid, disabled],
    );

    return (
        <AnimationControlStyled>
            <TransitionControl
                label="Animate"
                onChange={() => overlapIfNeeded(elementRef)}
                onChangeEnd={() => applyChanges(trackContainerRef, elementRef, trackDuration, onChangeEnd)}
                elementRef={elementRef}
                containerRef={containerRef}
                elementDuration={elementDuration}
                duration={animations.duration}
                style={animationsStyles}
                color={color}
                backgroundColor={backgroundColor}
                disabled={disabled}
                startControl
            />
        </AnimationControlStyled>
    );
};

export default AnimationControl;
