import { mat2d } from 'gl-matrix';
import { BaseCompLayer, Region } from '@bynder-studio/render-core';
import { type ColorParams, toRGBA } from '@bynder-studio/misc';

export class CanvasCompLayer extends BaseCompLayer {
    canvas: HTMLCanvasElement | OffscreenCanvas;

    context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;

    checksum: string;

    constructor(el, id, region, absoluteRegion, canvas = null, scale = 1) {
        super(el, id, region, absoluteRegion, scale);

        if (canvas) {
            this.canvas = canvas;
            this.context = this.canvas.getContext('2d');
        } else {
            this.createCanvas(region, scale);
        }

        this.checksum = null;
    }

    createCanvas(region: Region, scale: number) {
        const layerWidth = Math.ceil(region.getWidth() * scale);
        const layerHeight = Math.ceil(region.getHeight() * scale);

        if (region.getWidth() < 1 || region.getHeight() < 1) {
            console.error('Surface size must be positive');

            return null;
        }

        this.canvas = new OffscreenCanvas(layerWidth, layerHeight);
        this.context = this.canvas.getContext('2d');

        // Correct for this by translating this layer with the difference.
        // NOTE: This does not seem necesssary and might even make graphics more blurry, so disabled for now
        // const xCorrection = (layerWidth - region.getWidth() * scale) / 2;
        // const yCorrection = (layerHeight - region.getHeight() * scale) / 2;

        // if(xCorrection || yCorrection){
        //     this.applyTranslation(xCorrection, yCorrection);
        //     this.region = this.region.getTranslation(-xCorrection, -yCorrection);
        //     this.absoluteRegion = this.absoluteRegion.getTranslation(-xCorrection, -yCorrection);
        // }
    }

    resetTransform() {
        this.context.resetTransform();
    }

    getTransform() {
        const t = this.context.getTransform();

        return mat2d.fromValues(t.a, t.b, t.c, t.d, t.e, t.f);
    }

    clear(color: ColorParams | null = null) {
        const ctx = this.context;
        // clearRect is affected by transform, so reset it before clearing.
        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.restore();

        if (color) {
            ctx.save();
            ctx.fillStyle = toRGBA(color);
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.restore();
        }
    }

    getWidth(): number {
        return this.canvas.width;
    }

    getHeight(): number {
        return this.canvas.height;
    }

    getContext() {
        return this.context;
    }

    save() {
        this.context.save();
    }

    restore() {
        this.context.restore();
    }

    applyTranslation(deltaX: number, deltaY: number) {
        this.context.translate(super.s(deltaX), super.s(deltaY));
    }

    delete() {}
}
