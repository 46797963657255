import React, { useEffect, useState } from 'react';
import { ShapeTypes } from '@bynder-studio/render-core';
import { Dropdown, Form } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconCircle, IconRectangle, IconVectorPen } from '@bynder/icons';
import { DropDownButton } from './index.styled';
import generateTestId from '~/helpers/testIdHelpers';

type Props = {
    disabled: boolean;
    selectedElement: any;
    creativeModel: any;
};

const testId = generateTestId('shots_shape__type');

export const ShapeSelect = ({ disabled, selectedElement, creativeModel }: Props) => {
    const { translate } = useTranslate();

    const { locked, shapeType, id } = selectedElement;

    const [selectedShapeType, setSelectedShapeType] = useState(shapeType);

    const shapeTitles = {
        [ShapeTypes.CUSTOM]: translate('editor.sidebar.shots.shape.select.custom'),
        [ShapeTypes.RECTANGLE]: translate('editor.sidebar.shots.shape.select.rectangle'),
        [ShapeTypes.ELLIPSE]: translate('editor.sidebar.shots.shape.select.ellipse'),
    };

    const setShapeType = (shapeKind) => {
        creativeModel.updateElement(id, { shapeType: shapeKind });
        setSelectedShapeType(shapeKind);
    };

    useEffect(() => {
        setSelectedShapeType(shapeType);
    }, [selectedElement?.shapeType, selectedElement?.id]);

    return (
        <Form.Group>
            <Form.Label>{translate('editor.sidebar.shots.shape.label')}</Form.Label>
            <Dropdown
                trigger={({ isOpen, ...triggerProps }) => (
                    <DropDownButton
                        isPressed={isOpen}
                        icon={
                            selectedShapeType.toUpperCase() === ShapeTypes.CUSTOM ? (
                                <IconVectorPen />
                            ) : selectedShapeType === ShapeTypes.RECTANGLE ? (
                                <IconRectangle />
                            ) : (
                                <IconCircle />
                            )
                        }
                        rightIcon={<Dropdown.Arrow />}
                        isDisabled={locked || disabled || selectedShapeType.toUpperCase() === ShapeTypes.CUSTOM}
                        isFullWidth
                        {...triggerProps}
                        {...testId}
                    >
                        {shapeTitles[selectedShapeType]}
                    </DropDownButton>
                )}
                position="bottom"
            >
                <Dropdown.Item
                    isChecked={selectedShapeType.toUpperCase() === ShapeTypes.RECTANGLE}
                    onClick={() => setShapeType(ShapeTypes.RECTANGLE)}
                    icon={<IconRectangle />}
                >
                    {translate('editor.sidebar.shots.shape.select.rectangle')}
                </Dropdown.Item>
                <Dropdown.Item
                    isChecked={selectedShapeType.toUpperCase() === ShapeTypes.ELLIPSE}
                    onClick={() => setShapeType(ShapeTypes.ELLIPSE)}
                    icon={<IconCircle />}
                >
                    {translate('editor.sidebar.shots.shape.select.ellipse')}
                </Dropdown.Item>
            </Dropdown>
        </Form.Group>
    );
};

export default ShapeSelect;
