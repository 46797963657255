import React, { Fragment, useState } from 'react';
import { BaseMultiPageModel, CreativeTypes, ModelMetadata } from '@bynder-studio/render-core';
import { ContentPropertiesSettings } from '@bynder-studio/render-core/src/Models/BaseMultiPageModel/BaseMultiPageModel';
import { Button, Checkbox, CheckboxGroup, Dropdown, Form, Input, InputField, Modal } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import useSizes from 'packages/pages/designCreateModal/components/Blank/useSizes';
import { DropdownButton } from 'packages/pages/editor/RightSideMenu/Globals/SizeSelector/index.styled';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import generateTestId from '~/helpers/testIdHelpers';
import modalContainer from 'packages/common/modalContainer';

type PageDuplicateModalProps = {
    pageMetaData: ModelMetadata;
    creativeModel: BaseMultiPageModel;
    onToggle: (open: boolean) => void;
    handleConfirm: (
        name: string,
        { width, height, format }: { width: number; height: number; format: string },
        contentPropertiesSettings: ContentPropertiesSettings,
    ) => void;
    creativeType: CreativeTypes;
};

enum DimensionType {
    HEIGHT = 'HEIGHT',
    WIDTH = 'WIDTH',
}

const PageDuplicateModal = ({
    pageMetaData,
    creativeModel,
    onToggle,
    handleConfirm,
    creativeType,
}: PageDuplicateModalProps) => {
    const { isPlatformAllowed } = useAccessRights();
    const dimension = creativeModel?.getDimensions();

    const [width, setWidth] = useState(dimension.width);
    const [height, setHeight] = useState(dimension.height);
    const [isValidated, setIsValidated] = useState(true);

    const [videoSearch, setVideoSearch] = useState('');
    const [imageSearch, setImageSearch] = useState('');
    const { translate } = useTranslate();
    const dropdownTestId = generateTestId('multisize_format');
    const sizeWidthTestId = generateTestId('multisize_format_width');
    const sizeHeightTestId = generateTestId('multisize_format_height');

    const { videoCategories, imageCategories, findByFormatKey, customSizeCategory } = useSizes(
        videoSearch,
        imageSearch,
    );

    const isVideoCreative = creativeType === CreativeTypes.VIDEO;
    const sizeCategories = isVideoCreative ? videoCategories : imageCategories;
    const MAX_INPUT_SIZE = 120;

    const matchFormatChangeWithSelector = (format) => findByFormatKey(sizeCategories, format);

    const [selected, setSelected] = useState(matchFormatChangeWithSelector(pageMetaData.format));
    const [pageName, setPageName] = useState(pageMetaData.name || '');

    const isCreateSizeDisabled = !pageName.length || pageName.length > MAX_INPUT_SIZE || !isValidated;

    const onSizeSelect = (item) => {
        const { size } = item;
        setSelected(item);
        setWidth(size.width);
        setHeight(size.height);

        if (pageName === selected.label || !pageName.length) {
            setPageName(item.label);
        }
    };

    const handlePageDuplicate = () => {
        if (!isValidated) {
            return;
        }

        handleConfirm(
            pageName.length ? pageName : selected.label,
            { width, height, format: selected.size.format },
            contentPropertiesSettings,
        );
    };

    const onChange = (type, value) => {
        setIsValidated(false);

        if (type === DimensionType.HEIGHT) {
            setHeight(value);
        } else {
            setWidth(value);
        }
    };

    const onBlur = () => {
        const newDimensions = { width, height };
        const correctDimensions = editorAutocorrect('global_dimensions', newDimensions, creativeType);

        if (
            correctDimensions.width === dimension.width &&
            correctDimensions.height === dimension.height &&
            selected.label !== 'Custom'
        ) {
            return;
        }

        onSizeSelect(customSizeCategory);
        setWidth(correctDimensions.width);
        setHeight(correctDimensions.height);

        setIsValidated(true);
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            onBlur();
        }
    };

    const [contentPropertiesSettings, setContentPropertiesSettings] = useState<ContentPropertiesSettings>({
        video: false,
        image: false,
        text: false,
        shape: false,
    });
    const { video, ...contentPropertiesSettingsWithoutVideo } = contentPropertiesSettings;

    const contentPropertiesSettingsSomeSelected = Object.values(
        isVideoCreative ? contentPropertiesSettings : contentPropertiesSettingsWithoutVideo,
    ).some((item) => Boolean(item));
    const contentPropertiesSettingsAllSelected = Object.values(
        isVideoCreative ? contentPropertiesSettings : contentPropertiesSettingsWithoutVideo,
    ).every((item) => Boolean(item));

    const onContentPropertiesSettingsChangeAll = () => {
        setContentPropertiesSettings({
            video: !contentPropertiesSettingsAllSelected,
            image: !contentPropertiesSettingsAllSelected,
            text: !contentPropertiesSettingsAllSelected,
            shape: !contentPropertiesSettingsAllSelected,
        });
    };
    const updateContentPropertiesSettings = (name: keyof typeof contentPropertiesSettings) => {
        setContentPropertiesSettings((prev) => {
            return {
                ...prev,
                [name]: !prev[name],
            };
        });
    };

    return (
        <Modal
            container={modalContainer}
            isOpen
            title={translate('editor.page.duplicate.modal.title')}
            onClose={() => onToggle(false)}
            actionPrimary={
                <Button
                    variant="primary"
                    onClick={handlePageDuplicate}
                    isDisabled={isCreateSizeDisabled}
                    data-testid="editor : modal : duplicate page button"
                >
                    {translate('editor.page.duplicate.modal.duplicate')}
                </Button>
            }
            actionSecondary={
                <Button
                    variant="secondary"
                    onClick={() => onToggle(false)}
                    data-testid="editor : modal : cancel button"
                >
                    {translate('editor.page.duplicate.modal.cancel')}
                </Button>
            }
        >
            <InputField
                value={pageName}
                onChange={(name) => setPageName(name)}
                isInvalid={pageName.length > MAX_INPUT_SIZE}
                placeholder={translate('editor.page.duplicate.modal.input.placeholder')}
                hasClearButton
                label=""
                helperText={pageName.length > MAX_INPUT_SIZE && translate('input.error.max.length')}
            />
            <Form.Group>
                <Dropdown
                    trigger={({ isOpen, ...triggerProps }) => (
                        <DropdownButton
                            isPressed={isOpen}
                            isFullWidth
                            variant="secondary"
                            icon={selected.icon}
                            rightIcon={<Dropdown.Arrow />}
                            aria-label={selected.label}
                            {...dropdownTestId}
                            {...triggerProps}
                        >
                            {selected.label}
                        </DropdownButton>
                    )}
                    position="bottom"
                >
                    <Dropdown.SearchInput
                        value={isVideoCreative ? videoSearch : imageSearch}
                        onChange={(value) => (isVideoCreative ? setVideoSearch(value) : setImageSearch(value))}
                        placeholder={translate('editor.page.duplicate.modal.search.placeholder')}
                        aria-label={translate('editor.page.duplicate.modal.search.label')}
                        data-testid="multipage_selector_search_input"
                    />
                    {sizeCategories.map((category, index) => (
                        <Fragment key={category.label}>
                            <Dropdown.SectionTitle>{category.label}</Dropdown.SectionTitle>
                            {category.items.map((item) => (
                                <Dropdown.Item
                                    key={item.label}
                                    icon={item.icon}
                                    isChecked={item.label === selected.label}
                                    onClick={() => {
                                        if (selected.size.format === item.size.format) {
                                            return;
                                        }

                                        onSizeSelect(item);
                                    }}
                                    isDisabled={!item.size.width || !item.size.height}
                                >
                                    {item.label}
                                </Dropdown.Item>
                            ))}
                            {sizeCategories.length - 1 !== index ? <Dropdown.Divider /> : null}
                        </Fragment>
                    ))}
                    {!sizeCategories.length ? (
                        <Dropdown.Text>{translate('editor.header.page.modal.search.no_results')}</Dropdown.Text>
                    ) : null}
                </Dropdown>
            </Form.Group>
            <Form.Row>
                <Form.Group>
                    <Input
                        type="number"
                        id="child-size-selector-width"
                        aria-label="child-size-selector-width"
                        aria-describedby="multisize_selector_width_message"
                        value={width.toString()}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={(newWidth) => onChange(DimensionType.WIDTH, newWidth)}
                        suffix="px"
                        prefix="W"
                        {...sizeWidthTestId}
                    />
                </Form.Group>
                <Form.Group>
                    <Input
                        type="number"
                        id="child-size-selector-height"
                        aria-label="child-size-selector-height"
                        aria-describedby="multisize_selector_height_message"
                        value={height.toString()}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={(newHeight) => onChange(DimensionType.HEIGHT, newHeight)}
                        suffix="px"
                        prefix="H"
                        {...sizeHeightTestId}
                    />
                </Form.Group>
            </Form.Row>
            {isPlatformAllowed([features.CONTENT_PROPERTIES]) && (
                <Form.Group>
                    <CheckboxGroup>
                        <Checkbox
                            isChecked={contentPropertiesSettingsAllSelected}
                            onChange={onContentPropertiesSettingsChangeAll}
                            isIndeterminate={
                                contentPropertiesSettingsSomeSelected && !contentPropertiesSettingsAllSelected
                            }
                        >
                            {translate('editor.page.duplicate.modal.contentPropertiesSettings.all')}
                        </Checkbox>
                    </CheckboxGroup>
                    {contentPropertiesSettingsSomeSelected && (
                        <CheckboxGroup>
                            {isVideoCreative && (
                                <Checkbox
                                    isChecked={contentPropertiesSettings.video}
                                    onChange={() => updateContentPropertiesSettings('video')}
                                >
                                    {translate('editor.page.duplicate.modal.contentPropertiesSettings.video')}
                                </Checkbox>
                            )}
                            <Checkbox
                                isChecked={contentPropertiesSettings.image}
                                onChange={() => updateContentPropertiesSettings('image')}
                            >
                                {translate('editor.page.duplicate.modal.contentPropertiesSettings.image')}
                            </Checkbox>
                            <Checkbox
                                isChecked={contentPropertiesSettings.text}
                                onChange={() => updateContentPropertiesSettings('text')}
                            >
                                {translate('editor.page.duplicate.modal.contentPropertiesSettings.text')}
                            </Checkbox>
                            <Checkbox
                                isChecked={contentPropertiesSettings.shape}
                                onChange={() => updateContentPropertiesSettings('shape')}
                            >
                                {translate('editor.page.duplicate.modal.contentPropertiesSettings.shape')}
                            </Checkbox>
                        </CheckboxGroup>
                    )}
                </Form.Group>
            )}
        </Modal>
    );
};

export default PageDuplicateModal;
