import React, { createContext, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MultiPageVideoModel,
    PreviewTypes,
    SpecificationExporter,
    ValidationManagerForMultiPage,
    VideoWebSpecificationParser,
} from '@bynder-studio/render-core';
import { creativeModelFactory } from '@bynder-studio/render-web';
import { catchResponseError } from 'packages/helpers/helpers';
import { dataURItoArrayBuffer } from '~/helpers/helpers';
import { prepareDataForSave } from '~/common/editor/editor.bloc';
import useForceUpdate from '~/hooks/useForceUpdate';
import { sendAmplitudeDesignEvent } from '~/store/amplitude/actions';
import { AMPLITUDE_TYPES } from '~/store/amplitude/constants';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { getUserFonts } from '~/store/user/user.actions';
import defaultFontFile from '../../../../src/assets/fonts/SourceSansPro-Regular.ttf';
import ShotsCalculateService from 'packages/services/ShotsCalculate';
import { flattenTree } from '~/common/editor/helpers/elementtree';
import { getShotsFromPageAccordingDuration } from '~/helpers/template';
// import VariationSetsService from '~/services/VariationSetsService';

const defaultFontBlob = dataURItoArrayBuffer(defaultFontFile);

export const DesignContext = createContext({});

export function DesignProvider({
    children,
    template,
    fonts,
    textStyles,
    contentProperties,
    creativeType,
    creativeId,
    creativeVersionId,
    creativeName,
    isMultiVersionCreative,
    downloadModalRef,
    uploadModalRef,
}) {
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const data = useRef({
        loading: true,
        isUpdatedByVariation: false,
        creativeModel: undefined,
        assetLoader: undefined,
        creativeModelFactory: undefined,
        validationManager: undefined,
        canvasRenderer: undefined,
        baseDuration: 0,
        updatedPlaybackDuration: 0,
        creativeType,
        template,
        creativeId,
        creativeVersionId,
        creativeName,
        fonts,
        textStyles,
        isMultiVersionCreative,
    });
    const customerId = useSelector(customerIdSelector);

    const openDownloadModal = useCallback(() => {
        downloadModalRef.current?.open();
    }, [downloadModalRef]);

    const openUploadModal = useCallback(() => {
        uploadModalRef.current?.open();
    }, [uploadModalRef]);

    const setUpdatedByVariation = useCallback(() => {
        data.current.isUpdatedByVariation = true;
        forceUpdate();
    }, [forceUpdate]);

    // const getFirstVariationData = useCallback(
    //     () =>
    //         new Promise((resolve, reject) =>
    //             VariationSetsService.fetchVariationSetsByAggregate(creativeVersionId, {
    //                 groupBy: 'variation',
    //                 limit: 1,
    //                 includeProperties: true,
    //                 includeHasDeletedAssetFlag: true,
    //                 orderBy: 'updated',
    //                 sortingOrder: 'DESC',
    //                 page: 0,
    //             })
    //                 .then(({ json: { items = [] } }) => resolve(items))
    //                 .catch(reject),
    //         ),
    //     [creativeVersionId, fonts, template],
    // );

    const initCreativeModel = useCallback(async () => {
        // const templateToUse = await updateTemplateByVariation();

        creativeModelFactory(creativeType, {
            template,
            defaultFontBlob,
            fontFamilies: fonts,
            textStyles,
            contentProperties,
        }).then((modelFactory) => {
            const creativeModel = modelFactory.getCreativeModel();
            data.current.creativeModelFactory = modelFactory;
            data.current.creativeModel = creativeModel;
            data.current.assetLoader = modelFactory.getAssetLoader();
            data.current.validationManager = new ValidationManagerForMultiPage(
                creativeModel,
                creativeType,
                PreviewTypes.CONTENT,
            );
            data.current.loading = false;
            data.current.validationManager.validate();
            data.current.baseDuration =
                creativeType === 'VIDEO' ? creativeModel.getPlaybackDuration()?.getDuration() : 0;
            data.current.updatedPlaybackDuration = template.pages[creativeModel.getCurrentPageIndex()].duration;

            forceUpdate();
        });
    }, [creativeType, fonts, template, textStyles, contentProperties]);

    useLayoutEffect(() => {
        dispatch(
            sendAmplitudeDesignEvent({
                eventType: AMPLITUDE_TYPES.OPEN_DESIGN,
                design: template,
            }),
        );

        initCreativeModel();

        return () => {
            if (!data.current) {
                return;
            }

            data.current.validationManager?.unsubscribe();
        };
    }, [forceUpdate]);

    const recalculateShots = useCallback(
        (newDurations = []) => {
            const { creativeModel } = data.current;
            const { height, width } = creativeModel.getDimensions();
            const selectedPageIndex = creativeModel.getCurrentPageIndex();
            const model = creativeModel.models[selectedPageIndex];

            const newDurationMap = {};
            newDurations.forEach(({ elementId, duration }) => {
                newDurationMap[elementId] = duration;
            });

            const updateDurations = (elements) => {
                Object.values(elements).forEach((element) => {
                    if (element.id in newDurationMap) {
                        element.properties.duration = newDurationMap[element.id];
                    }

                    if (element.children) {
                        updateDurations(element.children);
                    }
                });
            };

            // todo, improve after export implementation
            const rawElements = SpecificationExporter.exportElements(model.getElements());

            updateDurations(rawElements);
            const { elements } = prepareDataForSave(false, rawElements);

            return new Promise((resolve) => {
                ShotsCalculateService.calculate({
                    height,
                    width,
                    duration: data.current.baseDuration,
                    creativeId,
                    doDynamicLengthAdjustments: true,
                    elements,
                    pageId: Number(creativeModel.getCurrentPageId()),
                })
                    .then(({ json, status }) => {
                        if (status === 200) {
                            data.current.updatedPlaybackDuration = json.duration;
                            model.updatePlaybackDuration(json.duration);
                            forceUpdate();

                            const { templateElements } = json;
                            const timelineElements = Object.values(flattenTree(templateElements))
                                .filter((element) =>
                                    ['IMAGE', 'VIDEO', 'TEXT', 'SHAPE', 'GROUP'].includes(element.type),
                                )
                                .map((el) => ({
                                    id: el.id,
                                    type: el.type,
                                    name: el.name,
                                    startFrame: el.properties.startFrame,
                                    duration: el.properties.duration,
                                    renderOrder: el.properties.renderOrder,
                                }));

                            resolve(newDurations.length ? timelineElements : []);

                            Promise.resolve().then(() => {
                                setTimeout(() => {
                                    // cutting elements duration that is longer than the page duration
                                    // and recalculate shot duration
                                    // this should be done in the backend, but we have what we have
                                    json.shots = getShotsFromPageAccordingDuration({
                                        ...json,
                                        elements: json.templateElements,
                                    });

                                    const shots = VideoWebSpecificationParser.parseShots(json);
                                    creativeModel.replaceShots(shots);
                                    forceUpdate();
                                }, 0);
                            });
                        } else {
                            resolve([]);
                        }
                    })
                    .catch(catchResponseError);
            });
        },
        [creativeType, forceUpdate],
    );

    const initCanvasRenderer = useCallback(
        (canvasWrapper, currentFrame = 0) => {
            const canvasRenderer = data.current.creativeModelFactory.getCreativeRenderer(canvasWrapper);
            canvasRenderer.init().then(async () => {
                if (creativeType === 'VIDEO') {
                    await canvasRenderer.setCurrentFrame(currentFrame);
                }
                data.current.canvasRenderer = canvasRenderer;
                forceUpdate();
            });
        },
        [forceUpdate],
    );

    const fontLoadErrorRetry = useCallback(() => {
        dispatch(getUserFonts(customerId));
    }, []);

    useEffect(() => {
        const { creativeModel, canvasRenderer, assetLoader } = data.current;
        if (!creativeModel || !canvasRenderer || !assetLoader) {
            return;
        }

        const handleCurrentSizeChange = () => {
            if (canvasRenderer) {
                canvasRenderer.calculateScaleRatio();

                // canvasRenderer.redraw();
                const compModel = canvasRenderer.creativeModel.getCompModel(canvasRenderer.frameIndex);
                canvasRenderer.drawCompModel(compModel);

                canvasRenderer?.pausePlayback?.();

                if (creativeModel instanceof MultiPageVideoModel) {
                    const pageDuration =
                        creativeModel.getPlaybackDuration().getDuration() ||
                        template.pages[creativeModel.getCurrentPageIndex()].duration;

                    // In case active page duration is less than current frame index
                    if (canvasRenderer.frameIndex > pageDuration) {
                        canvasRenderer.frameIndex = 0;
                    }
                }
            }
        };

        creativeModel.getEventEmitter().on('currentPageChange', handleCurrentSizeChange);
        assetLoader.eventEmitter.on('font.load.error', fontLoadErrorRetry);

        return () => {
            creativeModel.getEventEmitter().off('currentPageChange', handleCurrentSizeChange);
            canvasRenderer?.pausePlayback?.();
            assetLoader.eventEmitter.off('font.load.error', fontLoadErrorRetry);
        };
    }, [data.current.canvasRenderer, data.current.creativeModel, forceUpdate]);

    const setCurrentPageIndex = useCallback(
        (idx) => {
            data.current?.creativeModel.setCurrentPageIndex(idx);
            forceUpdate();
        },
        [forceUpdate],
    );

    const setPausePlayback = useCallback(() => {
        if (creativeType === 'VIDEO') {
            data.current.canvasRenderer?.pausePlayback?.();
        }
    }, [creativeType]);

    const setPlayPlayback = useCallback(() => {
        if (creativeType === 'VIDEO') {
            setTimeout(() => {
                data.current.canvasRenderer?.startPlayback?.();
            }, 100);
        }
    }, [creativeType]);

    const isPlaying = useCallback(() => {
        if (creativeType === 'VIDEO') {
            return data.current.canvasRenderer?.getIsPlaying();
        }

        return false;
    }, [creativeType]);

    useEffect(() => {
        const onVisibilityChange = () => {
            if (document.visibilityState !== 'visible') {
                return;
            }

            data.current?.canvasRenderer?.redraw();
        };

        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, [isPlaying, data.current.canvasRenderer]);

    if (data.current.loading) {
        return null;
    }

    const dimension = data.current.creativeModel?.getDimensions();
    const width = dimension?.getWidth();
    const height = dimension?.getHeight();

    const value = {
        ...data.current,
        width,
        height,
        initCanvasRenderer,
        openDownloadModal,
        openUploadModal,
        recalculateShots,
        setCurrentPageIndex,
        setUpdatedByVariation,
        setPausePlayback,
        setPlayPlayback,
        isPlaying,
    };

    return <DesignContext.Provider value={value}>{children}</DesignContext.Provider>;
}
