import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDesignScene } from '../../hooks/useDesignScene';
import { usePlayHeadDragging } from './usePlayHeadDragging';
import { useRedLine } from './useRedLine';
import { type GeneralRefType, getFrameIndexByPosX, getPositionByFrame } from './utils';
import { RedLineStyled, TimelineShotContainerStyled } from './TimelineShotContainer.styled';
import sceneDefault from '../../../../../src/assets/images/shot.png';

type TimelineShotContainerProps = {
    isPlaying: boolean;
    currentFrame: number;
    selectFrame: (frame: number) => void;
    shots: any[];
    selectedShotIndex: number;
    selectShot: (index: number, value: boolean) => void;
    timelineWrapperRef: GeneralRefType<HTMLDivElement>;
};

function fromPxToPercents(wrapperRef: GeneralRefType<HTMLDivElement>, px): number {
    const { width } = wrapperRef.current.getBoundingClientRect();

    return (100 / width) * px;
}

function TimelineShotContainer({
    isPlaying,
    currentFrame,
    selectFrame,
    shots,
    selectedShotIndex,
    selectShot,
    timelineWrapperRef,
}: TimelineShotContainerProps) {
    const lastLeftFrame = useRef<number>(-1);
    const timelineInnerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const redLineRef = useRef<HTMLDivElement>(null);
    const playHeadRef = useRef<HTMLDivElement>(null);
    const shotsRef = useRef([] as GeneralRefType<HTMLDivElement>[]);
    const getShotRef = useCallback((index) => (element) => (shotsRef.current[index] = element), []);
    const draggingData = usePlayHeadDragging(
        lastLeftFrame,
        selectFrame,
        shots,
        shotsRef,
        playHeadRef,
        timelineInnerRef,
    );
    const redLine = useRedLine(redLineRef, containerRef, 0);
    const duration = useMemo(() => shots.reduce((acc, shot) => acc + shot.duration, 0), [shots]);
    const { sceneImages } = useDesignScene();
    shotsRef.current = [];

    const handleMouseMoveX = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (draggingData.current.dragging) {
            redLineRef.current?.removeAttribute('style');

            return;
        }

        if (redLineRef.current === null && timelineWrapperRef.current === null) {
            return;
        }

        redLine.handleMouseMove(e);
    };

    const handleShotClick = (index: number, e: React.MouseEvent): void => {
        if (timelineWrapperRef.current && playHeadRef.current && timelineInnerRef.current) {
            const bounds = timelineInnerRef.current.getBoundingClientRect();
            const newPosX = e.clientX - bounds.left + timelineWrapperRef.current.scrollLeft;
            const leftInPercents = fromPxToPercents(timelineInnerRef, newPosX);
            const frame = getFrameIndexByPosX(newPosX, shots, shotsRef);

            if (frame > -1) {
                selectShot(index, false);
                playHeadRef.current?.setAttribute('style', `left: ${leftInPercents}%; display: block;`);
                playHeadRef.current.setAttribute('data-pos-percent', `${leftInPercents}`);
                lastLeftFrame.current = frame;
                selectFrame(frame);
            }
        }
    };

    const handleMouseLeave = () => {
        redLineRef.current?.removeAttribute('style');
    };

    useEffect(() => {
        let observer: IntersectionObserver | null = new IntersectionObserver(
            function (entries) {
                if (entries[0].isIntersecting === false && isPlaying) {
                    playHeadRef.current?.scrollIntoView({ inline: 'start', behavior: 'smooth' });
                }
            },
            { threshold: [1] },
        );

        if (playHeadRef.current) {
            observer.observe(playHeadRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        };
    }, [isPlaying]);

    useEffect(() => {
        if (draggingData.current.dragging || currentFrame >= duration) {
            return;
        }

        if (playHeadRef.current && timelineInnerRef.current) {
            const posX = getPositionByFrame(currentFrame, shots, shotsRef);
            const leftInPercents = fromPxToPercents(timelineInnerRef, posX);

            if (isPlaying && currentFrame <= lastLeftFrame.current) return;

            playHeadRef.current.setAttribute('style', `left: ${leftInPercents}%; display: block;`);
            playHeadRef.current.setAttribute('data-pos-percent', `${leftInPercents}`);
            lastLeftFrame.current = -1;
        }
    }, [currentFrame, shots, isPlaying, duration]);

    return (
        <TimelineShotContainerStyled ref={containerRef}>
            <RedLineStyled ref={redLineRef} />
            <div
                ref={timelineWrapperRef}
                className="timeline--shot-wrapper d-flex"
                onMouseMove={handleMouseMoveX}
                onMouseLeave={handleMouseLeave}
            >
                <div className="timeline--inner-wrapper" ref={timelineInnerRef}>
                    <div ref={playHeadRef} id="pLayHeadDragger" className="timeline--playhead">
                        <div className="timeline--playhead-drag-punch" id="Playhead-dragger-punch" />
                    </div>
                    {shots.map((shot, index) => (
                        <div
                            ref={getShotRef(index)}
                            key={shot.id}
                            id={shot.id}
                            onClick={(e) => handleShotClick(index, e)}
                            style={{ width: `calc(${parseFloat(((100 * shot.duration) / duration).toFixed(2))}%` }}
                            className={'d-flex align-items-center timeline--shot-item-container'}
                        >
                            <div
                                className={
                                    'timeline--shot-item d-flex align-items-center ' +
                                    (selectedShotIndex === index ? 'active' : '')
                                }
                            >
                                <img
                                    src={sceneImages[index] || sceneDefault}
                                    alt={`Shot ${index + 1}`}
                                    draggable={false}
                                />
                                <span className="timeline--shot-item-name">Scene {index + 1}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </TimelineShotContainerStyled>
    );
}

export default TimelineShotContainer;
