import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';

class GroupsService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/weadapt-users/bynder/groups') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getGroups() {
        return this.gateway.get('');
    }
}

export default new GroupsService();
