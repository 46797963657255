import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as v from 'valibot';
import { notify } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import FigmaService from 'packages/services/FigmaService';
import { catchResponseError } from 'packages/helpers/helpers';
import { ResponseModel } from '~/services/connectors/ResponseModel';
import useLocalStorage from 'packages/hooks/useLocalStorage';
import { FigmaLink } from './types';
import { FigmaFile } from 'packages/store/figma/types';
import { getFigmaFile } from 'packages/store/figma/figma.selectors';
import { setFigmaFile } from 'packages/store/figma/figma.actions';

const MAX_LINKS_IN_HISTORY = 10;

// increment this number if the structure of the stored data changes
// to avoid conflicts with the old data
// users will lose their history, but it's better than crashing the app
const LINKS_STORE_VERSION = 2;

const ERROR_CAUSES = {
    403: 'not authorized',
    400: 'bad request',
} as const;

export default function useFigmaFiles(figmaEmail: string) {
    const value = useSelector(getFigmaFile);
    const dispatch = useDispatch();
    const { translate } = useTranslate();

    const {
        values: links,
        setValue: setLink,
        removeValues: removeLinks,
        clear: clearLinks,
    } = useLocalStorage(`figma-files:${LINKS_STORE_VERSION}:${figmaEmail}`, parseLinks);

    const fetchFileReqRef = useRef<Promise<ResponseModel> | null>(null);

    const fetchFile = useCallback(
        async (link: string) => {
            if (fetchFileReqRef.current) {
                FigmaService.abortRequest(fetchFileReqRef.current);
            }

            dispatch(setFigmaFile({ status: 'loading' }));
            const isValid = validateFigmaShareLink(link);

            if (!isValid) {
                dispatch(setFigmaFile({ status: 'not found' }));

                return;
            }

            const fileKey = extractFigmaFileKey(link);

            const req = FigmaService.getFile(fileKey);
            fetchFileReqRef.current = req;

            const res = await req.catch(catchResponseError);

            // if the request was aborted
            if (!res) {
                return;
            }

            if (res.status === 404) {
                dispatch(setFigmaFile({ status: 'not found' }));

                return;
            }

            if (res.status >= 400) {
                dispatch(setFigmaFile({ status: 'error', cause: ERROR_CAUSES?.[res.status] }));

                if (res.status === 400 && res.json?.status === 'FIGMA_RENDER_TIMEOUT') {
                    notify({
                        variant: 'error',
                        title: translate('modal.design.create.figma_import.error.timeout'),
                    });

                    return;
                }

                if (res.status === 400 && res.json?.status === 'FIGMA_RATE_LIMIT_EXCEEDED') {
                    notify({
                        variant: 'error',
                        title: translate('modal.design.create.figma_import.error.rate-limit'),
                    });

                    return;
                }

                notify({
                    variant: 'error',
                    title: translate('modal.design.create.figma_import.error'),
                });

                return;
            }

            const figmaFile = {
                ...res.json,
                key: fileKey,
            } as FigmaFile;

            dispatch(setFigmaFile({ status: 'success', value: figmaFile }));
            setLink(fileKey, { link, key: fileKey, name: figmaFile.name, createdAt: Date.now() });
        },
        [dispatch, setLink, translate],
    );

    useEffect(() => {
        return () => {
            if (fetchFileReqRef.current) {
                FigmaService.abortRequest(fetchFileReqRef.current);
            }

            dispatch(setFigmaFile({ status: 'not started' }));
        };
    }, [dispatch]);

    useEffect(() => {
        const keysToRemove = Object.values(links)
            .sort((a, b) => b.createdAt - a.createdAt)
            .filter((_, idx) => idx >= MAX_LINKS_IN_HISTORY)
            .map((item) => item.key);

        if (keysToRemove.length > 0) {
            removeLinks(keysToRemove);
        }
    }, [links, removeLinks]);

    const cancelRequest = () => {
        if (fetchFileReqRef.current) {
            FigmaService.abortRequest(fetchFileReqRef.current);
            dispatch(setFigmaFile({ status: 'not started' }));
        }
    };

    return {
        value,
        fetchFile,
        links,
        clearLinks,
        validateLink: validateFigmaShareLink,
        cancelRequest,
    };
}

// share figma link
// https://www.figma.com/design/<file key>/<file name>

function extractFigmaFileKey(shareLink: string) {
    const url = new URL(shareLink);

    return url.pathname.split('/')[2];
}

function validateFigmaShareLink(shareLink: string) {
    if (!URL.canParse(shareLink)) {
        return false;
    }

    const url = new URL(shareLink);

    return [
        url.hostname === 'www.figma.com',
        url.pathname.match(/\/design\/[^/]+\/[^/]+/),
        url.pathname.split('/').length > 2,
    ].every((check) => check);
}

const linkSchema = v.object({
    link: v.string(),
    name: v.string(),
    key: v.string(),
    createdAt: v.number(),
});

const recordSchema = v.record(v.string(), v.unknown());

function parseLinks(data: unknown) {
    const parsedData = v.safeParse(recordSchema, data);

    if (!parsedData.success) {
        return {};
    }

    const record: Record<string, FigmaLink> = {};

    Object.entries(parsedData.output).forEach(([key, value]) => {
        const parsedLink = v.safeParse(linkSchema, value);

        if (!parsedLink.success) {
            return;
        }

        record[key] = parsedLink.output;
    });

    return record;
}
