import moment from 'moment';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

// type CreationDateLimitType = Brand<string, 'frame'>;

const timeToSeconds = (dateTime: string | 'DDTHH:mm:ss'): number => {
    const [days, time] = dateTime.split('T');
    const [hours, minutes, seconds] = time.split(':');

    const daysInSeconds = Number(days) * 24 * 60 * 60;
    const hoursInSeconds = Number(hours) * 60 * 60;
    const minutesInSeconds = Number(minutes) * 60;

    return daysInSeconds + hoursInSeconds + minutesInSeconds + Number(seconds);
};

const secondsToFriendlyTime = (seconds: number, returnType: 'minutes' | 'days' | 'hours') => {
    if (returnType === 'minutes') {
        return Math.round(seconds / 60);
    }

    if (returnType === 'hours') {
        return Math.round(seconds / 60 / 60);
    }

    if (returnType === 'days') {
        return Math.round(seconds / 60 / 60 / 24);
    }

    return seconds;
};

export const getFriendlyTime =
    (timeZone: string) =>
    (created: string): number | string => {
        const diff = moment().utc().diff(moment.utc(created), 'seconds');

        if (diff < 44) {
            return 'a few seconds ago';
        }

        if (diff >= 44 && diff <= 89) {
            return 'a minute ago';
        }

        if (diff >= 90 && diff <= timeToSeconds('00T00:44:00')) {
            return `${secondsToFriendlyTime(diff, 'minutes')} minutes ago`;
        }

        if (diff >= timeToSeconds('00T00:44:01') && diff <= timeToSeconds('00T00:89:00')) {
            return 'an hour ago';
        }

        if (diff >= timeToSeconds('00T00:89:01') && diff <= timeToSeconds('00T21:00:00')) {
            return `${secondsToFriendlyTime(diff, 'hours')} hours ago`;
        }

        if (diff >= timeToSeconds('00T21:00:01') && diff <= timeToSeconds('00T35:00:00')) {
            return 'yesterday';
        }

        if (diff >= timeToSeconds('00T35:00:01') && diff <= timeToSeconds('25T00:00:00')) {
            return `${secondsToFriendlyTime(diff, 'days')} days ago`;
        }

        if (diff >= timeToSeconds('25T00:00:01') && diff <= timeToSeconds('45T00:00:00')) {
            return 'a month ago';
        }

        return format(toZonedTime(moment.utc(created).toDate(), timeZone), 'MMM dd, yyyy HH:mm');
    };

export const getFriendlyDate = (date: string) => {
    return format(moment.utc(date).toDate(), 'MMMM dd, yyyy');
};
