import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
    type BaseMultiPageModel,
    FontFamily,
    SpecificationExporter,
    VideoWebSpecificationParser,
    ElementTypes,
    getAllElementsRecursively,
    TextElement,
} from '@bynder-studio/render-core';
import { ImageManipulationRenderer, PlaybackManipulationRenderer } from '@bynder-studio/render-web';
import useAccessRights from 'packages/hooks/useAccessRights';
import { isCtrlKey } from '~/helpers/hotKeys';
import { Account, ActionStatus, ColorPaletteAction } from 'packages/hooks/useAccessRights/types';
import { getUser } from 'packages/store/user/user.selectors';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { replaceDuplicatedIds, convertTextStyles, type MatchList } from '~/common/editor/presets/utils';
import { collectTopLevelElements } from '~/common/editor/timeline/timeline-actions/components/utils.js';
import { fontsElementsSelector } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { MAX_ELEMENT_NAME_LENGTH } from '~/common/editor/helpers';
import { syncBrandColors } from './syncBrandColors';

// cusotom mime type is used to avoid pasting element data to places other than our editor
// https://bynder.atlassian.net/browse/VBS-17415
const ELEMENT_BLOB_MIME_TYPE = 'application/vbs-element';
// web preffix is necessary for custom mime types
const ELEMENT_MIME_TYPE = `web ${ELEMENT_BLOB_MIME_TYPE}`;

type Props = {
    creativeModel?: BaseMultiPageModel;
    manipulationRenderer?: PlaybackManipulationRenderer | ImageManipulationRenderer;
    creativeId: number;
};

const useCopyPasteKeys = ({ creativeModel, manipulationRenderer, creativeId }: Props) => {
    const { isAccountActionAllowed } = useAccessRights();
    const fonts: FontFamily[] = useSelector(fontsElementsSelector);
    const user = useSelector(getUser);
    const { palettes } = useSelector(colorPalettes);

    // TODO: branch out for now, remove it later
    const getContentPropertiesFromElement = useCallback(
        (elements: any, matchList: MatchList) => {
            if (!creativeModel) {
                return [];
            }

            const allContentProperties = creativeModel.getContentPropertiesManager().toObject();
            const ids = new Set(elements.map((element) => element.contentPropertyUuid as string).filter(Boolean));

            const contentProperties = allContentProperties
                .filter((item) => ids.has(item.uuid))
                .map((item) => {
                    const uuid = uuidv4();

                    elements.forEach((element) => {
                        if (element.contentPropertyUuid !== item.uuid) {
                            return;
                        }

                        const el = elements.find(({ id }) => element.id === id);

                        if (el) {
                            el.contentPropertyUuid = uuid;
                        }
                    });

                    if (item.type === ElementTypes.TEXT) {
                        item.properties.textStyles = (item.properties.textStyles ?? []).reduce((acc, styleId) => {
                            const newVal = matchList[styleId];

                            if (newVal) {
                                acc.push(newVal);
                            }

                            return acc;
                        }, [] as string[]);

                        item.properties.value?.runs?.forEach((run) => {
                            run.styleId = matchList[run.styleId] ?? null;
                        });
                    }

                    return { ...item, uuid };
                });

            return contentProperties;
        },
        [creativeModel],
    );

    const copyAction = useCallback(
        (copiedElements) => {
            if (!creativeModel) {
                return;
            }

            const [idsMatchList, convertedTextStyles] = convertTextStyles(
                copiedElements,
                creativeModel.getTextStyles().list,
            );

            let specElements = SpecificationExporter.exportElements(copiedElements);
            Object.values(specElements).forEach((element) => {
                delete element.parentId;
                if (element.name.length + ' (copy)'.length <= MAX_ELEMENT_NAME_LENGTH) {
                    element.name += ' (copy)';
                }
            });

            const contentProperties = getContentPropertiesFromElement(Object.values(specElements), idsMatchList);

            specElements = replaceDuplicatedIds(specElements as any, idsMatchList);

            const json = JSON.stringify({
                elements: specElements,
                textStyles: convertedTextStyles,
                contentProperties,
                currentCompany: user?.currentCompany,
                bynderUserId: user?.user?.bynderUserId,
                identityId: user?.user?.identityId,
                creativeId,
            });

            const jsonClipboardItem = new ClipboardItem({
                [ELEMENT_MIME_TYPE]: new Blob([json], { type: ELEMENT_BLOB_MIME_TYPE }),
            });

            navigator.clipboard
                .write([jsonClipboardItem])
                .catch((error) => console.error('Failed to copy elements to clipboard:', error));
        },
        [
            creativeModel,
            getContentPropertiesFromElement,
            user?.currentCompany,
            user?.user?.bynderUserId,
            user?.user?.identityId,
        ],
    );

    const copy = useCallback(
        (e: KeyboardEvent) => {
            if (!manipulationRenderer) {
                return;
            }

            if (isCtrlKey(e) && (e.code === 'KeyC' || e.keyCode === 67)) {
                // User copy text from input or textarea. Skip it
                if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
                    return;
                }

                // User copy text, not elements. Skip it
                if (manipulationRenderer.canvasManipulation?.compositor?.textEditor) {
                    return;
                }

                const copiedElements = collectTopLevelElements(manipulationRenderer.getSelectedElements());

                // Nothing to copy
                if (copiedElements.length === 0) {
                    return;
                }

                copyAction(copiedElements);
            }
        },
        [copyAction, manipulationRenderer],
    );

    const paste = useCallback(
        (e: KeyboardEvent) => {
            if (!creativeModel) {
                return;
            }

            if (isCtrlKey(e) && (e.code === 'KeyV' || e.keyCode === 86)) {
                navigator.clipboard
                    .read()
                    .then((clipboardItems) => {
                        if (!clipboardItems[0].types.includes(ELEMENT_MIME_TYPE)) {
                            return null;
                        }

                        return clipboardItems[0].getType(ELEMENT_MIME_TYPE);
                    })
                    .then((blob) => (blob ? blob.text() : null))
                    .then((text) => {
                        if (!text) {
                            return;
                        }

                        try {
                            const json = JSON.parse(text);

                            if (
                                json.currentCompany !== user?.currentCompany ||
                                json.identityId !== user?.user?.identityId ||
                                json.bynderUserId !== user?.user?.bynderUserId
                            ) {
                                return;
                            }

                            const textStyles = creativeModel.getTextStyles();
                            const videoWebSpecificationParser = new VideoWebSpecificationParser();
                            const parsedElements = videoWebSpecificationParser.parseElements(json, fonts);

                            const updatedTextStylesIds = textStyles.extendStyles(json.textStyles ?? []);

                            const updatedContentPropertyIds = creativeModel
                                .getContentPropertiesManager()
                                .extendContentProperties(json.contentProperties ?? [], fonts, updatedTextStylesIds);

                            getAllElementsRecursively(parsedElements).forEach((element: any) => {
                                if (updatedContentPropertyIds.has(element.contentPropertyId)) {
                                    element.contentPropertyId = updatedContentPropertyIds.get(
                                        element.contentPropertyId,
                                    );
                                }

                                if (element instanceof TextElement) {
                                    element.textStyles = element.textStyles.filter((styleId) =>
                                        textStyles.getStyle(styleId),
                                    );
                                    element.formattedText.runs.forEach((run) => {
                                        if (!run.styleId) {
                                            return;
                                        }

                                        const newId = updatedTextStylesIds.get(run.styleId);

                                        if (newId) {
                                            run.styleId = newId;
                                        }
                                    });
                                }
                            });

                            creativeModel.addElements(parsedElements);

                            if (
                                isAccountActionAllowed(
                                    ColorPaletteAction.EDIT_COLOR_PALETTE,
                                    Account.COLOR_PALETTES,
                                ) === ActionStatus.ALLOWED
                            ) {
                                syncBrandColors(creativeModel, palettes, () => null);
                            }
                        } catch (error) {
                            // if not a json data it will throw an error and we will continue execution
                        }
                    })
                    .catch((error) => console.error('Failed to paste elements to clipboard:', error));
            }
        },
        [creativeModel, manipulationRenderer],
    );

    const cutAction = useCallback(
        (cutElements) => {
            if (!creativeModel) {
                return;
            }

            const editorElements = creativeModel.getAllElementsRecursively();
            const elementsToDelete = editorElements.filter((el) => cutElements.includes(el.id));
            const remainingElements = editorElements.filter((el) => !cutElements.includes(el.id));

            const elementsWithMask = editorElements.filter((el) => el.mask !== null && !cutElements.includes(el.id));

            const maskedElementsToUpdate = elementsWithMask.filter((maskedElement) =>
                editorElements.find((el) => el.id === maskedElement.id),
            );

            const detacheddMaskIds = elementsToDelete.filter((el) => el.mask !== null).map((el) => el.mask?.elementId);

            const masksToUpdate = remainingElements.filter((el) => detacheddMaskIds.includes(el.id));

            creativeModel.removeElements(cutElements);

            if (masksToUpdate) {
                masksToUpdate.forEach((mask) => {
                    creativeModel.updateElement(mask.id, {
                        mask: null,
                    });
                });
            }

            if (maskedElementsToUpdate) {
                maskedElementsToUpdate.forEach((element) => {
                    if (cutElements.includes(element.mask?.elementId)) {
                        creativeModel.updateElement(element.id, {
                            mask: null,
                        });
                    }
                });
            }
        },
        [creativeModel],
    );

    const cut = useCallback(
        (e: KeyboardEvent) => {
            if (!manipulationRenderer) {
                return;
            }

            if (isCtrlKey(e) && (e.code === 'KeyX' || e.keyCode === 88)) {
                // User cut text from input or textarea. Skip it
                if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
                    return;
                }

                // User cut text, not elements. Skip it
                if (manipulationRenderer.canvasManipulation?.compositor?.textEditor) {
                    return;
                }

                const cutElements = collectTopLevelElements(manipulationRenderer.getSelectedElements());

                // Nothing to cut
                if (cutElements.length === 0) {
                    return;
                }

                copyAction(cutElements);
                cutAction(cutElements.map((element) => element.id));
            }
        },
        [copyAction, cutAction, manipulationRenderer],
    );

    useEffect(() => {
        document.addEventListener('keydown', copy);
        document.addEventListener('keydown', paste);
        document.addEventListener('keydown', cut);

        return () => {
            document.removeEventListener('keydown', copy);
            document.removeEventListener('keydown', paste);
            document.removeEventListener('keydown', cut);
        };
    }, [copy, paste, cut]);
};

export default useCopyPasteKeys;
