import { useCallback, useEffect } from 'react';
import { type VisualElement } from '@bynder-studio/render-core';
import useForceUpdate from '~/hooks/useForceUpdate';
import useDesign from './useDesign';
import useVariations from './useVariations';

export default function useElement<TElement extends VisualElement>(id: number) {
    const { creativeModel } = useDesign();
    const { updateVariationElement } = useVariations();
    const forceUpdate = useForceUpdate();
    const models = creativeModel?.getModels() ?? [];
    const modelsMetaData = creativeModel?.getModelsMetaData() ?? [];
    const index = creativeModel?.getCurrentPageIndex() ?? 0;
    const model = models[index];
    const modelMetaData = modelsMetaData[index];
    const element = model.getElementById(id) as TElement | null | undefined;

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        creativeModel.getEventEmitter().on('currentPageChange', forceUpdate);
        creativeModel.getEventEmitter().on('elementUpdated', forceUpdate);

        return () => {
            creativeModel.getEventEmitter().off('currentPageChange', forceUpdate);
            creativeModel.getEventEmitter().off('elementUpdated', forceUpdate);
        };
    }, [creativeModel, forceUpdate]);

    const updateElement = useCallback(
        (params) => {
            model.updateElement(id, params);
            updateVariationElement(modelMetaData.id, id, params);
            forceUpdate();
        },
        [model, id, updateVariationElement, modelMetaData.id, forceUpdate],
    );

    const syncContentProperty = useCallback(
        (params) => {
            updateElement(params);
            models.forEach((m, i) => {
                m.getAllElementsRecursively().forEach((e) => {
                    if (e.contentPropertyId === element?.contentPropertyId) {
                        updateVariationElement(modelsMetaData[i]?.id, e.id, params);
                    }
                });
            });
            forceUpdate();
        },
        [models, modelsMetaData, id, forceUpdate, element?.contentPropertyId, updateVariationElement],
    );

    return {
        /** @deprecated Use element id or the element itself instead */
        key: `${id}-${index}`,
        element,
        updateElement,
        syncContentProperty,
        model,
    };
}
