import { event, EventAndListener, EventEmitter2, eventNS, Listener, ListenerFn, OnOptions } from 'eventemitter2';

export class DynamicEventEmitter extends EventEmitter2 {
    on(event: event | eventNS, listener: ListenerFn, options?: boolean | OnOptions): this | Listener {
        this.setMaxListeners(this.getMaxListeners() + 1);

        return super.on(event, listener, options);
    }

    off(event: event | eventNS, listener: ListenerFn): this {
        this.setMaxListeners(Math.max(this.getMaxListeners() - 1, 0));

        return super.off(event, listener);
    }

    onAny(listener: EventAndListener): this {
        this.setMaxListeners(Math.max(this.getMaxListeners() + 1, 0));

        return super.onAny(listener);
    }

    offAny(listener: ListenerFn): this {
        this.setMaxListeners(Math.max(this.getMaxListeners() + 1, 0));

        return super.offAny(listener);
    }
}
