import type { IAsset } from './IAsset';

export type BaseAssetParams = {
    id: string;
    src: string;
};

export abstract class BaseAsset implements IAsset {
    id: string;

    accessible = false;

    loading = false;

    src: string;

    protected constructor(params: BaseAssetParams) {
        this.src = params.src;
        this.id = params.id;
    }

    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    setAccessible(accessible: boolean): void {
        this.accessible = accessible;
    }

    isAccessible(): boolean {
        return this.accessible;
    }

    update(params: any): void {
        Object.entries(params).forEach(([key, value]) => {
            if (key in this) (this as any)[key] = value;
        });
    }
}
