import { type IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { equals } from '../../Helpers/utils';
import { IAsset } from '../Assets/IAsset';
import type { CreativeTypes } from '../../Enums/CreativeTypes';
import type { PreviewTypes } from '../../Enums/PreviewTypes';
import { type ManipulableObjectParams } from '../../types';

export abstract class ManipulableObject {
    id!: number;

    name = '';

    locked?: boolean = false;

    contentPropertyId = '';

    setAssetLoader(loader: IAssetsLoader): void {
        throw new Error('Method not implemented.');
    }

    constructAsset(frameRate: number): void {
        throw new Error('Method not implemented.');
    }

    isContainsAsset(asset: IAsset): boolean {
        throw new Error('Method not implemented.');
    }

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes): any {
        throw new Error('Method not implemented.');
    }

    setProperties(params: Partial<ManipulableObjectParams>): Set<ElementUpdateTypes> {
        const updateTypes = new Set<ElementUpdateTypes>();

        if (params.id !== undefined) {
            this.id = params.id;
            updateTypes.add(ElementUpdateTypes.ID);
        }

        if (params.name !== undefined) {
            this.name = params.name;
            updateTypes.add(ElementUpdateTypes.NAME);
        }

        if (params.locked !== undefined) {
            this.locked = params.locked;
            updateTypes.add(ElementUpdateTypes.LOCK);
        }

        if (params.contentPropertyId !== undefined) {
            this.contentPropertyId = params.contentPropertyId;
            updateTypes.add(ElementUpdateTypes.CONTENT_PROPERTY);
        }

        return updateTypes;
    }

    getValuesByUpcomingUpdate(data: Record<string, any>): Record<string, any> {
        const elementData: Record<string, any> = this.toObject();

        if ((this as any).children) {
            elementData.children = (this as any).children;
        }

        return Object.keys(data).reduce<Record<string, any>>((acc, key) => {
            if (key === 'parent') {
                if (data.parent !== (this as any).parent) {
                    acc.parent = (this as any).parent;
                }
            } else if (!equals(elementData[key], data[key])) {
                acc[key] = elementData[key];
            }

            return acc;
        }, {});
    }

    cleanupEmittingValues(values: Record<string, any>): void {}

    toObject(): ManipulableObjectParams {
        if ('locked' in this) {
            return {
                id: this.id,
                name: this.name,
                locked: this.locked,
                contentPropertyId: this.contentPropertyId,
            };
        }

        return {
            id: this.id,
            name: this.name,
            contentPropertyId: this.contentPropertyId,
        };
    }

    getCopy(): typeof this {
        if ('createCopy' in this) {
            return (this as any).createCopy();
        }

        const rawElement = this.toObject();
        const thisClass = this.constructor as new (params: ManipulableObjectParams) => typeof this;
        const instance = new thisClass(rawElement);
        instance.setProperties(rawElement);

        return instance;
    }
}
