import { ElementUpdateTypes } from '../../Enums/ElementUpdateTypes';
import { calculateBoxCrop } from '../../Helpers/calculateBoxCrop';
import type { IAsset } from '../Assets/IAsset';
import { ImageAsset } from '../Assets/ImageAsset';
import { ImageCompElement } from '../CompModels/Elements/ImageCompElement';
import { Box } from '../Shared/Box';
import { ContentTransform } from '../Shared/ContentTransform';
import { Dimension } from '../Shared/Dimension';
import { BaseVisualElement } from './BaseVisualElement';
import { type ElementUpdateOptions, type ImageElementParams } from '../../types';
import { TimelineBehavior } from '../../Enums/TimelineBehavior';
import { VirtualData } from '../Shared/VirtualData';
import type { CreativeTypes } from '../../Enums/CreativeTypes';
import { PreviewTypes } from '../../Enums/PreviewTypes';

export class ImageElement extends BaseVisualElement {
    src: string | null | undefined = null;

    srcId: string | null | undefined = null;

    srcType: string | null | undefined = null;

    fileName: string | null | undefined = null;

    timelineBehavior: TimelineBehavior = TimelineBehavior.AUTO;

    contentTransform: ContentTransform | null = null;

    naturalDimension!: Dimension;

    cropData: any;

    virtualData: VirtualData = { bynderCollectionId: null, allowPersonalUpload: true };

    constructor(params: Partial<ImageElementParams>) {
        super();
        this.setProperties(params);
    }

    setProperties(params: Partial<ImageElementParams>): Set<ElementUpdateTypes> {
        const updateTypes: Set<ElementUpdateTypes> = super.setProperties(params);

        if (params.src !== undefined) {
            this.src = params.src;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.srcType !== undefined) {
            this.srcType = params.srcType;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.srcId !== undefined) {
            this.srcId = params.srcId;
            updateTypes.add(ElementUpdateTypes.SOURCE);
        }

        if (params.fileName !== undefined) {
            this.fileName = params.fileName;
            updateTypes.add(ElementUpdateTypes.SOURCE); // related to source
        }

        if (
            params.timelineBehavior !== undefined &&
            this.timelineBehavior !== params.timelineBehavior &&
            Object.values(TimelineBehavior).includes(params.timelineBehavior)
        ) {
            this.timelineBehavior = params.timelineBehavior || TimelineBehavior.AUTO;
            updateTypes.add(ElementUpdateTypes.TIMELINE_BEHAVIOR);
        }

        if (params.contentTransform !== undefined && params.contentTransform !== null) {
            this.contentTransform = new ContentTransform(params.contentTransform);
            updateTypes.add(ElementUpdateTypes.CONTENT_TRANSFORM);
        }

        if (params.naturalDimension !== undefined && params.naturalDimension !== null) {
            this.naturalDimension = new Dimension(params.naturalDimension.width, params.naturalDimension.height);
            updateTypes.add(ElementUpdateTypes.NATURAL_DIMENSION);
        }

        // Calculate content position relative to box
        if (this.contentTransform && this.naturalDimension && this.dimension) {
            this.cropData = calculateBoxCrop(this.contentTransform, this.dimension, this.naturalDimension);
        }

        if (params.virtualData !== undefined && params.virtualData !== null) {
            this.virtualData = params.virtualData;
        }

        return updateTypes;
    }

    update(params: Partial<ImageElementParams>, options?: ElementUpdateOptions): Set<ElementUpdateTypes> {
        const { frameRate = 25 } = options || {};
        const oldSrcId = this.srcId;
        const updateTypes: Set<ElementUpdateTypes> = this.setProperties(params);

        if (this.srcId !== oldSrcId) {
            this.constructAsset(frameRate);
        }

        return updateTypes;
    }

    getCompElement(frameIndex: number) {
        if (!this.position || !this.cropData) {
            return null;
        }

        const compEl = new ImageCompElement();
        compEl.originalElement = this;
        compEl.id = this.id;
        compEl.assetId = this.srcId as string;
        compEl.hidden = this.hidden;
        compEl.renderOrder = this.renderOrder;
        compEl.opacity = this.opacity;
        compEl.rotation = this.rotation;
        compEl.scale = this.scale;
        compEl.dropShadow = this.dropShadow;
        compEl.mask = this.mask;
        compEl.blendMode = this.blendMode;
        compEl.isAssetLoading = this.isAssetLoading;
        compEl.boundingBox = new Box(this.position.getCopy(), this.dimension.getCopy());
        compEl.contentBox = new Box(this.cropData.position, this.cropData.dimension);
        compEl.cropPositionPct = this.cropData.cropPositionPct;
        compEl.cropDimensionPct = this.cropData.cropDimensionPct;

        return this.applyAnimations(frameIndex, compEl);
    }

    constructAsset(frameRate: number = 0): void {
        if (!this.src) {
            return;
        }

        const asset = new ImageAsset({
            id: this.srcId as string,
            src: this.src,
            startFrame: this.startFrame,
            naturalDimension: this.naturalDimension.getCopy(),
        });
        this.isAssetLoading = true;
        this.assetLoader!.setAsset(asset);
        this.assetLoader!.loadImage(asset)
            .catch((err) => {
                console.warn('Image asset loading error. Url:', this.src, err);
            })
            .finally(() => {
                this.isAssetLoading = false;
            });
    }

    isContainsAsset(asset: IAsset): boolean {
        return asset instanceof ImageAsset && asset.id === this.srcId && asset.src === this.src;
    }

    getValidationRules(creativeType: CreativeTypes, previewType: PreviewTypes) {
        const rules = super.getValidationRules(creativeType, previewType);

        if (previewType === PreviewTypes.CONTENT) {
            return rules;
        }

        return {
            ...rules,
            src: {
                REQUIRED: true,
            },
        };
    }

    toObject(): ImageElementParams {
        return {
            ...super.toObject(),
            src: this.src,
            srcId: this.srcId,
            srcType: this.srcType,
            fileName: this.fileName,
            timelineBehavior: this.timelineBehavior,
            contentTransform: this.contentTransform?.toObject() ?? null,
            naturalDimension: this.naturalDimension?.toObject() ?? null,
            virtualData: this.virtualData,
        };
    }
}
