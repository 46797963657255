import {
    BaseMultiPageModel,
    ElementTypes,
    getAllElementsRecursively,
    getRunsForExport,
    type BaseModel,
    type ImageModel,
    type MultiPageImageModel,
    type MultiPageVideoModel,
    type VideoModel,
    SpecificationParser,
    type TemplateElement,
    TextElement,
    type UpdateGlobalPropertyOptions,
} from '@bynder-studio/render-core';
import { getStructuredText } from '@bynder-studio/render-web';
import { clone, equals } from 'rambda';
import { FAILURE_REASON_TYPES } from 'packages/variation-export/types';
import { flattenTree, getElementType } from '~/common/editor/helpers/elementtree';

const EMPTY_AUDIO_PARAMS = {
    src: null,
    srcType: null,
    srcId: null,
    fileName: '',
    offsetTime: 0,
};

const EMPTY_AUDIO_ASSET_VALUE = {
    type: 'ASSET_MANAGER',
    value: null,
    offsetTime: 0,
    fileName: '',
    fileUrl: null,
    deleted: false,
};

// Limited list of properties that can be changed and saved in CC and should not be renamed
const COMMON_CHANGEABLE_PROPS = ['hidden', 'duration', 'startFrame', 'contentTransform', 'renderOrder', 'useAudio'];
const TEXT_CHANGEABLE_PROPS = ['textDirection', 'textBackground'];
const SHAPE_CHANGEABLE_PROPS = ['fillColor', 'borderColor'];
const PLAYBACK_CHANGEABLE_PROPS = ['gain', 'fadeIn', 'fadeOut', 'offsetTime'];

const ALL_CHANGEABLE_PROPS = [
    ...COMMON_CHANGEABLE_PROPS,
    ...TEXT_CHANGEABLE_PROPS,
    ...SHAPE_CHANGEABLE_PROPS,
    ...PLAYBACK_CHANGEABLE_PROPS,
];

const isPropNameValid = (propName: string) => ALL_CHANGEABLE_PROPS.includes(propName);

export const isVariationInvalid = (size) =>
    size.hasDeletedAsset || size.failureReason === FAILURE_REASON_TYPES.EXCEEDING_MAX_DURATION || size.hasOversetText;

export const cleanUpNewValues = (values: { [key: string]: any }) => {
    const temp = { ...values };

    Object.keys(temp).forEach((propName) => {
        if (TextElement.servicePropsList.includes(propName)) {
            delete temp[propName];
        }
    });

    return temp;
};

const filterPropertyValue = (type, name, value, templateElement) => {
    if (name === 'value') {
        switch (type) {
            case ElementTypes.TEXT:
                return { text: value };
            case ElementTypes.IMAGE:
                if (!value.thumbnail) {
                    return {
                        src: templateElement.src,
                        srcId: templateElement.srcId,
                        fileName: templateElement.fileName,
                        srcType: templateElement.srcType,
                        naturalDimension: templateElement.naturalDimension,
                    };
                }

                return {
                    src: value.thumbnail,
                    srcId: value.value,
                    fileName: value.fileName,
                    srcType: value.type,
                    naturalDimension: { width: value.naturalWidth, height: value.naturalHeight },
                };
            case ElementTypes.VIDEO:
                if (!value) {
                    return {
                        src: templateElement.videoPreviewUrl,
                        srcId: templateElement.srcId,
                        fileName: templateElement.fileName,
                        thumbnail: templateElement.thumbnail,
                        naturalDimension: templateElement.naturalDimension,
                        isAlpha: templateElement.isAlpha,
                        offsetTime: templateElement.offsetTime,
                    };
                }

                return {
                    src: value.videoPreviewUrl,
                    srcId: value.value,
                    fileName: value.fileName,
                    thumbnail: value.thumbnail,
                    naturalDimension: { width: value.naturalWidth, height: value.naturalHeight },
                    isAlpha: value.isAlpha,
                    offsetTime: value.offsetTime,
                };
            case ElementTypes.GLOBAL_AUDIO:
                if (!value) {
                    return {
                        src: templateElement.fileUrl,
                        srcType: templateElement.srcType,
                        srcId: templateElement.srcId,
                        fileName: templateElement.fileName,
                        offsetTime: templateElement.offsetTime,
                    };
                }

                return {
                    src: value.fileUrl,
                    srcType: 'ASSET_MANAGER',
                    srcId: value.value,
                    fileName: value.fileName,
                    offsetTime: value.offsetTime,
                };
            case ElementTypes.BACKGROUND_COLOR:
                return {
                    color: value,
                };
            case ElementTypes.POSTER_FRAME:
                return {
                    frame: value,
                };
            default:
                return null;
        }
    }

    if (ALL_CHANGEABLE_PROPS.includes(name)) {
        return { [name]: value };
    }

    return null;
};

const getCreativeModelElements = (creativeModel) =>
    creativeModel.getAllElementsRecursively().reduce((acc, el) => {
        acc[el.id] = { ...el.toObject(), type: getElementType(el) };
        return acc;
    }, {});

export const getGlobalProperties = (creativeModel) => {
    const globalProperties = {};

    if (creativeModel.getGlobalAudioTrack1) {
        const audio = creativeModel.getGlobalAudioTrack1();
        globalProperties[audio.id] = {
            ...audio,
            type: ElementTypes.GLOBAL_AUDIO,
        };
    }

    if (creativeModel.getGlobalAudioTrack2) {
        const audio = creativeModel.getGlobalAudioTrack2();
        globalProperties[audio.id] = {
            ...audio,
            type: ElementTypes.GLOBAL_AUDIO,
        };
    }

    if (creativeModel.getPosterFrame) {
        const posterFrame = creativeModel.getPosterFrame();
        globalProperties[posterFrame.id] = {
            ...posterFrame,
            type: ElementTypes.POSTER_FRAME,
        };
    }

    if (creativeModel.getBackgroundColor) {
        const backgroundColor = creativeModel.getBackgroundColor();
        globalProperties[backgroundColor.id] = {
            ...backgroundColor,
            type: ElementTypes.BACKGROUND_COLOR,
        };
    }

    return globalProperties;
};

export const parseVariation = ({ entries, creativeModel, template }) => {
    const allModels = creativeModel.getModels();
    const allModelsMetaData = creativeModel.getModelsMetaData();

    return entries.reduce((acc, item) => {
        const { creativeVersionPageId: sizeId, variationId, properties, ...params } = item;

        const pageModelIdx = allModelsMetaData.findIndex((model) => model.id.toString() === sizeId.toString());
        const pageModel = allModels[pageModelIdx];
        const templateEls = {
            ...getCreativeModelElements(pageModel),
            ...getGlobalProperties(pageModel),
        };
        const elements = {};

        properties.forEach(({ elementProperty: prop, value }) => {
            // check if audio asset is removed from variation
            if (prop?.type === 'VALUE_SOURCE' && !value) {
                value = EMPTY_AUDIO_ASSET_VALUE;
            } else if (value === null || value === undefined) {
                return;
            }

            const { templateElementId: id, name } = prop;

            // TODO: Delete after BE migration!
            if (name === 'renderOrder') {
                return;
            }

            if (id in templateEls) {
                const { type } = templateEls[id];
                const filteredValue = filterPropertyValue(type, name, value, templateEls[id]);

                if (!filteredValue) {
                    return;
                }

                if (type === ElementTypes.TEXT && filteredValue.text !== undefined && filteredValue.text !== null) {
                    const defaultprops = pageModel.getElementById(id).getTextProps().runs[0];
                    filteredValue.formattedText = getStructuredText(filteredValue.text, defaultprops);
                    // TODO: Seems useless, delete later
                    // delete filteredValue.text;
                }

                if (id in elements) {
                    elements[id] = { ...elements[id], ...filteredValue };
                } else {
                    elements[id] = filteredValue;
                }
            }
        });

        acc[sizeId] = {
            ...params,
            sizeId,
            properties,
            variationId,
            elements: mergeTemplateWithVariation({ template, pageId: sizeId, elements }),
        };

        return acc;
    }, {});
};

const getElementPropertyValue = (elementProps, elementType, propName) => {
    if (['TEXT_STYLES', 'BRAND_COLORS'].includes(elementType)) {
        return undefined;
    }

    if (propName === 'value') {
        switch (elementType) {
            case ElementTypes.TEXT:
                const { value, runs, layoutRuns } = elementProps.formattedText;
                return {
                    value,
                    layoutRuns,
                    runs: getRunsForExport(runs),
                };
            case ElementTypes.IMAGE:
            case ElementTypes.VIDEO:
                return {
                    type: 'ASSET_MANAGER',
                    value: elementProps.srcId,
                    offsetTime: elementProps.offsetTime,
                    thumbnailStorageUrl: null,
                };
            case ElementTypes.GLOBAL_AUDIO:
                if (!elementProps.srcId) {
                    return null;
                }

                return {
                    type: 'ASSET_MANAGER',
                    value: elementProps.srcId,
                    offsetTime: elementProps.offsetTime,
                };
            case ElementTypes.BACKGROUND_COLOR:
                return elementProps.color;
            case ElementTypes.POSTER_FRAME:
                return elementProps.frame;
            default:
                return undefined;
        }
        // TODO: Temporary solution until backend implements consistent behaviour for all properties
        // https://bynder.atlassian.net/browse/VBS-22156
    } else if (propName === 'offsetTime') {
        switch (elementType) {
            case ElementTypes.VIDEO:
                return {
                    type: 'ASSET_MANAGER',
                    value: elementProps.srcId,
                    offsetTime: elementProps.offsetTime,
                    thumbnailStorageUrl: null,
                };

            case ElementTypes.GLOBAL_AUDIO:
                if (!elementProps.srcId) {
                    return null;
                }

                return {
                    type: 'ASSET_MANAGER',
                    value: elementProps.srcId,
                    offsetTime: elementProps.offsetTime,
                };

            default:
                return undefined;
        }
    }

    if (propName in elementProps) {
        return elementProps[propName];
    }

    return undefined;
};

const getPropNameForSaving = (propertyName: string, elementType: string) => {
    if (isPropNameValid(propertyName)) {
        return propertyName;
    }

    switch (elementType) {
        case ElementTypes.TEXT: {
            return propertyName === 'formattedText' ? 'value' : '';
        }
        case ElementTypes.IMAGE:
        case ElementTypes.VIDEO:
        case ElementTypes.GLOBAL_AUDIO: {
            return propertyName === 'srcId' ? 'value' : '';
        }
        case ElementTypes.BACKGROUND_COLOR: {
            return propertyName === 'color' ? 'value' : '';
        }

        default:
            return '';
    }
};

const parsePropName = (propertyName: string, elementType: string) => {
    if (isPropNameValid(propertyName)) {
        return propertyName;
    }

    switch (elementType) {
        case ElementTypes.TEXT: {
            return propertyName === 'value' ? 'formattedText' : '';
        }
        case ElementTypes.IMAGE:
        case ElementTypes.VIDEO:
        case ElementTypes.GLOBAL_AUDIO: {
            return propertyName === 'value' ? 'srcId' : '';
        }
        case ElementTypes.BACKGROUND_COLOR: {
            return propertyName === 'value' ? 'color' : '';
        }
        default:
            return '';
    }
};

const getGlobalElementByType = (type: string, pageModel?: VideoModel | ImageModel, id?: string) => {
    if (!pageModel) {
        return null;
    }

    if (type === ElementTypes.GLOBAL_AUDIO) {
        const videoModel = pageModel as VideoModel;
        return [videoModel.getGlobalAudioTrack1(), videoModel.getGlobalAudioTrack2()].find(
            (audio) => audio.id === Number(id),
        );
    }
    if (type === ElementTypes.BACKGROUND_COLOR) {
        return pageModel.getBackgroundColor();
    }

    return null;
};

export const prepareVariationToSave = ({
    template,
    variation,
    creativeModel,
    resetPages = [],
}: {
    template: any;
    variation: any;
    creativeModel: MultiPageImageModel | MultiPageVideoModel;
    resetPages?: string[];
}) => {
    const newValues = variation.newValues || {};

    return Object.keys(variation.sizes).reduce((acc, pageId) => {
        if (resetPages.includes(pageId)) {
            return acc;
        }

        const page = variation.sizes[pageId];
        const pageModel = creativeModel.getModelByPageId(pageId.toString());
        const templateEls = {
            ...getCreativeModelElements(pageModel),
            ...getGlobalProperties(pageModel),
        };
        const savedProps = page.properties.reduce((accum, current) => {
            const {
                elementProperty: { templateElementId, name },
            } = current;

            // TODO: Delete after BE migration!
            if (name === 'renderOrder') {
                return accum;
            }

            if (!accum[templateElementId]) {
                accum[templateElementId] = new Set();
            }

            accum[templateElementId].add(parsePropName(name, templateEls[templateElementId].type));

            return accum;
        }, {});

        const allAffectedIds = new Set([...(Object.keys(newValues?.[pageId] || {}) || []), ...Object.keys(savedProps)]);
        const affectedElements = [...allAffectedIds].reduce((accum, current) => {
            if (!accum[current]) {
                accum[current] = new Set();
            }

            accum[current] = new Set([...(newValues?.[pageId]?.[current] || []), ...(savedProps?.[current] || [])]);
            return accum;
        }, {});

        Object.keys(affectedElements).forEach((elId) => {
            const changedProps = affectedElements[elId];

            changedProps.forEach((propName) => {
                const type = templateEls?.[elId]?.type;

                if (!type) {
                    return;
                }

                const name = getPropNameForSaving(propName, type);
                const { elements } = page;

                if (!(elId in elements) || !name) {
                    return;
                }

                const value = getElementPropertyValue(elements[elId], type, name);

                if (value || value !== undefined) {
                    const desiredPage = template.pages.find((page) => page.id === pageId);
                    const templateEl: TemplateElement | undefined = getAllElementsRecursively<TemplateElement>(
                        desiredPage?.elements || [],
                    ).find((el) => el.id === Number(elId));
                    const globalElementType = desiredPage?.globalElements.find((el) => el.id === Number(elId))?.type;
                    const globalElement = getGlobalElementByType(globalElementType, pageModel, elId);
                    const currentElement = globalElementType ? globalElement : pageModel.getElementById(Number(elId));

                    if (!currentElement || !templateEl) {
                        return;
                    }

                    const { tmpl, prop } = getComparableObj(getElementType(currentElement), currentElement, templateEl);
                    const parsedPropName = parsePropName(name, templateEl.type);
                    const isChanged = !equals(tmpl?.[parsedPropName], prop?.[parsedPropName]);

                    if (!isChanged) {
                        return;
                    }

                    if (!acc[pageId]) {
                        acc[pageId] = [];
                    }

                    // TODO: Temporary solution until backend implements consistent behaviour for all properties
                    // https://bynder.atlassian.net/browse/VBS-22156
                    const getName = (n) => (n === 'offsetTime' ? 'value' : n);

                    acc[pageId].push({ templateId: elId, name: getName(name), value });
                }
            });
        });

        return acc;
    }, {});
};

const mergeTemplateWithVariation = ({
    template,
    pageId,
    elements,
}: {
    template: { pages: any[] };
    pageId: number;
    elements: { [key: string]: any };
}) => {
    const page = template.pages.find((page) => Number(page.id) === pageId);

    const getDefinedValue = (value: any, defaultValue: any) => (value === undefined ? defaultValue : value);

    const getElementsRecursively = (elements: any[]) => {
        let temp = [];
        elements.forEach((el) => {
            temp = el.children?.length ? [...temp, ...getElementsRecursively(el.children)] : [...temp, ...elements];
        });

        return temp;
    };

    return getElementsRecursively(page.elements).reduce((accum, tmplEl) => {
        const variationData = elements?.[tmplEl.id] || {};

        switch (tmplEl.type) {
            case ElementTypes.TEXT: {
                const defaultProps = SpecificationParser.getTextDefaultProps(tmplEl);
                accum[tmplEl.id] = {
                    ...variationData,
                    startFrame: variationData?.startFrame ?? (tmplEl.properties.startFrame || 0),
                    duration: variationData?.duration ?? (tmplEl.properties.duration || 1),
                    hidden: variationData?.hidden ?? (tmplEl.properties?.hidden || false),
                    formattedText: getStructuredText(
                        variationData?.formattedText ?? (tmplEl.properties?.value || ''),
                        defaultProps,
                    ),
                    textDirection: variationData?.textDirection ?? (tmplEl.properties?.textDirection || null),
                    textBackground: variationData?.textBackground ?? (tmplEl.properties?.textBackground || null),
                };
                break;
            }

            case ElementTypes.IMAGE: {
                accum[tmplEl.id] = {
                    ...variationData,
                    startFrame: variationData?.startFrame ?? (tmplEl.properties.startFrame || 0),
                    duration: variationData?.duration ?? (tmplEl.properties.duration || 1),
                    hidden: variationData?.hidden ?? (tmplEl.properties?.hidden || false),
                    src: getDefinedValue(variationData?.src, tmplEl.properties?.value?.thumbnail),
                    srcId: getDefinedValue(variationData?.srcId, tmplEl.properties?.value?.value),
                    srcType: getDefinedValue(variationData?.srcType, tmplEl.properties?.value?.type),
                    fileName: getDefinedValue(variationData?.fileName, tmplEl.properties?.fileName || ''),
                    naturalDimension: {
                        width: variationData?.naturalDimension?.width ?? tmplEl.properties?.naturalWidth,
                        height: variationData?.naturalDimension?.height ?? tmplEl.properties?.naturalHeight,
                    },
                    contentTransform: variationData.contentTransform ?? tmplEl.properties?.contentTransform,
                };
                break;
            }

            case ElementTypes.VIDEO: {
                accum[tmplEl.id] = {
                    ...variationData,
                    startFrame: variationData?.startFrame ?? (tmplEl.properties.startFrame || 0),
                    duration: variationData?.duration ?? (tmplEl.properties.duration || 1),
                    hidden: variationData?.hidden ?? (tmplEl.properties?.hidden || false),
                    src: getDefinedValue(variationData?.src, tmplEl.properties?.value?.videoPreviewUrl),
                    srcId: getDefinedValue(variationData?.srcId, tmplEl.properties?.value?.value),
                    srcType: getDefinedValue(variationData?.srcType, tmplEl.properties?.value?.type),
                    fileName: getDefinedValue(variationData?.fileName, tmplEl.properties?.fileName || ''),
                    offsetTime: variationData?.offsetTime ?? (tmplEl.properties?.value?.offsetTime || 0),
                    isAlpha: variationData?.isAlpha ?? (tmplEl.properties?.value?.isAlpha || false),
                    useAudio: variationData?.useAudio ?? (tmplEl.properties?.useAudio || false),
                    naturalDimension: {
                        width: variationData?.naturalDimension?.width ?? tmplEl.properties?.naturalWidth,
                        height: variationData?.naturalDimension?.height ?? tmplEl.properties?.naturalHeight,
                    },
                    contentTransform: variationData?.contentTransform ?? tmplEl.properties?.contentTransform,
                    fadeIn: variationData?.fadeIn ?? (tmplEl.properties?.fadeIn || 0),
                    fadeOut: variationData?.fadeOut ?? (tmplEl.properties?.fadeOut || 0),
                    gain: variationData?.gain ?? (tmplEl.properties?.gain || 0),
                };
                break;
            }

            case ElementTypes.SHAPE: {
                accum[tmplEl.id] = {
                    ...variationData,
                    startFrame: variationData?.startFrame ?? (tmplEl.properties.startFrame || 0),
                    duration: variationData?.duration ?? (tmplEl.properties.duration || 1),
                    hidden: variationData?.hidden ?? (tmplEl.properties?.hidden || false),
                    fillColor: variationData?.fillColor ?? (tmplEl.properties.fillColor || null),
                    borderColor: variationData?.borderColor ?? (tmplEl.properties.borderColor || null),
                    borderWidth: variationData?.borderWidth ?? (tmplEl.properties.borderWidth || null),
                };
                break;
            }

            case ElementTypes.BACKGROUND_COLOR: {
                accum[tmplEl.id] = { ...variationData, color: variationData?.color ?? tmplEl.properties.value };
                break;
            }

            case ElementTypes.POSTER_FRAME: {
                accum[tmplEl.id] = { ...variationData, frame: tmplEl?.properties?.value };
                break;
            }

            case ElementTypes.GLOBAL_AUDIO: {
                accum[tmplEl.id] = {
                    ...variationData,
                    startFrame: variationData?.startFrame ?? (tmplEl.properties.startFrame || 0),
                    duration: variationData?.duration ?? (tmplEl.properties.duration || null),
                    src: getDefinedValue(variationData?.src, tmplEl.properties?.value?.url || ''),
                    srcId: getDefinedValue(variationData.srcId, tmplEl.properties?.value?.value || null),
                    srcType: getDefinedValue(variationData?.srcType, tmplEl.properties?.value?.type || null),
                    fileName: getDefinedValue(variationData?.fileName, tmplEl.properties?.fileName || ''),
                    offsetTime: variationData?.offsetTime ?? (tmplEl.properties?.value?.offsetTime || 0),
                    fadeIn: variationData?.fadeIn ?? (tmplEl.properties?.fadeIn || 0),
                    fadeOut: variationData?.fadeOut ?? (tmplEl.properties?.fadeOut || 0),
                    gain: variationData?.gain ?? (tmplEl.properties?.gain || 0),
                };
                break;
            }
        }

        return accum;
    }, {});
};

const cleanupContentTransform = (obj) => ({
    horizontalAlignment: obj.horizontalAlignment || 0,
    verticalAlignment: obj.verticalAlignment || 0,
    horizontalOffset: obj.horizontalOffset || 0,
    verticalOffset: obj.verticalOffset || 0,
    scale: obj.scale || 1,
    type: obj.type,
});

const cleanupUrl = (url) => {
    if (!url) return '';

    const { origin, pathname, searchParams, hash } = new URL(url);

    searchParams.delete('X-Goog-Algorithm');
    searchParams.delete('X-Goog-Expires');
    searchParams.delete('X-Goog-Date');
    searchParams.delete('X-Goog-Credential');
    searchParams.delete('X-Goog-SignedHeaders');
    searchParams.delete('X-Goog-Signature');

    return `${origin}${pathname}?${searchParams}#${hash}`;
};

const getComparableObj = (type: ElementTypes, element: any, templateElement: TemplateElement) => {
    let tmpl;
    let prop;

    switch (type) {
        case ElementTypes.BACKGROUND_COLOR: {
            tmpl = { color: templateElement?.properties?.value };
            prop = { color: element.color };
            break;
        }

        case ElementTypes.GLOBAL_AUDIO: {
            tmpl = {
                startFrame: templateElement.properties?.startFrame || 0,
                duration: templateElement.properties?.duration || null,
                src: templateElement.properties?.value?.url || '',
                srcId: templateElement.properties?.value?.value || null,
                srcType: templateElement.properties?.value?.type || null,
                offsetTime: templateElement.properties?.value?.offsetTime || 0,
                fileName: templateElement.properties?.fileName || '',
                gain: templateElement.properties?.gain || 0,
                fadeIn: templateElement.properties?.fadeIn || 0,
                fadeOut: templateElement.properties?.fadeOut || 0,
            };
            prop = {
                startFrame: element.startFrame || 0,
                duration: element.duration || null,
                src: element.src || '',
                srcId: element.srcId || null,
                srcType: element.srcType || null,
                offsetTime: element.offsetTime || 0,
                fileName: element.fileName || '',
                gain: element.gain || 0,
                fadeIn: element.fadeIn || 0,
                fadeOut: element.fadeOut || 0,
            };
            break;
        }

        case ElementTypes.POSTER_FRAME: {
            tmpl = { frame: templateElement?.properties?.value };
            prop = { frame: element.frame };
            break;
        }

        case ElementTypes.TEXT: {
            const defaultProps = SpecificationParser.getTextDefaultProps(templateElement);
            tmpl = {
                startFrame: templateElement.properties.startFrame || 0,
                duration: templateElement.properties.duration || 1,
                hidden: templateElement.properties?.hidden || false,
                formattedText: getStructuredText(templateElement.properties?.value || '', defaultProps),
                textDirection: templateElement.properties?.textDirection || null,
                textBackground: templateElement.properties?.textBackground || null,
            };
            prop = {
                startFrame: element.startFrame || 0,
                duration: element.duration || 1,
                hidden: element.hidden || false,
                formattedText: element.formattedText,
                textDirection: element.textDirection || null,
                textBackground: element.textBackground?.toObject() || null,
            };
            break;
        }

        case ElementTypes.IMAGE: {
            tmpl = {
                src: cleanupUrl(templateElement.properties?.value?.thumbnail),
                srcId: templateElement.properties?.value?.value,
                srcType: templateElement.properties?.value?.type,
                startFrame: templateElement.properties.startFrame || 0,
                duration: templateElement.properties.duration || 1,
                hidden: templateElement.properties?.hidden || false,
                naturalDimension: {
                    width: templateElement.properties?.naturalWidth,
                    height: templateElement.properties?.naturalHeight,
                },
                contentTransform: cleanupContentTransform(templateElement.properties?.contentTransform),
            };
            prop = {
                src: cleanupUrl(element.src),
                srcId: element.srcId,
                srcType: element.srcType,
                startFrame: element.startFrame || 0,
                duration: element.duration || 1,
                hidden: element.hidden || false,
                naturalDimension: {
                    width: element.naturalDimension.width,
                    height: element.naturalDimension.height,
                },
                contentTransform: cleanupContentTransform(element.contentTransform),
            };
            break;
        }

        case ElementTypes.VIDEO: {
            tmpl = {
                src: cleanupUrl(templateElement.properties?.value?.videoPreviewUrl),
                srcId: templateElement.properties?.value?.value,
                srcType: templateElement.properties?.value?.type,
                startFrame: templateElement.properties?.startFrame || 0,
                offsetTime: templateElement.properties?.value?.offsetTime || 0,
                isAlpha: templateElement.properties?.value?.isAlpha || false,
                useAudio: templateElement.properties?.useAudio || false,
                duration: templateElement.properties?.duration || 1,
                hidden: templateElement.properties?.hidden || false,
                naturalDimension: {
                    width: templateElement.properties?.naturalWidth,
                    height: templateElement.properties?.naturalHeight,
                },
                contentTransform: cleanupContentTransform(templateElement.properties?.contentTransform),
                fadeIn: templateElement.properties?.fadeIn || 0,
                fadeOut: templateElement.properties?.fadeOut || 0,
                gain: templateElement.properties?.gain || 0,
            };
            prop = {
                src: cleanupUrl(element.src),
                srcId: element.srcId,
                srcType: element.srcType,
                startFrame: element.startFrame || 0,
                offsetTime: element.offsetTime,
                isAlpha: element.isAlpha,
                useAudio: element.useAudio,
                duration: element.duration || 1,
                hidden: element.hidden || false,
                naturalDimension: {
                    width: element.naturalDimension.width,
                    height: element.naturalDimension.height,
                },
                contentTransform: cleanupContentTransform(element.contentTransform),
                fadeIn: element.fadeIn,
                fadeOut: element.fadeOut,
                gain: element.gain,
            };
            break;
        }

        case ElementTypes.SHAPE: {
            tmpl = {
                startFrame: templateElement.properties.startFrame || 0,
                duration: templateElement.properties.duration || 1,
                fillColor: templateElement.properties.fillColor || null,
                borderColor: templateElement.properties.borderColor || null,
                borderWidth: templateElement.properties.borderWidth || null,
                hidden: templateElement.properties.hidden || false,
            };
            prop = {
                startFrame: element?.startFrame || 0,
                duration: element?.duration || 1,
                fillColor: element?.fillColor || null,
                borderColor: element?.borderColor || null,
                borderWidth: element?.borderWidth || null,
                hidden: element?.hidden || false,
            };
            break;
        }
    }

    return { tmpl, prop };
};

export const getDiffBetweenTemplateAndVariation = (template, creativeModel) => {
    const globalPropertiesDiff = {};
    const pages = [];

    const getDiff = (a, b) => {
        const diff = {};
        Object.keys(a).forEach((key) => {
            if (!equals(a[key], b[key])) {
                diff[key] = a[key];
            }
        });
        return diff;
    };

    const templateElements = flattenTree(template.pages[creativeModel.getCurrentPageIndex()].elements);

    Object.entries(
        getGlobalProperties(creativeModel as BaseMultiPageModel<MultiPageVideoModel | MultiPageImageModel>),
    ).forEach(([id, globalProperty]: [id: string, globalProperty: any]) => {
        const templateGlobalProperty = clone(templateElements[id]);
        const { tmpl, prop } = getComparableObj(globalProperty.type, globalProperty, templateGlobalProperty);

        if (!equals(tmpl, prop)) {
            if (globalProperty.type === ElementTypes.GLOBAL_AUDIO) {
                globalPropertiesDiff[templateGlobalProperty.type] = { id, ...getDiff(tmpl, prop) };
            } else {
                globalPropertiesDiff[templateGlobalProperty.type] = { id, ...tmpl };
            }
        }
    });

    let hasChanges = Object.keys(globalPropertiesDiff).length > 0;

    const modelsMetaData = creativeModel.getModelsMetaData();
    const models = creativeModel.getModels();
    const currentPageIndex = creativeModel.getCurrentPageIndex();

    const model = models[currentPageIndex];
    const modelMetaData = modelsMetaData[currentPageIndex];
    const pageId = modelMetaData.id;

    const pageProperties = template.pages.find((page) => +page.id === +pageId)?.properties || [];
    const elementDiff = {} as any;

    model.getAllElementsRecursively().forEach((element) => {
        const templateElement = clone({
            ...templateElements[element.id],
            properties: { ...(templateElements[element.id]?.properties || {}) },
        });

        pageProperties
            .filter((p) => p.templateElementId === element.id)
            .forEach((item) => {
                templateElement.properties[item.propertyName] = item.value;
            });

        const { tmpl, prop } = getComparableObj(getElementType(element), element, templateElement);
        const isMediaElement = [ElementTypes.IMAGE, ElementTypes.VIDEO].some((type) => type === templateElement.type);

        if (!equals(tmpl, prop)) {
            elementDiff[element.id] = getDiff(tmpl, prop);

            if (isMediaElement) {
                if ('src' in elementDiff[element.id]) {
                    const srcPropName = templateElement.type === ElementTypes.IMAGE ? 'thumbnail' : 'videoPreviewUrl';
                    elementDiff[element.id].src = templateElement.properties?.value?.[srcPropName];
                }
            }
        }
    });

    const elements = Object.entries(elementDiff).map(([id, diff]) => ({ id: +id, ...diff }));

    hasChanges = hasChanges || elements.length > 0;

    pages.push({
        index: currentPageIndex,
        pageId,
        elements,
    });

    return {
        hasChanges,
        globalProperties: globalPropertiesDiff,
        pages,
    };
};

export const isVariationDifferFromTemplate = (template, creativeModel) => {
    const models = creativeModel.getModels();
    const modelsMetaData = creativeModel.getModelsMetaData();

    return models.some((model, pageIndex) => {
        const templateElements = flattenTree(template.pages[pageIndex].elements);
        const hasGlobalChanges = Object.entries(
            getGlobalProperties(model as BaseMultiPageModel<MultiPageImageModel | MultiPageVideoModel>),
        ).some(([id, globalProperty]: [id: string, globalProperty: any]) => {
            const templateGlobalProperty = clone(templateElements[id]);
            const { tmpl, prop } = getComparableObj(globalProperty.type, globalProperty, templateGlobalProperty);

            return !equals(tmpl, prop);
        });

        if (hasGlobalChanges) {
            return true;
        }

        const modelMetaData = modelsMetaData[pageIndex];
        const pageId = modelMetaData.id;
        const pageProperties = template.pages.find((page) => +page.id === +pageId)?.properties || [];

        return model.getAllElementsRecursively().some((element) => {
            const templateElement = clone({
                ...templateElements[element.id],
                properties: { ...(templateElements[element.id]?.properties || {}) },
            });

            pageProperties
                .filter((p) => p.templateElementId === element.id)
                .forEach((item) => {
                    templateElement.properties[item.propertyName] = item.value;
                });

            const { tmpl, prop } = getComparableObj(getElementType(element), element, templateElement);

            return !equals(tmpl, prop);
        });
    });
};

export function applyVariationDataToCreativeModel<TModel extends BaseModel>(
    variation,
    creativeModel: MultiPageImageModel | MultiPageVideoModel,
    options?: { skipGlobalAudio?: boolean },
) {
    const models = creativeModel.getModels();
    const modelsMetaData = creativeModel.getModelsMetaData();
    const { skipGlobalAudio = false } = options || {};

    modelsMetaData.forEach(({ id: sizeId }, index) => {
        const globalProperties = getGlobalProperties(models[index]);
        const { elements } = variation?.sizes[sizeId];
        const globalPropertiesToUpdate: {
            GLOBAL_AUDIO_1: any;
            GLOBAL_AUDIO_2: any;
            POSTER_FRAME?: any;
            BACKGROUND_COLOR?: any;
        } = {
            GLOBAL_AUDIO_1: EMPTY_AUDIO_PARAMS,
            GLOBAL_AUDIO_2: EMPTY_AUDIO_PARAMS,
        };

        const updateElementPayload = Object.entries(elements)
            .map(([elId, value]) => {
                if (globalProperties[elId]) {
                    if (globalProperties[elId].type === ElementTypes.GLOBAL_AUDIO && !skipGlobalAudio) {
                        if (globalProperties[elId].name === 'Global audio track 2') {
                            globalPropertiesToUpdate.GLOBAL_AUDIO_2 = value;
                        } else {
                            globalPropertiesToUpdate.GLOBAL_AUDIO_1 = value;
                        }
                    }

                    if (globalProperties[elId].type === ElementTypes.POSTER_FRAME) {
                        globalPropertiesToUpdate.POSTER_FRAME = value;
                    }

                    if (globalProperties[elId].type === ElementTypes.BACKGROUND_COLOR) {
                        globalPropertiesToUpdate.BACKGROUND_COLOR = value;
                    }

                    return null;
                }

                const params = {
                    ...(value as any),
                    id: Number(elId),
                };

                if (
                    params.naturalDimension &&
                    params.naturalDimension.width === 0 &&
                    params.naturalDimension.height === 0
                ) {
                    delete params.naturalDimension;
                }

                return params;
            })
            .filter(Boolean);

        Object.entries(globalPropertiesToUpdate).forEach(([elementType, params]) => {
            const options: UpdateGlobalPropertyOptions = {};
            if (elementType.startsWith('GLOBAL_AUDIO')) {
                options.audioTrackNumber = Number(elementType.substring('GLOBAL_AUDIO_'.length)) as 1 | 2;
                options.skipAssetDestroy = skipGlobalAudio;
                elementType = ElementTypes.GLOBAL_AUDIO;
            }

            models[index].updateGlobalProperty(elementType, params, options);
        });

        models[index].updateElements(updateElementPayload);
    });
}
