import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { equals } from 'rambda';
import { type BackgroundColorParams, type ColorParams, ShapeElement, TextElement } from '@bynder-studio/render-core';
import { useTranslate } from '@bynder/localization';
import { BrandColor } from 'packages/store/brandColors/types';
import { colorPalettes } from 'packages/store/brandColors/brandColors.selectors';
import { colorToCSS, PREVIEW_CANVAS_ID, rgbaToObject } from '~/common/editor/editorHelper';
import { EditorColorContainer, SidebarSection } from './EditorColor.styled';
import { hexToRgb } from '../ColorPicker/utils';
import ColorSearch from './components/ColorSearch';
import ColorPicker from '../ColorPicker';
import { getExistingBrandColor } from './utils';
import ColorDropdown from './components/ColorsDropdown';
import ColorDetach from './components/ColorDetachDropdown';

export type BrandColorOnElement = BrandColor & { paletteName: string };

type Props = {
    disabled: boolean;
    color: ColorParams;
    onChange: (name: string, value: { brandColorId: number | undefined; color: ColorParams }) => void;
    name?: string;
    property: string;
    testId: string;
    selectedElement?: any;
    configureColorsButton?: JSX.Element | null;
    elementColors?: ColorParams[] | [];
    selectedCustomColors?: ColorParams[] | [];
    onAppliedClick?: (color: BrandColorOnElement | ColorParams) => void;
};

const parsePropertyName = (element: TextElement | ShapeElement | BackgroundColorParams, propertyName: string) =>
    propertyName.split('.').reduce((accum, current) => accum[current], element);

export const EditorColor = ({
    color,
    onChange,
    disabled,
    selectedElement,
    name,
    property,
    testId,
    configureColorsButton = null,
    elementColors = [],
    selectedCustomColors = [],
    onAppliedClick = () => {},
}: Props) => {
    const { translate } = useTranslate();
    const { palettes } = useSelector(colorPalettes);

    const selectedColorProperty =
        name === 'dropShadow' ? selectedElement[name]?.color : parsePropertyName(selectedElement, property);

    const [selectedBrandColor, setSelectedBrandColor] = useState<BrandColorOnElement | null>(
        getExistingBrandColor(palettes, selectedColorProperty?.brandColorId) || null,
    );

    const emitChange = (value) => {
        if (equals(color, value)) {
            return;
        }

        onChange(property, value);
    };

    const setColor = (newValue) => {
        emitChange({ brandColorId: null, ...rgbaToObject(newValue) });
    };

    const setBrandColor = (brandColor: BrandColor) => {
        const { hexCode, alpha, id } = brandColor;
        const pickedBrandColor = getExistingBrandColor(palettes, id);

        if (pickedBrandColor) {
            setSelectedBrandColor(pickedBrandColor);
        }

        onChange(property, { brandColorId: id, ...rgbaToObject(hexToRgb(hexCode, alpha)) });
    };

    const onColorDetach = () => {
        if (selectedBrandColor) {
            const { hexCode, alpha } = selectedBrandColor;
            setSelectedBrandColor(null);
            emitChange({ brandColorId: null, ...rgbaToObject(hexToRgb(hexCode, alpha)) });
        }
    };

    const colorPickerValue = () => {
        if (selectedBrandColor) {
            return hexToRgb(selectedBrandColor.hexCode, selectedBrandColor.alpha);
        }

        return colorToCSS(color);
    };

    const selectedBrandColors = selectedBrandColor ? [selectedBrandColor] : [];

    useEffect(() => {
        if (!selectedColorProperty) {
            return;
        }

        if (!selectedColorProperty?.brandColorId) {
            setSelectedBrandColor(null);
        } else {
            const brandColor = getExistingBrandColor(palettes, selectedColorProperty?.brandColorId);
            setSelectedBrandColor(brandColor);
        }
    }, [selectedColorProperty]);

    return (
        <EditorColorContainer justifyContent="space-between" alignItems="center">
            {!selectedBrandColor?.id ? (
                <SidebarSection>
                    <ColorPicker
                        id={selectedElement?.id}
                        name="elementPropertyColor"
                        testId={testId}
                        handleOnChange={setColor}
                        isValid
                        value={colorPickerValue()}
                        canvas={PREVIEW_CANVAS_ID}
                        disabled={selectedElement?.locked || disabled}
                        label={translate('editor.sidebar.shots.colorpicker.label')}
                        htmlFor="element_property_color"
                        describedby="element_property_color_message"
                    />
                    <ColorDropdown
                        testId={testId}
                        colors={[color]}
                        palettes={palettes}
                        selectedBrandColors={selectedBrandColors}
                        disabled={selectedElement?.locked || disabled}
                    >
                        <ColorSearch
                            onAppliedClick={onAppliedClick}
                            elementColors={elementColors}
                            configureColorsButton={configureColorsButton}
                            selectedBrandColors={selectedBrandColors}
                            selectedCustomColors={selectedCustomColors}
                            setBrandColor={setBrandColor}
                            palettes={palettes}
                            testId={testId}
                        />
                    </ColorDropdown>
                </SidebarSection>
            ) : (
                <>
                    <ColorDropdown
                        testId={testId}
                        colors={[color]}
                        palettes={palettes}
                        selectedBrandColors={selectedBrandColors}
                        disabled={selectedElement?.locked || disabled}
                    >
                        <ColorSearch
                            onAppliedClick={onAppliedClick}
                            elementColors={elementColors}
                            configureColorsButton={configureColorsButton}
                            selectedBrandColors={selectedBrandColors}
                            setBrandColor={setBrandColor}
                            palettes={palettes}
                            testId={testId}
                        />
                    </ColorDropdown>
                    <ColorDetach
                        testId={testId}
                        onColorDetach={onColorDetach}
                        disabled={selectedElement?.locked || disabled}
                    />
                </>
            )}
        </EditorColorContainer>
    );
};

export default EditorColor;
