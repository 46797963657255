/**
 * Changes to group elements will propagate to its elements in final calculations
 * Changes in elements already in groups will not propagate up to the group (for the bounding box)
 */
import { Box } from '../../Shared/Box';
import { Dimension } from '../../Shared/Dimension';
import { Position } from '../../Shared/Position';
import { BaseCompElement, type BaseCompElementParams } from './BaseCompElement';
import { Region } from '../../Shared/Region';
import { RegionTypes } from '../../../Enums/RegionTypes';
import { transformRegion } from '../../../Helpers/compositorUtils';
import { DropShadowEffect } from '../../Effects/DropShadowEffect';
import { BlurEffect } from '../../Effects/BlurEffect';
import { MotionBlurEffect } from '../../Effects/MotionBlurEffect';

export class GroupCompElement extends BaseCompElement {
    private children: BaseCompElement[];

    public mergeLineIdx: number | null = null;

    public isStartMergeLine = false;

    constructor(childCompElements: BaseCompElement[], makeChildrenRelative = true) {
        super();
        this.children = childCompElements;
        // register group as parent for elements
        this.children.forEach((compEl) => {
            compEl.parent = this;
        });
        // update positions to be relative. children are assumed to contain absolute positions.
        this.updatePositions(makeChildrenRelative);
    }

    setProperties(params: BaseCompElementParams) {
        super.setProperties(params);
    }

    getChildren(): BaseCompElement[] {
        return this.children;
    }

    setChildren(childCompElements: BaseCompElement[]) {
        this.children = childCompElements;
        // register group as parent for elements
        this.children.forEach((compEl) => {
            compEl.parent = this;
        }); // update positions to be relative. children are assumed to contain absolute positions.
        // this.updatePositions(makeChildrenRelative);
    }

    /**
     * Get the (local) region relative to the provided element content origin
     *
     * In case of a group, the region always includes the children regions with type EFFECT
     *
     * When motion blur is enabled, the content origin will be that of the current frame and extended with the open and close shutter data
     *
     * Note that we need to take transformations into account when recursively calculating the group regions
     *
     * Types:
     * - Untransformed - Region of content without transforms or effects applied
     * - Transformed - Region of content with transforms, but no effects applied
     * - Effect - Region of content with transforms and effects applied. Note that effects are always applied to the transformed region.
     *
     * @type the region type indicating what to include in the region
     * @returns Region
     */
    public getRegion(type: string = RegionTypes.TRANSFORMED) {
        const hasTransforms = this.rotation != 0 || this.scale != 1;
        const hasOverflowEffects = !!this.dropShadow || this.blur !== 0;
        const hasMotionBlur = this.requiresMotionBlur();

        // create content region for element
        let region = null;

        // in case of a group, we calculate the region based on the children
        const regions: Region[] = [];
        this.getChildren().forEach((childEl) => {
            const r = childEl.getRegion(RegionTypes.EFFECT);

            if (r) {
                const t = r.getTranslation(
                    childEl.boundingBox.position.x + childEl.contentBox.position.x,
                    childEl.boundingBox.position.y + childEl.contentBox.position.y,
                );
                regions.push(t);
            }
        });

        if (regions.length) {
            region = regions.reduce((p, c) => p.getUnion(c));
        } else {
            // TODO: check if this is correct.
            return new Region(0, 0, this.contentBox.dimension.width, this.contentBox.dimension.height);

            return new Region(
                Number.POSITIVE_INFINITY,
                Number.POSITIVE_INFINITY,
                Number.NEGATIVE_INFINITY,
                Number.NEGATIVE_INFINITY,
            ); // can be the case when no children are visible
        }

        if (type == RegionTypes.UNTRANSFORMED) {
            return region;
        }

        // Determine the transformed region
        if (hasMotionBlur) {
            // in case motion blur is enabled, the motion blur includes the transforms
            region = MotionBlurEffect.getRegionOfInterest(region, this);
        } else if (hasTransforms) {
            const transform = this.getScaleAndRotationTransform();
            region = transformRegion(transform, region);
        }

        // TODO: apply clip effect to transformed region
        // region = ClipEffect.getRegionOfInterest(region, el);

        // In case of effects, apply to the transformed region
        if (type == RegionTypes.EFFECT && hasOverflowEffects) {
            region = DropShadowEffect.getRegionOfInterest(region, this);
            region = BlurEffect.getRegionOfInterest(region, this);
        }

        return region;
    }

    // Calculate bounding dimensions and positions on the basis of children
    // Position is relative to the box position,
    // BoxPosition is relative to the parent position
    // We can switch between abs. and rel. calculations by adding the bBox position
    private calcBoxByChildren(propName: 'bgBoundingBox' | 'contentBox', includeBBox = false) {
        let tlX: number = Number.POSITIVE_INFINITY; // top left
        let tlY: number = Number.POSITIVE_INFINITY;
        let brX: number = Number.NEGATIVE_INFINITY; // bottom right
        let brY: number = Number.NEGATIVE_INFINITY;

        this.children.forEach((compEl) => {
            if (!compEl[propName]) {
                return;
            }

            const { x, y } = compEl[propName].position;
            const { width, height } = compEl[propName].dimension;
            const bBoxX = includeBBox ? compEl.boundingBox.position.getX() : 0;
            const bBoxY = includeBBox ? compEl.boundingBox.position.getY() : 0;

            tlX = Math.min(x + bBoxX, tlX);
            tlY = Math.min(y + bBoxY, tlY);
            brX = Math.max(x + width + bBoxX, brX);
            brY = Math.max(y + height + bBoxY, brY);
        });

        return { tlX, tlY, brX, brY };
    }

    /**
     * The bounding box of a group element matches the contentBoxes of its elements (no padding).
     * A result of this is that:
     * - the content box of a group element always has position 0, 0.
     * - the dimension of the content and bounding boxes are always the same.
     */
    private updatePositions(makeChildrenRelative = true) {
        // Groups always fit the content of the children
        const bBoxData = this.calcBoxByChildren('contentBox', true);

        const isBoxValid = (boxCords: typeof bBoxData) =>
            boxCords.tlX !== Number.POSITIVE_INFINITY &&
            boxCords.brX !== Number.NEGATIVE_INFINITY &&
            boxCords.tlY !== Number.POSITIVE_INFINITY &&
            boxCords.brY !== Number.NEGATIVE_INFINITY;

        if (isBoxValid(bBoxData)) {
            const dimension: Dimension = new Dimension(bBoxData.brX - bBoxData.tlX, bBoxData.brY - bBoxData.tlY);

            this.contentBox = new Box(new Position(0, 0), dimension);
            this.boundingBox = new Box(new Position(bBoxData.tlX, bBoxData.tlY), dimension);
        } else {
            this.contentBox = new Box(new Position(0, 0), new Dimension(0, 0));
            this.boundingBox = new Box(new Position(0, 0), new Dimension(0, 0));
        }

        const bgBBoxData = this.calcBoxByChildren('bgBoundingBox');

        if (isBoxValid(bgBBoxData)) {
            this.bgBoundingBox = new Box(
                new Position(bgBBoxData.tlX, bgBBoxData.tlY),
                new Dimension(bgBBoxData.brX - bgBBoxData.tlX, bgBBoxData.brY - bgBBoxData.tlY),
            );
        }

        // Update children with relative coordinates
        if (makeChildrenRelative) {
            this.children.forEach((compEl) => {
                const oldBoundingBoxPosition = this.boundingBox.position;

                compEl.boundingBox.position = compEl.boundingBox.position.subtract(oldBoundingBoxPosition);

                if (compEl.bgBoundingBox) {
                    compEl.bgBoundingBox.position = compEl.bgBoundingBox.position.subtract(oldBoundingBoxPosition);
                }
            });
        }
    }

    getLocalHashData() {
        return {
            ...super.getLocalHashData(),
            children: this.children.map((el) => el.getLocalHashData()),
        };
    }

    hasOverflowEffects(): boolean {
        const blendMode = this.blendMode?.getCompositeOperation() || 'source-over';
        const isGroupWithBlendMode = !!(this.children || []).length && blendMode !== 'source-over';

        return isGroupWithBlendMode || super.hasOverflowEffects();
    }

    toObject() {
        return { ...super.toObject(), type: 'GROUP', children: this.children.map((el) => el.toObject()) };
    }
}
