import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompositor } from '../../Compositor/ICompositor';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import { VideoAsset } from '../../Models/Assets/VideoAsset';
import { VideoCompElement } from '../../Models/CompModels/Elements/VideoCompElement';
import { Dimension } from '../../Models/Shared/Dimension';
import { type VideoModel } from '../../Models/Models/VideoModel';
import type { IShotRenderer } from './IShotRenderer';
import { RequireFramesUpdateEventData, ShotsReplacedEventData } from '../../event-types';
import { frameIndexToHTMLSeekTime } from '../../Helpers/framesToTime';
import { type Shot } from '../../Models/Shot/Shot';
import { CompElement } from '../../types';

export class ShotRenderer extends DynamicEventEmitter implements IShotRenderer {
    private assetLoader: IAssetsLoader;

    private compositor: ICompositor;

    private videoModel: VideoModel;

    private shotDimension: Dimension;

    private shotProcessingQueue: number[];

    private requireFramesUpdateListener = ({ startFrame, endFrame }: RequireFramesUpdateEventData) =>
        this.handleFramesUpdateRequest(startFrame, endFrame);

    private framesUpdateParams: {
        startFrame: number;
        endFrame: number;
    } = {
        startFrame: 0,
        endFrame: 0,
    };

    private framesUpdateTimout: ReturnType<typeof setTimeout> = null;

    constructor(videoModel: VideoModel, assetLoader: IAssetsLoader, compositor: ICompositor) {
        super();
        this.videoModel = videoModel;
        this.compositor = compositor;
        this.assetLoader = assetLoader;
        this.shotDimension = new Dimension(200, 150);
        this.shotProcessingQueue = [];
        this.videoModel.eventEmitter.on('requireFramesUpdate', this.requireFramesUpdateListener);
        this.videoModel.eventEmitter.on('shotUpdated', ({ shot }) => this.drawShot(shot));
        this.videoModel.eventEmitter.on('shotsReplaced', ({ shots }: ShotsReplacedEventData) => {
            shots.forEach((shot) => this.shotProcessingQueue.push(shot.getDisplayOrder()));
            this.processShots();
        });
    }

    private handleFramesUpdateRequest(startFrame: number, endFrame: number): void {
        const { startFrame: start, endFrame: end } = this.framesUpdateParams;
        this.framesUpdateParams.startFrame = start === -1 ? startFrame : Math.min(start, startFrame);
        this.framesUpdateParams.endFrame = Math.max(end, endFrame);

        if (this.framesUpdateTimout) {
            clearTimeout(this.framesUpdateTimout);
        }

        this.framesUpdateTimout = setTimeout(() => {
            const { startFrame: startFrameParam, endFrame: endFrameParam } = this.framesUpdateParams;
            this.framesUpdateParams.startFrame = -1;
            this.framesUpdateParams.endFrame = -1;
            this.framesUpdateTimout = null;
            const shots = this.videoModel.getShots();

            for (const shot of shots) {
                const shotFrame = shot.getDisplayFrame();

                if (shotFrame >= startFrameParam && shotFrame <= endFrameParam) {
                    if (!this.shotProcessingQueue.includes(shot.getDisplayOrder())) {
                        this.shotProcessingQueue.push(shot.getDisplayOrder());
                    }
                }
            }

            this.processShots();
        }, 100);
    }

    async processShots() {
        if (this.shotProcessingQueue.length) {
            const shotIndex = this.shotProcessingQueue.shift();
            const shots = this.videoModel.getShots();
            const shot = shots.find((s) => s.getDisplayOrder() === shotIndex);

            if (!shot) {
                return;
            }

            await this.drawShot(shot);
            await this.processShots();
        }
    }

    setShotSize(width: number, height: number): void {
        this.shotDimension = new Dimension(width, height);
    }

    async drawShots() {
        this.calculateAndSetScale();
        const shots = this.videoModel.getShots();

        for (const shot of shots) {
            this.shotProcessingQueue.push(shot.getDisplayOrder());
        }

        await this.processShots();
    }

    private async drawShot(shot: Shot): Promise<void> {
        const frameRate = this.videoModel.getPlaybackDuration().getFrameRate();
        const frameIndex = shot.getDisplayFrame();
        const compModel = this.videoModel.getCompModel(frameIndex);
        // todo: improve
        compModel.setElements(this.videoModel.getVisibleElements(frameIndex));
        await Promise.all(
            compModel.getAllCompElementsRecursively().map((el) => this.seekVideoCompElement(el, frameIndex, frameRate)),
        );
        this.compositor.drawCompModel(compModel);
        const dataUrl = this.compositor.getDataUrl();
        this.emit('frameUpdate', {
            id: shot.id,
            shotIndex: shot.getDisplayOrder(),
            dimension: this.compositor.getCompositorDimension(),
            frameIndex,
            dataUrl,
        });
    }

    private seekVideoCompElement = async (el: CompElement, frameIndex: number, frameRate: number) => {
        if (el instanceof VideoCompElement) {
            const loadedVideoAsset = this.assetLoader.getAsset(el.assetId) as VideoAsset;

            if (!loadedVideoAsset || !loadedVideoAsset.object) {
                return;
            }

            const seekTime = frameIndexToHTMLSeekTime(frameIndex, frameRate);
            const key = parseFloat(seekTime.toFixed(2));

            if (!loadedVideoAsset.frameBuffer.has(key)) {
                await loadedVideoAsset.createFrameBuffer(frameIndex);
            }
        }
    };

    private calculateAndSetScale = () => {
        // current shot wrapper page
        const kBox = this.shotDimension.getWidth() / this.shotDimension.getHeight();
        // template page
        const templateDimension = this.videoModel.getDimensions();
        const kTemplate = templateDimension.getWidth() / templateDimension.getHeight();
        let scale = 1;
        let newDimension;

        if (kTemplate < kBox) {
            const newWidth = kTemplate * this.shotDimension.getHeight();
            newDimension = new Dimension(newWidth, this.shotDimension.getHeight());
            scale = newWidth / templateDimension.getWidth();
        } else {
            const newHeight = this.shotDimension.getWidth() / kTemplate;
            newDimension = new Dimension(this.shotDimension.getWidth(), newHeight);
            scale = this.shotDimension.getWidth() / templateDimension.getWidth();
        }

        this.compositor.setCompositorDimension(newDimension);
        this.compositor.setScale(scale);
    };
}
