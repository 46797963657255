import { type IAssetsLoader, type ICompositor, type MultiPageImageModel } from '@bynder-studio/render-core';
import CanvasManipulation from '../../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import { BaseManipulationRenderer } from '../BaseManipulationRenderer/BaseManipulationRenderer';

export class ImageManipulationRenderer extends BaseManipulationRenderer {
    declare creativeModel: MultiPageImageModel;

    constructor(
        imageModel: MultiPageImageModel,
        assetLoader: IAssetsLoader,
        compositor: ICompositor,
        canvasManipulation: CanvasManipulation,
    ) {
        super(imageModel, assetLoader, compositor, canvasManipulation);
    }

    private handleCompModelUpdate = () => this.drawCompModel();

    async init() {
        await super.init();
        await this.drawCompModel();
        this.getCreativeModel().eventEmitter.on('requireCreativeUpdate', this.handleCompModelUpdate);
    }

    drawCompModel(): Promise<boolean> {
        this.redrawCompModel(this.creativeModel.getCompModel());

        return Promise.resolve(true);
    }

    redraw() {
        const compModel = this.creativeModel.getCompModel();

        this.redrawCompModel(compModel);
    }
}
