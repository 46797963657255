import React, { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, EmptyState, List } from '@bynder/design-system';
import { IconVideoTemplate } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import { getGallery } from 'packages/store/gallery/gallery.selectors';
import { selectedGallerySelector } from '~/store/gallery/gallery.selectors';
import { Grid } from 'packages/ds/Grid/Grid';
import { setPage, deselectBlankType } from 'packages/store/newDesign/newDesign.actions';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import { dropImportedTemplate } from 'packages/store/importTemplate/importTemplate.actions';
import { TemplateCard, GalleryTemplateCard } from 'packages/pages/components/templates/TemplateCard';
import useTemplateRenameModal from 'packages/pages/components/templates/useTemplateRenameModal';
import { Template } from 'packages/store/templates/types';
import { HiddenActions } from 'packages/pages/components/templates/TemplateContextMenu/types';
import features from '~/configs/features';
import { initializeGallery, deselectGallery, selectGalleryItem } from '~/store/gallery/gallery.actions';
import { selectTemplate, deselectTemplate, initializeTemplates } from '~/store/templates/templates.actions';
import Blank from '../../components/Blank';
import { EmptyContainer, ModalContent, StyledGridContainer } from './all.styled';
import { DesignSubPages } from '../../components/Sidebar/types';
import useTemplateDeleteModal from '../../useTemplateDeleteModal';
import { PageTitle } from '../../DesignCreateModal.styled';
import useTemplateShareModal from '../../../shareModal/useTemplateShareModal';
import useGroupedTemplates from './useGroupedTemplates';

const HIDDEN_MENU_ACTIONS: HiddenActions = ['editTemplate', 'removeFromCategory', 'setMoveModalTemplate'];

function AllPage() {
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const gallery = useSelector(getGallery);
    const { renderModalTemplateRename, setRenameModalTemplate } = useTemplateRenameModal({ isNewDesignModal: true });
    const { renderModalTemplateDelete, setDeleteModalTemplate } = useTemplateDeleteModal();
    const { renderModalTemplateShare, setShareModalTemplate } = useTemplateShareModal({});
    const { isPlatformAllowed } = useAccessRights();
    const { setQueryParam } = useQueryParams();

    const { categories, categoriesLoading, templates, templatesLoading, selectedTemplate, duplicateTemplate } =
        useGroupedTemplates();
    const selectedGalleryTemplate: Template | null = useSelector(selectedGallerySelector);
    const isLoading = categoriesLoading || templatesLoading;

    useLayoutEffect(() => {
        dispatch(dropImportedTemplate());
        dispatch(initializeTemplates());
        dispatch(initializeGallery());
    }, []);

    const onTemplateSelect = useCallback(
        (template: Template) => {
            dispatch(selectTemplate(template));
            dispatch(deselectBlankType(true));
            dispatch(deselectGallery());
        },
        [dispatch],
    );

    const onGalleryTemplateSelect = useCallback(
        (template: Template) => {
            dispatch(selectGalleryItem(template));
            dispatch(deselectBlankType(true));
            dispatch(deselectTemplate());
        },
        [dispatch],
    );

    const previewTemplate = useCallback(
        (template: Template) => {
            dispatch(selectTemplate(template));
            setQueryParam('template', template.id.toString());
            dispatch(setPage(DesignSubPages.TEMPLATE_PREVIEW));
        },
        [dispatch],
    );

    const previewGallery = useCallback(
        (template: Template) => {
            dispatch(selectGalleryItem(template));
            dispatch(setPage(DesignSubPages.GALLERY_PREVIEW));
        },
        [dispatch],
    );

    return (
        <>
            <ModalContent id="new_design_modal_all" data-testid="design create all templates overview">
                <Blank />
                {!isLoading && !categories.length && !templates.length && (
                    <EmptyContainer>
                        <EmptyState
                            icon={<IconVideoTemplate />}
                            title={translate('modal.design.create.empty.templates.title')}
                            text={translate('modal.design.create.empty.templates.info')}
                        />
                    </EmptyContainer>
                )}
                {!!categories.length &&
                    !categoriesLoading &&
                    categories.map(({ id, templates: categoryTemplates }) =>
                        categoryTemplates && categoryTemplates.length ? (
                            <>
                                <List.Item
                                    rightElements={
                                        <Button
                                            variant="clean"
                                            onClick={() => {
                                                dispatch(setPage(DesignSubPages.CATEGORY_DETAILS));
                                                setQueryParam('categoryId', id.toString());
                                            }}
                                            title={translate('modal.design.create.all.show')}
                                        >
                                            {translate('modal.design.create.all.show')}
                                        </Button>
                                    }
                                >
                                    <PageTitle>{categoryTemplates[0].categoryName || ''}</PageTitle>
                                </List.Item>
                                <StyledGridContainer id={id.toString()} data-testid="design create all category">
                                    <Grid>
                                        {categoryTemplates.map((template) => (
                                            <TemplateCard
                                                key={template.id}
                                                template={template}
                                                isSelected={selectedTemplate?.id === template.id}
                                                onSelect={onTemplateSelect}
                                                onClick={onTemplateSelect}
                                                menuActions={{
                                                    setRenameModalTemplate: () =>
                                                        setRenameModalTemplate(template, true),
                                                    setDeleteModalTemplate,
                                                    setShareModalTemplate,
                                                    showPreview: previewTemplate,
                                                    duplicateTemplate,
                                                }}
                                                hiddenMenuActions={HIDDEN_MENU_ACTIONS}
                                            />
                                        ))}
                                    </Grid>
                                </StyledGridContainer>
                            </>
                        ) : null,
                    )}
                {!!templates.length && !templatesLoading && (
                    <>
                        <List.Item
                            rightElements={
                                <Button
                                    variant="clean"
                                    onClick={() => {
                                        dispatch(setPage(DesignSubPages.CATEGORIES));
                                    }}
                                    title={translate('modal.design.create.all.show')}
                                >
                                    {translate('modal.design.create.all.show')}
                                </Button>
                            }
                        >
                            <PageTitle id="new_design_modal_all_uncategorized_templates">
                                {translate('modal.design.create.uncategorized_templates.title')}
                            </PageTitle>
                        </List.Item>
                        <StyledGridContainer
                            id="new_design_modal_all_uncategorized_templates_body"
                            data-testid="design create all uncategorized templates"
                        >
                            <Grid>
                                {!templatesLoading &&
                                    templates.map((template) => (
                                        <TemplateCard
                                            key={template.id}
                                            template={template}
                                            isSelected={selectedTemplate?.id === template.id}
                                            onSelect={onTemplateSelect}
                                            onClick={onTemplateSelect}
                                            menuActions={{
                                                setRenameModalTemplate,
                                                setDeleteModalTemplate,
                                                setShareModalTemplate,
                                                showPreview: previewTemplate,
                                                duplicateTemplate,
                                            }}
                                            hiddenMenuActions={HIDDEN_MENU_ACTIONS}
                                        />
                                    ))}
                            </Grid>
                        </StyledGridContainer>
                    </>
                )}

                {isPlatformAllowed([features.GALLERY_ENABLED]) &&
                    gallery.templates.length > 0 &&
                    !gallery.loadingData && (
                        <>
                            <List.Item
                                rightElements={
                                    <Button
                                        variant="clean"
                                        onClick={() => dispatch(setPage(DesignSubPages.GALLERY))}
                                        title={translate('modal.design.create.all.show')}
                                    >
                                        {translate('modal.design.create.all.show')}
                                    </Button>
                                }
                            >
                                <PageTitle>{translate('modal.design.create.gallery.title')}</PageTitle>
                            </List.Item>

                            <StyledGridContainer
                                id="new_design_modal_all_gallery_templates"
                                data-testid="design create all gallery overview"
                            >
                                <Grid>
                                    {gallery.templates.map((template) => (
                                        <GalleryTemplateCard
                                            key={template.id}
                                            template={template}
                                            onSelect={onGalleryTemplateSelect}
                                            onClick={onGalleryTemplateSelect}
                                            isSelected={selectedGalleryTemplate?.id === template.id}
                                            showPreview={previewGallery}
                                        />
                                    ))}
                                </Grid>
                            </StyledGridContainer>
                        </>
                    )}
            </ModalContent>
            {renderModalTemplateRename()}
            {renderModalTemplateDelete()}
            {renderModalTemplateShare()}
        </>
    );
}

export default AllPage;
