import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Tooltip } from '@bynder/design-system';
import { IconCancel, IconFilterFunnel } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useQueryParams from 'packages/hooks/useQueryParams';
import { ExportFilterProps } from './types';
import { ActivityFeedFilter } from 'packages/store/activityFeed/types';

const Export = ({ value, onSelect, ...props }: ExportFilterProps) => {
    const [option, setOption] = useState('');
    // const [showUnread, setShowUnread] = useState(false);
    const { translate } = useTranslate();
    const { getQueryParam, resetQueryParams } = useQueryParams();

    const onExportOptionSelect = (option: ActivityFeedFilter) => {
        setOption(option);
        onSelect(option);
    };

    // Might be used in the future
    //
    // const onShowUnreadSelect = () => {
    //   setShowUnread(!showUnread);
    //   onSelect(ActivityFeedFilter.SHOW_UNREAD);
    // };

    const optionsTitles = {
        [ActivityFeedFilter.ALL]: translate('filters.export.all'),
        [ActivityFeedFilter.DOWNLOAD]: translate('filters.export.download'),
        [ActivityFeedFilter.WAITING_ROOM]: translate('filters.export.waiting-room'),
        [ActivityFeedFilter.SHOW_UNREAD]: translate('filters.export.show-unread'),
    };

    useEffect(() => {
        const queriedExportFilter = getQueryParam('export')?.toUpperCase();

        if (queriedExportFilter && Object.keys(ActivityFeedFilter).includes(queriedExportFilter)) {
            setOption(ActivityFeedFilter[queriedExportFilter]);
        } else {
            setOption(ActivityFeedFilter.ALL);
        }
    }, []);

    useEffect(() => {
        if (!value) {
            setOption(ActivityFeedFilter.ALL);
        }
    }, [value]);

    return (
        <>
            <Dropdown
                position="top-left"
                trigger={({ isOpen, ...triggerProps }) => (
                    <Button
                        data-testid="activity feed export options filter"
                        variant="clean"
                        isPressed={isOpen}
                        {...triggerProps}
                        aria-label={translate('filters.export.label')}
                        rightIcon={<Dropdown.Arrow />}
                        icon={<IconFilterFunnel />}
                    >
                        {translate('pages.activity.filter')}{' '}
                        {!option.length ? optionsTitles[ActivityFeedFilter.ALL] : optionsTitles[option]}
                    </Button>
                )}
                {...props}
            >
                <Dropdown.Item onClick={() => onExportOptionSelect(ActivityFeedFilter.ALL)} isChecked={!option.length}>
                    {optionsTitles[ActivityFeedFilter.ALL]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedFilter.DOWNLOAD)}
                    isChecked={option === ActivityFeedFilter.DOWNLOAD}
                >
                    {optionsTitles[ActivityFeedFilter.DOWNLOAD]}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onExportOptionSelect(ActivityFeedFilter.WAITING_ROOM)}
                    isChecked={option === ActivityFeedFilter.WAITING_ROOM}
                >
                    {optionsTitles[ActivityFeedFilter.WAITING_ROOM]}
                </Dropdown.Item>
                {/* Might be used in the future
          <Dropdown.Divider />
            <Dropdown.Item onClick={onShowUnreadSelect} isChecked={showUnread} checkedVariant="switch">
                {optionsTitles[ActivityFeedFilter.SHOW_UNREAD]}
        </Dropdown.Item> */}
            </Dropdown>
            {option.length > 0 && (
                <Tooltip content={translate('pages.activity.clear-filters')}>
                    <Button
                        variant="clean"
                        title="Clear filter"
                        onClick={() => {
                            resetQueryParams(['export']);
                            onExportOptionSelect(ActivityFeedFilter.ALL);
                        }}
                        icon={<IconCancel />}
                    />
                </Tooltip>
            )}
        </>
    );
};

export default Export;
