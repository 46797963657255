import { JOB_PROGRESS_STATUS } from 'packages/pages/activity/types';
import { ExportJobItemType } from 'packages/variation-export/AllExports/types';

export enum ActivityFeedFilter {
    ALL = '',
    DOWNLOAD = 'DOWNLOAD',
    WAITING_ROOM = 'WAITING_ROOM',
    SHOW_UNREAD = 'SHOW_UNREAD',
}

export type ActivityFeedOptions = {
    page: number;
    size: number;
    exportJobType: ActivityFeedFilter;
    provideProgress: boolean;
    includeDeleted: boolean;
    sortingField: string;
    sortingOrder: 'ASC' | 'DESC';
    search: string;
    exportJobVariationStatus: JOB_PROGRESS_STATUS[];
};

export type ActivityFeedState = {
    options: ActivityFeedOptions;
    progressOptions: ActivityFeedOptions;
    isFetching: boolean;
    items: ExportJobItemType[];
    errors: any;
    totalItems: number;
    inProgressItems: ExportJobItemType[];
};

export enum actionTypes {
    INIT_ACTIVITY_FEED = 'INIT_ACTIVITY_FEED',
    GET_ACTIVITY_FEED_REQUEST = 'GET_ACTIVITY_FEED_REQUEST',
    GET_ACTIVITY_FEED_SUCCESS = 'GET_ACTIVITY_FEED_SUCCESS',
    GET_ACTIVITY_FEED_FAILURE = 'GET_ACTIVITY_FEED_FAILURE',
    GET_IN_PROGRESS_ACTIVITY_FEED_REQUEST = 'GET_IN_PROGRESS_IN_PROGRESS_ACTIVITY_FEED_REQUEST',
    GET_IN_PROGRESS_ACTIVITY_FEED_SUCCESS = 'GET_IN_PROGRESS_ACTIVITY_FEED_SUCCESS',
    GET_IN_PROGRESS_ACTIVITY_FEED_FAILURE = 'GET_IN_PROGRESS_ACTIVITY_FEED_FAILURE',
    DELETE_ACTIVITY_FEED_ITEM_SUCCESS = 'DELETE_ACTIVITY_FEED_ITEM_SUCCESS',
    DELETE_ACTIVITY_FEED_ITEM_FAILURE = 'DELETE_ACTIVITY_FEED_ITEM_FAILURE',
    PROGRESS_COMPLETE = 'PROGRESS_COMPLETE',
}
