import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserTimeZone } from 'packages/store/user/user.selectors';
import { getFriendlyTime } from '~/helpers/freindlyTime';

export const useFriendlyTime = () => {
    const timeZone = useSelector(getUserTimeZone);

    const getFriendlyTimeWithTz = useCallback((created) => getFriendlyTime(timeZone)(created), [timeZone]);

    return {
        getFriendlyTime: getFriendlyTimeWithTz,
    };
};
