import {
    ContentPropertiesManager,
    IAssetsLoader,
    PagePreviewRenderer,
    ShotRenderer,
    TextRenderer,
    TextStyles,
    VariationThumbnailRenderer,
    type VideoModel,
    VideoWebSpecificationParser,
} from '@bynder-studio/render-core';
import hbWasmURL from '@bynder-studio/hbjs/hb.wasm';
import { setFontFamilies } from '@bynder-studio/structured-text';
import { BrowserAssetsLoader } from '../AssetLoader/BrowserAssetsLoader';
import { CanvasLayerCompositor } from '../Compositor/CanvasLayerCompositor/CanvasLayerCompositor';
import CanvasManipulation from '../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import CanvasManipulationCompositor from '../Compositor/CanvasManipulationCompositor/CanvasManipulationCompositor';
import CanvasShotCompositor from '../Compositor/CanvasShotCompositor/CanvasShotCompositor';
import CanvasPagePreviewCompositor from '../Compositor/CanvasPagePreviewCompositor/CanvasPagePreviewCompositor';
import { PlaybackManipulationRenderer } from '../Renderers/PlaybackManipulationRenderer/PlaybackManipulationRenderer';
import { PlaybackRenderer } from '../Renderers/PlaybackRenderer/PlaybackRenderer';
import { CreativeModelFactoryConfig } from './types';

export const videoModelFactory = async ({
    template,
    defaultFontBlob,
    fontFamilies,
    textStyles,
    contentProperties,
    containerDocument,
}: CreativeModelFactoryConfig) => {
    // todo: improve
    const assetLoader = new BrowserAssetsLoader();
    const devicePixelRatio = window.devicePixelRatio;

    const loopInterval = (callback) => window.requestAnimationFrame(callback);

    setFontFamilies(fontFamilies);

    const hbWasmFile: any = await fetch(hbWasmURL).then((res) => res.arrayBuffer());
    (await TextRenderer.init(hbWasmFile)).setDefaultFont(defaultFontBlob);

    const textStylesInstance = new TextStyles();
    const contentPropertiesManager = new ContentPropertiesManager();
    const videoWebSpecificationParser = new VideoWebSpecificationParser();

    textStylesInstance.loadStyles(textStyles);
    contentPropertiesManager.loadContentProperties(
        VideoWebSpecificationParser.parseContentProperties(contentProperties, fontFamilies, template),
    );

    const multiSizeVideoModel = videoWebSpecificationParser
        .parseMultiPage(template, fontFamilies)
        .setAssetLoader(assetLoader as IAssetsLoader)
        .setTextStyles(textStylesInstance)
        .setContentPropertiesManager(contentPropertiesManager)
        .setup();

    return {
        getCreativeModel: () => multiSizeVideoModel,
        getAssetLoader: () => assetLoader,
        getCreativeRenderer: (canvasWrapperEl) => {
            const canvasCompositor = new CanvasLayerCompositor(
                canvasWrapperEl,
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );

            return new PlaybackRenderer(
                multiSizeVideoModel,
                assetLoader as IAssetsLoader,
                canvasCompositor,
                loopInterval,
            );
        },
        getCreativeManipulationRenderer: (canvasWrapperEl) => {
            const canvasCompositor = new CanvasManipulationCompositor(
                canvasWrapperEl,
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );
            const canvasManipulation = new CanvasManipulation(canvasCompositor, containerDocument, fontFamilies);

            return new PlaybackManipulationRenderer(
                multiSizeVideoModel,
                assetLoader as IAssetsLoader,
                canvasCompositor,
                canvasManipulation,
                loopInterval,
            );
        },
        getShotRenderer: () => {
            const canvasShotCompositor = new CanvasShotCompositor(assetLoader as IAssetsLoader, devicePixelRatio);

            return new ShotRenderer(
                multiSizeVideoModel as unknown as VideoModel,
                assetLoader as IAssetsLoader,
                canvasShotCompositor,
            );
        },
        getPagePreviewRenderer: () => {
            const canvasPagePreviewCompositor = new CanvasPagePreviewCompositor(
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );

            return new PagePreviewRenderer(
                multiSizeVideoModel,
                assetLoader as IAssetsLoader,
                canvasPagePreviewCompositor,
            );
        },
        getVariationThumbnailRenderer: () => {
            const canvasVariationThumbnailCompositor = new CanvasPagePreviewCompositor(
                assetLoader as IAssetsLoader,
                devicePixelRatio,
            );

            return new VariationThumbnailRenderer(assetLoader as IAssetsLoader, canvasVariationThumbnailCompositor);
        },
    };
};
