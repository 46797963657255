import { BaseCompElement, Position, Scrollbars, truthy } from '@bynder-studio/render-core';
import CanvasManipulationCompositor from './CanvasManipulationCompositor';
import ManipulationEmitter from './ManipulationEmitter';
import { type CompElement } from '@bynder-studio/render-core';

abstract class BaseManipulation {
    containerDocument: Document;

    canvas: HTMLCanvasElement = null;

    compositor: CanvasManipulationCompositor;

    emitter: ManipulationEmitter;

    abstract unsubscribe(): void;

    constructor(compositor: CanvasManipulationCompositor, containerDocument, emitter) {
        this.compositor = compositor;
        this.containerDocument = containerDocument;
        this.emitter = emitter;
        this.canvas = this.compositor.getCanvas();
    }

    getElementToSelect(skipGroup = false): number | null {
        if (!this.compositor.currentCompModel) {
            return null;
        }

        this.compositor.drawGhostCompModel(this.compositor.currentCompModel);
        const elements = this.compositor.currentCompModel
            .getAllCompElementsRecursively()
            .filter(truthy<BaseCompElement>);
        const calculatedMousePosition: Position = this.compositor.mousePosition.multiply(
            this.compositor.getScale() * this.compositor.devicePixelRatio,
        );
        const elId = this.compositor.getElementIdForPoint(calculatedMousePosition);

        if (([Scrollbars.HORIZONTAL, Scrollbars.VERTICAL] as number[]).includes(elId as number)) {
            return elId as number;
        }

        const elementToSelect = elements.find((el) => el.id === elId);

        const checkElementParent = (element) => {
            if (!element) {
                return null;
            }

            if (element.parent?.id) {
                const parentAlreadyChosen =
                    element?.parent?.id === this.compositor.currentSelectedGroupId ||
                    element?.parent?.id === this.compositor.currentParentId;

                if (parentAlreadyChosen) {
                    return element.id;
                }

                const parentElement = elements.find((el) => el.id === element.parent.id);

                return checkElementParent(parentElement);
            }

            if (element.id === this.compositor.currentParentId) {
                return null;
            }

            return element.id;
        };

        return skipGroup ? elementToSelect?.id : checkElementParent(elementToSelect);
    }

    getElementById(id: number): CompElement {
        if (!this.compositor.currentCompModel) {
            return null;
        }

        return this.compositor.currentCompModel.getAllCompElementsRecursively().find((el) => el.id === id);
    }

    requestToChangeCursor(newValue) {
        if (this.canvas.style.cursor !== newValue) {
            this.canvas.style.cursor = newValue;
        }
    }
}

export default BaseManipulation;
