import { ImageCompElement, type ImageCompElementParams } from './ImageCompElement';

export type VideoCompElementParams = ImageCompElementParams & {
    offsetTime: number;
    offsetTimeFrame: number;
    useAudio: boolean;
    isAlpha: boolean;
};

export class VideoCompElement extends ImageCompElement {
    offsetTime!: number;

    offsetTimeFrame!: number; // offset time for specific frame index

    useAudio!: boolean;

    isAlpha!: boolean;

    setProperties(params: VideoCompElementParams) {
        super.setProperties(params);
        this.offsetTime = params.offsetTime ?? this.offsetTime;
        this.offsetTimeFrame = params.offsetTimeFrame ?? this.offsetTimeFrame;
        this.useAudio = params.useAudio ?? this.useAudio;
        this.isAlpha = params.isAlpha ?? this.isAlpha;
    }

    getLocalHashData() {
        return {
            ...super.getLocalHashData(),
            offsetTime: this.offsetTime,
            offsetTimeFrame: this.offsetTimeFrame,
            useAudio: this.useAudio,
            isAlpha: this.isAlpha,
        };
    }

    toObject() {
        return {
            ...super.toObject(),
            type: 'VIDEO',
            offsetTime: this.offsetTime,
            offsetTimeFrame: this.offsetTimeFrame,
            useAudio: this.useAudio,
            isAlpha: this.isAlpha,
        };
    }
}
