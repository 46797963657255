import React, { useCallback, useEffect, useRef } from 'react';
import { fromPxToPercents, fromPercentsToPx, getFrameIndexByPosX } from './utils';

export const usePlayHeadDragging = (lastLeftFrame, selectFrame, shots, shotsRef, playHeadRef, timelineWrapperRef) => {
    const data = useRef({
        dragging: false,
        scrollLeft: 0,
        startPosX: 0,
        startX: 0,
    });

    const handlePlayHeadMouseDown = useCallback((ev: React.MouseEvent): void => {
        const lastDraggedPosInPercents = fromPercentsToPx(
            timelineWrapperRef,
            Number(playHeadRef.current?.getAttribute('data-pos-percent')),
        );

        data.current.startX = ev.pageX;
        data.current.startPosX = lastDraggedPosInPercents || 0;
        data.current.scrollLeft = timelineWrapperRef.current.scrollLeft;
        data.current.dragging = true;
    }, []);

    const handlePlayHeadMouseUp = useCallback((): void => {
        if (!data.current.dragging) {
            return;
        }

        data.current.dragging = false;
    }, [shots]);

    const handleMouseMove = useCallback(
        (ev: MouseEvent) => {
            if (!data.current.dragging) {
                return;
            }

            const shotsActualWidth = shotsRef.current.reduce((acc, el) => acc + el?.clientWidth, 0);
            const scrollLeft = timelineWrapperRef.current.scrollLeft - data.current.scrollLeft;
            const diff = ev.pageX - data.current.startX;
            const posX = Math.max(0, Math.min(data.current.startPosX + diff + scrollLeft, shotsActualWidth));
            const leftInPercents = fromPxToPercents(timelineWrapperRef, posX);
            const frame = getFrameIndexByPosX(posX, shots, shotsRef);

            playHeadRef.current?.setAttribute('style', `left: ${leftInPercents}%; display: block;`);
            playHeadRef.current?.setAttribute('data-pos-percent', leftInPercents.toString());

            lastLeftFrame.current = frame;
            selectFrame(frame);
        },
        [shots, selectFrame],
    );

    useEffect(() => {
        const instance = playHeadRef.current;

        instance.addEventListener('mousedown', handlePlayHeadMouseDown, false);
        document.addEventListener('mouseup', handlePlayHeadMouseUp, false);
        document.addEventListener('mouseleave', handlePlayHeadMouseUp, false);
        document.addEventListener('mousemove', handleMouseMove, false);

        return () => {
            instance.removeEventListener('mousedown', handlePlayHeadMouseDown);
            document.removeEventListener('mouseup', handlePlayHeadMouseUp);
            document.removeEventListener('mouseleave', handlePlayHeadMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handlePlayHeadMouseDown, handlePlayHeadMouseUp, handleMouseMove]);

    return data;
};
