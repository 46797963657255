export enum JOB_PROGRESS_STATUS {
    PREPARING = 'PREPARING',
    CREATING = 'CREATING',
    UPLOADING = 'UPLOADING',
    UPLOADING_FAILED = 'UPLOADING_FAILED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
}

export enum EXPORT_OPTIONS {
    DOWNLOAD = 'DOWNLOAD',
    WAITING_ROOM = 'BYNDER',
}
