import {
    type CompModel,
    type IAssetsLoader,
    type ICompositor,
    type MultiPageVideoModel,
    type Position,
    type Shot,
} from '@bynder-studio/render-core';
import CanvasManipulation from '../../Compositor/CanvasManipulationCompositor/CanvasManipulation';
import { BaseManipulationRenderer } from '../BaseManipulationRenderer/BaseManipulationRenderer';
import { IPlaybackManipulationRenderer } from './IPlaybackManipulationRenderer';
import { LoopInterval } from '../PlaybackRenderer/IPlaybackRenderer';
import { Playback } from '../Playback/Playback';

export class PlaybackManipulationRenderer
    extends BaseManipulationRenderer<MultiPageVideoModel>
    implements IPlaybackManipulationRenderer
{
    selectedShot: Shot;

    protected playback: Playback;

    constructor(
        videoModel: MultiPageVideoModel,
        assetLoader: IAssetsLoader,
        compositor: ICompositor,
        canvasManipulation: CanvasManipulation,
        loopInterval: LoopInterval,
    ) {
        super(videoModel, assetLoader, compositor, canvasManipulation);
        this.playback = new Playback(this, loopInterval);
        const shots = videoModel.getShots();

        if (shots && shots.length) {
            this.selectedShot = shots[0];
        }
    }

    async init(): Promise<void> {
        super.init();
        await this.playback.init();
    }

    redraw() {
        const compModel = this.creativeModel.getCompModel(this.playback.frameIndex);
        this.redrawCompModel(compModel);
    }

    setPanPosition(panPosition: Position) {
        super.setPanPosition(panPosition);
        this.drawCompModel(this.creativeModel.getCompModel(this.playback.frameIndex));

        return this;
    }

    selectShot(shotIndex: number): void {
        const shot = this.creativeModel.getShots()[shotIndex];
        this.selectedShot = shot;
        this.eventEmitter.emit('shotSelected', {
            shot,
        });
    }

    getSelectedShot(): Shot {
        return this.selectedShot;
    }

    startPlayback(): void {
        this.playback.startPlayback();
        this.canvasManipulation.onPlayingStateChange(this.playback.getIsPlaying());
    }

    pausePlayback(): void {
        this.playback.pausePlayback();
        this.canvasManipulation.onPlayingStateChange(this.playback.getIsPlaying());
    }

    setCurrentFrame(frameIndex: number) {
        return this.playback.setCurrentFrame(frameIndex);
    }

    getCurrentFrame(): number {
        return this.playback.getCurrentFrame();
    }

    getPlaybackDuration() {
        return this.playback.getPlaybackDuration();
    }

    getIsPlaying = () => {
        return this.playback.getIsPlaying();
    };

    async drawCompModel(compModel: CompModel): Promise<boolean> {
        const res = await this.playback.drawCompModel(compModel);

        if (res) {
            this.canvasManipulation.setCompModel(compModel);
        }

        return res;
    }
}
