import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { templatesSelector } from '~/store/templates/templates.selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ModalBase } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { Template } from 'packages/store/templates/types';
import TemplateContextMenu from 'packages/pages/components/templates/TemplateContextMenu';
import useAccessRights from 'packages/hooks/useAccessRights';
import useQueryParams from 'packages/hooks/useQueryParams';
import { type HiddenActions } from 'packages/pages/components/templates/TemplateContextMenu/types';
import { ActionStatus, Entity, TemplateAction } from 'packages/hooks/useAccessRights/types';
import modalContainer from 'packages/common/modalContainer';
import { changePage } from '~/store/general/general.actions';
import roles from '~/configs/roles';
import features from '~/configs/features';
import Preview from '../designCreateModal/pages/preview/Preview';

type Params = {
    setDeleteModalTemplate: (templates: Template[]) => void;
    setRenameModalTemplate: (template: Template) => void;
    setMoveModalTemplate?: (templates: Template[]) => void;
    setShareModalTemplate?: (template: Template) => void;
    createDesign: (template: Template) => void;
    duplicateTemplate: (template: Template) => void;
};

const StyledModalContent = styled.div`
    grid-area: content;
`;

export const TemplateModalContent = ({
    template,
    backButton,
    createDesign,
    duplicateTemplate,
    setDeleteModalTemplate,
    setMoveModalTemplate,
    setRenameModalTemplate,
    setShareModalTemplate,
}: {
    template?: Template | null;
    createDesign?: () => void;
    backButton?: {
        title: string;
        onClick: () => void;
    };
} & Params) => {
    const { translate } = useTranslate();
    const { isEntityActionAllowed, isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { resetQueryParams } = useQueryParams();

    const hiddenMenuActions: HiddenActions = [
        'showPreview',
        'editTemplate',
        'removeFromCategory',
        'setMoveModalTemplate',
    ];

    // if (!template?.categoryId) {
    //     hiddenMenuActions.push('removeFromCategory');
    // }

    // if (!setMoveModalTemplate) {
    //     hiddenMenuActions.push('removeFromCategory');
    //     hiddenMenuActions.push('setMoveModalTemplate');
    // }

    const editTemplate = useCallback(() => {
        if (!template) {
            return;
        }

        dispatch(
            changePage({
                back: location.pathname + location.search,
            }),
        );
        navigate(`/templates/${template.id}/edit`);
    }, [template, history, location, dispatch]);

    const isAccessible = useMemo(() => {
        return (
            template &&
            isEntityActionAllowed(TemplateAction.LIST, Entity.TEMPLATE, template?.grantedPermissions) ===
                ActionStatus.ALLOWED
        );
    }, [template]);

    useEffect(() => {
        if (!isAccessible && backButton) {
            // Temporary disable redirect to make sharing templates to work
            // backButton?.onClick();
        }
        if (isAccessible === undefined) {
            resetQueryParams(['template', 'page']);
        }
    }, [isAccessible]);

    return (
        <StyledModalContent>
            {template && (
                <Preview
                    entity={template}
                    createDesign={createDesign}
                    header={
                        <ModalBase.Header
                            title={template?.name || ''}
                            backButton={backButton}
                            additionalActions={
                                template &&
                                template.type === 'TEMPLATE' && (
                                    <>
                                        {!isAccountRoleAssigned(roles.video_brand_studio.video.manage) && (
                                            <TemplateContextMenu
                                                template={template}
                                                menuActions={{
                                                    setDeleteModalTemplate,
                                                    setRenameModalTemplate,
                                                    setMoveModalTemplate,
                                                    setShareModalTemplate,
                                                    duplicateTemplate,
                                                }}
                                                hiddenActions={hiddenMenuActions}
                                                isCardAction={false}
                                            />
                                        )}
                                        {isEntityActionAllowed(
                                            TemplateAction.EDIT,
                                            Entity.TEMPLATE,
                                            template.grantedPermissions,
                                        ) !== ActionStatus.NOT_ALLOWED &&
                                            isPlatformAllowed([features.EDITABLE_TEMPLATES]) && (
                                                <Button onClick={editTemplate}>
                                                    {translate('pages.categories.category.template.menu.edit')}
                                                </Button>
                                            )}
                                    </>
                                )
                            }
                        />
                    }
                />
            )}
        </StyledModalContent>
    );
};

const useTemplatePreviewModal = ({
    setDeleteModalTemplate,
    setRenameModalTemplate,
    setMoveModalTemplate,
    setShareModalTemplate,
    createDesign,
    duplicateTemplate,
}: Params) => {
    const [previewModalTemplate, setPreviewModalTemplate] = useState<Template | null>(null);
    const templates: Template[] = useSelector(templatesSelector);
    const previewTemplate = useMemo(
        () => templates.find((t) => t.id === previewModalTemplate?.id),
        [templates, previewModalTemplate?.id],
    );

    const location = useLocation();
    const { resetQueryParams } = useQueryParams();

    const onModalClose = useCallback(() => {
        setPreviewModalTemplate(null);
        resetQueryParams(['template', 'page']);

        if (location.pathname.includes('categories')) {
            resetQueryParams(['create']);
        }
    }, []);

    const renderModalTemplatePreview = () => (
        <ModalBase
            container={modalContainer}
            isOpen={previewModalTemplate !== null && previewTemplate !== undefined}
            onClose={onModalClose}
            size="fullscreen"
        >
            <TemplateModalContent
                template={previewTemplate}
                createDesign={() => previewTemplate && createDesign(previewTemplate)}
                duplicateTemplate={duplicateTemplate}
                setDeleteModalTemplate={setDeleteModalTemplate}
                setRenameModalTemplate={setRenameModalTemplate}
                setMoveModalTemplate={setMoveModalTemplate}
                setShareModalTemplate={setShareModalTemplate}
            />
        </ModalBase>
    );

    return {
        previewModalTemplate,
        setPreviewModalTemplate,
        renderModalTemplatePreview,
    };
};

export default useTemplatePreviewModal;
