import { stripHTMLTags } from '@bynder-studio/structured-text';
import { framesToTime } from './framesToTime';
import { type ValidationRule } from '../types';

export const validate = (inputValue: any, rule: ValidationRule, frameRate: number) => {
    const items = Object.keys(rule)
        .map((type) => {
            let isValid = true;
            let content = '';
            const value = rule[type];

            switch (type) {
                case 'EVEN':
                    isValid = value ? Number(inputValue) % 2 === 0 : true;
                    content = 'Only even numbers are allowed';
                    break;

                case 'REQUIRED':
                    isValid = inputValue && stripHTMLTags(inputValue.toString()).length > 0;
                    content = 'The field is required and cannot be left blank';
                    break;

                case 'MIN_LENGTH':
                    isValid = stripHTMLTags(inputValue.toString()).length >= value;
                    content = `The minimum allowed length for the field is ${value}`;
                    break;

                case 'MAX_LENGTH':
                    isValid = stripHTMLTags(inputValue.toString()).length <= value;
                    content = `The maximum allowed length for the field is ${value}`;
                    break;

                case 'LESS_THAN':
                    isValid = Number(inputValue) <= value;
                    content =
                        rule.LESS_THAN_MSG ||
                        `The maximum allowed value for the field is ${value} ${
                            rule.FRAMES_TO_TIME ? `frames or ${framesToTime(value, frameRate)}` : ''
                        }`;
                    break;

                case 'GREATER_THAN':
                    isValid = Number(inputValue) >= value;
                    content =
                        rule.GREATER_THAN_MSG ||
                        `The minimum allowed value for the field is ${value} ${
                            rule.FRAMES_TO_TIME ? `frames or ${framesToTime(value, frameRate)}` : ''
                        }`;
                    break;

                case 'MIN_LINE_BREAKS':
                    isValid = inputValue.split(/\r\n|\r|\n/).length > value;
                    content = `The minimum allowed value for line breaks is ${value}`;
                    break;

                case 'MAX_LINE_BREAKS':
                    isValid = inputValue.split(/\r\n|\r|\n/).length <= value + 1;
                    content = `The maximum allowed value for line breaks is ${value}`;
                    break;

                case 'PATTERN':
                    isValid = new RegExp(value).test(inputValue);
                    content = `value should match ${value}`;
                    break;

                case 'EQUAL_LENGTHS': {
                    if (!inputValue) {
                        isValid = true;
                    } else {
                        isValid = inputValue.length === value;
                    }

                    content = rule.EQUAL_LENGTHS_MSG || `value does not correspond to ${value}`;
                }
            }

            return {
                content,
                isValid,
            };
        })
        .filter((item) => !item.isValid);
    const isValid = !items.length;
    const messages = items.map((item) => item.content);

    return {
        isValid,
        messages,
    };
};
