import React, { useEffect, useState } from 'react';
import { useTranslate } from '@bynder/localization';
import { Button, Form, Input, List, notify, Slider, Thumbnail, Tooltip } from '@bynder/design-system';
import {
    IconCircle,
    IconPadding,
    IconPaddingBottom,
    IconPaddingLeft,
    IconPaddingLeftAndRight,
    IconPaddingRight,
    IconPaddingTop,
    IconPaddingTopAndBottom,
    IconRectangle,
    IconTextBackground,
} from '@bynder/icons';
import { BaseMultiPageModel, BaseMultiPageModel, TextElement } from '@bynder-studio/render-core';
import { defaultTextBg, TextBackground } from '@bynder-studio/misc';
import { useInputBlur } from 'packages/hooks/useInputBlur';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import generateTestId from '~/helpers/testIdHelpers';
import { FixedWidthDiv } from 'packages/pages/editor/RightSideMenu/Shots/Text/TextBackground/Component.styled';
import TextBackgroundColor from './Color';

type Props = {
    creativeModel: BaseMultiPageModel;
    selectedElement: TextElement;
};

type Spacing = {
    spacingLeft: number | string;
    spacingTop: number | string;
    spacingRight: number | string;
    spacingBottom: number | string;
};

const collectSpacingProps = (textBg: TextBackground) => {
    return {
        spacingLeft: textBg?.spacingLeft ?? defaultTextBg.spacingLeft,
        spacingTop: textBg?.spacingTop ?? defaultTextBg.spacingTop,
        spacingRight: textBg?.spacingRight ?? defaultTextBg.spacingRight,
        spacingBottom: textBg?.spacingBottom ?? defaultTextBg.spacingBottom,
    } as Spacing;
};

const areCounterSidesEqual = (spacing: Spacing) =>
    spacing.spacingLeft === spacing.spacingRight && spacing.spacingTop === spacing.spacingBottom;

const TextBackground = ({ creativeModel, selectedElement }: Props) => {
    const textBg = selectedElement?.textBackground;
    const isLocked = Boolean(selectedElement?.locked);
    const isActive = !!textBg && textBg.isActive();

    const { translate } = useTranslate();
    const { onFocus, unsubscribeListener } = useInputBlur();
    const [radius, setRadius] = useState(textBg?.cornerRadius || defaultTextBg.cornerRadius);
    const [spacing, setSpacing] = useState<Spacing>(collectSpacingProps(textBg || defaultTextBg));
    const [applyToBothSides, setApplyToBothSides] = useState(areCounterSidesEqual(spacing));

    const getNewSpacingValue = (value: string | number, sides: (keyof Spacing)[]) => {
        const temp = {};
        const sidesToChange = !applyToBothSides ? [sides[0]] : sides;
        sidesToChange.forEach((side) => (temp[side] = value));

        return {
            ...spacing,
            ...temp,
        };
    };

    const onSpacingChange = (value: string | number, sides: (keyof Spacing)[]) => {
        setSpacing(getNewSpacingValue(value, sides));
    };

    const applyChanges = (newSpacing = null) => {
        const spacingToUse = newSpacing ?? spacing;
        const temp = { ...spacingToUse };
        Object.keys(temp).forEach((key) => (temp[key] = editorAutocorrect('text_bg_spacing', Number(temp[key]))));

        setSpacing(spacing);
        creativeModel.updateElement(selectedElement.id, {
            textBackground: {
                ...textBg,
                ...temp,
            },
        });
    };

    const onBlur = () => {
        unsubscribeListener();
        applyChanges();
    };

    const onSpacingKeyDown = (sides: (keyof Spacing)[]) => (event: KeyboardEvent) => {
        switch (event.key) {
            case 'Enter':
                applyChanges();
                break;
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                const numberToAdd = event.key === 'ArrowDown' ? -1 : 1;
                const factor = event.shiftKey ? 10 : 1;
                const value = editorAutocorrect('text_bg_spacing', Number(spacing[sides[0]]) + numberToAdd * factor);

                applyChanges(getNewSpacingValue(value, sides));
                break;
            }
        }
    };

    const onApplyToBothSidesToggle = () => {
        if (!applyToBothSides) {
            applyChanges({
                ...spacing,
                spacingRight: spacing.spacingLeft,
                spacingBottom: spacing.spacingTop,
            });
            setApplyToBothSides(true);
        } else {
            setApplyToBothSides(false);
        }
    };

    const onTextBgToggle = () => {
        const params = {} as any;
        let textBreakupNotify = false;

        params.textBackground =
            textBg === null ? defaultTextBg : { ...textBg, state: isActive ? 'DISABLED' : 'ENABLED' };

        if (selectedElement.animationIn?.config?.textBreakup) {
            textBreakupNotify = true;
            params.animationIn = {
                ...selectedElement.animationIn.config,
                textBreakup: null,
            };
        }

        if (selectedElement.animationOut?.config?.textBreakup) {
            textBreakupNotify = true;
            params.animationOut = {
                ...selectedElement.animationOut.config,
                textBreakup: null,
            };
        }

        if (textBreakupNotify) {
            notify({
                title: translate('editor.sidebar.shots.text.background.notify.title'),
                description: translate('editor.sidebar.shots.text.background.notify.description'),
            });
        }

        creativeModel.updateElement(selectedElement.id, params);
    };

    useEffect(() => {
        if (!textBg) {
            return;
        }

        setSpacing(collectSpacingProps(textBg));
    }, [textBg, selectedElement.id]);

    useEffect(() => {
        if (!textBg) {
            return;
        }

        setApplyToBothSides(areCounterSidesEqual(collectSpacingProps(textBg)));
    }, [selectedElement.id]);

    useEffect(() => {
        if (!textBg || textBg.cornerRadius === radius) {
            return;
        }

        setRadius(textBg.cornerRadius);
    }, [textBg?.cornerRadius]);

    return (
        <>
            <List.Item
                checkedVariant="switch"
                onClick={onTextBgToggle}
                isChecked={isActive}
                thumbnail={<Thumbnail icon={<IconTextBackground />} />}
                isDisabled={isLocked}
                {...generateTestId('text_bg_switch')}
            >
                {translate('editor.sidebar.shots.text.background.label')}
            </List.Item>

            {isActive && (
                <>
                    <Form.Group>
                        <TextBackgroundColor
                            creativeModel={creativeModel}
                            selectedElement={selectedElement}
                            disabled={isLocked}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{translate('editor.sidebar.shots.text.background.radius.label')}</Form.Label>
                        <Slider
                            inputPosition="right"
                            iconLeft={<IconRectangle />}
                            iconRight={<IconCircle />}
                            onChange={(value) => {
                                setRadius(Number(value));
                                creativeModel.updateElement(selectedElement.id, {
                                    textBackground: {
                                        ...textBg,
                                        cornerRadius: Number(value),
                                    },
                                });
                            }}
                            value={radius}
                            min={0}
                            max={100}
                            isDisabled={isLocked}
                            {...generateTestId('text_bg_radius')}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{translate('editor.sidebar.shots.text.background.spacing.label')}</Form.Label>
                        <Form.InputRow>
                            <Input
                                type="number"
                                isDisabled={isLocked}
                                icon={applyToBothSides ? <IconPaddingLeftAndRight /> : <IconPaddingLeft />}
                                value={spacing.spacingLeft.toString()}
                                onFocus={onFocus}
                                onChange={(value) => {
                                    onSpacingChange(value, ['spacingLeft', 'spacingRight']);
                                }}
                                onKeyDown={onSpacingKeyDown(['spacingLeft', 'spacingRight'])}
                                onBlur={onBlur}
                                {...generateTestId('text_bg_spacing_left')}
                            />
                            <Input
                                type="number"
                                isDisabled={isLocked}
                                icon={applyToBothSides ? <IconPaddingTopAndBottom /> : <IconPaddingTop />}
                                value={spacing.spacingTop}
                                onFocus={onFocus}
                                onChange={(value) => {
                                    onSpacingChange(value, ['spacingTop', 'spacingBottom']);
                                }}
                                onKeyDown={onSpacingKeyDown(['spacingTop', 'spacingBottom'])}
                                onBlur={onBlur}
                                {...generateTestId('text_bg_spacing_top')}
                            />
                            <FixedWidthDiv>
                                <Tooltip
                                    content={translate('editor.sidebar.shots.text.background.spacing.tooltip')}
                                    position="top-left"
                                >
                                    <Button
                                        variant="clean"
                                        icon={<IconPadding />}
                                        isPressed={!applyToBothSides}
                                        isDisabled={isLocked}
                                        onClick={onApplyToBothSidesToggle}
                                        {...generateTestId('text_bg_spacing_btn_toggle')}
                                    />
                                </Tooltip>
                            </FixedWidthDiv>
                        </Form.InputRow>
                    </Form.Group>
                    <Form.Group>
                        <Form.InputRow>
                            {!applyToBothSides && (
                                <>
                                    <Input
                                        type="number"
                                        icon={<IconPaddingRight />}
                                        value={spacing.spacingRight}
                                        onFocus={onFocus}
                                        onChange={(value) => {
                                            onSpacingChange(value, ['spacingRight']);
                                        }}
                                        onKeyDown={onSpacingKeyDown(['spacingRight'])}
                                        onBlur={onBlur}
                                        {...generateTestId('text_bg_spacing_right')}
                                    />
                                    <Input
                                        type="number"
                                        icon={<IconPaddingBottom />}
                                        value={spacing.spacingBottom.toString()}
                                        onFocus={onFocus}
                                        onChange={(value) => {
                                            onSpacingChange(value, ['spacingBottom']);
                                        }}
                                        onKeyDown={onSpacingKeyDown(['spacingBottom'])}
                                        onBlur={onBlur}
                                        {...generateTestId('text_bg_spacing_bottom')}
                                    />
                                    <FixedWidthDiv />
                                </>
                            )}
                        </Form.InputRow>
                    </Form.Group>
                </>
            )}
        </>
    );
};

export default TextBackground;
