import useElement from 'packages/pages/design/hooks/useElement';
import type { TextElement as RenderTextElement, TextElement } from '@bynder-studio/render-core';
import { Direction } from '@bynder-studio/structured-text';
import { useCallback } from 'react';

export function useTextElementDirection(elementId: number) {
    const { element, updateElement } = useElement(elementId);
    const { rtl, detectedDirection } = (element as TextElement).detectedTextDirections;
    const direction = (element as TextElement).textDirection || detectedDirection;
    const showDirectionMode =
        !!(element as RenderTextElement).textDirection || detectedDirection !== Direction.LTR || rtl;

    const handleDirectionChange = useCallback(() => {
        updateElement({
            textDirection: direction === Direction.RTL ? Direction.LTR : Direction.RTL,
        });
    }, [updateElement, direction]);

    return {
        direction,
        showDirectionMode,
        handleDirectionChange,
    };
}
