import React, { useCallback, useMemo } from 'react';
import { GroupElement } from '@bynder-studio/render-web';
import { Tooltip, Button, Divider, Dropdown } from '@bynder/design-system';
import { IconCopy, IconCreateGroup, IconDelete, IconMore, IconUngroup, IconStarredFolder } from '@bynder/icons';
import useEditor from '~/hooks/useEditor';
import AddLayerButton from '~/common/editor/layers/addLayerButton/AddLayerButton';
import permissions from '~/configs/permissions';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import { collectTopLevelElements } from '~/common/editor/timeline/timeline-actions/components/utils';
import usePresets from '~/common/editor/presets/usePresets';
import {
    DELETE_GROUP,
    DELETE_LAYER,
    DUPLICATE_LAYER,
    GROUP_LAYERS,
    LAYERS,
    MORE_OPTIONS,
    SAVE_AS_PRESET,
    UNGROUP,
} from '~/helpers/textConstants';
import { AddElementWrapper } from '../addLayerButton/AddLayerButton.styled';
import useElementGeneralActions from 'packages/pages/editor/useElementGeneralActions';
import useGeneralHotkeys from 'packages/pages/editor/useGeneralHotKeys';
import useForceUpdate from '~/hooks/useForceUpdate';

const LayerHeader = ({ selectedGroups, setSelectedGroups }) => {
    const { createPreset } = usePresets();
    const { creativeModel, manipulationRenderer } = useEditor();
    const forceUpdate = useForceUpdate();

    const selectedElements = manipulationRenderer?.getSelectedElements() || [];
    const isGroup = useMemo(() => {
        const els = selectedElements.filter(
            (el) => !el.parent || selectedElements.findIndex((e) => e.id === el.parent.id) === -1,
        );

        return els.length === 1 && els.every((el) => el instanceof GroupElement);
    }, [selectedElements]);

    const { handleSelection, handleDelete, handleDuplicate, handleLock } = useElementGeneralActions(
        setSelectedGroups,
        selectedGroups,
    );

    const handleAddOrRemoveGroup = useCallback(() => {
        if (isGroup) {
            const elementToSelect = selectedGroups[selectedGroups.length - 1].children.reduce(
                (elA, elB) => ((elA?.renderOrder || 0) > (elB?.renderOrder || 0) ? elA : elB),
                null,
            );

            const editorElements = creativeModel.getAllElementsRecursively();
            const maskedElements = editorElements.filter((el) => el.mask && el.mask.elementId);
            selectedGroups.forEach((group) => {
                creativeModel.unwrapGroupElement(group.id);

                if (maskedElements) {
                    maskedElements.forEach((element) => {
                        if (group.id === element.mask.elementId) {
                            creativeModel.updateElement(element.id, {
                                mask: null,
                            });
                        }
                    });
                }

                if (group.mask && group.mask.elementId) {
                    const sameElementMaskedElements = maskedElements
                        .filter((el) => !el.id === group.id)
                        .filter((el) => el.mask.elementId === group.mask.elementId);

                    if (!sameElementMaskedElements.length) {
                        creativeModel.updateElement(group.mask.elementId, {
                            mask: null,
                        });
                    }
                }
            });

            if (elementToSelect) {
                manipulationRenderer.selectElement(elementToSelect.id);
            } else {
                setSelectedGroups([]);
            }
        } else if (selectedElements.length) {
            const groupElement = creativeModel.wrapElementsIntoGroup(selectedElements.map((el) => el.id));
            manipulationRenderer.selectElement(groupElement.id);
        }
    }, [isGroup, selectedElements, creativeModel, manipulationRenderer]);

    const handleCreatePreset = useCallback(() => {
        createPreset(selectedGroups[0]);
    }, [createPreset, selectedGroups]);

    const handleHide = useCallback(() => {
        collectTopLevelElements(manipulationRenderer?.getSelectedElements()).forEach((el) => {
            creativeModel.updateElement(el.id, { hidden: !el.hidden });
        });
        forceUpdate();
    }, [creativeModel, manipulationRenderer]);

    useGeneralHotkeys({
        handleDuplicate,
        handleSelection,
        handleGroup: handleAddOrRemoveGroup,
        handleUnGroup: handleAddOrRemoveGroup,
        handleDelete,
        handleLock,
        handleHide,
    });

    const showActionButtons = !!selectedElements.length;

    return (
        <div className="layers--header position-relative">
            <span>{LAYERS}</span>
            <div className="layers--header-action-btns d-flex align-items-center">
                {showActionButtons && (
                    <Tooltip content={!isGroup ? GROUP_LAYERS : UNGROUP}>
                        <AddElementWrapper>
                            <Button
                                title=""
                                variant="clean"
                                className="add--layer-btn"
                                isDisabled={!selectedElements.length}
                                onClick={handleAddOrRemoveGroup}
                                icon={!isGroup ? <IconCreateGroup /> : <IconUngroup />}
                            />
                        </AddElementWrapper>
                    </Tooltip>
                )}
                {showActionButtons && (
                    <Tooltip content={DUPLICATE_LAYER}>
                        <AddElementWrapper>
                            <Button
                                title=""
                                variant="clean"
                                className="add--layer-btn"
                                onClick={handleDuplicate}
                                icon={<IconCopy />}
                            />
                        </AddElementWrapper>
                    </Tooltip>
                )}
                {showActionButtons && !isGroup && (
                    <Tooltip content={DELETE_LAYER}>
                        <AddElementWrapper>
                            <Button
                                title=""
                                variant="clean"
                                className="add--layer-btn"
                                onClick={handleDelete}
                                icon={<IconDelete />}
                            />
                        </AddElementWrapper>
                    </Tooltip>
                )}
                {showActionButtons && isGroup && (
                    <Dropdown
                        position="bottom-right"
                        trigger={({ isOpen, ...triggerProps }) => (
                            <Tooltip content={MORE_OPTIONS}>
                                <AddElementWrapper>
                                    <Button
                                        title=""
                                        icon={<IconMore />}
                                        variant="clean"
                                        className="add--layer-btn"
                                        isDisabled={false}
                                        {...triggerProps}
                                    />
                                </AddElementWrapper>
                            </Tooltip>
                        )}
                    >
                        {AuthorizationHelper.hasPermission(permissions.PRESETS.WRITE) && (
                            <Dropdown.Item icon={<IconStarredFolder />} onClick={handleCreatePreset}>
                                {SAVE_AS_PRESET}
                            </Dropdown.Item>
                        )}
                        <Divider />
                        <Dropdown.Item icon={<IconDelete />} onClick={handleDelete}>
                            {DELETE_GROUP}
                        </Dropdown.Item>
                    </Dropdown>
                )}
                <AddLayerButton />
            </div>
        </div>
    );
};

export default LayerHeader;
