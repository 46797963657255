import React, { useEffect, useState, useCallback } from 'react';
import { Button, Divider, Dropdown, List } from '@bynder/design-system';
import {
    IconCopy,
    IconDelete,
    IconEdit,
    IconMore,
    IconReorder,
    IconArrowTop,
    IconArrowUp,
    IconArrowBottom,
    IconArrowDown,
    IconTranslate,
    IconReplay,
} from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import type { MultiPageImageModel, MultiPageVideoModel } from '@bynder-studio/render-core';
import useAccessRights from 'packages/hooks/useAccessRights';
import features from '~/configs/features';
import useDesign from 'packages/pages/design/hooks/useDesign';
import {
    applyVariationDataToCreativeModel,
    isVariationDifferFromTemplate,
} from 'packages/pages/design/sidebar/variations/utils';
import useVariations from 'packages/pages/design/hooks/useVariations';

const VariationDropdownActions = ({
    variationId,
    onRenameClick,
    onDuplicateClick,
    onDeleteClick,
    isDuplicateDisabled,
    isOrderingDisabled,
    isMoveTopUpDisabled,
    isMoveDownBottomDisabled,
    isTranslateDisabled,
    onTranslateClick,
    onResetClick,
    onTopClick,
    onUpClick,
    onBottomClick,
    onDownClick,
}) => {
    const { translate } = useTranslate();
    const { isPlatformAllowed } = useAccessRights();
    const { creativeModel, template } = useDesign();
    const { variations, currentVariation } = useVariations();
    const [hasChanges, setHasChanges] = useState(false);

    const checkChanges = useCallback(() => {
        if (!creativeModel) {
            return;
        }

        const variation = variations.find((v) => v.id === variationId);

        if (!variation) {
            return;
        }

        if (!variation.hasUnsavedChanges) {
            setHasChanges(Object.values(variation.sizes).some((page) => !!page.properties.length));

            return;
        }

        if (variationId === currentVariation) {
            setHasChanges(isVariationDifferFromTemplate(template, creativeModel));

            return;
        }

        const model = creativeModel.getCopy() as MultiPageVideoModel | MultiPageImageModel;

        applyVariationDataToCreativeModel(variation, model);

        setHasChanges(isVariationDifferFromTemplate(template, model));
    }, [creativeModel, template, variationId, currentVariation, variations]);

    useEffect(() => {
        checkChanges();
    }, [creativeModel, template, variationId]);

    return (
        <Dropdown
            trigger={({ isOpen, ...triggerProps }) => (
                <List.ConcealedAction isVisible={isOpen}>
                    <Button variant="clean" isPressed={isOpen} icon={<IconMore />} {...triggerProps} title="actions" />
                </List.ConcealedAction>
            )}
            position="bottom-right"
        >
            <Dropdown.Item icon={<IconEdit />} onClick={onRenameClick}>
                {translate('design.sidebar.variations.menu.rename')}
            </Dropdown.Item>
            <Dropdown.Item icon={<IconCopy />} onClick={onDuplicateClick} isDisabled={isDuplicateDisabled}>
                {translate('design.sidebar.variations.menu.duplicate')}
            </Dropdown.Item>
            {hasChanges && (
                <>
                    <Divider />
                    <Dropdown.Item icon={<IconReplay />} onClick={onResetClick}>
                        {translate('design.sidebar.variations.menu.reset')}
                    </Dropdown.Item>
                </>
            )}
            {isPlatformAllowed([features.AI_VARIATIONS_TRANSLATE, features.MULTI_VARIATION]) && (
                <>
                    <Divider />
                    <Dropdown.Item
                        icon={<IconTranslate />}
                        onClick={() => onTranslateClick(variationId)}
                        isDisabled={isTranslateDisabled}
                    >
                        {translate('design.sidebar.variations.menu.translate')}
                    </Dropdown.Item>
                </>
            )}
            <Divider />
            {!isOrderingDisabled && (
                <>
                    <Dropdown.Item
                        icon={<IconReorder />}
                        submenu={
                            <>
                                <Dropdown.Item
                                    icon={<IconArrowTop />}
                                    onClick={onTopClick}
                                    isDisabled={isMoveTopUpDisabled}
                                >
                                    {translate('design.sidebar.variations.menu.reorder.submenu.move-top')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    icon={<IconArrowUp />}
                                    onClick={onUpClick}
                                    isDisabled={isMoveTopUpDisabled}
                                >
                                    {translate('design.sidebar.variations.menu.reorder.submenu.move-up')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    icon={<IconArrowDown />}
                                    onClick={onDownClick}
                                    isDisabled={isMoveDownBottomDisabled}
                                >
                                    {translate('design.sidebar.variations.menu.reorder.submenu.move-down')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    icon={<IconArrowBottom />}
                                    onClick={onBottomClick}
                                    isDisabled={isMoveDownBottomDisabled}
                                >
                                    {translate('design.sidebar.variations.menu.reorder.submenu.move-bottom')}
                                </Dropdown.Item>
                            </>
                        }
                    >
                        {translate('design.sidebar.variations.menu.reorder')}
                    </Dropdown.Item>
                    <Divider />
                </>
            )}
            <Dropdown.Item icon={<IconDelete />} onClick={onDeleteClick}>
                {translate('design.sidebar.variations.menu.delete')}
            </Dropdown.Item>
        </Dropdown>
    );
};

export default VariationDropdownActions;
