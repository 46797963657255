import { ListenerFn } from 'eventemitter2';
import { CreativeTypes } from '../Enums/CreativeTypes';
import { PreviewTypes } from '../Enums/PreviewTypes';
import type { BaseModel } from '../Models/Models/BaseModel';
import type { BaseMultiPageModel } from '../Models/Models/BaseMultiPageModel';
import { DynamicEventEmitter } from './DynamicEventEmitter';
import { ValidationManager, ValidationState } from './ValidationManager';
import { EventData, EventName } from '../event-types';

export type ListenerType = {
    model: BaseModel;
    pageIndex: number;
};

export class ValidationManagerForMultiPage {
    private multiPageModel: BaseMultiPageModel;

    private validationManagers: ValidationManager[];

    private eventEmitter: DynamicEventEmitter = new DynamicEventEmitter();

    private listeners: ListenerFn[] = [];

    private pageAddListener = ({ model, pageIndex }: ListenerType) => this.addPage(model, pageIndex);

    private pageRemoveListener = ({ pageIndex }: ListenerType) => this.removePage(pageIndex);

    private readonly creativeType: CreativeTypes;

    private previewType: PreviewTypes;

    constructor(
        multiPageModel: BaseMultiPageModel,
        creativeType: CreativeTypes = CreativeTypes.VIDEO,
        previewType: PreviewTypes = PreviewTypes.EDITOR,
    ) {
        this.multiPageModel = multiPageModel;
        this.validationManagers = Array(multiPageModel.getModels().length);
        this.creativeType = creativeType;
        this.previewType = previewType;
        multiPageModel.getModels().forEach((creativeModel, index) => {
            this.validationManagers[index] = new ValidationManager(creativeModel, creativeType, previewType);
            this.subscribeToValidationManagerEvents(this.validationManagers[index], index);
        });
        this.multiPageModel.getEventEmitter().on('pageAdded', this.pageAddListener);
        this.multiPageModel.getEventEmitter().on('pageRemoved', this.pageRemoveListener);
    }

    getValidationManager(pageIndex: number): ValidationManager {
        return this.validationManagers[pageIndex];
    }

    unsubscribe(): void {
        this.multiPageModel.getEventEmitter().off('pageAdded', this.pageAddListener);
        this.multiPageModel.getEventEmitter().off('pageRemoved', this.pageRemoveListener);
        this.validationManagers.forEach((validationManager, index) => {
            this.unsubscribeFromValidationManagerEvents(validationManager, index);
            validationManager.unsubscribe();
        });
    }

    validate(): void {
        this.validationManagers.forEach((validationManager) => {
            validationManager.validate();
        });
    }

    getElementValidationState(elementId: string | number): ValidationState | null | undefined {
        return this.validationManagers[this.multiPageModel.getCurrentPageIndex()].getElementValidationState(elementId);
    }

    getGlobalPropertiesValidationState(): ValidationState {
        return this.validationManagers[this.multiPageModel.getCurrentPageIndex()].getGlobalPropertiesValidationState();
    }

    validateDimension(width: number, height: number): [boolean, Record<string, string[]>] {
        return this.validationManagers[this.multiPageModel.getCurrentPageIndex()].validateDimension(width, height);
    }

    isValid(): boolean {
        return this.validationManagers.every((validationManager, index) => {
            return validationManager.isValid();
        });
    }

    on(eventName: string, callback: (...args: Array<any>) => any): void {
        this.eventEmitter.on(eventName, callback);
    }

    off(eventName: string, callback: (...args: Array<any>) => any): void {
        this.eventEmitter.off(eventName, callback);
    }

    private addPage(creativeModel: BaseModel, pageIndex: number): void {
        this.validationManagers[pageIndex] = new ValidationManager(creativeModel, this.creativeType, this.previewType);
        this.subscribeToValidationManagerEvents(this.validationManagers[pageIndex], pageIndex);
        this.validate();
    }

    private removePage(pageIndex: number): void {
        const validationManager = this.getValidationManager(pageIndex);
        this.unsubscribeFromValidationManagerEvents(validationManager, pageIndex);
        validationManager.unsubscribe();
        this.validationManagers.splice(pageIndex, 1);
    }

    private subscribeToValidationManagerEvents(validationManager: ValidationManager, pageIndex: number): void {
        const listener = (eventName: EventName, eventData: EventData = {}): void => {
            if (eventName !== 'validated' && this.multiPageModel.getCurrentPageIndex() !== pageIndex) {
                return;
            }

            eventData.pageIndex = pageIndex;
            this.eventEmitter.emit(eventName, eventData);
        };

        this.listeners[pageIndex] = listener;
        validationManager.onAny(listener);
    }

    private unsubscribeFromValidationManagerEvents(validationManager: ValidationManager, pageIndex: number): void {
        validationManager.offAny(this.listeners[pageIndex]);
        this.listeners.splice(pageIndex, 1);
    }

    onElementValidationChange(elementId: number, callbackFn: (data: any) => void): () => void {
        const listener = (data) => {
            if (data.element.id === elementId) {
                callbackFn(data);
            }
        };

        this.eventEmitter.on('elementValidated', listener);

        return () => this.eventEmitter.off('elementValidated', listener);
    }

    onGlobalPropertiesValidationChange(listener: () => void): () => void {
        const eventName = 'globalPropertiesValidated';
        this.eventEmitter.on(eventName, listener);

        return () => this.eventEmitter.off(eventName, listener);
    }

    onValidationChange(listener: () => void): () => void {
        const eventName = 'validated';
        this.eventEmitter.on(eventName, listener);

        return () => this.eventEmitter.off(eventName, listener);
    }
}
