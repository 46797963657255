import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EmptyState } from '@bynder/design-system';
import { IconVideoTemplate } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import useAccessRights from 'packages/hooks/useAccessRights';
import {
    fetchTemplates,
    initializeTemplates,
    selectTemplate,
    getTemplate,
    duplicateTemplates,
} from '~/store/templates/templates.actions';
import { selectedTemplateSelector } from '~/store/templates/templates.selectors';
import features from '~/configs/features';
import roles from '~/configs/roles';
import AuthorizationHelper from '~/helpers/AuthorizationHelper';
import useQueryParams from 'packages/hooks/useQueryParams';
import filtersStorage from 'packages/hooks/filtersStorage';
import { getTemplates, getPendingTemplates } from 'packages/store/templates/templates.selectors';
import { getCategoriesState, getCategory } from 'packages/store/categories/categories.selectors';
import { fetchCategory } from 'packages/store/categories/categories.actions';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { INITIALIZE_TEMPLATES } from '~/store/types';
import Filters from 'packages/pages/components/filters';
import useFilters from '../../../components/categories/useCategoryDetailFilters';
import useFluidWidth from 'packages/pages/components/grid/useFluidWidth';
import { MediaFilterType } from 'packages/pages/components/filters/components/MediaType/types';
import { removeEmptyProps, TEMPLATES_PAGE_SIZE } from 'packages/pages/utils';
import EmptyFilteredState, { isFilteredList } from 'packages/pages/components/empty/EmptyFilteredState';
import useTemplateRenameModal from 'packages/pages/components/templates/useTemplateRenameModal';
import useTemplateMoveModal from 'packages/pages/components/templates/useTemplateMoveModal';
import useTemplateDeleteModal from 'packages/pages/components/templates/useTemplateDeleteModal';
import { setPage, deselectBlankType } from 'packages/store/newDesign/newDesign.actions';
import { Template } from 'packages/store/templates/types';
import { deselectGallery, initializeGallery } from '~/store/gallery/gallery.actions';
import { HiddenActions } from 'packages/pages/components/templates/TemplateContextMenu/types';
import TemplateGrid from 'packages/pages/components/templates/TemplateGrid';
import { DesignSubPages } from '../../components/Sidebar/types';
import { ModalFilters, ModalPageBody } from '../../components/DesignCreateModal.styled';
import useTemplateShareModal from 'packages/pages/shareModal/useTemplateShareModal';
import useCreativeThumbnailsUpdates from 'packages/hooks/useCreativeThumbnailsUpdates';

const HIDDEN_TEMPLATE_MENU_ACTIONS: HiddenActions = ['setMoveModalTemplate', 'removeFromCategory'];

const CategoryDetail = () => {
    const { isPlatformAllowed, isAccountRoleAssigned } = useAccessRights();
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const { getQueryParam, getAllQueryParams, getAllQueryParamsRaw, restoreQueryParams, setQueryParam } =
        useQueryParams();
    const { getFiltersFromStorage, setFiltersToStorage } = filtersStorage();
    const queriedCategoryId = () => getQueryParam('categoryId');
    const selectedTemplate = useSelector(selectedTemplateSelector);
    const { templates, loadingData: templatesLoading, fetchOptions, totalItems } = useSelector(getTemplates);
    const pendingTemplates = useSelector(getPendingTemplates);
    const { isFetching: categoriesLoading } = useSelector(getCategoriesState);
    const category = useSelector(getCategory);
    const location = useLocation();

    const { setDeleteModalTemplate, renderModalTemplateDelete } = useTemplateDeleteModal();
    const { setRenameModalTemplate, renderModalTemplateRename } = useTemplateRenameModal({ isNewDesignModal: true });
    const { setMoveModalTemplate, renderModalTemplateMove } = useTemplateMoveModal({});
    const { setShareModalTemplate, renderModalTemplateShare } = useTemplateShareModal({
        collectionName: category?.name,
    });

    const isTrialAccount =
        isPlatformAllowed([features.TRIAL_ENABLED]) && isAccountRoleAssigned(roles.video_brand_studio.video.manage);

    const templateFromCategory = templates.filter((templates) => templates.categoryId === category?.id);

    const isTemplateSelected = (template: Template) => selectedTemplate?.id === template.id;

    useCreativeThumbnailsUpdates(pendingTemplates);

    // first load
    useLayoutEffect(() => {
        dispatch(initializeTemplates());
        dispatch(initializeGallery());
        dispatch(deselectBlankType());

        const storedQueries = getFiltersFromStorage(`newDesign:categoryDetails:${queriedCategoryId()}`);

        // removing template id from stored query params to avoid pre-selection of template
        const storedFilteredQueries =
            storedQueries?.length &&
            storedQueries
                .split('&')
                .reduce((acc, query) => {
                    if (!query.includes('template')) {
                        acc.push(query);
                    }

                    return acc;
                }, [])
                .join('&');

        if (storedFilteredQueries) {
            restoreQueryParams(storedFilteredQueries);
        }

        const querySearch = getQueryParam('search');
        const queriedMediaType = getQueryParam('typeDesign')?.toUpperCase();
        const queriedSorting = getQueryParam('sortedBy');
        const queriedOrder = getQueryParam('sortOrder');
        const queriedOwnership = getQueryParam('ownership')?.toUpperCase();

        const queriedParams = removeEmptyProps({
            search: querySearch || '',
            creativeType: queriedMediaType?.toUpperCase() === MediaFilterType.ANY ? undefined : queriedMediaType,
            sort: queriedSorting && queriedOrder && [queriedSorting, queriedOrder],
            ownershipSource: queriedOwnership,
        });

        if (queriedCategoryId()) {
            dispatch(initializeTemplates());
            dispatch(
                fetchTemplates({
                    newOptions: {
                        collectionId: queriedCategoryId(),
                        assignedToCollection: true,
                        ownershipSource: isTrialAccount
                            ? OwnershipFilterType.OWNED_BY_OTHERS
                            : OwnershipFilterType.OWNED_BY_ANYONE,
                        ...queriedParams,
                    },
                }),
            );
            dispatch(fetchCategory(queriedCategoryId()));
        }

        return () => {
            if (location.pathname.includes('categories') && !location.search.includes('page=categories')) {
                dispatch({ type: INITIALIZE_TEMPLATES });
            }
        };
    }, [queriedCategoryId()]);

    useEffect(() => {
        const queriedTemplateId = getQueryParam('template');

        if (queriedTemplateId) {
            dispatch(getTemplate(queriedTemplateId));
        }
    }, []);

    useEffect(() => {
        if (location.pathname.includes('categories/')) {
            setFiltersToStorage('categoryDetails', getAllQueryParams());
        }

        if (
            location.search.includes('page=category_details') &&
            location.search.includes('create=true') &&
            location.search.includes('categoryId=') &&
            queriedCategoryId()
        ) {
            setFiltersToStorage(`newDesign:categoryDetails:${queriedCategoryId()}`, getAllQueryParams());
        }
    }, [getAllQueryParams()]);

    const onScrollGrid = () => {
        const notLoading = !templatesLoading;
        const notLastPage = templates.length < totalItems;

        if (notLoading && notLastPage) {
            const newOptions = { ...fetchOptions, collectionId: queriedCategoryId() };

            // We increase the page only if all templates from previous page were fetched
            // because sometimes we fetch only small part of templates of the page
            if (templates.length >= newOptions.page * TEMPLATES_PAGE_SIZE) {
                newOptions.page += 1;
            }

            newOptions.size = TEMPLATES_PAGE_SIZE;
            dispatch(fetchTemplates({ newOptions, setSelected: false }));
        }
    };

    const onTemplateSelect = (template: Template) => {
        dispatch(selectTemplate(template));
        dispatch(deselectBlankType(true));
        dispatch(deselectGallery());
    };

    const duplicateTemplate = (template: Template) => {
        dispatch(duplicateTemplates([template.id], template.categoryId));
        setQueryParam('template', '');
    };

    const templateMenuActions = {
        setRenameModalTemplate,
        setDeleteModalTemplate,
        setMoveModalTemplate,
        setShareModalTemplate,
        showPreview: (t: Template) => {
            dispatch(selectTemplate(t));
            setQueryParam('template', t.id.toString());
            dispatch(setPage(DesignSubPages.TEMPLATE_PREVIEW));
        },
        duplicateTemplate,
    };

    const { onSearch, onMediaTypeSelect, onOwnershipSelect } = useFilters();
    const { gridContainer } = useFluidWidth();

    const renderEmpty = () => {
        const isFiltered = isFilteredList(getAllQueryParamsRaw());

        return isFiltered ? (
            <EmptyFilteredState
                storageKey="categoryDetails"
                onResetFilters={() =>
                    dispatch(
                        fetchTemplates({
                            newOptions: { ...fetchOptions, search: '', creativeType: undefined },
                        }),
                    )
                }
            />
        ) : (
            <EmptyState
                icon={<IconVideoTemplate />}
                title={translate('pages.categories.category.empty.templates.title')}
                text={translate('pages.categories.category.empty.templates.info')}
            />
        );
    };

    return (
        <>
            <ModalFilters>
                <Filters.FiltersLeftBlock>
                    <Filters.Search onSearch={onSearch} />
                    {(!isAccountRoleAssigned(roles.video_brand_studio.video.manage) ||
                        (AuthorizationHelper.isFeatureAvailable(features.IMAGES_ENABLED) &&
                            AuthorizationHelper.isFeatureAvailable(features.VIDEOS_ENABLED))) && (
                        <Filters.FiltersDivider />
                    )}
                    {!isAccountRoleAssigned(roles.video_brand_studio.video.manage) && (
                        <Filters.Ownership sortBy={fetchOptions.ownershipSource} onSort={onOwnershipSelect} />
                    )}
                    {AuthorizationHelper.isFeatureAvailable(features.IMAGES_ENABLED) &&
                        AuthorizationHelper.isFeatureAvailable(features.VIDEOS_ENABLED) && (
                            <Filters.MediaType value={fetchOptions.creativeType} onSelect={onMediaTypeSelect} />
                        )}
                </Filters.FiltersLeftBlock>
            </ModalFilters>
            <ModalPageBody ref={gridContainer} data-testid="category detail overview body">
                {!templateFromCategory.length && !templatesLoading
                    ? renderEmpty()
                    : gridContainer.current?.clientWidth && (
                          <TemplateGrid
                              templates={templateFromCategory}
                              menuActions={templateMenuActions}
                              hiddenMenuActions={HIDDEN_TEMPLATE_MENU_ACTIONS}
                              isTemplateSelected={isTemplateSelected}
                              onSelect={onTemplateSelect}
                              onCardClick={onTemplateSelect}
                              isLoading={templatesLoading || categoriesLoading}
                              totalItems={totalItems}
                              loadMore={onScrollGrid}
                              includeSharedIndicator={!isAccountRoleAssigned(roles.video_brand_studio.video.manage)}
                          />
                      )}
            </ModalPageBody>
            {renderModalTemplateRename()}
            {renderModalTemplateDelete()}
            {renderModalTemplateMove()}
            {renderModalTemplateShare()}
        </>
    );
};

export default CategoryDetail;
