import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompositor } from '../../Compositor/ICompositor';
import { DynamicEventEmitter } from '../../Helpers/DynamicEventEmitter';
import { type BaseModel } from '../../Models/Models/BaseModel';
import { type CompModel } from '../../Models/CompModels/CompModel';
import { Dimension } from '../../Models/Shared/Dimension';
import { Position } from '../../Models/Shared/Position';
import type { IBaseRenderer } from './IBaseRenderer';

export class BaseRenderer<Model extends BaseModel = BaseModel> implements IBaseRenderer {
    public eventEmitter: DynamicEventEmitter = new DynamicEventEmitter();

    assetLoader: IAssetsLoader;

    creativeModel: Model;

    compositor: ICompositor;

    constructor(creativeModel: Model, assetLoader: IAssetsLoader, compositor: ICompositor) {
        this.creativeModel = creativeModel;
        this.compositor = compositor;
        this.assetLoader = assetLoader;
    }

    init(): void {
        this.calculateScaleRatio();
        this.creativeModel.eventEmitter.on('dimensionUpdated', this.onDimensionUpdated.bind(this));
    }

    getCompositor() {
        return this.compositor;
    }

    getCreativeModel() {
        return this.creativeModel;
    }

    setScale(scale: number) {
        this.compositor.setScale(scale);
        this.eventEmitter.emit('scaleUpdated', { scale });
        this.redraw();

        return this;
    }

    getScale() {
        return this.compositor.getScale();
    }

    setPanPosition(panPosition: Position) {
        this.compositor.setPanPosition(panPosition);
        this.redraw();

        return this;
    }

    getPanPosition(): Position {
        return this.compositor.getPanPosition();
    }

    calculateScaleRatio(preserveScale = false) {
        // current canvas size
        const compositorWrapperDimension = this.compositor.getCompositorWrapperDimension();
        const padding = this.compositor.getCompositorWrapperPadding();
        const canvasW = compositorWrapperDimension.getWidth() - 2 * padding;
        const canvasH = compositorWrapperDimension.getHeight() - 2 * padding;
        const kBox = canvasW / canvasH;

        // template size
        const templateDimension = this.creativeModel.getDimensions();
        const templateW = templateDimension.getWidth();
        const templateH = templateDimension.getHeight();
        const oldScale = this.getScale();
        let scale: number;

        const kTemplate = templateW / templateH;
        const width = Math.min(canvasW, templateW);
        const height = Math.min(canvasH, templateH);

        if (kTemplate < kBox) {
            const newWidth = kTemplate * height;
            this.compositor.setCompositorDimension(new Dimension(newWidth, height));
            scale = newWidth / templateW;
        } else {
            const newHeight = width / kTemplate;
            this.compositor.setCompositorDimension(new Dimension(width, newHeight));
            scale = width / templateW;
        }

        // We use scale "to fit" only if the artboard was fit before the dimensions' changing
        const wasScaledToFit = oldScale <= this.compositor.getMaxFitScale();

        this.compositor.setMaxFitScale(scale);
        this.setScale(preserveScale && !wasScaledToFit ? oldScale : scale);

        return scale;
    }

    drawCompModel(compModel: CompModel): Promise<boolean> {
        return new Promise((resolve) => {
            this.requestToDrawCompModel(compModel);
            requestAnimationFrame(() => {
                resolve(true);
            });
        });
    }

    requestToDrawCompModel(compModel: CompModel) {
        this.compositor.requestToDrawCompModel(compModel);

        return this;
    }

    redraw() {
        throw new Error('The redraw method should be implemented in child classes.');
    }

    private onDimensionUpdated() {
        this.calculateScaleRatio();
    }
}
