import {
    BaseCompElement,
    Box,
    CORNERS,
    Dimension,
    InteractionStates,
    Position,
    ROTATIONS,
    SIDES,
} from '@bynder-studio/render-core';
import BoundingBox from './BoundingBox';

const CORNER_RENDER_SIZE = 10;
const CORNER_HANDLE_SIZE = 16;
const ROTATION_CORNER_SIZE = 32;

const scalePoint = (point: Position, center: Position, sx: number, sy: number) => {
    const x0 = center.getX();
    const y0 = center.getY();
    const x = point.getX();
    const y = point.getY();

    return new Position((x - x0) * sx + x0, (y - y0) * sy + y0);
};

class TransformerBox extends BoundingBox {
    multiElementOwnBoxes: BoundingBox[] = [];

    selectionHandles: Box[] = [];

    isResizeable = true;

    isMultiBox = false;

    rotation = 0;

    boxInteractionState: string = InteractionStates.SELECTED;

    virtualRotation = 0;

    isOffsetCalculated = false;

    startPosition: Position;

    startDimension: Dimension;

    constructor(
        position: Position,
        dimension: Dimension,
        rotation: number,
        boxInteractionState,
        isOffsetCalculated: boolean,
        checkIsElementIsMask,
    ) {
        super(position, dimension, rotation, checkIsElementIsMask);
        this.startPosition = this.position.getCopy();
        this.startDimension = this.dimension.getCopy();
        this.boxInteractionState = boxInteractionState;
        this.rotation = rotation;
        this.isOffsetCalculated = isOffsetCalculated;
    }

    setTransformerMode(boxInteractionState: InteractionStates) {
        this.boxInteractionState = boxInteractionState;
    }

    createMultiElementOwnBoxes(elements: BaseCompElement[]) {
        this.multiElementOwnBoxes = [];
        elements.forEach((element) => {
            const { position, dimension, rotation } = element.originalElement;
            this.multiElementOwnBoxes.push(
                new BoundingBox(position, dimension, rotation, this.checkIsElementIsMask, element.id),
            );
        });
    }

    createSelectionHandles(scale: number) {
        this.selectionHandles = [];

        const cornerRenderOffset = CORNER_RENDER_SIZE / 2 / scale;
        const cornerOffset = CORNER_HANDLE_SIZE / 2 / scale;
        const cornerHandleDimension = new Dimension(CORNER_HANDLE_SIZE / scale, CORNER_HANDLE_SIZE / scale);

        this.selectionHandles[CORNERS.LEFT_TOP] = new Box(
            new Position(this.position.getX() - cornerOffset, this.position.getY() - cornerOffset),
            cornerHandleDimension,
            0,
            new Position(this.position.getX() - cornerRenderOffset, this.position.getY() - cornerRenderOffset),
        );
        this.selectionHandles[CORNERS.RIGHT_TOP] = new Box(
            new Position(
                this.position.getX() + this.dimension.getWidth() - cornerOffset,
                this.position.getY() - cornerOffset,
            ),
            cornerHandleDimension,
            0,
            new Position(
                this.position.getX() + this.dimension.getWidth() - cornerRenderOffset,
                this.position.getY() - cornerRenderOffset,
            ),
        );
        this.selectionHandles[CORNERS.LEFT_BOTTOM] = new Box(
            new Position(
                this.position.getX() - cornerOffset,
                this.position.getY() + this.dimension.getHeight() - cornerOffset,
            ),
            cornerHandleDimension,
            0,
            new Position(
                this.position.getX() - cornerRenderOffset,
                this.position.getY() + this.dimension.getHeight() - cornerRenderOffset,
            ),
        );
        this.selectionHandles[CORNERS.RIGHT_BOTTOM] = new Box(
            new Position(
                this.position.getX() + this.dimension.getWidth() - cornerOffset,
                this.position.getY() + this.dimension.getHeight() - cornerOffset,
            ),
            cornerHandleDimension,
            0,
            new Position(
                this.position.getX() + this.dimension.getWidth() - cornerRenderOffset,
                this.position.getY() + this.dimension.getHeight() - cornerRenderOffset,
            ),
        );

        const lineSize = CORNER_HANDLE_SIZE / 2;
        const lineOffset = lineSize / scale;
        // This value could be divied by DPR also, to scale in case of browser zoom/
        // Dicuss it with Robin
        // const dpr = Math.min(1, window.devicePixelRatio);

        this.selectionHandles[SIDES.UP] = new Box(
            new Position(this.position.getX() + lineOffset, this.position.getY() - lineOffset),
            new Dimension(this.dimension.width - lineOffset * 2, lineOffset),
        );
        this.selectionHandles[SIDES.BOTTOM] = new Box(
            new Position(this.position.getX() + lineOffset, this.position.getY() + this.dimension.getHeight()),
            new Dimension(this.dimension.width - lineOffset * 2, lineOffset),
        );
        this.selectionHandles[SIDES.LEFT] = new Box(
            new Position(this.position.getX() - lineOffset, this.position.getY() + lineOffset),
            new Dimension(lineOffset, this.dimension.height - lineOffset * 2),
        );
        this.selectionHandles[SIDES.RIGHT] = new Box(
            new Position(this.position.getX() + this.dimension.getWidth(), this.position.getY() + lineOffset),
            new Dimension(lineOffset, this.dimension.height - lineOffset * 2),
        );

        if (this.isMultiBox) {
            return;
        }

        const quarterOffset = ROTATION_CORNER_SIZE / 4 / scale;
        const threeQuartersOffset = quarterOffset * 3;
        const rotationDimension = new Dimension(ROTATION_CORNER_SIZE / scale, ROTATION_CORNER_SIZE / scale);

        this.selectionHandles[ROTATIONS.LEFT_TOP] = new Box(
            new Position(this.position.getX() - threeQuartersOffset, this.position.getY() - threeQuartersOffset),
            rotationDimension,
        );
        this.selectionHandles[ROTATIONS.RIGHT_TOP] = new Box(
            new Position(
                this.position.getX() + this.dimension.getWidth() - quarterOffset,
                this.position.getY() - threeQuartersOffset,
            ),
            rotationDimension,
        );
        this.selectionHandles[ROTATIONS.LEFT_BOTTOM] = new Box(
            new Position(
                this.position.getX() - threeQuartersOffset,
                this.position.getY() + this.dimension.getHeight() - quarterOffset,
            ),
            rotationDimension,
        );
        this.selectionHandles[ROTATIONS.RIGHT_BOTTOM] = new Box(
            new Position(
                this.position.getX() + this.dimension.getWidth() - quarterOffset,
                this.position.getY() + this.dimension.getHeight() - quarterOffset,
            ),
            rotationDimension,
        );
    }

    isMouseOverHandles(mousePosition: Position): CORNERS | SIDES | -1 {
        let resizeHandleIndex = -1;

        if (!this.isResizeable) {
            return resizeHandleIndex;
        }

        const centerPosition = new Position(
            this.position.getX() + this.dimension.getWidth() / 2,
            this.position.getY() + this.dimension.getHeight() / 2,
        );

        for (const handleBoxIndex in this.selectionHandles) {
            if (this.selectionHandles[handleBoxIndex].isPointInBox(mousePosition, centerPosition, this.rotation)) {
                resizeHandleIndex = Number(handleBoxIndex);
                break;
            }
        }

        return Number(resizeHandleIndex);
    }

    isMouseOverBox(mousePosition: Position) {
        return super.isPointInBox(mousePosition);
    }

    recalculateSize(
        oldMousePosition: Position,
        newMousePosition: Position,
        resizeHandleIndex: CORNERS | SIDES,
        keepAspectRatio = false,
        resizeFromCenter = false,
    ) {
        const oldCenterPosition = this.getStartCenterPosition();
        const oldPosition = this.position.getCopy();
        const oldDimension = this.dimension.getCopy();
        const center = this.getScaleCenter(resizeHandleIndex, resizeFromCenter);
        const rotatedOldPosition = oldMousePosition.rotatePoint(oldCenterPosition, this.rotation).subtract(center);
        const rotatedNewPosition = newMousePosition.rotatePoint(oldCenterPosition, this.rotation).subtract(center);
        const diffPosition = rotatedNewPosition.subtract(rotatedOldPosition);
        const diffDimension = new Dimension(diffPosition.getX(), diffPosition.getY());

        const [sx, sy] = this.getScaleRate(diffDimension, resizeHandleIndex, keepAspectRatio, resizeFromCenter);
        const position = scalePoint(this.startPosition, center, sx, sy);
        const point1 = this.startPosition.add(
            new Position(this.startDimension.getWidth(), this.startDimension.getHeight()),
        );
        const point2 = scalePoint(point1, center, sx, sy);
        const dimension = new Dimension(point2.getX() - position.getX(), point2.getY() - position.getY());

        this.position = position;
        this.dimension = dimension;

        const p1 = this.position.rotatePoint(this.getStartCenterPosition(), -this.rotation);
        const p2 = this.position.rotatePoint(this.getCenterPosition(), -this.rotation);
        this.position = this.position.subtract(p2.subtract(p1));

        if (this.dimension.getWidth() <= 10 || this.dimension.getHeight() <= 10) {
            this.dimension = oldDimension;
            this.position = oldPosition;
        }

        return !(this.position.equals(oldPosition) && this.dimension.equals(oldDimension));
    }

    recalculateRotation(oldMousePosition: Position, newMousePosition: Position) {
        const oldCenterPosition = this.getCenterPosition();
        const zoomedPosition = newMousePosition.getCopy();
        const angle =
            (Math.atan2(
                zoomedPosition.getY() - oldCenterPosition.getY(),
                zoomedPosition.getX() - oldCenterPosition.getX(),
            ) *
                180) /
            Math.PI;
        this.rotation += angle - this.rotation - 90;

        return true;
    }

    getScaleCenter(resizeHandleIndex: CORNERS | SIDES, resizeFromCenter = false) {
        const center = this.getStartCenterPosition();

        if (resizeFromCenter) {
            return center;
        }

        switch (resizeHandleIndex) {
            case SIDES.RIGHT:
                center.setX(this.startPosition.getX());
                break;
            case SIDES.LEFT:
                center.setX(this.startPosition.getX() + this.startDimension.getWidth());
                break;
            case SIDES.BOTTOM:
                center.setY(this.startPosition.getY());
                break;
            case SIDES.UP:
                center.setY(this.startPosition.getY() + this.startDimension.getHeight());
                break;
            case CORNERS.LEFT_TOP:
                center.setX(this.startPosition.getX() + this.startDimension.getWidth());
                center.setY(this.startPosition.getY() + this.startDimension.getHeight());
                break;
            case CORNERS.RIGHT_TOP:
                center.setX(this.startPosition.getX());
                center.setY(this.startPosition.getY() + this.startDimension.getHeight());
                break;
            case CORNERS.RIGHT_BOTTOM:
                center.setX(this.startPosition.getX());
                center.setY(this.startPosition.getY());
                break;
            case CORNERS.LEFT_BOTTOM:
                center.setX(this.startPosition.getX() + this.startDimension.getWidth());
                center.setY(this.startPosition.getY());
                break;
        }

        return center;
    }

    getScaleRate(
        diffDimension: Dimension,
        resizeHandleIndex: CORNERS | SIDES,
        keepAspectRatio = false,
        resizeFromCenter = false,
    ) {
        const w = this.startDimension.getWidth() / (resizeFromCenter ? 2 : 1);
        const h = this.startDimension.getHeight() / (resizeFromCenter ? 2 : 1);
        const signX = [CORNERS.LEFT_TOP, CORNERS.LEFT_BOTTOM, SIDES.LEFT].includes(resizeHandleIndex) ? -1 : 1;
        const signY = [CORNERS.LEFT_TOP, CORNERS.RIGHT_TOP, SIDES.UP].includes(resizeHandleIndex) ? -1 : 1;

        let sx = (w + signX * diffDimension.getWidth()) / w;
        let sy = (h + signY * diffDimension.getHeight()) / h;

        switch (resizeHandleIndex) {
            case SIDES.RIGHT:
            case SIDES.LEFT:
                sy = keepAspectRatio ? 0 : 1;
                break;
            case SIDES.BOTTOM:
            case SIDES.UP:
                sx = keepAspectRatio ? 0 : 1;
                break;
        }

        if (keepAspectRatio) {
            sy = Math.max(sx, sy);
            sx = sy;
        }

        return [sx, sy];
    }

    getStartCenterPosition() {
        return new Position(
            this.startPosition.getX() + this.startDimension.getWidth() / 2,
            this.startPosition.getY() + this.startDimension.getHeight() / 2,
        );
    }

    getCenterPosition() {
        return new Position(
            this.position.getX() + this.dimension.getWidth() / 2,
            this.position.getY() + this.dimension.getHeight() / 2,
        );
    }

    commit() {
        this.startPosition = this.position.getCopy();
        this.startDimension = this.dimension.getCopy();
    }
}

export default TransformerBox;
