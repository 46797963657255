import React from 'react';
import { notify } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { fetchCategory, removeTemplatesFromCategory } from 'packages/store/categories/categories.actions';
import { OwnershipFilterType } from 'packages/pages/components/filters/components/Ownership/types';
import { catchResponseError, isTrialAccountStorageFn } from 'packages/helpers/helpers';
import { sendAppcuesEvent } from '~/helpers/RouteWithAppcues';
import * as types from '../types';
import TemplatesService from '../../services/TemplatesService';
import { updateInArray } from '../../helpers/helpers';
import { fetchCreative } from '~/store/creatives/common.actions';
import CreativesService from '~/services/CreativesService';

export function updateTemplateThumbnails(templateThumbnails) {
    return {
        type: types.TEMPLATES_CHANGE_SOME,
        templateThumbnails,
    };
}

export function resetOwnership() {
    return (dispatch, getState) => {
        dispatch({
            type: types.TEMPLATES_RESET_OWNERSHIP,
            ownershipSource: OwnershipFilterType.OWNED_BY_OTHERS,
        });
    };
}

export function initializeTemplates() {
    return (dispatch, getState) => {
        dispatch({ type: types.INITIALIZE_TEMPLATES });

        if (isTrialAccountStorageFn(getState())) {
            dispatch(resetOwnership());
        }
    };
}

export function fetchTemplates({
    newOptions = {},
    setSelected = false,
    fetchFromStart = false,
    deselect,
    movedTemplates = {},
    sharedTemplates = [],
}) {
    return async (dispatch, getState) => {
        const oldState = getState();
        const { fetchOptions } = oldState.templates;
        const options = {
            ...fetchOptions,
            ...newOptions,
            customerId: oldState.user.currentCompany,
        };

        if (!newOptions.assignedToCollection) {
            delete options.assignedToCollection;
        }

        dispatch({ type: types.GET_TEMPLATES_REQUEST, options, searching: fetchOptions.search !== options.search });

        const optionsToSend = { ...options };

        if (fetchFromStart) {
            optionsToSend.size = (optionsToSend.page + 1) * optionsToSend.size;
            optionsToSend.page = 0;
            dispatch({
                type: types.RESET_FETCHED_TEMPLATES,
                keep: sharedTemplates,
            });
        }

        const res = await TemplatesService.fetchTemplates(optionsToSend).catch(catchResponseError);

        if (!res || getState().templates.fetchOptions.gallery !== options.gallery) {
            return;
        }

        if (res.status !== 200) {
            dispatch({ type: types.GET_TEMPLATES_FAIL });

            return;
        }

        const { totalItems, items } = res.json;

        let newItems = items;

        if (options.page > 0 && !fetchFromStart) {
            newItems = [...oldState.templates.templates, ...items];
        }

        // prevents rerendering of the templates list when the shared templates are fetched
        sharedTemplates.forEach((templateId) => {
            const idx = newItems.findIndex(({ id }) => id === templateId);

            if (idx === -1) {
                return;
            }

            const sharedTemplate = oldState.templates.templates.find(({ id }) => id === templateId);

            if (!sharedTemplate) {
                return;
            }

            newItems[idx] = sharedTemplate;
        });

        dispatch({
            type: types.GET_TEMPLATES_SUCCESS,
            templates: newItems,
            totalItems,
            setSelected,
            deselect,
            movedTemplates,
        });
    };
}

// because we get option from the state inside the action creator
// we can memoize and debounce callbacks that use it
export function fetchTemplatesBySearchQuery(searchQuery) {
    return (dispatch, getState) => {
        const newOptions = {
            ...getState().templates.fetchOptions,
            search: searchQuery,
        };

        dispatch(fetchTemplates({ newOptions, setSelected: false, fetchFromStart: true }));
    };
}

export function getTemplate(id) {
    return (dispatch) => {
        TemplatesService.fetchTemplate(id)
            .then(({ status, json }) => {
                if (status === 200) {
                    dispatch({ type: types.SET_TEMPLATE, template: json });
                }
            })
            .catch(catchResponseError);
    };
}

export function fetchSystemTemplates() {
    return (dispatch, getState) => {
        dispatch({ type: types.GET_SYSTEM_TEMPLATES_REQUEST });
        const options = {
            system: true,
            page: 0,
            size: 100,
            customerId: getState().user.currentCompany,
            search: '',
            sortingField: 'UPDATED',
            sortingOrder: 'DESC',
        };
        TemplatesService.fetchTemplates(options)
            .then(({ status, json }) => {
                if (status === 200) {
                    const { items } = json;
                    dispatch({ type: types.GET_SYSTEM_TEMPLATES_SUCCESS, templates: items });
                } else {
                    dispatch({ type: types.GET_SYSTEM_TEMPLATES_FAIL });
                }
            })
            .catch(catchResponseError);
    };
}

export function selectTemplate(template) {
    return (dispatch, getState) => {
        dispatch({ type: types.SELECT_TEMPLATE, templateId: template.id });
        sendAppcuesEvent('New design modal - Template selected', { id: template.id, name: template.name });
    };
}

export function toggleTemplate(id) {
    return (dispatch, getState) => {
        dispatch({ type: types.TOGGLE_TEMPLATE, id });
    };
}

export function toggleAllTemplates(check) {
    return (dispatch, getState) => {
        dispatch({ type: types.TOGGLE_ALL_TEMPLATES, check });
    };
}

export function renameTemplate({ id, name, description, fetchNew }) {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_RENAME_TEMPLATE_STATUS,
            status: true,
        });

        CreativesService.renameCreativeOrTemplate(id, { name, description })
            .then(({ status, json: template }) => {
                if (status === 200) {
                    const { templates } = getState().templates;

                    dispatch({
                        type: types.UPDATE_TEMPLATES,
                        templates: updateInArray(
                            templates,
                            (templateItem) => templateItem.id === id,
                            (prev) => ({ ...prev, ...template }),
                        ),
                    });

                    if (fetchNew) {
                        dispatch(fetchCreative(id, fetchNew));
                    }

                    notify({
                        title: <Translate id="modal.design.create.template.rename.success" />,
                        variant: 'success',
                    });
                    sendAppcuesEvent('Template renamed', { id: template.id, name: template.name });
                } else {
                    dispatch({
                        type: types.SET_RENAME_TEMPLATE_STATUS,
                        status: false,
                    });
                    notify({
                        title: <Translate id="modal.design.create.template.rename.failure" />,
                        variant: 'error',
                    });
                }
            })
            .catch((err) => {
                catchResponseError(err);

                dispatch({
                    type: types.SET_RENAME_TEMPLATE_STATUS,
                    status: false,
                });
            });
    };
}

export function deleteTemplates(ids, categoryId = null) {
    return async (dispatch, getState) => {
        dispatch({ type: types.DELETE_TEMPLATES_IN_PROGRESS });

        const res = await TemplatesService.deleteTemplates(ids).catch(catchResponseError);

        if (!res) {
            return;
        }

        if (res.status !== 200) {
            dispatch({ type: types.DELETE_TEMPLATES_FAIL });
            notify({
                title: <Translate id="modal.design.create.template.delete.failure" />,
                variant: 'error',
            });

            return;
        }

        dispatch({ type: types.DELETE_TEMPLATES_SUCCESS });
        dispatch(deselectTemplate());

        const { options } = getState().templates.templates;

        dispatch(fetchTemplates({ ...options, fetchFromStart: true }, false, true, true));

        if (categoryId) {
            dispatch(fetchCategory(categoryId));
            dispatch(removeTemplatesFromCategory(categoryId, ids));
        }

        notify({
            title: <Translate id="modal.design.create.template.delete.success" />,
            variant: 'success',
        });
        sendAppcuesEvent('Templates removed', { ids });
    };
}

export function duplicateTemplates(ids, categoryId = null) {
    return async (dispatch, getState) => {
        dispatch({ type: types.DUPLICATE_TEMPLATES_IN_PROGRESS });

        const res = await TemplatesService.duplicateTemplates(ids, categoryId).catch(catchResponseError);

        if (!res) {
            return;
        }

        if (res.status !== 200) {
            dispatch({ type: types.DUPLICATE_TEMPLATES_FAIL });
            notify({
                title: <Translate id="modal.design.create.template.duplicate.failure" />,
                variant: 'error',
            });

            return;
        }

        dispatch({ type: types.DUPLICATE_TEMPLATES_SUCCESS });
        dispatch(deselectTemplate());

        const { options } = getState().templates.templates;

        dispatch(fetchTemplates({ ...options, fetchFromStart: true }, false, true, true));

        notify({
            title: <Translate id="modal.design.create.template.duplicate.success" />,
            variant: 'success',
        });
        sendAppcuesEvent('Templates duplicated', { ids });
    };
}

export function deselectTemplate() {
    return {
        type: types.DESELECT_TEMPLATE,
    };
}
