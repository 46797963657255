import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    type CreateElementOptions,
    type VisualElement,
    ShapeTypes,
    ElementTypes,
    FontFamily,
    CreativeTypes,
} from '@bynder-studio/render-core';
import { PlaybackManipulationRenderer } from '@bynder-studio/render-web';
import useEditor from '~/hooks/useEditor';
import { getElementsFonts, getFonts } from 'packages/store/user/user.selectors';
import { getElementPropertiesFromSource } from '~/common/editor/helpers';

export type ElementCreator = {
    createTextElement: () => void;
    createEllipseElement: () => void;
    createRectangleElement: () => void;
    createVideoElement: (source: Source) => void;
    createImageElement: (source: Source) => void;
};

type ElementToSubmit = {
    data: [ElementTypes, any, CreateElementOptions];
    onCreated?: (element: VisualElement) => void;
};

type Source = {
    width: number;
    height: number;
    [key: string]: any; // todo: make it more specific
};

const DEFAULT_VIRTUAL_DATA = {
    bynderCollectionId: null,
    allowPersonalUpload: false,
};

export default function useElementCreator(): ElementCreator {
    const { creativeModel, manipulationRenderer, creativeType } = useEditor();
    const fonts = useSelector(getFonts);
    const elementsFonts = useSelector(getElementsFonts);

    /**
     * Creates an element from provided data,
     * executes provided callback if some update right after creation needed
     */
    const submitElement = useCallback(
        ({ data, onCreated }: ElementToSubmit): void => {
            if (onCreated) {
                creativeModel.beginAccumulation();

                const element = creativeModel.createElement(...data);
                onCreated(element);

                creativeModel.endAccumulation();
            } else {
                creativeModel.createElement(...data);
            }

            manipulationRenderer.redraw();
        },
        [creativeModel, manipulationRenderer],
    );

    const createSourceUpdater = useCallback(
        (type: ElementTypes.IMAGE | ElementTypes.VIDEO, source: Source) => (element: VisualElement) => {
            const updateData = getElementPropertiesFromSource(element, type, source);
            creativeModel.updateElement(element.id, updateData);
        },
        [creativeModel],
    );

    const createImageElement = useCallback(
        (source: Source) => {
            const type = ElementTypes.IMAGE;
            const rawParams = {
                name: 'New image element',
                startFrame: getStartFrame(),
                creativeType,
                naturalDimension: getNaturalDimension(source),
                virtualData: DEFAULT_VIRTUAL_DATA,
            };

            submitElement({
                data: [type, rawParams, {}],
                onCreated: createSourceUpdater(type, source),
            });
        },
        [manipulationRenderer, createSourceUpdater, submitElement],
    );

    const getStartFrame = useCallback(
        () =>
            manipulationRenderer instanceof PlaybackManipulationRenderer ? manipulationRenderer.getCurrentFrame() : 0,
        [manipulationRenderer],
    );

    const createVideoElement = useCallback(
        (source: Source) => {
            if (creativeType !== CreativeTypes.VIDEO) {
                return;
            }

            const type = ElementTypes.VIDEO;
            const rawParams = {
                name: 'New video element',
                startFrame: getStartFrame(),
                creativeType,
                naturalDimension: getNaturalDimension(source),
                virtualData: DEFAULT_VIRTUAL_DATA,
            };

            submitElement({
                data: [type, rawParams, {}],
                onCreated: createSourceUpdater(type, source),
            });
        },
        [manipulationRenderer, createSourceUpdater, submitElement],
    );

    const createTextElement = useCallback(() => {
        const rawParams = {
            name: 'New text element',
            text: 'Add text',
            startFrame: getStartFrame(),
            fontId: getDefaultFontId(elementsFonts, fonts),
            creativeType,
        };

        submitElement({ data: [ElementTypes.TEXT, rawParams, {}] });
    }, [fonts, elementsFonts, manipulationRenderer, submitElement]);

    const createShapeElement = useCallback(
        (shapeType: ShapeTypes) => {
            const rawParams = {
                name: 'New shape element',
                startFrame: getStartFrame(),
                shapeType,
                creativeType,
            };

            submitElement({ data: [ElementTypes.SHAPE, rawParams, {}] });
        },
        [manipulationRenderer, submitElement],
    );

    const createRectangleElement = useCallback(() => createShapeElement(ShapeTypes.RECTANGLE), [createShapeElement]);
    const createEllipseElement = useCallback(() => createShapeElement(ShapeTypes.ELLIPSE), [createShapeElement]);

    const elementCreator = useMemo(
        () => ({
            createTextElement,
            createEllipseElement,
            createRectangleElement,
            createVideoElement,
            createImageElement,
        }),
        [createTextElement, createEllipseElement, createRectangleElement, createVideoElement, createImageElement],
    );

    return elementCreator;
}

function getDefaultFontId(elementsFonts: ReturnType<typeof getElementsFonts>, fonts: FontFamily[]) {
    const elementsFontId = elementsFonts[0]?.font.fonts[0]?.id;
    const fonFamily = fonts.find((item) => item.id === elementsFontId) || fonts[0];
    const font = fonFamily.fonts.find((item) => item.id === fonFamily.id) || fonFamily.fonts[0];

    return font.id;
}

function getNaturalDimension(source: Source) {
    return {
        width: source.width,
        height: source.height,
    };
}
