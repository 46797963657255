import { ElementUpdateTypes } from '../Enums/ElementUpdateTypes';
import { ElementRemovedEventData, ElementUpdatedEventData, Event } from '../event-types';
import { mergeData } from './utils';
import { truthy } from '@bynder-studio/misc';
import { GroupElement } from '../Models/Elements/GroupElement';
import { getElementType } from './elementUtils';
import { ElementTypes } from '../Enums/ElementTypes';
import { type VisualElement } from '../types';

const getAllparentsIds = (element: VisualElement | null) => {
    const parents = [];
    let parent = element?.parent;

    while (parent) {
        parents.push(parent.id);
        parent = parent.parent;
    }

    return parents;
};

const eventAccumulationComparator = (a: Event, b: Event) => {
    if (a[0] === 'elementUpdated' && b[0] === 'elementUpdated') {
        const el1 = (a[1] as ElementUpdatedEventData).element;
        const el2 = (b[1] as ElementUpdatedEventData).element;
        const type1 = getElementType(el1);
        const type2 = getElementType(el2);

        if (getAllparentsIds(el1).includes(el2?.id)) {
            return -1;
        }

        if (getAllparentsIds(el2).includes(el1?.id)) {
            return 1;
        }

        // sort a before b if a is group and b is not
        if (type1 === ElementTypes.GROUP && type2 !== ElementTypes.GROUP) {
            return (el1 as GroupElement).isContainsElement(el2.id) ? 1 : -1;
        }

        // sort a after b if b is group and a is not
        if (type1 !== ElementTypes.GROUP && type2 === ElementTypes.GROUP) {
            return (el2 as GroupElement).isContainsElement(el1.id) ? -1 : 1;
        }

        // when both are groups
        if (type1 === ElementTypes.GROUP && type2 === ElementTypes.GROUP) {
            // sort a before b if b element in children list of a
            if ((el1 as GroupElement).isContainsElement(el2.id)) {
                return -1;
            }

            // sort a after b if a element in children list of b
            if ((el2 as GroupElement).isContainsElement(el1.id)) {
                return 1;
            }
        }
    }

    if (a[0] === b[0]) {
        return 0;
    }

    if (a[0] === 'elementRemoved') {
        return 1;
    }

    if (b[0] === 'elementRemoved') {
        return -1;
    }

    return 0;
};

export function mergeAccumulatedData(accumulator: Event[]): Event[] {
    const elementIdToIndex: { [key: string]: number } = {};
    const arr: (Event | null)[] = [...accumulator];
    arr.forEach((item, index) => {
        if (!item) {
            return;
        }

        const [eventName, eventData] = item;

        if (eventName !== 'elementUpdated') {
            return;
        }

        const { element, oldValues, newValues, updateTypes } = eventData as ElementUpdatedEventData;

        if (element.id in elementIdToIndex) {
            const prevIndex = elementIdToIndex[element.id];
            const data = arr[prevIndex]![1] as ElementUpdatedEventData;
            data.element = element;
            if (data.oldValues.parent && oldValues.parent) delete oldValues.parent;
            if (data.oldValues.children && oldValues.children) delete oldValues.children;
            if (data.newValues.parent && newValues.parent) delete data.newValues.parent;
            if (data.newValues.children && newValues.children) delete data.newValues.children;
            data.oldValues = mergeData(oldValues, data.oldValues);
            data.newValues = mergeData(data.newValues, newValues);
            data.updateTypes = new Set([...updateTypes, ...data.updateTypes]);
            // NOTE check this later
            arr[index] = null;
        } else {
            elementIdToIndex[element.id] = index;
        }
    });

    const getUpdateIndex = (elementId: number) =>
        arr.findIndex(
            (item) =>
                item && item[0] === 'elementUpdated' && (item[1] as ElementUpdatedEventData).element.id === elementId,
        );

    const getRemoveIndex = (elementId: number) =>
        arr.findIndex(
            (item) =>
                item && item[0] === 'elementRemoved' && (item[1] as ElementRemovedEventData).element.id === elementId,
        );

    arr.forEach((item: Event | null) => {
        if (!item) {
            return;
        }

        const [eventName, eventData] = item;

        if (eventName !== 'elementRemoved') {
            return;
        }

        const { element } = eventData as ElementRemovedEventData;
        const updateIndex = getUpdateIndex(element.id);

        if (updateIndex === -1) {
            return;
        }

        element.setProperties((arr[updateIndex]![1] as ElementUpdatedEventData).oldValues);

        if (element instanceof GroupElement && element.children) {
            element.setProperties(element.getDiffBasedOnChildren!());
            element.children.forEach((child) => {
                const removeIndex = getRemoveIndex(child.id);

                if (removeIndex !== -1) {
                    arr[removeIndex] = null;
                }
            });
        }

        arr[updateIndex] = null;
    });

    return arr.filter(truthy).sort(eventAccumulationComparator);
}

export function checkIsNeedUpdateElementsTreeByAccumulator(accumulator: Event[]) {
    const check =
        (type: ElementUpdateTypes) =>
        (args: ['elementUpdated', ElementUpdatedEventData]): boolean =>
            args[1].updateTypes.has(type);

    const updates = accumulator.filter(([eventName]) => eventName === 'elementUpdated') as [
        'elementUpdated',
        ElementUpdatedEventData,
    ][];
    const isThereCreatedElement = accumulator.some(([eventName]) => eventName === 'elementCreated');
    const isThereRemovedElement = accumulator.some(([eventName]) => eventName === 'elementRemoved');
    const isThereTimeframeChanges = updates.some(check(ElementUpdateTypes.TIMEFRAME));
    const isThereRenderOrderChanges = updates.some(check(ElementUpdateTypes.RENDER_ORDER));
    const isThereMaskChanges = updates.some(check(ElementUpdateTypes.MASK));

    return (
        isThereCreatedElement ||
        isThereRemovedElement ||
        isThereTimeframeChanges ||
        isThereRenderOrderChanges ||
        isThereMaskChanges
    );
}

export function getElementsToSiblingsFromAccumulator(accumulator: Event[]) {
    return accumulator
        .filter(([eventName]) => eventName === 'elementUpdated')
        .map((args) => {
            const { element, updateTypes } = args[1] as ElementUpdatedEventData;

            if (updateTypes.has(ElementUpdateTypes.TIMEFRAME) || updateTypes.has(ElementUpdateTypes.RENDER_ORDER)) {
                return element;
            }
        })
        .filter(truthy);
}
