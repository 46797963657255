import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import { ElementTypes } from '../../Enums/ElementTypes';
import { getAllElementsRecursively, getElementDiffByAsset } from '../../Helpers/elementUtils';
import type { IAsset } from '../Assets/IAsset';
import { BaseModel, defaultCreateElementOptions } from './BaseModel';
import { type CreateElementOptions, Reason, type UpdateGlobalPropertyOptions, type VisualElement } from '../../types';
import { CompModel } from '../CompModels/CompModel';
import { createElement } from '../Elements/Helpers/ElementHelpers';
import { BaseProperty } from '../Properties/BaseProperty';

export class ImageModel extends BaseModel {
    protected compModel!: CompModel;

    protected assetLoadListener = ({ asset }: { asset: IAsset }) => this.handleAssetLoad(asset);

    endAccumulation(reason: Reason = 'user') {
        super.endAccumulation(reason);
        this.invalidateCompModel();
    }

    // create element with basic props
    createElement(type: ElementTypes, rawParams: any, options: CreateElementOptions = {}): VisualElement {
        const { reason } = { ...defaultCreateElementOptions, ...options };
        const newElement = createElement(type, rawParams, this.getElements(), this.getDimensions());

        this.addElements([newElement], newElement.startFrame, {
            reason,
        });

        return newElement;
    }

    addElements(elements: VisualElement[], startFrame: number, options: CreateElementOptions = {}) {
        this.beginAccumulation();
        const flattenElementsArray = elements.map((element) => getAllElementsRecursively([element]));
        super.addElements(elements, startFrame, options, flattenElementsArray);
        elements.forEach((element, index) => this.setupElements(flattenElementsArray[index]));
        this.endAccumulation();
    }

    updateGlobalProperty(elType: string, rawElement: any, options: UpdateGlobalPropertyOptions = {}) {
        super.updateGlobalProperty(elType, rawElement, options);
        this.invalidateCompModel();
    }

    updateDimension(
        rawDimension: {
            width: number;
            height: number;
        },
        options: UpdateGlobalPropertyOptions = {},
    ) {
        super.updateDimension(rawDimension, options);
        this.invalidateCompModel();
    }

    setAssetLoader(assetLoader: IAssetsLoader) {
        super.setAssetLoader(assetLoader);
        this.getAssetLoader().eventEmitter.on('asset.load', this.assetLoadListener);

        return this;
    }

    getVisibleElements(): VisualElement[] {
        return this.getElements().filter((el) => !el.hidden);
    }

    getCompModel(frameIndex = 0) {
        if (this.compModel?.isUpToDate()) {
            return this.compModel;
        }

        this.compModel = new CompModel()
            .setFrame(0) // TODO: frameIndex
            .setDimensions(this.getDimensions())
            .setBackgroundColor(this.getBackgroundColor().color)
            .setElements(this.getVisibleElements())
            .setMaskElementRanges(this.getAllMaskElementRanges());

        return this.compModel;
    }

    getAllGlobalProperties(): BaseProperty[] {
        return [this.backgroundColor];
    }

    protected handleAssetLoad(asset: IAsset): void {
        const elements = this.getAllElementsRecursively();
        elements.forEach((element) => {
            if (element.isContainsAsset(asset)) {
                element.setProperties(getElementDiffByAsset(element, asset));
            }
        });

        const compModel = this.getCompModel();

        if (!compModel.isContainsAsset(asset)) {
            return;
        }

        this.invalidateCompModel();
    }

    protected setupElements(elements: VisualElement[] = this.getAllElementsRecursively()) {
        super.setupElements(elements);
        elements.forEach((el) => {
            el.constructAsset();
        });
    }

    protected invalidateCompModel() {
        if (this.compModel) {
            this.compModel.invalidate();
            this.emit('requireCreativeUpdate', {});
        }
    }

    getCopy() {
        return new ImageModel()
            .setElements(this.getElementsCopy())
            .setBackgroundColor(this.backgroundColor.getCopy())
            .setDimensions(this.dimension.getCopy());
    }
}
