import React from 'react';
import { getFontById } from '@bynder-studio/structured-text';
import { type TextElement } from '@bynder-studio/render-core';
import { Button, Divider, Dropdown, Flex } from '@bynder/design-system';
import { IconAdd, IconMore, IconPlaylistAdd } from '@bynder/icons';
import { useTranslate } from '@bynder/localization';
import generateTestId from '~/helpers/testIdHelpers';
import MaybeTooltip from 'packages/common/MaybeTooltip';
import useCreateStyleModal from './modals/useCreateStyleModal';
import useDeleteStyleModal from './modals/useDeleteStyleModal';
import useRenameStyleModal from './modals/useRenameStyleModal';
import useManageStyleModal from './modals/useManageStyleModal';
import { useElementStyleModal } from './modals/useElementStyleModal';
import FontThumbnail from './shared/FontThumbnail';
import { useTextStyles } from './useTextStyles';
import { TextStyleItem } from './TextStyleItem';
import { textStyleSubtext } from './utils';
import { DropdownNewButton, FullWidthButton, FullWidthDropdown, TextStyleName } from './Component.styled';

type TextStylesProps = {
    creativeModel: any;
    selectedElement: TextElement;
};

const TextStyles = ({ creativeModel, selectedElement }: TextStylesProps) => {
    const {
        item,
        hasStyleList,
        elementStyleList,
        allStyleList,
        searchValue,
        handleSearchChange,
        isAllowedToCreateTextStyle,
        handleTextStyleSelect,
        createTextStyle,
        deleteTextStyle,
        renameTextStyle,
        detachTextStyle,
        updateStyleWithOverrides,
        resetAllOverrides,
        stylesWithOverrides,
        styleIds,
        isMixed,
        mixedValue,
        overrides,
    } = useTextStyles(creativeModel, selectedElement);
    const { locked } = selectedElement ?? {};

    const { translate } = useTranslate();
    const { setCreateStyleModalOpen, renderCreateStyleModal } = useCreateStyleModal({ createTextStyle });
    const { setDeleteStyleModalOpen, renderDeleteStyleModal } = useDeleteStyleModal({ deleteTextStyle });
    const { setRenameStyleModalOpen, renderRenameStyleModal } = useRenameStyleModal({ item, renameTextStyle });
    const { setManageStyleModalOpen, renderManageStyleModal } = useManageStyleModal({ creativeModel, allStyleList });
    const { openElementTextStylesModal, renderElementStyleModal } = useElementStyleModal({
        creativeModel,
        selectedElement,
    });

    const openNewTextModal = () => {
        setCreateStyleModalOpen(true);
    };

    const hasAppliedStyles = item || isMixed;

    return (
        <>
            <Flex alignItems="center" justifyContent="space-between">
                <FullWidthDropdown
                    minWidth="288px"
                    onClose={() => handleSearchChange('')}
                    trigger={({ isOpen, ...triggerProps }) => (
                        <Flex>
                            {!hasAppliedStyles ? (
                                <FullWidthButton
                                    rightElements={<Dropdown.Arrow />}
                                    isDisabled={locked}
                                    {...generateTestId('shots_text_styles_list')}
                                    {...triggerProps}
                                >
                                    {translate('editor.sidebar.shots.text.textStyle.no_style')}
                                </FullWidthButton>
                            ) : (
                                <FullWidthButton
                                    isSelected={isOpen}
                                    isDisabled={locked}
                                    subtext={
                                        isMixed && !overrides
                                            ? translate('editor.sidebar.shots.text.textStyle.styles_q', {
                                                  styles: styleIds.length,
                                              })
                                            : textStyleSubtext(item, overrides)
                                    }
                                    rightElements={<Dropdown.Arrow />}
                                    thumbnail={
                                        !isMixed && item ? (
                                            <FontThumbnail
                                                id={item.uuid + item.fontId}
                                                url={getFontById(item.fontId)?.url ?? ''}
                                                overrides={overrides}
                                            />
                                        ) : undefined
                                    }
                                    {...triggerProps}
                                    {...generateTestId('shots_text_styles_list')}
                                >
                                    <TextStyleName>{isMixed ? mixedValue : item.name}</TextStyleName>
                                </FullWidthButton>
                            )}
                        </Flex>
                    )}
                >
                    <Dropdown.SearchInput
                        value={searchValue}
                        onChange={handleSearchChange}
                        placeholder="Search"
                        iconRight={
                            <MaybeTooltip
                                hasTooltip={isAllowedToCreateTextStyle}
                                content={translate('editor.sidebar.shots.text.textStyle.button.create')}
                            >
                                <DropdownNewButton
                                    variant="clean"
                                    title={translate('editor.sidebar.shots.text.textStyle.button.create')}
                                    onClick={openNewTextModal}
                                    isDisabled={!isAllowedToCreateTextStyle}
                                    icon={<IconAdd />}
                                    {...generateTestId('shots_text_styles_dropdown_button_create')}
                                />
                            </MaybeTooltip>
                        }
                        aria-label="Search for style"
                    />
                    {!hasStyleList && (
                        <Dropdown.Text>{translate('editor.sidebar.shots.text.textStyle.no_styles')}</Dropdown.Text>
                    )}
                    {hasStyleList && (
                        <Dropdown.SectionTitle>
                            {translate('editor.sidebar.shots.text.textStyle.element_styles')}
                        </Dropdown.SectionTitle>
                    )}
                    {hasStyleList && !elementStyleList.length && (
                        <Dropdown.Text>{translate('editor.sidebar.shots.text.textStyle.no_styles')}</Dropdown.Text>
                    )}
                    {elementStyleList.map((item) => (
                        <TextStyleItem
                            key={item.uuid}
                            item={item}
                            selected={styleIds.includes(item.uuid)}
                            useMultiselectIcon={styleIds.length > 1}
                            onClick={handleTextStyleSelect}
                        />
                    ))}
                    {hasStyleList && (
                        <Dropdown.Item icon={<IconPlaylistAdd />} onClick={openElementTextStylesModal}>
                            {translate('editor.sidebar.shots.text.textStyle.configure-element-styles')}
                        </Dropdown.Item>
                    )}
                    {hasStyleList && <Divider />}
                    {hasStyleList && (
                        <Dropdown.SectionTitle>
                            {translate('editor.sidebar.shots.text.textStyle.all_styles')}
                        </Dropdown.SectionTitle>
                    )}
                    {hasStyleList && !allStyleList.length && (
                        <Dropdown.Text>{translate('editor.sidebar.shots.text.textStyle.no_styles')}</Dropdown.Text>
                    )}
                    {allStyleList.map((item) => (
                        <TextStyleItem
                            key={item.uuid}
                            item={item}
                            selected={styleIds.includes(item.uuid)}
                            useMultiselectIcon={styleIds.length > 1}
                            onClick={handleTextStyleSelect}
                        />
                    ))}
                </FullWidthDropdown>
                <MaybeTooltip
                    hasTooltip={!hasAppliedStyles && isAllowedToCreateTextStyle && !locked}
                    position="top-left"
                    content={translate('editor.sidebar.shots.text.textStyle.button.create')}
                    {...generateTestId('shots_text_styles_button_create_tooltip')}
                >
                    {!hasAppliedStyles ? (
                        <Button
                            isDisabled={!isAllowedToCreateTextStyle || locked}
                            variant="clean"
                            title=""
                            icon={<IconAdd />}
                            onClick={openNewTextModal}
                            {...generateTestId('shots_text_styles_button_create')}
                        />
                    ) : (
                        <Dropdown
                            position="bottom-right"
                            trigger={({ isOpen, ...triggerProps }) => (
                                <Button
                                    isDisabled={locked}
                                    isPressed={isOpen}
                                    variant="clean"
                                    title="Options"
                                    icon={<IconMore />}
                                    {...generateTestId('shots_text_styles_item_menu')}
                                    {...triggerProps}
                                />
                            )}
                        >
                            {!!overrides && (
                                <>
                                    <Dropdown.Item onClick={updateStyleWithOverrides}>
                                        {translate('editor.sidebar.shots.text.textStyle.update', {
                                            styles: stylesWithOverrides,
                                        })}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={resetAllOverrides}>
                                        {translate('editor.sidebar.shots.text.textStyle.reset_all')}
                                    </Dropdown.Item>
                                    <Divider />
                                </>
                            )}
                            {isMixed ? (
                                <>
                                    <Dropdown.Item
                                        onClick={detachTextStyle}
                                        {...generateTestId('shots_text_styles_item_menu_detach_styles')}
                                    >
                                        {translate('editor.sidebar.shots.text.textStyle.detach_all')}
                                    </Dropdown.Item>
                                    <Divider />
                                    <Dropdown.Item onClick={() => setManageStyleModalOpen(true)}>
                                        {translate('editor.sidebar.shots.text.textStyle.manage')}
                                    </Dropdown.Item>
                                </>
                            ) : (
                                <>
                                    <Dropdown.Item
                                        onClick={detachTextStyle}
                                        {...generateTestId('shots_text_styles_item_menu_detach_style')}
                                    >
                                        {translate('editor.sidebar.shots.text.textStyle.detach')}
                                    </Dropdown.Item>
                                    <Divider />
                                    <Dropdown.Item onClick={() => setRenameStyleModalOpen(true)} isDisabled={isMixed}>
                                        {translate('editor.sidebar.shots.text.textStyle.rename')}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setManageStyleModalOpen(true)}>
                                        {translate('editor.sidebar.shots.text.textStyle.manage')}
                                    </Dropdown.Item>
                                    <Divider />
                                    <Dropdown.Item onClick={() => setDeleteStyleModalOpen(true)}>
                                        {translate('editor.sidebar.shots.text.textStyle.delete')}
                                    </Dropdown.Item>
                                </>
                            )}
                        </Dropdown>
                    )}
                </MaybeTooltip>
            </Flex>
            {renderCreateStyleModal()}
            {renderDeleteStyleModal()}
            {renderRenameStyleModal()}
            {renderManageStyleModal()}
            {renderElementStyleModal()}
        </>
    );
};

export default TextStyles;
