import { type MultiPageVideoModel } from '../Models/Models/MultiPageVideoModel';
import { ElementTypes } from '../Enums/ElementTypes';
import type { BaseMultiPageModel } from '../Models/Models/BaseMultiPageModel';

import { deepClone, equals, mergeData, safeDeepCloneForElement } from './utils';
import { UndoRedoChangeTypes } from '../Enums/UndoRedoChangeTypes';
import { type ContentProperty, type Reason, type TextStyle, type UndoRedoReason, type VisualElement } from '../types';
import {
    BackgroundColorUpdatedEventData,
    DimensionUpdatedEventData,
    DurationUpdatedEventData,
    ElementCreatedEventData,
    ElementRemovedEventData,
    ElementUpdatedEventData,
    GlobalAudioUpdatedEventData,
    PosterFrameUpdatedEventData,
} from '../event-types';

export type UndoRedoStateType = 'element' | 'globalProperty' | 'textStyle' | 'contentProperty';
export type UndoRedoState = {
    type: UndoRedoStateType;
    id: number | string;
    action: UndoRedoChangeTypes;
    oldValue: VisualElement | any;
    newValue: VisualElement | any;
    order: number;
    pageIndex: number;
};

export type BatchUndoRedoState = { [key in number | string]: UndoRedoState };

type EventData = {
    reason: Reason;
    pageIndex: number;
};

const isNotBySelfChanges = (reason: Reason) => !['undo', 'redo'].includes(reason);

export class UndoRedoManager {
    private creativeModel: BaseMultiPageModel;

    private cursor = 0;

    private stackCapacity = Infinity;

    private stack: BatchUndoRedoState[] = [];

    private current: BatchUndoRedoState | undefined;

    private currentPageIndex = 0;

    private lastChangedProperties: (string | number)[] = [];

    private timer = 0;

    private elementCreateListener = ({ element, reason, pageIndex }: ElementCreatedEventData & EventData) =>
        isNotBySelfChanges(reason) && this.elementCreated(element, pageIndex);

    private elementRemoveListener = ({ element, reason, pageIndex }: ElementRemovedEventData & EventData) =>
        isNotBySelfChanges(reason) && this.elementRemoved(element, pageIndex);

    // NOTE: pass any as temporary solve
    private elementChangeListener = ({
        element,
        oldValues,
        newValues,
        reason,
        pageIndex,
    }: ElementUpdatedEventData & EventData) =>
        isNotBySelfChanges(reason) && this.elementUpdated(element, oldValues, newValues, pageIndex);

    private backgroundColorUpdateListener = ({
        oldValues,
        newValues,
        reason,
        pageIndex,
    }: BackgroundColorUpdatedEventData & EventData) =>
        isNotBySelfChanges(reason) &&
        this.globalPropertyChanged(ElementTypes.BACKGROUND_COLOR, oldValues, newValues, pageIndex);

    private posterFrameUpdateListener = ({
        oldValues,
        newValues,
        reason,
        pageIndex,
    }: PosterFrameUpdatedEventData & EventData) =>
        isNotBySelfChanges(reason) &&
        this.globalPropertyChanged(ElementTypes.POSTER_FRAME, oldValues, newValues, pageIndex);

    private globalAudioUpdateListener = ({
        oldValues,
        newValues,
        reason,
        pageIndex,
    }: GlobalAudioUpdatedEventData & EventData) =>
        isNotBySelfChanges(reason) &&
        this.globalPropertyChanged(ElementTypes.GLOBAL_AUDIO, oldValues, newValues, pageIndex);

    private durationUpdateListener = ({
        oldValue,
        newValue,
        reason,
        pageIndex,
    }: DurationUpdatedEventData & EventData) =>
        isNotBySelfChanges(reason) && this.globalPropertyChanged('duration', oldValue, newValue, pageIndex);

    private dimensionUpdateListener = ({
        oldValues,
        newValues,
        reason,
        pageIndex,
    }: DimensionUpdatedEventData & EventData) =>
        isNotBySelfChanges(reason) && this.globalPropertyChanged('dimension', oldValues, newValues, pageIndex);

    private textStylesChangedListener = ({ action, oldValue, newValue, reason }) =>
        isNotBySelfChanges(reason) && this.textStylesChanged(action, oldValue, newValue);

    private contentPropertyChangedListener = ({ action, oldValue, newValue, reason }) =>
        isNotBySelfChanges(reason) && this.contentPropertyChanged(action, oldValue, newValue);

    private currentPageChangeListener = () => (this.currentPageIndex = this.creativeModel.getCurrentPageIndex());

    constructor(creativeModel: BaseMultiPageModel) {
        this.creativeModel = creativeModel;
        this.subscribe();
        this.currentPageIndex = this.creativeModel.getCurrentPageIndex();
    }

    private subscribe() {
        this.creativeModel.eventEmitter.on('elementCreated', this.elementCreateListener);
        this.creativeModel.eventEmitter.on('elementUpdated', this.elementChangeListener);
        this.creativeModel.eventEmitter.on('elementRemoved', this.elementRemoveListener);
        this.creativeModel.eventEmitter.on('backgroundColorUpdated', this.backgroundColorUpdateListener);
        this.creativeModel.eventEmitter.on('posterFrameUpdated', this.posterFrameUpdateListener);
        this.creativeModel.eventEmitter.on('globalAudioUpdated', this.globalAudioUpdateListener);
        this.creativeModel.eventEmitter.on('durationUpdated', this.durationUpdateListener);
        this.creativeModel.eventEmitter.on('dimensionUpdated', this.dimensionUpdateListener);
        this.creativeModel.eventEmitter.on('textStylesChanged', this.textStylesChangedListener);
        this.creativeModel.eventEmitter.on('contentPropertyChanged', this.contentPropertyChangedListener);
        this.creativeModel.eventEmitter.on('currentPageChange', this.currentPageChangeListener);
    }

    unsubscribe() {
        this.creativeModel.eventEmitter.off('elementCreated', this.elementCreateListener);
        this.creativeModel.eventEmitter.off('elementUpdated', this.elementChangeListener);
        this.creativeModel.eventEmitter.off('elementRemoved', this.elementRemoveListener);
        this.creativeModel.eventEmitter.off('backgroundColorUpdated', this.backgroundColorUpdateListener);
        this.creativeModel.eventEmitter.off('posterFrameUpdated', this.posterFrameUpdateListener);
        this.creativeModel.eventEmitter.off('globalAudioUpdated', this.globalAudioUpdateListener);
        this.creativeModel.eventEmitter.off('durationUpdated', this.durationUpdateListener);
        this.creativeModel.eventEmitter.off('dimensionUpdated', this.dimensionUpdateListener);
        this.creativeModel.eventEmitter.on('textStylesChanged', this.textStylesChangedListener);
        this.creativeModel.eventEmitter.on('contentPropertyChanged', this.contentPropertyChangedListener);
        this.creativeModel.eventEmitter.off('currentPageChange', this.currentPageChangeListener);
    }

    private flush() {
        if (!this.current) {
            return;
        }

        this.stack[this.cursor] = this.current;
        this.current = undefined;
        this.cursor++;

        if (this.stack.length !== this.cursor) {
            this.stack = this.stack.slice(0, this.cursor);
        }

        if (this.stack.length > this.stackCapacity) {
            this.stack.shift();
            this.cursor--;
        }
    }

    private store({ id, type, action, oldValue, newValue, pageIndex }: Omit<UndoRedoState, 'order'>) {
        const changedProperties = [id, ...Object.keys(oldValue)];
        const delay = equals(this.lastChangedProperties, changedProperties) ? 550 : 400;

        if (newValue?.srcId && !oldValue?.srcId) {
            this.timer = Date.now();
        }

        if (this.current && Date.now() - this.timer > delay) {
            this.flush();
        }

        if (!this.current) {
            this.current = {};
        }

        if (action === UndoRedoChangeTypes.update) {
            if (oldValue?.children) {
                oldValue.children = oldValue.children.map((child) => child.id);
            }

            if (newValue?.children) {
                newValue.children = newValue.children.map((child) => child.id);
            }

            if (oldValue?.parent) {
                oldValue.parent = oldValue.parent.id;
            }

            if (newValue?.parent) {
                newValue.parent = newValue.parent.id;
            }
        }

        if (!this.current[id]) {
            this.current[id] = {
                id,
                type,
                action,
                oldValue,
                newValue,
                order: Object.keys(this.current).length,
                pageIndex,
            };
        } else if (this.current[id].action === UndoRedoChangeTypes.create) {
            if (action === 'remove') {
                delete this.current[id];
            } else {
                this.current[id].newValue.setProperties(newValue);
            }
        } else {
            this.current[id].oldValue = mergeData(oldValue, this.current[id].oldValue);
            this.current[id].newValue = mergeData(this.current[id].newValue, newValue);
        }

        this.lastChangedProperties = changedProperties;
        this.currentPageIndex = pageIndex;
        this.timer = Date.now();
        this.creativeModel.eventEmitter.emit('undoRedoStateUpdate', { canUndo: true });
    }

    private globalPropertyChanged(
        name: UndoRedoState['id'],
        oldValue: UndoRedoState['oldValue'],
        newValue: UndoRedoState['newValue'],
        pageIndex: UndoRedoState['pageIndex'],
    ) {
        this.store({
            id: name,
            type: 'globalProperty',
            action: UndoRedoChangeTypes.update,
            oldValue: safeDeepCloneForElement(oldValue),
            newValue: safeDeepCloneForElement(newValue),
            pageIndex,
        });
    }

    private elementCreated(element: VisualElement, pageIndex: number) {
        const data = element.getCopy();
        data.parent = element.parent;
        this.store({
            id: element.id,
            type: 'element',
            action: UndoRedoChangeTypes.create,
            oldValue: data,
            newValue: data,
            pageIndex,
        });
    }

    private elementUpdated(element: VisualElement, oldValue: any, newValue: any, pageIndex: number) {
        if (!Object.keys(oldValue).length) {
            return;
        }

        this.store({
            id: element.id,
            type: 'element',
            action: UndoRedoChangeTypes.update,
            oldValue: safeDeepCloneForElement(oldValue),
            newValue: safeDeepCloneForElement(newValue),
            pageIndex,
        });
    }

    private elementRemoved(element: VisualElement, pageIndex: number) {
        const data = element.getCopy();
        data.parent = element.parent;
        this.store({
            id: element.id,
            type: 'element',
            action: UndoRedoChangeTypes.remove,
            newValue: data,
            oldValue: data,
            pageIndex,
        });
    }

    private textStylesChanged(action: UndoRedoChangeTypes, onlValue?: TextStyle, newValue?: TextStyle) {
        const id = newValue?.uuid || onlValue?.uuid;
        this.store({
            id,
            type: 'textStyle',
            action,
            oldValue: onlValue ? deepClone(onlValue) : {},
            newValue: newValue ? deepClone(newValue) : {},
            pageIndex: this.currentPageIndex,
        });
    }

    private contentPropertyChanged(
        action: UndoRedoChangeTypes,
        onlValue?: ContentProperty,
        newValue?: ContentProperty,
    ) {
        const id = newValue?.uuid || onlValue?.uuid;
        this.store({
            id,
            type: 'contentProperty',
            action,
            oldValue: onlValue ? deepClone(onlValue) : {},
            newValue: newValue ? deepClone(newValue) : {},
            pageIndex: this.currentPageIndex,
        });
    }

    // NOTE: Can't understand is reason here of two values(undo, redo) or all??! //UndoRedoReasonType
    private applyChanges(state: UndoRedoState, dataKey: 'oldValue' | 'newValue', reason: UndoRedoReason): void {
        const { id, type, action, [dataKey]: data } = state;

        if (type === 'globalProperty') {
            switch (id) {
                case 'duration':
                    (this.creativeModel as MultiPageVideoModel).updatePlaybackDuration(data, {
                        reason,
                    });
                    break;

                case 'dimension':
                    this.creativeModel.updateDimension(data, {
                        reason,
                    });
                    break;

                default:
                    this.creativeModel.updateGlobalProperty(id as string, data, {
                        reason,
                    });
            }
        }

        if (type === 'element') {
            switch (action) {
                case 'update': {
                    const diff = { ...data };

                    if (data.children) {
                        diff.children = data.children.map((id) => this.creativeModel.getElementById(id));
                    }

                    if (data.parent) {
                        diff.parent = this.creativeModel.getElementById(data.parent);
                    }

                    this.creativeModel.updateElement(id as number, diff, {
                        reason,
                        walkChildren: false,
                        walkParent: false,
                    });
                    break;
                }

                case 'create':
                    if (reason === 'undo') {
                        this.creativeModel.removeElements([id as number], {
                            reason,
                        });
                    } else {
                        const el = data.getCopy();
                        el.parent = data.parent ? this.creativeModel.getElementById(data.parent.id) : data.parent;
                        this.creativeModel.addElements([el], el.startFrame, {
                            reason,
                        });
                    }

                    break;

                case 'remove':
                    if (reason === 'undo') {
                        const el = data.getCopy();
                        el.parent = data.parent ? this.creativeModel.getElementById(data.parent.id) : data.parent;
                        this.creativeModel.addElements([el], el.startFrame, {
                            reason,
                        });
                    } else {
                        this.creativeModel.removeElements([id as number], {
                            reason,
                        });
                    }

                    break;
            }
        }

        if (type === 'textStyle') {
            switch (action) {
                case 'update':
                    this.creativeModel.getTextStyles().updateStyle(data, reason);
                    break;
                case 'create':
                    if (reason === 'undo') {
                        this.creativeModel.getTextStyles().deleteStyle(id.toString(), reason);
                    } else {
                        this.creativeModel.getTextStyles().createStyle(data, reason);
                    }

                    break;
                case 'remove':
                    if (reason === 'undo') {
                        this.creativeModel.getTextStyles().createStyle(data, reason);
                    } else {
                        this.creativeModel.getTextStyles().deleteStyle(id.toString(), reason);
                    }

                    break;
            }
        }

        if (type === 'contentProperty') {
            switch (action) {
                case 'update':
                    this.creativeModel.getContentPropertiesManager().updateContentProperty(data, reason);
                    break;
                case 'create':
                    if (reason === 'undo') {
                        this.creativeModel.getContentPropertiesManager().deleteContentProperty(id.toString(), reason);
                    } else {
                        this.creativeModel.getContentPropertiesManager().createContentProperty(data, reason);
                    }

                    break;
                case 'remove':
                    if (reason === 'undo') {
                        this.creativeModel.getContentPropertiesManager().createContentPropertyFromElement(data, reason);
                    } else {
                        this.creativeModel.getContentPropertiesManager().deleteContentProperty(id.toString(), reason);
                    }

                    break;
            }
        }
    }

    private applyStateChanges(batchData: BatchUndoRedoState, reason: UndoRedoReason): void {
        const dataKey = reason === 'undo' ? 'oldValue' : 'newValue';
        const stats = Object.values(batchData);
        const getStateOrder = (reason: UndoRedoReason, state: UndoRedoState) => {
            if (state.type === 'globalProperty' || (reason === 'undo' && state.action === 'remove')) {
                return state.order - stats.length;
            }

            if (reason === 'undo' && state.action === 'create') {
                return state.order + stats.length;
            }

            return state.order;
        };

        const compareByOrder = (a: UndoRedoState, b: UndoRedoState) =>
            getStateOrder(reason, a) - getStateOrder(reason, b);

        if (!stats.length) {
            return;
        }

        const { pageIndex } = stats[0];

        this.creativeModel.setCurrentPageIndex(pageIndex);
        this.creativeModel.beginAccumulation();
        stats.sort(compareByOrder).forEach((stat) => {
            this.applyChanges(stat, dataKey, reason);
        });
        this.creativeModel.endAccumulation(reason);
        this.creativeModel.eventEmitter.emit('undoRedoStateUpdate', { canUndo: !!this.cursor });
    }

    undo(): void {
        this.flush();

        if (!this.cursor) {
            return;
        }

        const data = this.stack[--this.cursor];
        this.applyStateChanges(data, 'undo');
    }

    redo(): void {
        if (this.cursor >= this.stack.length) {
            return;
        }

        const data = this.stack[this.cursor++];
        this.applyStateChanges(data, 'redo');
    }
}
