import ProxyServerConnector from './connectors/ProxyServerConnector';

class ExportService extends ProxyServerConnector {
    constructor(path = '') {
        super(path);
        this.gateway = new ProxyServerConnector('', GATEWAY_PREFIX);
    }

    getActivityFeedItems(data) {
        const searchParams = new URLSearchParams(data);

        if (searchParams.has('search')) {
            searchParams.set('query', searchParams.get('search'));
            searchParams.delete('search');
        }

        return this.gateway.get(`/exports?${searchParams.toString()}`);
    }

    deleteFailedExportJob(jobId) {
        return this.delete(`/variation-sets/exports/${jobId}`);
    }
}

export default new ExportService();
