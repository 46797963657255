import hbjs from '@bynder-studio/hbjs';
import hb from '@bynder-studio/hbjs/hb.js';
import { StrokeType, TextBackground, TextDecoration, TextScript } from '@bynder-studio/misc';
import {
    IStructuredGlyph,
    ShapedLineMetrics,
    ShapedText,
    StructuredGlyph,
    StructuredGlyphData,
    TextProps,
} from './StructuredText';
import { BoundedText } from './BoundedText';
import {
    DEFAULT_STRIKEOUT_POSITION,
    DEFAULT_THICKNESS,
    DEFAULT_UNDERLINE_POSITION,
    Direction,
    OS2Metrics,
    Region,
    REPLACEMENT_GLYPH_DATA,
} from './Types';
import {
    applyTextTransform,
    charMappingRange,
    createRectanglePath,
    createRoundedRectanglePath,
    splitRunsByDirection,
} from './utils';
import { getFontById } from './FontsFamilies';

export type FontData = {
    hbFont: any;
    blob: ArrayBuffer;
    extents: {
        ascender: number;
        descender: number;
        line_gap: number;
    };
    upem: number;
    glyphCache: Map<string, any>;
    axisInfos: any;
};

// Manage access to harfbuzz wasm and fonts
export class TextController {
    private fontsMap = new Map<string, FontData>();

    private fontsMetricsMap = new Map<string, OS2Metrics>();

    autoResizeScale = 1;

    fontScale = 1;

    ranges: [];

    private constructor(
        private hb: ReturnType<typeof hbjs>,
        private cacheGlyphs = true, // TODO / WARNING: caching does not take scaling into account
    ) {}

    static async init(hbWasmLoader: () => Buffer) {
        const wasmBinary = hbWasmLoader();
        const module = await hb({ wasmBinary });
        const hbInstance = hbjs(module);

        return new TextController(hbInstance);
    }

    setFontScale(scale: number) {
        this.fontScale = scale;
    }

    setAutoResizeScale(scale: number) {
        this.autoResizeScale = scale;
    }

    getAutoResizeScale() {
        return this.autoResizeScale;
    }

    destroy() {
        // Destroy all fonts
        this.fontsMap.forEach((fontData, fontId) => {
            this.destroyFont(String(fontId));
        });
        this.fontsMap = new Map();

        // Clear harfbuzz
        this.hb = null;
    }

    private addFont(fontId: string, fontBlob: ArrayBuffer) {
        if (!this.fontsMap.has(String(fontId))) {
            const fontBlobArray = new Uint8Array(fontBlob);
            const hbBlob = this.hb.createBlob(fontBlobArray);
            const hbFace = this.hb.createFace(hbBlob, 0);
            const hbFont = this.hb.createFont(hbFace);

            // Get static font details
            const fontData = {
                hbFont,
                blob: fontBlob,
                extents: this.hb.getFontExtents(hbFont),
                upem: this.hb.getFaceUpem(hbFace),
                glyphCache: new Map(),
                axisInfos: hbFace.getAxisInfos(),
            };

            this.fontsMap.set(String(fontId), fontData);

            // No further need for Font face and blob
            hbFace.destroy();
            hbBlob.destroy();

            // console.log(`font ${fontId} added`);
        } else {
            // console.warn(`font ${fontId} already loaded`);
        }
    }

    // todo: can we deltete this?
    // loadFonts = async (fontUrls: Record<string, string>) =>
    //     Promise.all(
    //         Object.keys(fontUrls).map(async (fontId) => {
    //             const response = await fetch(fontUrls[fontId]);
    //             const fontBlob = await response.arrayBuffer();

    //             this.addFont(fontId, fontBlob);
    //         }),
    //     );

    // hasLoadedFont(fontId: string) {
    //     return this.fontsMap.has(String(fontId));
    // }

    addExternallyLoadedFonts(fontFiles: Map<string, any>) {
        fontFiles.forEach((fontFile, fontId) => {
            this.addFont(fontId, fontFile);
        });
    }

    getFontData(fontId) {
        const id = String(fontId);

        if (this.fontsMap.has(id)) {
            const fontData = this.fontsMap.get(id);

            return {
                ascender: fontData.extents.ascender,
                descender: fontData.extents.descender,
                line_gap: fontData.extents.line_gap,
                upem: fontData.upem,
            };
        }

        console.warn(`font ${id} not loaded, cannot get font data`);
    }

    private destroyFont(fontId: string) {
        const id = String(fontId);
        // Remove font

        if (this.fontsMap.has(id)) {
            this.fontsMap.get(id).hbFont.destroy();
            this.fontsMap.get(id).glyphCache.clear();
            this.fontsMap.delete(id);
            // console.log(`font ${id} removed`);
        } else {
            // console.warn(`font ${id} not loaded`);
        }
    }

    createBoundedText(textStruct: TextProps, config: any) {
        return new BoundedText(this, textStruct, config);
    }

    /**
     * In case of empty TextProps, default line metrics are calculated on the basis of possible run and default props
     * @param param0
     * @returns
     */
    private createDefaultShapedLineMetrics({ runs }: TextProps) {
        // assert(value.lenght == 0);

        const run = runs[0];
        const fontId = run.fontId;
        const fontSize = run.fontSize;

        let fontData: any;

        if (this.fontsMap.has(String(fontId))) {
            fontData = this.fontsMap.get(String(fontId));
        } else {
            // If font is not found, fall back to default font
            fontData = this.fontsMap.get('default');
            // fontId = 'default';
        }

        const fontExtents = fontData.extents;

        const m = new ShapedLineMetrics();

        // em values
        m.ascenderEm = fontExtents.ascender / fontData.upem;
        m.descenderEm = fontExtents.descender / fontData.upem;
        m.lineGapEm = fontExtents.line_gap / fontData.upem;
        m.lineHeightEm = m.ascenderEm - m.descenderEm + m.lineGapEm;
        m.widthEm = 0.0;

        // px values
        m.ascenderPx = m.ascenderEm * fontSize;
        m.descenderPx = m.descenderEm * fontSize;
        m.lineGapPx = m.lineGapEm * fontSize;
        m.lineHeightPx = m.ascenderPx - m.descenderPx + m.lineGapPx;
        m.widthPx = 0.0;

        return m;
    }

    private createNewLineGlyph(fontId: string, fontSize: number): IStructuredGlyph {
        let fontData: any;

        if (this.fontsMap.has(String(fontId))) {
            fontData = this.fontsMap.get(String(fontId));
        } else {
            // If font is not found, fall back to default font
            fontData = this.fontsMap.get('default');
            fontId = 'default';
        }

        const fontExtents = fontData.extents;
        const font = getFontById(fontId);
        const lineGapForLineHeight = font?.includeLineGap === false ? 0 : fontExtents.line_gap;

        const glyphData = {} as StructuredGlyphData;
        glyphData.fontId = fontId;
        glyphData.fontSize = fontSize;
        glyphData.upem = fontData.upem;
        glyphData.ascender = fontExtents.ascender;
        glyphData.descender = fontExtents.descender;
        glyphData.lineGap = fontExtents.line_gap;
        // we subtract descender, since it is always expressed as a negative value, lineheight should correspond to upem + line_gap
        glyphData.lineHeight = fontExtents.ascender - fontExtents.descender + lineGapForLineHeight;

        glyphData.leadingEm = 0.0; // glyph specific leadingEm
        glyphData.trackingEm = 0.0; // glyph specific trackingEm
        glyphData.path = '';

        glyphData.pathScale = 1;
        glyphData.xAdvance = 0;
        glyphData.yAdvance = 0;
        glyphData.xOffset = 0;
        glyphData.yOffset = 0;

        glyphData.absLeftPx = 0;
        glyphData.absTopPx = 0;
        glyphData.absBbox = new Region(0, 0, 0, 0);
        glyphData.absContentBbox = new Region(0, 0, 0, 0);

        return (new StructuredGlyph(glyphData) as IStructuredGlyph).markAsNewLine();
        // return new NewLineGlyph(glyphData);
    }

    /**
     * Map glyph clusters to text. Each glyph maps to a list of references to the origin characters.
     * @param text
     * @param structuredGlyphs
     * @param direction
     * @returns
     */
    private getGlyphToCharClusterMapping(
        text: string,
        structuredGlyphs: IStructuredGlyph[],
        direction: Direction,
    ): number[][] {
        const glyphToCharMap = [] as number[][];

        if (!structuredGlyphs.length) {
            return glyphToCharMap;
        }

        const fromIndex = direction === Direction.RTL ? 0 : structuredGlyphs.length - 1;
        const toIndex = direction === Direction.RTL ? structuredGlyphs.length - 1 : 0;
        const glyphIndexes = charMappingRange(fromIndex, toIndex);

        const nextCharIdxMap = new Map<number, number>();
        const clusterIds = structuredGlyphs.map((g) => g.data.clusterId).sort((a, b) => a - b);
        const clusterIdToIndex = new Map<number, number>();
        clusterIds.forEach((cId, idx) => {
            clusterIdToIndex.set(cId, idx);
        });
        structuredGlyphs.forEach((g) => {
            const clusterId = g.data.clusterId;
            const nextIndex = clusterIdToIndex.get(clusterId) + 1;
            const nextClusterId = clusterIds.length > nextIndex ? clusterIds[nextIndex] : text.length;
            nextCharIdxMap.set(clusterId, nextClusterId);
        });

        glyphIndexes.forEach((gIdx) => {
            const charIdx = structuredGlyphs[gIdx].data.clusterId;

            if (charIdx === undefined) {
                return;
            }

            const nextCharIdx = nextCharIdxMap.get(charIdx);

            for (let cIdx = charIdx; cIdx < nextCharIdx; cIdx++) {
                if (glyphToCharMap[gIdx] === undefined) {
                    // map glyph to the list of characters it contains
                    glyphToCharMap[gIdx] = [...Array(nextCharIdx - cIdx)].map((_, i) => i + cIdx);
                }
            }

            if (direction === Direction.RTL) {
                glyphToCharMap[gIdx] = glyphToCharMap[gIdx].reverse();
            }
        });

        return glyphToCharMap;
    }

    /**
     * Reshape all input runs
     */
    shapeText(shapedText: ShapedText, layoutSettings?: { textBackground: TextBackground; textDirection?: Direction }) {
        const glyphs: IStructuredGlyph[] = [];
        const textProps = shapedText.getTextProps();
        const { runs: textRuns, value } = textProps;
        const filteredRuns = textRuns.filter((run) => run.length > 0);
        const runs = splitRunsByDirection(filteredRuns, value, layoutSettings?.textDirection);

        // shape all runs
        let glyphIdx = 0;
        let lineIdx = 0;

        const getVerticalAlignScale = (textScript: TextScript, fontId: string) => {
            if (textScript === TextScript.BASE) {
                return 1;
            }

            if (textScript === TextScript.SUB || textScript === TextScript.SUPER) {
                return 0.6;
            }

            return 1;
        };

        const maxFontSizeByLine = {};
        let index = 0;
        runs.forEach((run) => {
            const text = value.substring(run.startIdx, run.startIdx + run.length);
            const textLines = text.split('\n');

            textLines.forEach((textLine, lineSubIdx: number) => {
                if (!maxFontSizeByLine[index] || run.fontSize > maxFontSizeByLine[index]) {
                    maxFontSizeByLine[index] = run.fontSize;
                }

                if (lineSubIdx < textLines.length - 1) {
                    index++;
                }
            });
        });

        runs.forEach((run, runIdx) => {
            const direction = run.direction;
            const level = run.level;
            let textIdx = run.startIdx;
            const text = applyTextTransform(
                value.substring(run.startIdx, run.startIdx + run.length),
                run.textTransform,
            );

            // resolve properties required by shaper
            const fontId = run.fontId;
            const textScriptScale = getVerticalAlignScale(run.textScript, fontId);
            const fontSize = run.fontSize * this.autoResizeScale * this.fontScale * textScriptScale;

            // since runs can contain linebreaks, shape each line within the run separately
            const textLines = text.split('\n');
            textLines.forEach((textLine, lineSubIdx: number) => {
                const lineGlyphs = this.getStructuredGlyphs(textLine, fontId, fontSize, direction);

                // map glyph indexes to textline indexes
                const glyphToCharMap = this.getGlyphToCharClusterMapping(textLine, lineGlyphs, direction);

                if (direction === Direction.RTL) {
                    lineGlyphs.reverse();
                    glyphToCharMap.reverse();
                }

                lineGlyphs.forEach((lineGlyph: IStructuredGlyph, lineGlyphIdx: number) => {
                    if (!glyphToCharMap[lineGlyphIdx]) {
                        return;
                    }

                    const glyphMap = glyphToCharMap[lineGlyphIdx].map((charIdx) => charIdx + textIdx);
                    const chars = glyphMap.map((charIdx) => value[charIdx]);
                    const { underlinePosition, underlineThickness, yStrikeoutPosition, yStrikeoutSize } =
                        this.getFontMetrics(fontId);

                    const isLastLineGlyph =
                        (direction === Direction.RTL
                            ? glyphToCharMap[lineGlyphIdx][0] === 0
                            : value[Math.max(...glyphMap) + 1] === '\n') ||
                        (runs.length - 1 === runIdx &&
                            textLines.length - 1 === lineSubIdx &&
                            lineGlyphs.length - 1 === lineGlyphIdx);

                    lineGlyph.data.textIdxs = glyphMap;
                    lineGlyph.data.text = chars;
                    lineGlyph.data.direction = direction;
                    lineGlyph.data.level = level;
                    lineGlyph.data.lineIdx = lineIdx;
                    lineGlyph.data.runIdx = run.runIdx;
                    lineGlyph.data.leadingEm = Math.max(run.leading, 0);
                    lineGlyph.data.trackingEm = isLastLineGlyph ? 0.0 : run.tracking;
                    lineGlyph.data.glyphIdx = glyphIdx++;
                    lineGlyph.data.strokeType = run.stroke?.type || StrokeType.NONE;
                    lineGlyph.data.strokeWidth = run.stroke?.width || 0;
                    lineGlyph.data.textScriptScale = textScriptScale;

                    if (run.textDecoration === TextDecoration.STRIKETHROUGH) {
                        if (!lineGlyph.data.decorationPaths) {
                            lineGlyph.data.decorationPaths = [];
                        }

                        const offsetY = yStrikeoutPosition || DEFAULT_STRIKEOUT_POSITION;
                        const thickness = yStrikeoutSize || DEFAULT_THICKNESS;
                        const width =
                            lineGlyph.data.xAdvance -
                            lineGlyph.data.xOffset +
                            Math.abs(lineGlyph.data.upem * lineGlyph.data.trackingEm);
                        lineGlyph.data.decorationPaths.push(createRectanglePath(0, offsetY, width, thickness));
                        const { yMax, yMin } = lineGlyph.data;
                        lineGlyph.data.xMin = Math.min(lineGlyph.data.xMin, 0);
                        lineGlyph.data.xMax = Math.max(lineGlyph.data.xMax, width);
                        lineGlyph.data.yMax = Math.max(yMax, offsetY + thickness);
                        lineGlyph.data.yMin = 2 * lineGlyph.data.yMax - Math.min(2 * yMax - yMin, offsetY);
                        lineGlyph.data.width = lineGlyph.data.xMax - lineGlyph.data.xMin;
                        lineGlyph.data.height = lineGlyph.data.yMin - lineGlyph.data.yMax;
                    }

                    if (run.textDecoration === TextDecoration.UNDERLINE) {
                        if (!lineGlyph.data.decorationPaths) {
                            lineGlyph.data.decorationPaths = [];
                        }

                        const offsetY = underlinePosition || DEFAULT_UNDERLINE_POSITION;
                        const thickness = underlineThickness || DEFAULT_THICKNESS;
                        const width =
                            lineGlyph.data.xAdvance -
                            lineGlyph.data.xOffset +
                            Math.abs(lineGlyph.data.upem * lineGlyph.data.trackingEm);
                        lineGlyph.data.decorationPaths.push(createRectanglePath(0, offsetY, width, thickness));
                        const { yMax, yMin } = lineGlyph.data;
                        lineGlyph.data.xMin = Math.min(lineGlyph.data.xMin, 0);
                        lineGlyph.data.xMax = Math.max(lineGlyph.data.xMax, width);
                        lineGlyph.data.yMax = Math.max(yMax, offsetY + thickness);
                        lineGlyph.data.yMin = 2 * lineGlyph.data.yMax - Math.min(2 * yMax - yMin, offsetY);
                        lineGlyph.data.width = lineGlyph.data.xMax - lineGlyph.data.xMin;
                        lineGlyph.data.height = lineGlyph.data.yMin - lineGlyph.data.yMax;
                    }

                    if (run.textScript === TextScript.SUB) {
                        const { sTypoAscender, sTypoDescender, ySubscriptXOffset } = this.getFontMetrics(fontId);
                        const typoSize = sTypoAscender - sTypoDescender;
                        const yOffset = typoSize * 0.3;

                        lineGlyph.data.yOffset += yOffset;
                        lineGlyph.data.xOffset += ySubscriptXOffset / textScriptScale;
                    } else if (run.textScript === TextScript.SUPER) {
                        const { sTypoAscender, sTypoDescender, ySuperscriptXOffset } = this.getFontMetrics(fontId);
                        const typoSize = sTypoAscender - sTypoDescender;
                        const yOffset = typoSize * (1 - 0.35);

                        lineGlyph.data.yOffset -= yOffset;
                        lineGlyph.data.xOffset += ySuperscriptXOffset / textScriptScale;
                    }

                    if (layoutSettings?.textBackground?.isActive()) {
                        const { spacingLeft, spacingBottom, spacingTop, spacingRight, cornerRadius } =
                            layoutSettings.textBackground;
                        const width =
                            lineGlyph.data.xAdvance -
                            lineGlyph.data.xOffset +
                            lineGlyph.data.upem * lineGlyph.data.trackingEm;
                        const maxFontSize = maxFontSizeByLine[lineIdx];
                        const maxFontSizeScale = maxFontSize / run.fontSize;
                        const scale = this.autoResizeScale * maxFontSizeScale;

                        const fromPxToUnits = (v) =>
                            ((v / maxFontSize) * lineGlyph.data.upem * scale) / textScriptScale;

                        const bgSpacingLeft = fromPxToUnits(spacingLeft);
                        const bgSpacingRight = fromPxToUnits(spacingRight);
                        const bgYMaxDefault =
                            (lineGlyph.data.ascender / textScriptScale) * maxFontSizeScale + lineGlyph.data.yOffset;
                        const bgYMinDefault =
                            (lineGlyph.data.descender / textScriptScale) * maxFontSizeScale + lineGlyph.data.yOffset;

                        lineGlyph.data.bgXMax = Math.max(lineGlyph.data.xMax, width) + bgSpacingRight;
                        lineGlyph.data.bgXMin = Math.min(lineGlyph.data.xMin, 0) - bgSpacingLeft;
                        lineGlyph.data.bgYMax = bgYMaxDefault + fromPxToUnits(spacingTop);
                        lineGlyph.data.bgYMin = bgYMinDefault - fromPxToUnits(spacingBottom);

                        lineGlyph.data.xMin = Math.min(lineGlyph.data.bgXMin, Math.min(lineGlyph.data.xMin, 0));
                        lineGlyph.data.xMax = Math.max(lineGlyph.data.bgXMax, Math.max(lineGlyph.data.xMax, width));
                        lineGlyph.data.yMax = Math.max(lineGlyph.data.bgYMax, bgYMaxDefault);
                        lineGlyph.data.yMin = Math.min(lineGlyph.data.bgYMin, bgYMinDefault);
                        lineGlyph.data.width = lineGlyph.data.xMax - lineGlyph.data.xMin;
                        lineGlyph.data.height = Math.abs(lineGlyph.data.yMin - lineGlyph.data.yMax);

                        const bgCornerRadius = (lineGlyph.data.height / textScriptScale / 2) * (cornerRadius / 100);

                        lineGlyph.data.bgPaths = [
                            createRoundedRectanglePath(
                                lineGlyph.data.xMin,
                                lineGlyph.data.yMin,
                                lineGlyph.data.width,
                                lineGlyph.data.height,
                                bgCornerRadius,
                            ),
                        ];
                    } else {
                        lineGlyph.data.bgPaths = [];
                    }

                    glyphs.push(lineGlyph);
                });

                textIdx += textLine.length;

                if (lineSubIdx < textLines.length - 1) {
                    const glyph = this.createNewLineGlyph(fontId, fontSize);

                    glyph.data.textIdxs = [textIdx];
                    glyph.data.text = [value[textIdx]];
                    glyph.data.direction = direction;
                    glyph.data.level = level;
                    glyph.data.lineIdx = lineIdx;
                    glyph.data.runIdx = run.runIdx;
                    glyph.data.leadingEm = run.leading;
                    glyph.data.strokeType = run.stroke?.type || StrokeType.NONE;
                    glyph.data.strokeWidth = run.stroke?.width || 0;
                    glyph.data.textScriptScale = textScriptScale;
                    glyph.data.glyphIdx = glyphIdx++;
                    glyph.data.bgPaths = [];

                    glyphs.push(glyph);
                    lineIdx++;
                    textIdx++;
                }
            });
        });

        if (glyphs.length > 0) {
            shapedText.setGlyphs(glyphs);
        } else {
            // In case of an empty text, the default metrics need to be provided
            const shapedLineMetrics = this.createDefaultShapedLineMetrics(shapedText.getTextProps());
            shapedText.setDefaultLineMetrics(shapedLineMetrics);
        }
    }

    private getFontMetrics(fontId: string): OS2Metrics {
        if (!this.fontsMap.has(String(fontId))) {
            fontId = 'default';
        }

        if (!this.fontsMetricsMap.has(String(fontId))) {
            const fontData = this.fontsMap.get(String(fontId));
            const { upem } = fontData;
            const { ascender, descender } = fontData.extents;
            const metrics = this.hb.getOtMetrics(fontData.hbFont);
            this.fontsMetricsMap.set(String(fontId), { ...metrics, upem, ascender, descender });
        }

        return this.fontsMetricsMap.get(String(fontId));
    }

    // todo: can be private?
    /**
     * Shape a text with all required parameter into a list of glyphs
     * @param text
     * @param fontId
     * @param fontSize
     * @param direction
     * @returns
     */
    getStructuredGlyphs(text: string, fontId: string, fontSize: number, direction: Direction): IStructuredGlyph[] {
        // Resolve glyph data for provided text
        let fontData: any;

        if (this.fontsMap.has(String(fontId))) {
            fontData = this.fontsMap.get(String(fontId));
        } else {
            // If font is not found, fall back to default font
            // console.warn(`font ${fontId} not loaded`);
            fontData = this.fontsMap.get('default');
            fontId = 'default';
        }

        const fontExtents = fontData.extents;
        const hbFont = fontData.hbFont;
        const glyphCache = fontData.glyphCache;
        const hbResult = this.hb.getGlyphData(hbFont, text, 0, direction.toLowerCase());

        const sGlyphs: IStructuredGlyph[] = [];

        hbResult.forEach((hbResItem: any) => {
            let glyphData: Partial<StructuredGlyphData> = {};
            const glyphId = hbResItem.g;

            if (glyphId === 0) {
                glyphData = { ...REPLACEMENT_GLYPH_DATA };
            } else {
                // first get cachable part of the glyph
                if (this.cacheGlyphs && glyphCache.has(String(glyphId))) {
                    glyphData = { ...glyphCache.get(String(glyphId)) };
                } else {
                    const glyphPath = hbFont.glyphToPath(glyphId);
                    const glyphExtents = this.hb.getGlyphExtents(hbFont, glyphId);
                    const font = getFontById(fontId);
                    const lineGapForLineHeight = font?.includeLineGap === false ? 0 : fontExtents.line_gap;

                    glyphData.fontId = fontId;
                    glyphData.glyphId = glyphId;

                    glyphData.path = glyphPath;
                    glyphData.upem = fontData.upem;
                    glyphData.ascender = fontExtents.ascender;
                    glyphData.descender = fontExtents.descender;
                    glyphData.lineGap = fontExtents.line_gap;
                    // we subtract descender, since it is always expressed as a negative value, lineheight should correspond to upem + line_gap
                    glyphData.lineHeight = fontExtents.ascender - fontExtents.descender + lineGapForLineHeight;
                    glyphData.width = glyphExtents.width;
                    glyphData.height = -glyphExtents.height; // we need to flip the height, since it is a negative value
                    // glyphData.xBearing = glyphExtents.x_bearing;
                    // glyphData.yBearing = glyphExtents.y_bearing;

                    // glyph bbox, NOTE: only correct for horizontal positioning
                    glyphData.xMin = glyphExtents.x_bearing;
                    glyphData.xMax = glyphExtents.x_bearing + glyphData.width;
                    // note that ybearing is actually reversed here because of our coordinate system.
                    glyphData.yMin = glyphExtents.y_bearing + glyphData.height;
                    glyphData.yMax = glyphExtents.y_bearing;

                    // unused metrics
                    // glyphData.leftSideBearing = glyphData.xBearing;
                    // glyphData.rightSideBearing = hbResItem["ax"] - glyphData.width - glyphData.xBearing;

                    if (this.cacheGlyphs) {
                        glyphCache.set(String(glyphId), { ...glyphData });
                    }
                }

                // extend with combination-specific properties, do NOT cache
                glyphData.xAdvance = hbResItem.ax;
                glyphData.yAdvance = hbResItem.ay;
                glyphData.xOffset = hbResItem.dx;
                glyphData.yOffset = hbResItem.dy * Math.sign(-glyphData.height);
                // todo: find a way to detect the accent glyphs
                // glyphData.isAccent = hbResItem.ax === 0 && hbResItem.ay === 0 && (hbResItem.dx !== 0 || hbResItem.dy !== 0);
            }

            // set cluster ID and font size always, even for replacement glyphs
            glyphData.clusterId = hbResItem.cl;
            glyphData.fontSize = fontSize;
            const sGlyph: IStructuredGlyph = new StructuredGlyph(glyphData as StructuredGlyphData);
            sGlyphs.push(sGlyph);
        });

        return sGlyphs;
    }
}
