import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { type CreativeModelFactory } from '@bynder-studio/render-web';
import useForceUpdate from '~/hooks/useForceUpdate';
import { MultiPageVideoModel } from '@bynder-studio/render-core';

type DesignSceneState = {
    sceneRenderer: any;
    sceneImages: string[];
    scenes: any[];
};

type DesignSceneProviderProps = {
    children: React.ReactNode;
    creativeModelFactory: CreativeModelFactory;
    creativeModel: MultiPageVideoModel;
};

export const DesignSceneContext = createContext({} as DesignSceneState);

export const DesignSceneProvider = ({ children, creativeModelFactory, creativeModel }: DesignSceneProviderProps) => {
    const forceUpdate = useForceUpdate();
    const data = useRef<DesignSceneState>({
        sceneRenderer: null,
        sceneImages: [],
        scenes: [],
    });

    const handleFrameUpdate = useCallback<(data: { shotIndex: number; dataUrl: string }) => void>(
        ({ shotIndex, dataUrl }) => {
            data.current.sceneImages[shotIndex] = dataUrl;
            forceUpdate();
        },
        [forceUpdate],
    );

    useEffect(() => {
        const { sceneRenderer } = data.current;

        if (!creativeModel || !sceneRenderer) {
            return;
        }

        const handler = () => {
            sceneRenderer.drawShots().catch(console.error);
        };

        creativeModel.on('dimensionUpdated', handler);
        creativeModel.on('currentPageChange', handler);

        return () => {
            creativeModel.off('dimensionUpdated', handler);
            creativeModel.off('currentPageChange', handler);
        };
    }, [creativeModel, data.current.sceneRenderer]);

    useEffect(() => {
        if (!creativeModelFactory || !('getShotRenderer' in creativeModelFactory)) {
            return;
        }

        const sceneRenderer = creativeModelFactory.getShotRenderer();
        sceneRenderer.setShotSize(64, 64);
        sceneRenderer.drawShots().catch(console.error);
        data.current.sceneRenderer = sceneRenderer;

        sceneRenderer.on('frameUpdate', handleFrameUpdate);
        return () => {
            sceneRenderer.off('frameUpdate', handleFrameUpdate);
        };
    }, [creativeModelFactory, handleFrameUpdate]);

    useEffect(() => {
        if (!creativeModel) {
            return;
        }

        data.current.scenes = creativeModel.getShots();
        const handleShotsReplaced = () => {
            data.current.scenes = creativeModel.getShots();
            forceUpdate();
        };

        creativeModel.on('shotsReplaced', handleShotsReplaced);
        creativeModel.on('currentPageChange', handleShotsReplaced);
        return () => {
            creativeModel.off('shotsReplaced', handleShotsReplaced);
            creativeModel.off('currentPageChange', handleShotsReplaced);
        };
    }, [creativeModel, forceUpdate]);

    const value = { ...data.current };

    return <DesignSceneContext.Provider value={value}>{children}</DesignSceneContext.Provider>;
};
