import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    type MultiPageVideoModel,
    TimelineBehavior,
    VideoElement,
    ImageElement,
    ShapeElement,
    TextElement,
} from '@bynder-studio/render-core';
import { Dropdown, Form, List, Thumbnail } from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { IconArrowDown, IconArrowUp, IconTime } from '@bynder/icons';
import { currentEditorType } from 'packages/store/creativeEditor/creativeEditor.selectors';
import { framesToTime } from '~/helpers/helpers';
import generateTestId from '~/helpers/testIdHelpers';
import editorAutocorrect from 'packages/pages/editor/EditorAutocorrectRules';
import { Dot } from 'packages/pages/brandColors/BrandColors.styled';
import FramePicker from '../../FormComponents/FramePicker';
import { DurationListItem, DynamicLengthButton } from './index.styled';

type Props = {
    disabled: boolean;
    selectedElement: VideoElement | ImageElement | ShapeElement | TextElement;
    frameRate: number;
    creativeModel: MultiPageVideoModel;
};

const testId = generateTestId('shots_duration');

const Duration = ({ disabled, selectedElement, frameRate, creativeModel }: Props) => {
    const { translate } = useTranslate();
    const editorType = useSelector(currentEditorType);
    const [isActive, setIsActive] = useState(false);
    const isVideoElement = useMemo(() => selectedElement instanceof VideoElement, [selectedElement]);
    const [dynamicContent, setDynamicContent] = useState(
        isVideoElement ? (selectedElement as VideoElement)?.useDynamicLength : false,
    );
    const [timelineBehavior, setTimelineBehavior] = useState<TimelineBehavior>(selectedElement.timelineBehavior);

    const { duration, startFrame, id, locked } = selectedElement;

    const autocorrectStart = useCallback(
        (newStart) => editorAutocorrect('element_start_frame', newStart, frameRate),
        [frameRate],
    );
    const autocorrectEnd = useCallback(
        (newEnd) => editorAutocorrect('element_end_frame', newEnd, startFrame),
        [startFrame],
    );

    const isDisabled = (editorType === 'create' && locked) || disabled;

    const TimelineTitles = {
        AUTO: translate('editor.sidebar.shots.timeline_behaviour.auto'),
        STATIC: translate('editor.sidebar.shots.timeline_behaviour.static'),
        SLIDE: translate('editor.sidebar.shots.timeline_behaviour.slide'),
        STRETCH: translate('editor.sidebar.shots.timeline_behaviour.stretch'),
    };

    const subText = (
        <>
            {framesToTime(duration)}
            <Dot>•</Dot>
            {TimelineTitles[timelineBehavior]}
        </>
    );

    const handleFieldChange = (propName, value) => {
        const maxDuration = 300;
        const { duration: elDuration, startFrame: elStartFrame } = selectedElement;

        if (elDuration + elStartFrame - selectedElement[propName] + value > frameRate * maxDuration) {
            return;
        }

        creativeModel.updateElement(id, { [propName]: value });
    };

    const handleDynamicTypeChange = (value: TimelineBehavior) => {
        setTimelineBehavior(value);
        creativeModel.updateElement(id, { timelineBehavior: value });
    };

    const handleDynamicChange = (): void => {
        setDynamicContent(!dynamicContent);
        creativeModel.updateElement(id, { useDynamicLength: !dynamicContent });
    };

    useEffect(() => {
        if (isVideoElement) {
            setDynamicContent((selectedElement as VideoElement).useDynamicLength);
        }

        setTimelineBehavior(selectedElement.timelineBehavior);
    }, [selectedElement, id]);

    return (
        <>
            <List.Item
                subtext={subText}
                thumbnail={<Thumbnail shape="square" icon={<IconTime />} />}
                rightElements={!isActive ? <IconArrowDown /> : <IconArrowUp />}
                onClick={() => setIsActive(!isActive)}
                {...testId}
            >
                {translate('editor.sidebar.shots.duration.label')}
            </List.Item>
            {isActive && (
                <>
                    <DurationListItem>
                        <Form.Row>
                            <FramePicker
                                name="Start frame"
                                disabled={isDisabled}
                                handleOnChange={(value) => handleFieldChange('startFrame', value)}
                                value={startFrame}
                                frameRate={frameRate}
                                prefix={translate('editor.sidebar.shots.timeline_behaviour.framepicker.start')}
                                autocorrect={autocorrectStart}
                                testId="shots_duration__start_frame"
                            />
                            <FramePicker
                                name="End frame"
                                disabled={isDisabled}
                                handleOnChange={(value) => handleFieldChange('duration', value - startFrame)}
                                value={duration + startFrame}
                                frameRate={frameRate}
                                prefix={translate('editor.sidebar.shots.timeline_behaviour.framepicker.end')}
                                autocorrect={autocorrectEnd}
                                testId="shots_duration__end_frame"
                            />
                        </Form.Row>
                    </DurationListItem>
                    {isVideoElement && (
                        <DurationListItem
                            checkedVariant="switch"
                            isChecked={dynamicContent}
                            isDisabled={isDisabled}
                            onClick={handleDynamicChange}
                            subtext={translate('editor.sidebar.shots.timeline_behaviour.dynamic.desc')}
                            {...generateTestId('shots_properties_dynamic_content')}
                        >
                            {translate('editor.sidebar.shots.timeline_behaviour.dynamic.title')}
                        </DurationListItem>
                    )}
                    <DurationListItem>
                        <Form.Group>
                            <Form.Label>{translate('editor.sidebar.shots.timeline_behaviour.label')}</Form.Label>
                            <Dropdown
                                trigger={({ isOpen, ...triggerProps }) => (
                                    <DynamicLengthButton
                                        isDisabled={isDisabled}
                                        isPressed={isOpen}
                                        rightIcon={<Dropdown.Arrow />}
                                        isFullWidth
                                        {...triggerProps}
                                        {...generateTestId('shots_properties_timeline_behaviour')}
                                    >
                                        {TimelineTitles[timelineBehavior]}
                                    </DynamicLengthButton>
                                )}
                                position="bottom"
                            >
                                <Dropdown.Item
                                    onClick={() => handleDynamicTypeChange(TimelineBehavior.AUTO)}
                                    isChecked={timelineBehavior === TimelineBehavior.AUTO}
                                >
                                    {`${TimelineTitles[TimelineBehavior.AUTO]} 
                                (${translate('editor.sidebar.shots.timeline_behaviour.recommended')})`}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleDynamicTypeChange(TimelineBehavior.STATIC)}
                                    isChecked={timelineBehavior === TimelineBehavior.STATIC}
                                >
                                    {TimelineTitles[TimelineBehavior.STATIC]}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleDynamicTypeChange(TimelineBehavior.SLIDE)}
                                    isChecked={timelineBehavior === TimelineBehavior.SLIDE}
                                >
                                    {TimelineTitles[TimelineBehavior.SLIDE]}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => handleDynamicTypeChange(TimelineBehavior.STRETCH)}
                                    isChecked={timelineBehavior === TimelineBehavior.STRETCH}
                                >
                                    {TimelineTitles[TimelineBehavior.STRETCH]}
                                </Dropdown.Item>
                            </Dropdown>
                        </Form.Group>
                    </DurationListItem>
                </>
            )}
        </>
    );
};

export default Duration;
