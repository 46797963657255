import { checkIsNeedUpdateElementsTreeByAccumulator, mergeAccumulatedData } from '../../Helpers/accumulator';
import type { BaseModel } from '../Models/BaseModel';
import type { Event } from '../../event-types';
import { Reason } from '../../types';

export class EventAccumulator {
    private level = 0;

    private events: Event[] = [];

    private model: BaseModel;

    constructor(model: BaseModel) {
        this.model = model;
    }

    begin() {
        if (!this.level) {
            this.model.emit('accumulator.begin', {});
        }

        this.level += 1;
    }

    end(reason: Reason = 'user') {
        this.level = 0;
        const events = this.mergeEvents();
        events.forEach(([eventName, eventData = {}]) => {
            eventData.reason = reason;
            this.model.emit(eventName, eventData);
        });
        this.model.emit('accumulator.end', {});

        if (checkIsNeedUpdateElementsTreeByAccumulator(events)) {
            this.model.emit('elementsTreeUpdated', {});
        }

        this.resetEvents();
    }

    addEvent(event: Event) {
        this.events.push(event);
    }

    getLevel() {
        return this.level;
    }

    setLevel(level: number) {
        this.level = level;
    }

    getEvents() {
        return this.events;
    }

    mergeEvents(events?: Event[]): Event[] {
        this.events = mergeAccumulatedData(events || this.events);

        return this.events;
    }

    resetEvents() {
        this.events = [];
    }
}
