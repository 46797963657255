import { SidebarItemType } from '../../../components/sidebar/types';

export enum DesignSubPages {
    ALL = 'ALL',
    BLANK = 'BLANK',
    CATEGORIES = 'CATEGORIES',
    CATEGORY_DETAILS = 'CATEGORY_DETAILS',
    GALLERY = 'GALLERY',
    IMPORT = 'IMPORT',
    IMPORT_STATUS = 'IMPORT_STATUS',
    TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW',
    GALLERY_PREVIEW = 'GALLERY_PREVIEW',
    FIGMA_IMPORT = 'FIGMA_IMPORT',
}
export type DesignSidebarItemType = Omit<SidebarItemType, 'to'> & {
    to: string[];
};

export type SidebarPermissions = {
    isImportDisabled: boolean;
    isGalleryDisabled: boolean;
};

export type GetDefaultSidebarItems = (permissions: SidebarPermissions) => {
    top: DesignSidebarItemType[];
    bottom: DesignSidebarItemType[];
};

export type SidebarProps = {
    sidebarItems: {
        top: DesignSidebarItemType[];
        bottom: DesignSidebarItemType[];
    };
    backButton?: {
        title: string;
        onClick: () => void;
    };
};
