import ProxyServerConnector from '~/services/connectors/ProxyServerConnector';
import { createQuery as createQueryGeneric } from '~/helpers/queryString';
import type { BrandColorPalette } from 'packages/store/brandColors/types';

const createQuery = (params) => `?${createQueryGeneric(params)}`;

class ColorPalettesService extends ProxyServerConnector {
    gateway: ProxyServerConnector;

    constructor(path = '/color-palettes') {
        super(path);
        this.gateway = new ProxyServerConnector(path, GATEWAY_PREFIX);
    }

    getPalettes() {
        return this.gateway
            .get<{ items: BrandColorPalette[]; totalItems: number }>(createQuery({ sort: ['date_created', 'DESC'] }))
            .then(({ status, json: { items: palettes, totalItems } }) => {
                return { status, json: { palettes, totalItems } };
            });
    }

    getPalette(id) {
        return this.gateway.get(`/${id}`).then(({ status, json }) => ({ status, palette: json }));
    }

    addPalette(name) {
        return this.gateway.post('', { name });
    }

    renamePalette(id, name) {
        return this.gateway.put(`/${id}`, { name });
    }

    deletePalette(ids) {
        return this.gateway.delete('', { ids });
    }
}

export default new ColorPalettesService();
