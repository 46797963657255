import React, { createContext, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    SpecificationExporter,
    ValidationManagerForMultiPage,
    VideoWebSpecificationParser,
    GuideManager,
    PreviewTypes,
    MultiPageVideoModel,
} from '@bynder-studio/render-core';
import { creativeModelFactory } from '@bynder-studio/render-web';
import useVirtualProperties from 'packages/hooks/useVirtualProperties';
import useArrowScrollBlocker from 'packages/hooks/useArrowScrollBlocker';
import useCopyPasteKeys from 'packages/pages/editor/useCopyPasteKeys';
import { catchResponseError } from 'packages/helpers/helpers';
import { customerIdSelector } from 'packages/store/user/user.selectors';
import { dataURItoArrayBuffer } from '~/helpers/helpers';
import { prepareDataForSave } from '~/common/editor/editor.bloc';
import useEditorUndoRedo from '~/hooks/useEditorUndoRedo';

import { getUserFonts } from '~/store/user/user.actions';
import defaultFontFile from '../../../../src/assets/fonts/SourceSansPro-Regular.ttf';
import useForceUpdate from '../hooks/useForceUpdate';
import ShotsCalculateService from 'packages/services/ShotsCalculate';
import debounce from '../helpers/debounce';

type Props = {
    template: any;
    fonts: any[];
};

const defaultFontBlob = dataURItoArrayBuffer(defaultFontFile);

export const EditorContext = createContext({});

export function EditorProvider({
    children,
    template,
    fonts,
    textStyles,
    contentProperties,
    type,
    creativeType,
    creativeId,
}: Props) {
    const forceUpdate = useForceUpdate();
    const dispatch = useDispatch();
    const data = useRef<any>({
        isPublishing: false,
        loading: true,
        creativeModel: undefined,
        assetLoader: undefined,
        creativeModelFactory: undefined,
        validationManager: undefined,
        undoRedoManager: undefined,
        manipulationRenderer: undefined,
        guideManager: undefined,
        type,
        creativeType,
    });
    const {
        exportIntegrations,
        toggleExportIntegration,
        changeExportValue,
        approvalEnabled,
        setApprovalEnabled,
        reviewers,
        addReviewer,
        removeReviewer,
        globalsTab,
        setGlobalsTab,
    } = useVirtualProperties({
        integrations: template.exportIntegrations || [],
        approvalEnabled: template.approvalEnabled || false,
        reviewers: template.reviewers || [],
    });
    const { canUndo } = useEditorUndoRedo(data.current.creativeModel);
    useArrowScrollBlocker();
    useCopyPasteKeys({
        creativeModel: data.current.creativeModel,
        manipulationRenderer: data.current.manipulationRenderer,
        creativeId,
    });
    const customerId = useSelector(customerIdSelector);

    const fontLoadErrorRetry = useCallback(() => {
        dispatch(getUserFonts(customerId));
    }, []);

    const calculateShots = useCallback(() => {
        if (!data.current.creativeModel) {
            return;
        }

        const { creativeModel } = data.current;
        const { height, width } = creativeModel.getDimensions();

        const rawCreativePage = SpecificationExporter.exportPage(creativeModel.getCurrentModel(), creativeType);
        const { elements } = prepareDataForSave(false, rawCreativePage.elements);
        const duration = creativeModel?.getPlaybackDuration()?.getDuration();

        ShotsCalculateService.calculate({
            height,
            width,
            duration,
            elements,
            pageId: Number(creativeModel.getCurrentPageId()),
        })
            .then(({ json, status }) => {
                if (status === 200) {
                    const shots = VideoWebSpecificationParser.parseShots(json);
                    creativeModel.replaceShots(shots);
                    forceUpdate();
                }
            })
            .catch(catchResponseError);
    }, [template]);

    useEffect(() => {
        creativeModelFactory(creativeType, {
            template,
            defaultFontBlob,
            fontFamilies: fonts,
            textStyles,
            contentProperties,
            containerDocument: document,
        }).then((creativeModelFactory) => {
            const creativeModel = creativeModelFactory.getCreativeModel();
            data.current.isPublishing = false;
            data.current.creativeModelFactory = creativeModelFactory;
            data.current.creativeModel = creativeModel;
            data.current.assetLoader = creativeModelFactory.getAssetLoader();
            data.current.validationManager = new ValidationManagerForMultiPage(
                creativeModel,
                creativeType,
                PreviewTypes.EDITOR,
            );
            data.current.guideManager = new GuideManager();
            data.current.loading = false;
            data.current.validationManager.validate();
            data.current.guideManager.setModel(creativeModel.getCurrentModel());
            forceUpdate();
        });

        return () => {
            if (!data.current) {
                return;
            }

            data.current.validationManager?.unsubscribe();
            data.current.manipulationRenderer?.canvasManipulation?.unsubscribeEvents();
        };
    }, [forceUpdate]);

    useEffect(() => {
        const { creativeModel } = data.current;

        if (!creativeModel) {
            return;
        }

        const elementsTreeUpdatedListener = debounce(() => {
            calculateShots();
        }, 10);

        if (creativeType === 'VIDEO') {
            creativeModel.on('elementsTreeUpdated', elementsTreeUpdatedListener);
            creativeModel.on('durationUpdated', elementsTreeUpdatedListener);
        }

        return () => {
            if (creativeType === 'VIDEO') {
                creativeModel.off('elementsTreeUpdated', elementsTreeUpdatedListener);
                creativeModel.off('durationUpdated', elementsTreeUpdatedListener);
            }
        };
    }, [data.current.creativeModel, calculateShots]);

    useEffect(() => {
        const { creativeModel, manipulationRenderer, guideManager, assetLoader } = data.current;

        if (!creativeModel || !manipulationRenderer || !guideManager || !assetLoader) {
            return;
        }

        const handleDeselection = () => manipulationRenderer.selectElement(null);
        const handleElementSelection = ({ element }) => {
            return manipulationRenderer.selectElement(element.id);
        };
        const handleCurrentSizeChange = () => {
            if (manipulationRenderer) {
                manipulationRenderer.zoomToFit();

                if (creativeType === 'VIDEO') {
                    manipulationRenderer.pausePlayback();
                }
            }

            guideManager.setModel(creativeModel.getCurrentModel());
        };
        const markDirty = guideManager.markDirty.bind(guideManager);
        const setFrame = guideManager.setFrame.bind(guideManager);

        const handleDimensionResize = () => {
            if (document.fullscreenElement) {
                return;
            }

            if (manipulationRenderer) {
                manipulationRenderer.calculateScaleRatio(true);
                manipulationRenderer.redraw();
            }
        };

        const onVisibilityChange = () => {
            if (document.visibilityState !== 'visible') {
                if ('getIsPlaying' in manipulationRenderer && manipulationRenderer.getIsPlaying()) {
                    manipulationRenderer.pausePlayback();
                }

                return;
            }

            manipulationRenderer.redraw();
        };

        window.addEventListener('resize', handleDimensionResize);
        document.addEventListener('visibilitychange', onVisibilityChange);
        creativeModel.on('dimensionUpdated', forceUpdate);
        creativeModel.on('elementRemoved', handleDeselection);
        creativeModel.on('elementCreated', handleElementSelection);
        creativeModel.on('dimensionUpdated', markDirty);
        creativeModel.on('elementRemoved', markDirty);
        creativeModel.on('elementCreated', markDirty);
        creativeModel.on('elementUpdated', markDirty);
        creativeModel.getEventEmitter().on('currentPageChange', handleCurrentSizeChange);
        manipulationRenderer.eventEmitter.on('frameUpdate', setFrame);
        assetLoader.eventEmitter.on('font.load.error', fontLoadErrorRetry);

        return () => {
            window.removeEventListener('resize', handleDimensionResize);
            document.removeEventListener('visibilitychange', onVisibilityChange);
            creativeModel.off('dimensionUpdated', forceUpdate);
            creativeModel.off('elementRemoved', handleDeselection);
            creativeModel.off('elementCreated', handleElementSelection);
            creativeModel.off('dimensionUpdated', markDirty);
            creativeModel.off('elementRemoved', markDirty);
            creativeModel.off('elementCreated', markDirty);
            creativeModel.off('elementUpdated', markDirty);
            creativeModel.getEventEmitter().off('currentPageChange', handleCurrentSizeChange);
            manipulationRenderer.eventEmitter.off('frameUpdate', setFrame);
            assetLoader.eventEmitter.off('font.load.error', fontLoadErrorRetry);
        };
    }, [data.current.manipulationRenderer, data.current.creativeModel, forceUpdate]);

    const initManipulationRenderer = useCallback(
        (canvasWrapper, currentFrame) => {
            const { creativeModelFactory, guideManager } = data.current;

            const manipulationRenderer = creativeModelFactory.getCreativeManipulationRenderer(canvasWrapper);
            manipulationRenderer.init().then(async () => {
                if (creativeType === 'VIDEO') {
                    await manipulationRenderer.setCurrentFrame(currentFrame);
                }

                data.current.manipulationRenderer = manipulationRenderer;
                forceUpdate();
            });

            if (guideManager) {
                guideManager.setHandler((xAxisSnapLines, yAxisSnapLines) => {
                    manipulationRenderer.setElementSnapLines(xAxisSnapLines, yAxisSnapLines);
                });
                manipulationRenderer.canvasManipulation.emitter.on('requestElementMove', (event) => {
                    guideManager.onElementChange('move', event);
                });
                manipulationRenderer.canvasManipulation.emitter.on('requestElementResize', (event) => {
                    guideManager.onElementChange('resize', event);
                });
                // manipulationRenderer.canvasManipulation.on('requestElementRotate', (event) =>
                //     guideManager.onElementChange('rotate', event),
                // );
            }
        },
        [forceUpdate],
    );

    const setIsPublishing = useCallback(
        (isPublishing) => {
            data.current.isPublishing = isPublishing;
            forceUpdate();
        },
        [forceUpdate],
    );

    if (data.current.loading) {
        return null;
    }

    const dimension = data.current.creativeModel.getDimensions();
    const width = dimension.getWidth();
    const height = dimension.getHeight();

    const value = {
        ...data.current,
        width,
        height,
        initManipulationRenderer,
        exportIntegrations,
        toggleExportIntegration,
        changeExportValue,
        approvalEnabled,
        setApprovalEnabled,
        reviewers,
        addReviewer,
        removeReviewer,
        canUndo,
        globalsTab,
        setGlobalsTab,
        setIsPublishing,
        frameRate:
            data.current.creativeModel instanceof MultiPageVideoModel
                ? data.current.creativeModel?.getPlaybackDuration()?.getFrameRate() || 25
                : null,
    };

    return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
}
