import { BaseMultiPageModel } from './BaseMultiPageModel';
import { ImageModel } from './ImageModel';

export class MultiPageImageModel extends BaseMultiPageModel<ImageModel> {
    createModel(): ImageModel {
        return new ImageModel();
    }

    getCopy(): MultiPageImageModel {
        const copy = new MultiPageImageModel();
        copy.copyFrom(this);

        return copy;
    }
}
