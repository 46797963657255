import type { IAssetsLoader } from '../../AssetLoader/IAssetsLoader';
import type { ICompositor } from '../../Compositor/ICompositor';
import { type ImageModel } from '../../Models/Models/ImageModel';
import { Dimension } from '../../Models/Shared/Dimension';
import { BaseRenderer } from '../BaseRenderer/BaseRenderer';

export class ThumbnailRenderer extends BaseRenderer {
    private thumbnailDimension: Dimension;
    private handleCompModelUpdate = () => this.redrawThumbnail();

    constructor(imageModel: ImageModel, assetLoader: IAssetsLoader, compositor: ICompositor) {
        super(imageModel, assetLoader, compositor);
        this.thumbnailDimension = new Dimension(200, 150);
        this.getCreativeModel().eventEmitter.on('requireCreativeUpdate', this.handleCompModelUpdate);
    }

    setThumbnailSize(width: number, height: number): void {
        this.thumbnailDimension = new Dimension(width, height);
    }

    async drawThumbnail() {
        return this.redrawThumbnail();
    }

    private async redrawThumbnail() {
        this.calculateAndSetScale();
        await this.drawCompModel(this.creativeModel.getCompModel());
        const dataUrl = this.getCompositor().getDataUrl();
        const dimension = this.getCompositor().getCompositorDimension();
        this.eventEmitter.emit('imageUpdate', {
            dataUrl,
            dimension,
        });
    }

    private calculateAndSetScale = () => {
        // current shot wrapper page
        const thumbAspectRatio = this.thumbnailDimension.getWidth() / this.thumbnailDimension.getHeight();
        // template page
        const templateDimension = this.creativeModel.getDimensions();
        const templateAspectRatio = templateDimension.getWidth() / templateDimension.getHeight();
        let scale = 1;
        let newDimension;

        if (templateAspectRatio < thumbAspectRatio) {
            const newWidth = templateAspectRatio * this.thumbnailDimension.getHeight();
            newDimension = new Dimension(newWidth, this.thumbnailDimension.getHeight());
            scale = newWidth / templateDimension.getWidth();
        } else {
            const newHeight = this.thumbnailDimension.getWidth() / templateAspectRatio;
            newDimension = new Dimension(this.thumbnailDimension.getWidth(), newHeight);
            scale = this.thumbnailDimension.getWidth() / templateDimension.getWidth();
        }

        this.getCompositor().setCompositorDimension(newDimension);
        this.getCompositor().setScale(scale);
    };
}
