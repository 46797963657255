import {
    BaseRenderer,
    type CompModel,
    type IAssetsLoader,
    type ICompositor,
    MultiPageVideoModel,
} from '@bynder-studio/render-core';
import type { IPlaybackRenderer, LoopInterval } from './IPlaybackRenderer';
import { Playback } from '../Playback/Playback';

export class PlaybackRenderer extends BaseRenderer<MultiPageVideoModel> implements IPlaybackRenderer {
    isPlaying = false;

    protected playback: Playback;

    constructor(
        videoModel: MultiPageVideoModel,
        assetLoader: IAssetsLoader,
        compositor: ICompositor,
        public loopInterval: LoopInterval,
    ) {
        super(videoModel, assetLoader, compositor);
        this.playback = new Playback(this, loopInterval);
    }

    async init(): Promise<void> {
        super.init();
        await this.playback.init();
    }

    setCurrentFrame(frameIndex: number) {
        return this.playback.setCurrentFrame(frameIndex);
    }

    getCurrentFrame(): number {
        return this.playback.getCurrentFrame();
    }

    getPlaybackDuration() {
        return this.playback.getPlaybackDuration();
    }

    startPlayback() {
        this.playback.startPlayback();
    }

    pausePlayback() {
        this.playback.pausePlayback();
    }

    getIsPlaying = () => {
        return this.playback.getIsPlaying();
    };

    redraw() {
        const compModel = this.creativeModel.getCompModel(this.playback.frameIndex);
        this.compositor.drawCompModel(compModel);
    }

    drawCompModel(compModel: CompModel): Promise<boolean> {
        return this.playback.drawCompModel(compModel);
    }
}
